import Vue from 'vue'

export default {
  // /////////////////////////////////////////////
  // OMNI-CHANNEL
  // /////////////////////////////////////////////

  UPDATE_OMNICHANNEL_FLOWS(state, payload) {
    state.OmniChannelFlows = payload.map(item => Object.seal(item));
  },

  UPDATE_ALLOWEDITEMS(state, payload) {
    state.allowedMenuItems = payload;
  },

  //CHAT
  REGISTER_CHAT_SUBSCRIBER_LOG_MAP(state, { key, value }) {
    Vue.set(state.chatSubscribersMap, key, value);
  },

  RESET_CHAT_SUBSCRIBER_LOG_MAP(state) {
    state.chatSubscribersMap = {};
  },

  UPDATE_CHAT_SETTINGS(state, payload) {
    state.chatSettings = payload;
  },

  UPDATE_PAGING_MAP(state, { account_id, channel, paging_url }) {
    Vue.set(state.chatConversationsPagingMap, account_id, {
      paging_url,
      channel
    });
  },

  UPDATE_FILTERED_ACCOUNTS(state, payload) {
    if (!Array.isArray(payload)) { return false; }
    state.filteredAccounts = payload;
  },

  RESET_CHATLOG_MESSAGES(state) {
    state.chatLogMessages = {}
  },

  REGISTER_CHATLOG_MESSAGE(state, { key, value }) {
    Vue.set(state.chatLogMessages, key, value);
  },

  UPDATE_CHATLOG_MESSAGES(state, payload) {
    state.chatLogMessages = payload;
  },

  REMOVE_CHATLOG_TMP_MESSAGES(state) {
    return false;
    /*for (const conversationKey of Object.keys(state.chatLogMessages)) {
      const conversation = state.chatLogMessages[conversationKey];
      if (conversation.temporary === true) {
        Vue.delete(state.chatLogMessages, conversationKey);
      }
    }*/
  },

  UPDATE_SELECTED_SUBSCRIBER(state, payload) {
    if (payload?.chat_agent) {
      payload.chat_agent = String(payload?.chat_agent)
    } else {
      payload.chat_agent = "none"
    }
    state.selectedSubscriber = payload;
  },

  UPDATE_SELECTED_SUBSCRIBER_DATA(state, payload) {
    state.selectedSubscriberData = payload;
  }

}
