<template>
    <vs-card actionable class="cardx" ref="motherCard">
        <!--Product Media Here-->
            <div class="relative" slot="media" @click="goToProduct">
                <img
                    :src="productImage" 
                    class="responsive"
                    @loaded="fixMediaHeight"
                />
            </div>
        <!--Product Title Here-->
            <div @click="goToProduct">
                <h5>{{productData.product_name}}</h5>
            </div>

        <!--product Price-->
            <div>
                <h3 class="font-bold" v-html="ecom.getPrice()"></h3>
            </div>

            <div v-if="ecom.currentStock()">
                <span v-if="productData.stock_display==1">{{ecom.currentStock()}} Item(s) In Stock</span>
                <span v-else class="text-success">Available</span>
            </div>
            <div v-else>
                <span class="text-danger">Not Available</span>
            </div>

        <!--product Reviews-->
            <div class="mb-6 flex justify-start items-center">
                <template v-if="productData.show_review=='1' && !isNaN(ecom.averageRating())">
                    <star-rating class="mr-3" :show-rating="false" :rating="Math.ceil(ecom.averageRating())" read-only :star-size="15"></star-rating> ({{productData.reviews.length}})
                </template>
            </div>

        <!--Product Description Here-->
            <div class="content-max-height" v-html="productData.product_description"></div>

        <!--Product Action Buttons Here-->
            <div slot="footer">
                <vs-button :disabled="ecom.isDisabled()" v-if="!ecom.isInCart()" class="w-full mb-2" @click="addToCart">{{productData.has_variant==1 ? 'VIEW OPTIONS' : $oChain(storeData,'misc_config','atc') ? $oChain(storeData,'misc_config','atc') : "ADD TO CART"}}</vs-button>
                <vs-button :disabled="ecom.isDisabled()" v-else color="success" class="w-full mb-2" @click="$nuxt.$router.push('/cart')">{{$oChain(storeData,'misc_config','view_cart') ? $oChain(storeData,'misc_config','view_cart') : "VIEW CART"}}</vs-button>
                <vs-button :disabled="ecom.isDisabled()" @click="buyNow" color="danger" class="w-full mb-2">{{$oChain(storeData,'misc_config','buy_now') ? $oChain(storeData,'misc_config','buy_now') : "BUY NOW"}}</vs-button>
            </div>

    </vs-card>  
</template>
<script>
    import ProductCardMixins from "./cartMixins.js"
    export default {
        mixins: [ProductCardMixins]
    }
</script>
<style lang="scss">
    .content-max-height {
        overflow: hidden;
        text-overflow: ellipsis!important;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        max-height: 100px;
    }
</style>