import initMMIOaxios from "@/utils/axios"

const landing_pages = {
    async getLandingPages({ commit,state,dispatch }){
        var payload = await get_landing_pages({user_id: state.AppActiveUser.user_id})
        commit('UPDATE_LANDING_PAGES', payload)
		dispatch("executeUpdateActualUsageLog", {
			moduleName: "LANDING_PAGE_BUILDER_LIMIT_ON_NUMBER_OF_LANDING_PAGES_A_USER_CAN_CREATE",
			data: payload.filter( page => page.type != 0)
		})
    },
}

export default landing_pages

// HELPER FUNCTIONS

async function get_landing_pages(payload) {
	const axios = await initMMIOaxios()
	const {data} = await axios.post("/apis_landing_page/get_landing_pages", payload)
	return data;
}