
import autocomment from "./states/autocomment.js"
import ecommerce from "./states/ecommerce.js"
import gmb from "./states/gmb.js"
import integrations from "./states/integrations.js"
import messengerIG from "./states/messengerIG.js"
import notifictions from "./states/notifictions.js"
import omnichannel from "./states/omnichannel.js"
import pageBuilder from "./states/pageBuilder.js"
import sms from "./states/sms.js"
import system from "./states/system.js"
import users from "./states/users.js"
import whatsapp from "./states/whatsapp.js"
import telegram from "./states/telegram.js"
import utilities from "./states/utilities.js"
import common from "./states/common.js"
import webWidget from "./states/webWidget.js"

const mmioStates = {
    ...autocomment,
    ...ecommerce,
    ...integrations,
    ...messengerIG,
    ...notifictions,
    ...omnichannel,
    ...pageBuilder,
    ...sms,
    ...system,
    ...users,
    ...gmb,
    ...whatsapp,
    ...telegram,
    ...utilities,
    ...common,
    ...webWidget,
    //UI NAV
    popperToggle: null,
    //COMMON
    suitCases: [],
    userAccessLevels: [],
}

export default mmioStates