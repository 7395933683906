import store from "@/store"
import Vue from 'vue'

export default {
    // /////////////////////////////////////////////
    // Flow Builder
    // /////////////////////////////////////////////

    BOTSHEETS(state, val) {
        try {
            state.BotSheets = val.sort((a, b) => b.id - a.id).map(item => Object.seal(item));
            return true;
        } catch (e) { }
        state.BotSheets = val
    },

    FLOWS(state, val) {
        let output = [];
        output = Object.values(val).sort((a, b) => b.id - a.id).map(template => {
            try {
                template.name = decodeURIComponent(template.name);
                Object.seal(template);
            } catch (e) { __console.log(e) }
            return template;
        })
        state.Flows = output
    },

    TEMPLATES(state, val) {
        try {
            state.Templates = val.sort((a, b) => b.id - a.id).map(item => Object.seal(item));
            return true;
        } catch (e) { }
        state.Templates = val
    },

    USER_GROWTH_TOOLS(state, val) {
        if (!(val instanceof Object)) { return }
        for (const key in val) {
            Vue.set(state.UserGrowthTools, key, val[key].map(item => Object.seal(item)));
        }
    },

    USER_POSTBACKS(state, val) {
        state.UserPostBacks = Object.values(val).map(item => Object.seal(item));
    },

    CONTACT_TAGS(state, val) {
        state.ContactTags = Object.values(val).map(item => Object.seal(item));
    },

    USER_MESSENGER_BROADCASTS(state, val) {
        state.MessengerBroadcast = Object.values(val).sort((a, b) => b.id - a.id).map(item => Object.seal(item));
    },

    CURRENT_CONTEXT_MENU(state, val) {
        state.CurrentContextMenu = val
    },

    CONTEXT_MENU_ANCESTRY_RAW(state, val) {
        var out = {}
        val.forEach((page, k) => {
            try {
                out[page.page_id] = {}
                out[page.page_id].id = uuidv4()
                out[page.page_id].type = "root"
                out[page.page_id].children = []
                out[page.page_id].locale = page.locale
                out[page.page_id].composer_input_disabled = (page.composer_input_disabled == "1") ? true : false;
                out[page.page_id].menu_id = page.id
                out[page.page_id].status = page.status
                var json = JSON.parse(page.item_json)
                if (typeof json.call_to_actions != 'undefined') {
                    Object.entries(json.call_to_actions).map((_child) => {
                        var child = _child[1]
                        child = transformToAncestry(child)
                        out[page.page_id].children.push(child)
                    })
                }
            } catch (e) {

            }
        })
        if (out) {
            state.AncestryRecordsMultiPage = out
        }
    },

    CONTEXT_MENU_ANCESTRY(state, val) {
        __console.log("CONTEXT_MENU_ANCESTRY", val)
        var temp = ___(state.AncestryRecordsMultiPage);
        temp[val.page_id] = val.value
        state.AncestryRecordsMultiPage = temp
    },

    UPDATE_ACTIVE_WEBVIEWFORM(state, val) {
        state.$WebviewForm = val
    },

    UPDATE_ACTIVE_PAGE(state, val) {
        try {
            if (Object.entries(val).length) {
                state.ActivePage = val;
                store.dispatch("getFlowsAction");
                return val
            }
        } catch (e) { }
        state.ActivePage = {}
        return false
    },

    UPDATE_PAGE_OPTIONS(state, val) {
        state.PageOptions = val
    },

    UPDATE_PAGE_OPTIONS_SINGLE(state, val) {
        var key = val.id
        if (state.PageOptions?.[key] instanceof Object) {
            if (!val.is_deleted) {
                val = { ...state.PageOptions[key], ...val }
            } else if (val.is_deleted === true) {
                val = {}
            }
        }
        Vue.set(state.PageOptions, key, Object.seal(val))
    },

    UPDATE_MESSENGER_SEQUENCES(state, val) {
        state.MessengerSequences = (Array.isArray(val) ? val : []).map(item => Object.seal(item));
    },

    UPDATE_CUSTOM_VARIABLES(state, val) {
        state.CustomVariables = (Array.isArray(val) ? val : []).map(item => Object.seal(item));
    },

    UPDATE_OTN(state, val) {
        state.OTN = (Array.isArray(val) ? val : []).map(item => Object.seal(item));
    },

    UPDATE_PERSISTENTMENU(state, page) {
        const { key, val } = page
        Vue.set(state.PersistentMenuCache, key, val)
    },

    CLOSE_PERSISTENTMENU_SETTINGS(state, value) {
        state.persistentMenuItemSettingsClose = value
    },

    ADD_QR_MAP(state, { postback_id, qr_url }) {
        Vue.set(state.PostbackQrMap, postback_id, qr_url);
    },

    TOGGLE_FLOW_READONLY(state, value) {
        state.IsFlowReadOnly = value;
    },

    UPDATE_FLOW_STATS(state, { postback_id, stats }) {
        Vue.set(state.FlowStats, postback_id, stats);
    },

    UPDATE_RECURRING_CAMPAIGNS(state, payload) {
        state.RecurringCampaigns = (Array.isArray(payload) ? payload : []).map(item => Object.seal(item));
    },

    RESET_FLOW_STATS(state) {
        state.FlowStats = {}
    },

    UPDATE_FLOW_ENABLE_STATUS(state, { id, value }) {
        const index = state.Flows.findIndex(f => f.id == id)
        if (index < 0) return
        const flow = state.Flows[index]
        flow.is_enabled = value
        Vue.set(state.Flows, index, flow)
    },
}

function transformToAncestry(json) {

    var obj = {}

    if (typeof json.id != 'undefined') {
        obj.id = json.id
    } else {
        obj.id = uuidv4()
    }

    if (typeof json.title != 'undefined') {
        obj.label = json.title
    } else {
        obj.label = ""
    }

    if (typeof json.data != 'undefined') {
        obj.data = json.data
    } else {
        obj.data = {}
        switch (json.type) {
            case "web_url":
                obj.data.type = "website"
                obj.data.value = json.url
                break;
            case "postback":
                obj.data.type = "flow"
                obj.data.value = json.payload
                break;
            case "nested":
                obj.children = []
                obj.data.type = "sub-menu"
                if (typeof json.call_to_actions != 'undefined') {
                    Object.entries(json.call_to_actions).map((_child) => {
                        var child = _child[1]
                        child = transformToAncestry(child)
                        obj.children.push(child)
                    })
                }
                break;
        }
    }

    return obj

}