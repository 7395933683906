export default {
    // /////////////////////////////////////////////
    // INTEGRATIONS
    // /////////////////////////////////////////////

    UPDATE_USER_INTEGRATIONS(state, val) {
        state.userIntegrations = (Array.isArray(val) ? val : []).map(item => Object.seal(item));
    },

    UPDATE_CHATBOTINT_EMAILLISTS(state, val) {
        val.forEach(typedLists => {
            if (state.ChatbotIntegrationEmailLists?.[typedLists.type]) {
                state.ChatbotIntegrationEmailLists[typedLists.type] = typedLists.value
            }
        })
    },
}