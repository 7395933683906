export default [
    // =============================================================================
    // SMS Marketing
    // =============================================================================     
    {
        path: '/sms-marketing',
        name: 'SMS Dashboard',
        component: () => import( /* webpackChunkName: "SmsDashboard" */ '@/views/SMS/SmsDashboard.vue'),
        slug: 'sms_dashboard',
        category: "SMS Marketing",
    },
    {
        path: '/sms-marketing/broadcasting',
        name: 'SMS Broadcasting',
        component: () => import( /* webpackChunkName: "SMSBroadcastCreate" */ '@/views/SMS/Broadcast/SMSBroadcastCreate.vue'),
        slug: 'sms_broadcast',
        category: "SMS Marketing",
    },
    {
        path: '/sms-marketing/broadcasting-dashboard',
        name: 'SMS Broadcast Dashboard',
        component: () => import( /* webpackChunkName: "SMSBroadcastAll" */ '@/views/SMS/Broadcast/SMSBroadcastAll.vue'),
        slug: 'sms_broadcast',
        category: "SMS Marketing",
    },
    {
        path: '/sms-marketing/sender-ids',
        name: 'Sender IDs',
        component: () => import( /* webpackChunkName: "SenderIDs" */ '@/views/SMS/SenderIDs.vue'),
        slug: 'my_sender_ids',
        category: "SMS Marketing",
    },
    {
        path: '/sms-marketing/sms-sequence',
        name: 'SMS Sequence',
        component: () => import( /* webpackChunkName: "SMSsequence" */ '@/views/SMS/Sequence/SMSsequence.vue'),
        slug: 'sms_sequence',
        category: "SMS Marketing",
    },
    {
        path: '/sms-marketing/sms-sequence-create',
        name: 'SMS Sequence Create',
        component: () => import( /* webpackChunkName: "SMSsequenceCreate" */ '@/views/SMS/Sequence/SMSsequenceCreate.vue'),
        slug: 'sms_sequence',
        category: "SMS Marketing",
    },
    {
        path: '/sms-marketing/sms-sent-history',
        name: 'SMS Sent History',
        component: () => import( /* webpackChunkName: "SMSSentHistory" */ '@/views/SMS/SMSSentHistory.vue'),
        slug: 'sms_sent_history',
        category: "SMS Marketing",
    },    
]