export default [
    // =============================================================================
    // Ecommerce
    // =============================================================================
    {
        path: '/ecommerce',
        name: 'Ecommerce',
        component: () => import( /* webpackChunkName: "EcommerceHome" */ '@/views/Ecommerce/EcommerceHome.vue'),
        slug: 'ecom_dashboard',
        category: "Ecommerce",
    },
    {
        path: '/ecommerce/create-store',
        name: 'Create Store',
        component: () => import( /* webpackChunkName: "EcommerceCreateStore" */ '@/views/Ecommerce/Stores/EcommerceCreateStore.vue'),
        slug: 'ecom_dashboard',
        category: "Ecommerce",
    },
    {
        path: '/ecommerce/orders',
        name: 'Orders',
        component: () => import( /* webpackChunkName: "EcommerceAddOrder" */ '@/views/Ecommerce/Orders/EcommerceAddOrder.vue'),
        slug: 'orders',
        category: "Ecommerce",
    },
    {
        path: '/ecommerce/products',
        name: 'Products',
        component: () => import( /* webpackChunkName: "EcommerceAllProduct" */ '@/views/Ecommerce/Products/EcommerceAllProduct.vue'),
        slug: 'products',
        category: "Ecommerce",
    },
    {
        path: '/ecommerce/products/create',
        name: 'Create Product',
        component: () => import( /* webpackChunkName: "EcommerceProductsCreate" */ '@/views/Ecommerce/Products/EcommerceProductsCreate.vue'),
        slug: 'products',
        category: "Ecommerce",
    },
    {
        path: '/ecommerce/coupons',
        name: 'Coupons',
        component: () => import( /* webpackChunkName: "EcommerceAllCoupons" */ '@/views/Ecommerce/Coupons/EcommerceAllCoupons.vue'),
        slug: 'coupons',
        category: "Ecommerce",
    },
    {
        path: '/ecommerce/categories',
        name: 'Categories',
        component: () => import( /* webpackChunkName: "EcommerceAllCategory" */ '@/views/Ecommerce/Categories/EcommerceAllCategory.vue'),
        slug: 'categories',
        category: "Ecommerce",
    },
    {
        path: '/ecommerce/shipping',
        name: 'Shipping',
        component: () => import( /* webpackChunkName: "EcommerceShippingAll" */ '@/views/Ecommerce/Shipping/EcommerceShippingAll.vue'),
        slug: 'shipping',
        category: "Ecommerce",
    },
    {
        path: '/ecommerce/reports',
        name: 'Ecom Reports',
        component: () => import( /* webpackChunkName: "EcommerceReports" */ '@/views/Ecommerce/Reports/EcommerceReports.vue'),
        slug: 'ecommerce_reports',
        category: "Ecommerce",
    },
    {
        path: '/ecommerce/notifications',
        name: 'Shop Notifications',
        component: () => import( /* webpackChunkName: "EcommerceNotifications" */ '@/views/Ecommerce/Notifications/EcommerceNotifications.vue'),
        slug: 'ecommerce_notifications',
        category: "Ecommerce",
    },
    {
        path: '/ecommerce/notifications/create',
        name: 'Shop Notifications Create',
        component: () => import( /* webpackChunkName: "EcommerceNotificationsCreate" */ '@/views/Ecommerce/Notifications/EcommerceNotificationsCreate.vue'),
        slug: 'ecommerce_notifications',
        category: "Ecommerce",
    },
    {
        path: '/ecommerce/checkout-settings',
        name: 'Checkout Settings',
        component: () => import( /* webpackChunkName: "CheckoutSettings" */ '@/views/Ecommerce/CheckoutSettings/CheckoutSettings.vue'),
        slug: 'checkout_settings',
        category: "Ecommerce",
    },
    {
        path: '/ecommerce/designer',
        name: 'Shop Designer',
        component: () => import( /* webpackChunkName: "ShopDesigner" */ '@/views/Ecommerce/ShopDesigner/ShopDesigner.vue'),
        slug: 'shop_designer',
        category: "Ecommerce",
    },
    {
        path: '/ecommerce/upsells',
        name: 'Shop Upsells',
        component: () => import( /* webpackChunkName: "Upsells" */ '@/views/Ecommerce/Upsells/Upsells.vue'),
        slug: 'shop_upsells',
        category: "Ecommerce",
    },
    {
        path: '/ecommerce/upsells/create',
        name: 'Shop Upsells Create',
        component: () => import( /* webpackChunkName: "Upsells" */ '@/views/Ecommerce/Upsells/Components/UpsellDownsell.vue'),
        slug: 'shop_upsells_create',
        category: "Ecommerce",
    },
    {
        path: '/page-builder/create',
        name: 'Page Builder Editor',
        component: () => import( /* webpackChunkName: "PageBuilder" */ '@/views/Ecommerce/PageBuilder/PageBuilder.vue'),
        slug: 'page_builder',
        category: "Ecommerce",
    },
    {
        path: '/page-builder',
        name: 'Page Builder Dashboard',
        component: () => import( /* webpackChunkName: "PagesDashboard" */ '@/views/Ecommerce/PageBuilder/PagesDashboard.vue'),
        slug: 'page_builder_dashboard',
        category: "Ecommerce",
    },
    {
        path: '/funnel-builder',
        name: 'Funnel Builder',
        component: () => import( /* webpackChunkName: "FunnelBuilder" */ '@/views/FunnelBuilder/FunnelBuilder.vue'),
        slug: 'funnel_builder',
        category: "Ecommerce",
    },
    {
        path: '/ecommerce/woo-plugins',
        name: 'WooCommerce Plugins',
        component: () => import( /* webpackChunkName: "WooCommercePluginsDashboard" */ '@/views/Ecommerce/WooCommercePlugins/Dashboard.vue'),
        slug: 'woo_plugins',
        category: "Ecommerce",
    },
]
