import mmioStates from '@/store/mmio-states.js';
import Swal from 'sweetalert2';

export default {
// /////////////////////////////////////////////
// User/Account
// /////////////////////////////////////////////

	// Updates user info in state and localstorage

	UPDATE_USER_INFO(state, payload) {

		// Get Data localStorage
		var userInfo = {}
		try{
			userInfo = state.AppActiveUser.length?state.AppActiveUser:JSON.parse(localStorage.getItem("userInfo")).length?JSON.parse(localStorage.getItem("userInfo")):{}
		}catch(e){}

		// <-- Update the Active user details
			state.AppActiveUser = ___({...userInfo, ...payload})
			__console.log("state.AppActiveUser", state.AppActiveUser)
			localStorage.setItem("userInfo", JSON.stringify(userInfo))
			// Update the Active user details -->

		// <-- Update the Active User Pages
			var pages = []
			const active_fb_account = state.AppActiveUser?.active_fb_account || state.AppActiveUser?.facebook_rx_fb_user_info
			if(active_fb_account){
				pages = state.userPagesAll.filter(page => page.facebook_rx_fb_user_info_id == active_fb_account)
			}
			state.UserPages = pages
			//state.ActivePage = {}
			// Update the Active User Pages -->

		// <-- Update the Active User Groups
			var output = []
			if(active_fb_account){
				output = state.userGroupsAll.filter(group => group.facebook_rx_fb_user_info_id == active_fb_account)
			}
			state.userGroups = output
		// Update the Active User Groups -->
		try{
			if(localStorage.getItem("new_account_verify")){
				if(state.AppActiveUser.package_info.package_name !== localStorage.getItem("new_account_verify")){
					Swal.fire("New Package Activated!", `Your account is now on ${state.AppActiveUser.package_info.package_name} Package!`, "success" )
					delete window.getUserAccount;
					localStorage.removeItem("new_account_verify")
				}
			}
		}catch(e){}

		window.userAccountMounted = true;
		__console.log("Load user account...", state.AppActiveUser)

	},

	UPDATE_USER_NOTIFICATIONS(state, payload){
		state.UserNotifications = payload
	},

	LOGOUT(state, that=false){
		state.AppActiveUser = {}
		Object.keys(mmioStates).forEach( key => {
			if(Array.isArray(state[key])){
				state[key] = []
			}else if(state[key] instanceof Object){
				state[key] = {}
			}else if(typeof state[key] == "boolean"){
				state[key] = false
			}else{
				state[key] = ""
			}
		});
		localStorage.removeItem("UserLoggedOnFacebook")
		//Delete localStorages for Flow and Email Builders
		var FlowBuilderData = Object.keys(localStorage).filter((key) => /FlowBuilderData/.test(key));
		var page_json_ = Object.keys(localStorage).filter((key) => /page_json_/.test(key));
		var page_html = Object.keys(localStorage).filter((key) => /page_html/.test(key));
		var ChatsequenceData = Object.keys(localStorage).filter((key) => /ChatsequenceData/.test(key));
		let getStarted = Object.keys(localStorage).filter((key) => /get_started_enabled_/.test(key));
		let iceBreakers = Object.keys(localStorage).filter((key) => /get_icebreaker_enabled_/.test(key));
		let FlowPowerShare = Object.keys(localStorage).filter((key) => /FlowPowerShare/.test(key));
		let EmailFlowBuilderCache = Object.keys(localStorage).filter((key) => /EmailFlowBuilderCache/.test(key));
		[ ...EmailFlowBuilderCache, ...FlowPowerShare, ...ChatsequenceData, ...page_html, ...page_json_, ...FlowBuilderData, ...getStarted, ...iceBreakers].forEach( key => {
			localStorage.removeItem(key)
		});
    localStorage.removeItem("browser_uid")
    localStorage.removeItem("userInfo")
		if(that!==false){
			const loginRoute = that.$router.resolve("/home/login");
			window.location.href = loginRoute.href
		}
	},

	UPDATE_USER_GROUPS(state, {data}){
		if(!data){return}
		state.userGroupsAll = Object.values(data).map( item => Object.seal(item))
	},

	UPDATE_ACTIVE_USER_GROUPS(state){
		var output = []
		const active_fb_account = state.AppActiveUser?.active_fb_account || state.AppActiveUser?.facebook_rx_fb_user_info
		if(active_fb_account){
			output = state.userGroupsAll
			.filter(group => group.facebook_rx_fb_user_info_id == active_fb_account)
		}
		state.userGroups = output.map( item => Object.seal(item))
	},

	UPDATE_USER_ACCOUNTS(state, {data}){
		if(!data?.length){data = []}
		let temp = Object.values(data).map( item => Object.seal(item))
		if(!state.AppActiveUser.active_fb_account && temp?.[0]?.id){
			state.AppActiveUser.active_fb_account = temp?.[0]?.id
		}
		state.userAccounts = temp
	},

	UPDATE_PW_RESET_ACCESS_TOKEN(state, payload){
		state.accessTokenConfirmed = payload
	},

	USER_PAGES(state, val) {
		state.userPagesAll = (Array.isArray(val)?val:[]).map( item => Object.seal(item))
	},

	UPDATE_ACTIVE_USER_PAGES(state){
		var output = []
		const active_fb_account = state.AppActiveUser?.active_fb_account || state.AppActiveUser?.facebook_rx_fb_user_info
		if(active_fb_account){
			output = state.userPagesAll
			.filter(page => page.facebook_rx_fb_user_info_id == active_fb_account)
			.map( item => Object.seal(item))
		}
		state.UserPages = output
	},

	PACKAGES(state, val) {
		state.Packages = (Array.isArray(val)?val:[]).map(item => {item.is_yearly = false; return item;}).map( item => Object.seal(item))
	},

	MODULES(state, val) {
		state.Modules = (Array.isArray(val)?val:[]).map( item => Object.seal(item))
	},

	USAGE_LOG(state, val){
		if(!val?.status){return}
		state.UsageLog = {status: val.status, data: val.data.map( item => Object.seal(item))}
	},

	TRANSACTION_HISTORY(state, val){
		state.TransactionHistory = (Array.isArray(val)?val:[]).map( item => Object.seal(item))
	},

	UPDATE_USER_TICKETS(state, val){
		state.Tickets = (Array.isArray(val)?val:[]).map( item => Object.seal(item))
	},

	UPDATE_SUB_USERS(state, val){
		state.SubUsers = (Array.isArray(val)?val:[]).map( item => Object.seal(item))
	},

	UPDATE_USER_PUSH_EVENTS(state, val){
		state.userPushEvents = val
	},

	UPDATE_MANUAL_PAY_EXTRA_FEES(state, val){
		state.extraFeeManualPay =val
	},

	UPDATE_USER_ACCESSLEVELS(state, payload){
		state.userAccessLevels = (Array.isArray(payload)?payload:[]).map( item => Object.seal(item))
	},
}
