export default {
	//PAGE BUILDER GETTERS
	GetDomObjectContent(state) {
		return function (id) {
			var output = {}
			try {
				if (state.domObject.entities.contents[id]) {
					return state.domObject.entities.contents[id]
				} else if (state.domObject.entities.root[id]) {
					__console.log("FOUND ROOT!", id)
					return state.domObject.entities.root[id]
				}
			} catch (e) { }
			return
		}
	},

	GetDomObjectContentViewer(state) {
		return function (id) {
			try {
				if (state.domObjectViewer.entities.contents[id]) {
					return state.domObjectViewer.entities.contents[id]
				} else if (state.domObjectViewer.entities.root[id]) {
					__console.log("[Viewer]FOUND ROOT!", id)
					return state.domObjectViewer.entities.root[id]
				}
			} catch (e) { }
			return
		}
	},    
}