<script>

    const flipCountdown = () => import(/* webpackChunkName: "countdown" */ '@/components/FlipCountDown.vue')
    const Products = () => import(/* webpackChunkName: "Products" */ './Parts/Products.vue')
    const mmioForm = () => import(/* webpackChunkName: "mmioForm" */ './Parts/Form.vue')
    import ProductsCarousel from "./Parts/ProductsCarousel.vue"
    import offerHandlers from '@/views/Ecommerce/Utils/EcomMixins/OffersHandlers'
    import VueSwal from '@/components/VueSwal'
    import contentGenerator from '@/views/Ecommerce/PageBuilder/Parts/contentGenerator'
    export default {
      name: "Atomic",
      components: {
        flipCountdown,
        Products,
        ProductsCarousel,
        mmioForm
      },
      props: {
        modelKey: {
          type: String,
          default: ""
        },
        developmentMode: {
          type: Boolean,
          default: true
        },
        parentKey: null
      },
      data(){
        return {
          modelClone: {},
          animations: []
        }
      },
      render: function (createElement) {

          const that = this

          var modelClone = this.$clone(this.model)
          var duplicateClone = ___(modelClone)
          var copyFxn = {
            click: function(){
              _duplicateAction(that.parentKey, duplicateClone)
            }
          }

          let deleteFxn = {
            click: function(){
              _deleteModel(duplicateClone)
            }
          }

          //modelClone.property.props.model = this.$clone(this.model)
          var btnClass = []
          var defaultContent = []
          btnClass.push("w-full")

          if(this.developmentMode){
            btnClass.push("--type-content mb-3")
            defaultContent.push(
              createElement("div", {class: "--type-marker justify-center items-center no-open"}, [
                createElement("span", {class:"bg-royalblue-400"}, "Content"),
                createElement("span", {
                  class:"duplicator bg-danger text-md cursor-pointer no-open",
                  domProps:{
                    title:"Delete"
                  },
                  on: deleteFxn
                }, [
                  createElement("i", {class: "las la-trash-alt no-open"})
                ]),
                createElement("span", {
                  class:"duplicator bg-warning text-md cursor-pointer no-open",
                  domProps:{
                    title:"Duplicate"
                  },
                  on: copyFxn
                }, [
                  createElement("i", {class: "las la-copy duplicator no-open"})
                ]),
              ])
            )
            defaultContent.push(
              createElement("div", {class: "w-full add-resource-wrap flex justify-center items-center"}, [
                createElement("span", {
                  class:"add-resource text-xs",
                  on: {
                    click: this.addResource
                  }
                }, "Add Content")
              ])
            )
          }

          let onEvents = {};
          if(modelClone?.property?.on){
            onEvents = modelClone?.property?.on
            delete modelClone.property.on
            delete modelClone.property.nativeOn
          }

          this.animations = modelClone.property.class.filter(item => item.includes("animate") )

          modelClone.property.class = modelClone.property.class.filter(item => !item.includes("animate") )

          switch(modelClone.tag){
              case "img":

                  if(modelClone?.property?.props?.align){
                    btnClass.push(`flex`)
                    switch(modelClone?.property?.props?.align){
                      case "left":
                        btnClass.push(`justify-start`)
                        break;
                      case "right":
                        btnClass.push(`justify-end`)
                        break;
                      case "center":
                      default:
                        btnClass.push(`justify-center`)
                        break;                        
                    }
                  }

                  var attrs = modelClone.property.attrs
                  delete modelClone.property.attrs

                  defaultContent.push(
                      createElement('img', {
                        class: "responsive",
                        attrs: attrs
                      })
                  )

                  return createElement('div', {
                    class: btnClass,
                    on: onEvents
                  }, [createElement('div', modelClone.property, defaultContent)])
                    
              break;
              case "button":

                  if(modelClone?.property?.props?.align){
                    btnClass.push(`text-${modelClone?.property?.props?.align}`)
                  }

                  if(modelClone.property.props.action && !this.developmentMode){
                    if(modelClone.property.props.action.isUrl){
                      if(modelClone.property.props.action.url.includes("{{accept_offer}}")){
                          modelClone.property.on = {
                            click: function(){
                              scroll({
                                  top: 0,
                                  behavior: "smooth"
                              });
                            }
                          }
                      }else if(modelClone.property.props.action.url.includes("{{reject_offer}}")){
                          modelClone.property.on = {
                            click: function(e){
                              e.preventDefault();
                              _wait("Rejecting Special Offer...")
                              that.proceedToNextOffer("reject")
                            }
                          }
                      }else{
                        modelClone.property.props.href = modelClone.property.props.action.url
                        modelClone.property.props.target = modelClone.property.props.action.mode
                      }
                    }else{
                      var path = modelClone.property.props.action.to.path
                      if(typeof path == "string"){
                        modelClone.property.props.to = {name: path }
                      }else{
                        modelClone.property.props.to = {name: "Ecom Page",  params: {path: path}}
                      }
                    }
                  }
                  delete modelClone.property.props.action

                  defaultContent.push(createElement("vs-button", modelClone.property))
                  return createElement('div', {
                    class: btnClass,
                    on: onEvents
                  }, defaultContent)

              break;
              case "timer":

                  if(modelClone?.property?.props?.align){
                    btnClass.push(`flex`)
                    switch(modelClone?.property?.props?.align){
                      case "left":
                        btnClass.push(`justify-start`)
                        break;
                      case "right":
                        btnClass.push(`justify-end`)
                        break;
                      case "center":
                      default:
                        btnClass.push(`justify-center`)
                        break;                        
                    }
                  }

                  modelClone.property.props.deadline = moment().add(modelClone.property.props.deadline, "minute").format("YYYY-MM-DD HH:mm:ss")
                  if(!this.developmentMode){
                    modelClone.property.on = {
                      timeElapsed: function(){
                        const orderData = that.$store.state.__finalOrderData
                        that.$router.push({
                            name: "Ecom Thankyou",
                            params: orderData,
                        });
                      }
                    }
                  }

                  defaultContent.push(createElement("flipCountdown", modelClone.property))
                  return createElement('div', {
                    class: btnClass,
                    on: onEvents
                  }, defaultContent)

              break;
              case "products":

                  if(modelClone?.property?.props?.align){
                    btnClass.push(`flex`)
                    switch(modelClone?.property?.props?.align){
                      case "left":
                        btnClass.push(`justify-start`)
                        break;
                      case "right":
                        btnClass.push(`justify-end`)
                        break;
                      case "center":
                      default:
                        btnClass.push(`justify-center`)
                        break;                        
                    }
                  }

                  modelClone.property.props.products = this.activeProducts
                  modelClone.property.props.inDevelopment = this.developmentMode
                  
                  defaultContent.push(createElement("Products", modelClone.property))
                  return createElement('div', {
                    class: btnClass,
                    on: onEvents
                  }, defaultContent)

              break;
              case "products-carousel":

                  if(modelClone?.property?.props?.align){
                    btnClass.push(`flex`)
                    switch(modelClone?.property?.props?.align){
                      case "left":
                        btnClass.push(`justify-start`)
                        break;
                      case "right":
                        btnClass.push(`justify-end`)
                        break;
                      case "center":
                      default:
                        btnClass.push(`justify-center`)
                        break;                        
                    }
                  }
                  __console.log({modelClone})
                  defaultContent.push(createElement("ProductsCarousel", modelClone.property))
                  return createElement('div', {
                    class: btnClass,
                    on: onEvents
                  }, defaultContent)

              break;
              case "form":

                  if(modelClone?.property?.props?.align){
                    btnClass.push(`flex`)
                    switch(modelClone?.property?.props?.align){
                      case "left":
                        btnClass.push(`justify-start`)
                        break;
                      case "right":
                        btnClass.push(`justify-end`)
                        break;
                      case "center":
                      default:
                        btnClass.push(`justify-center`)
                        break;                        
                    }
                  }

                  defaultContent.push(createElement("mmioForm", modelClone.property))
                  return createElement('div', {
                    class: btnClass,
                    on: onEvents
                  }, defaultContent);

              break;
              default:

                  modelClone = this.replaceVariables(modelClone)
                  if(modelClone?.property?.props?.align){
                    btnClass.push(`flex`)
                    switch(modelClone?.property?.props?.align){
                      case "right":
                        modelClone.property.class.push(`ml-auto`)
                        btnClass.push(`justify-end`)
                        break;
                      case "left":
                        modelClone.property.class.push(`mr-auto`)
                        btnClass.push(`justify-start`)
                        break;
                      case "center":
                      default:
                          modelClone.property.class.push(`m-auto`)
                          btnClass.push(`justify-center`)
                        break;                        
                    }
                  }

                  if(modelClone?.property?.props?.overlay){
                    btnClass.push("the-overlay-parent")
                    defaultContent.push(createElement("div", {class:"the-overlay"}))
                    modelClone.property.class.push("the-overlay-siblings")
                  }

                  defaultContent.push(createElement("div", modelClone.property))
                  return createElement("div", {
                    class: btnClass,
                    on: onEvents,
                    ref: 'myRef',
                  }, defaultContent)

              break;
          }
      },
      methods: {
        ...offerHandlers.methods,
        getModel(item){
          return this.$clone((this.$store.getters.GetDomObjectContent(item) || {}))
        },
        replaceVariables(model){
          if(this.developmentMode){ return model}
          try{
            let modelStr = JSON.stringify(model)
            const variables = ["{{first_nam}}", "{{last_name}}", "{{email}}", "{{phone_number}}"];
            for(const variable of variables){
              modelStr = modelStr.replaceAll(variable, this.getVariableValue(variable))
            }
            return JSON.parse(modelStr);
          }catch(e){ __console.log(e)}
          return model;
        },
        getVariableValue(variable){
          const orderData = this.$store.state.__finalOrderData
          if(!(orderData instanceof Object)){ return variable}
          if(!Object.entries(orderData).length){ return variable}
          let output = "";
          switch(variable){
            case "{{first_name}}":
              output = orderData?.["buyer_first_name"]
            break;
            case "{{last_name}}":
              output = orderData?.["buyer_last_name"]
            break;
            case "{{email}}":
              output = orderData?.["buyer_email"]
            break;
            case "{{phone_number}}":
              output = orderData?.["buyer_mobile"]	
            break;
            default:
              output = variable
            break;
          }
          return output ? output : variable
        },
        insert(data,index=false){
          try{
            this.$store.commit("UPDATE_DOMOBJECT_SINGLE", data )
            var clone = this.getModel(this.model.parent_key)
            index = clone.contents.findIndex(key => key == this.model.key) + 1
            clone.contents.splice(index, 0, data.key);
            __console.log({index,clone})
            this.$store.commit("UPDATE_DOMOBJECT_SINGLE", clone )
          }catch(e){}
        },
        addResource(){
          VueSwal(contentGenerator, {
              showCancelButton: true,
              showConfirmButton: true,
              confirmButtonText: `👍 INSERT CONTENT`
            }).then( ObjValue => {
              if(Object.entries(ObjValue).length){
                __console.log({ObjValue})
                this.insert(ObjValue,false)
              }
          })
        }
      },
      computed: {
        activeProducts(){
          return this.developmentMode ? this.$store.state.Products.filter(prod => prod.store_id == this.theStore?.id) : this.$store.state.__storeProducts
        },
        theStore(){
          return this.developmentMode ? this.$store.state.ActiveStore : this.$store.state.__storeDetails;
        },
        model(){
          return this.getModel(this.modelKey)
        }
      },
      mounted(){
        var that = this
        try{
          const coolElement = this.$refs.myRef;
          const observer = new IntersectionObserver(function(val){
            if(that.animations.length && val[0].isIntersecting){
              coolElement.classList.add(...that.animations)
            }
          });
          observer.observe(coolElement);
        }catch(e){  }
      }
    };

</script>
<style>
    [style*="--aspect-ratio"] > :first-child {
      width: 100%;
    }
    [style*="--aspect-ratio"] > img {  
      height: auto;
    } 
    @supports (--custom:property) {
      [style*="--aspect-ratio"] {
        position: relative;
      }
      [style*="--aspect-ratio"]::before {
        content: "";
        display: block;
        padding-bottom: calc(100%/1.7778);
      }  
      [style*="--aspect-ratio"] > :first-child {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }  
    }

    .text-shadow-sm {
      text-shadow: 0 2px 4px rgba(0,0,0,0.10);
    }

    .text-shadow {
      text-shadow: 0 2px 4px rgba(0,0,0,0.50);
    }

    .text-shadow-md {
      text-shadow: 0 4px 8px rgba(0,0,0,0.5), 0 2px 4px rgba(0,0,0,0.8);
    }

    .text-shadow-lg {
      text-shadow: 0 15px 30px rgba(0,0,0,1), 0 5px 15px rgba(0,0,0,0.8);
    }

    .text-shadow-none {
      text-shadow: none;
    }

    .the-overlay-parent {
      position: relative;
    }

    .the-overlay-siblings {
      z-index: 2;
      position: relative;
    }

    .the-overlay {
      position: absolute;
      background-color: rgba(0,0,0,.5);
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      z-index: 1;      
    }

    mark {
      margin: 0 -0.4em;
      padding: 0.1em 0.4em;
      border-radius: 0.8em 0.3em;
      background: transparent;
      background-image: linear-gradient(
        to right,
        rgba(255, 225, 0, 0.1),
        rgba(255, 225, 0, 0.7) 4%,
        rgba(255, 225, 0, 0.3)
      );
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
    } 

</style>