export default [
    // =============================================================================
    // Google My Business Messages
    // =============================================================================
    {
        path: '/gmb/brands',
        name: 'GMB Brands',
        component: () => import( /* webpackChunkName: "GmbBrandsDashboard" */ '@/views/Gmb/Brands/BrandsDashboard.vue'),
        slug: 'gmb_brands',
        category: "Google My Business",
    },
    {
        path: '/gmb/agents',
        name: 'GMB Agents',
        component: () => import( /* webpackChunkName: "GmbAgentsDashboard" */ '@/views/Gmb/Agents/AgentsDashboard.vue'),
        slug: 'gmb_agents',
        category: "Google My Business",
    },
    {
        path: '/gmb/agents/create',
        name: 'Create GMB Agent',
        component: () => import( /* webpackChunkName: "GmbAgentCreate" */ '@/views/Gmb/Agents/AgentCreate.vue'),
        slug: 'gmb_create_agents',
        category: "Google My Business",
    },
    {
        path: '/gmb/flows/create',
        name: 'Create GMB Flow',
        component: () => import( /* webpackChunkName: "GmbFlowBuilder" */ '@/views/Gmb/GMBFlow/FlowBuilder.vue'),
        slug: 'gmb_create_flow',
        category: "Google My Business",
    },
    {
        path: '/gmb/flows',
        name: 'GMB Flows',
        component: () => import( /* webpackChunkName: "GmbFlowBuilderDashboard" */ '@/views/Gmb/GMBFlow/FlowBuilderDashboard.vue'),
        slug: 'gmb_flows',
        category: "Google My Business",
    },
    {
        path: '/gmb/livechat',
        name: 'GMB Livechat',
        component: () => import( /* webpackChunkName: "GmbBrandsDashboard" */ '@/views/Gmb/Chat/Chat.vue'),
        slug: 'gmb_livechat',
        category: "Google My Business",
    },
    {
        path: '/gmb/locations',
        name: 'GMB Locations',
        component: () => import( /* webpackChunkName: "GmbLocationsDashboard" */ '@/views/Gmb/Locations/LocationsDashboard.vue'),
        slug: 'gmb_locations',
        category: "Google My Business",
    },    
]