import initMMIOaxios from "@/utils/axios"

export default {

    async getWebWidgetAccounts({ commit, dispatch }) {
        const axios = await initMMIOaxios()
        const { data } = await axios.get("/widget/get_accounts")
        commit("UPDATE_CHAT_WIDGET_ACCOUNTS", data);
        dispatch("executeUpdateActualUsageLog", { moduleName: "WEBCHAT_WIDGET_ACCOUNTS", data });
        return data;
    },

    async getWebWidgetFlows({ commit, dispatch }) {
		const axios = await initMMIOaxios();
		options.small = true;
		if ( !options?.page_id ) {
			options.page_id = state.ActiveWebWidgetAccount.widget_id;
		}
        options.channel = "widget";
		let enpoint = "/apis/ext_get_user_flows";
		if (_isObj(options)) {
			const params = new URLSearchParams(options).toString();
			enpoint = enpoint + "?" + params;
		}
		const { data } = await axios.get(enpoint);
		commit("UPDATE_CHAT_WIDGET_FLOWS", data);
		dispatch("executeUpdateActualUsageLog", { moduleName: "WEBCHAT_WIDGET_FLOWS", data });
    },

	async getWebWidgetFlowActionSingle(dummy, options = {}) {
		if (!options?.id) { return {}; }
		const axios = await initMMIOaxios();
		const opt = {
			id: options.id,
			postbacks_only: !!options?.postbacks_only ? 1 : 0,
		}
		const params = new URLSearchParams(opt).toString();
		const enpoint = "/apis/ext_get_user_flows?" + params;
		const { data } = await axios.get(enpoint);
		return Array.isArray(data) ? data[0] : {};
	},

}