import initMMIOaxios from "@/utils/axios"

const facebookAutomations = {
	// /////////////////////////////////////////////
	// Facebook Automations
	// ////////////////////////////////////////////

	async getUserCommentAutomationPosts({ commit, state, dispatch }) {
		var payload = await _getUserCommentAutomationPosts(state.ActivePage.access_token)
		if (!payload) { return }
		commit('COMMENT_AUTOMATIONS', payload)
		dispatch("executeUpdateActualUsageLog", { moduleName: "FACEBOOK_COMMENT_AUTOMATION" })
	},

	async getUserCommentAutomationTemplates({ commit }) {
		var payload = await _getUserCommentAutomationTemplates()
		commit('COMMENT_AUTOMATION_TEMPLATES', payload)
	},
}

export default facebookAutomations

//*************** HELPER FUNCTIONS ***************//

async function _getUserCommentAutomationPosts() {
	const axios = await initMMIOaxios()
	const {data} = await axios.get("/apis_fbauto/get_auto_comments")
	return data;
}

async function _getUserCommentAutomationTemplates() {
	const axios = await initMMIOaxios()
	const {data} = await axios.get("/apis_fbauto/get_templates")
	return data;
}