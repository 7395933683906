<template>
    <div id="row-generator">

        <vs-tabs vs-alignment="fixed" alignment="fixed">
            <vs-tab :label="i18n.t('Raw Blocks')">
                <div class="grid grid-cols-6 pt-5">
                    <!--Row Grid-->
                    <div :class="active==1?'active':''" class="col-span-2 flex flex-col justify-center items-center">
                        <p class="w-full text-center text-xs">{{ i18n.t("1 Column") }}</p>
                        <div class="div element text-center rounded w-90 p-2 m-2 inline-block border-2 border-dashed border-grey-400" @click="activate('block',1)">
                            <div class="node_icon w-full flex justify-center items-center p-2">
                            <div class="block-section-wrap grid grid-cols-12 gap-1">
                                <div class="h-full block-section col-span-12"></div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <!--Block Big left-->
                    <div :class="active==2?'active':''" class="col-span-2 flex flex-col justify-center items-center">
                        <p class="w-full text-center text-xs">{{ i18n.t("2 Equal Cols") }}</p>
                        <div class="div element text-center rounded w-90 h-90 p-2 m-2 inline-block border-2 border-dashed border-grey-400" @click="activate('block1',2)">
                            <div class="node_icon w-full flex justify-center items-center p-2">
                            <div class="block-section-wrap grid grid-cols-12 gap-1">
                                <div class="h-full block-section col-span-6"></div>
                                <div class="h-full block-section col-span-6"></div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <!--Block Big left-->
                    <div :class="active==3?'active':''" class="col-span-2 flex flex-col justify-center items-center">
                        <p class="w-full text-center text-xs">{{ i18n.t("2 Cols - Big Left") }}</p>
                        <div class="div element text-center rounded w-90 h-90 p-2 m-2 inline-block border-2 border-dashed border-grey-400" @click="activate('block2',3)">
                            <div class="node_icon w-full flex justify-center items-center p-2">
                            <div class="block-section-wrap grid grid-cols-12 gap-1">
                                <div class="h-full block-section col-span-8"></div>
                                <div class="h-full block-section col-span-4"></div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <!--Block Big Right-->
                    <div :class="active==4?'active':''" class="col-span-2 flex flex-col justify-center items-center">
                        <p class="w-full text-center text-xs">{{ i18n.t("2 Cols - Big Right") }}</p>
                        <div class="div element text-center rounded w-90 h-90 p-2 m-2 inline-block border-2 border-dashed border-grey-400" @click="activate('block3',4)">
                            <div class="node_icon w-full flex justify-center items-center p-2">
                            <div class="block-section-wrap grid grid-cols-12 gap-1">
                                <div class="h-full block-section col-span-4"></div>
                                <div class="h-full block-section col-span-8"></div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <!--Block Big left-->
                    <div :class="active==5?'active':''" class="col-span-2 flex flex-col justify-center items-center">
                        <p class="w-full text-center text-xs">{{ i18n.t("3 Equal Cols") }}</p>
                        <div class="div element text-center rounded w-90 h-90 p-2 m-2 inline-block border-2 border-dashed border-grey-400" @click="activate('block4',5)">
                            <div class="node_icon w-full flex justify-center items-center p-2">
                                <div class="block-section-wrap grid grid-cols-12 gap-1">
                                    <div class="h-full block-section col-span-4"></div>
                                    <div class="h-full block-section col-span-4"></div>
                                    <div class="h-full block-section col-span-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Block Big left-->
                    <div :class="active==6?'active':''" class="col-span-2 flex flex-col justify-center items-center">
                        <p class="w-full text-center text-xs">{{ i18n.t("3 Cols - Big Center") }}</p>
                        <div class="div element text-center rounded w-90 h-90 p-2 m-2 inline-block border-2 border-dashed border-grey-400" @click="activate('block5',6)">
                            <div class="node_icon w-full flex justify-center items-center p-2">
                                <div class="block-section-wrap grid grid-cols-12 gap-1">
                                    <div class="h-full block-section col-span-3"></div>
                                    <div class="h-full block-section col-span-6"></div>
                                    <div class="h-full block-section col-span-3"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Block Big left-->
                    <div :class="active==7?'active':''" class="col-span-2 flex flex-col justify-center items-center">
                        <p class="w-full text-center text-xs">{{ i18n.t("4 Equal Cols") }}</p>
                        <div class="div element text-center rounded w-90 h-90 p-2 m-2 inline-block border-2 border-dashed border-grey-400" @click="activate('block6',7)">
                            <div class="node_icon w-full flex justify-center items-center p-2">
                                <div class="block-section-wrap grid grid-cols-12 gap-1">
                                    <div class="h-full block-section col-span-3"></div>
                                    <div class="h-full block-section col-span-3"></div>
                                    <div class="h-full block-section col-span-3"></div>
                                    <div class="h-full block-section col-span-3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vs-tab>
            <vs-tab label="Pre-built Blocks">
                <div class="grid grid-cols-4 pt-5">
                    <!--Block Big left-->
                    <div :class="active==8?'active':''" class="col-span-2 flex flex-col justify-center items-center">
                        <p class="w-full text-center text-xs">{{ i18n.t("Hero Banner (Full Screen)") }}</p>
                        <div class="div element text-center rounded p-2 m-2 inline-block border-2 border-dashed border-grey-400" @click="activate('premadeHero',8)">
                            <div class="flex justify-center items-center">
                                <img class="responsive" src="https://app.marketingmaster.io/upload/ecommerce/product_1_1633949519181225.JPG" />
                            </div>
                        </div>
                    </div>

                    <!--Block Big left-->
                    <div :class="active==9?'active':''" class="col-span-2 flex flex-col justify-center items-center">
                        <p class="w-full text-center text-xs">{{ i18n.t("Hero Banner 2 (Full Screen)") }}</p>
                        <div class="div element text-center rounded p-2 m-2 inline-block border-2 border-dashed border-grey-400" @click="activate('premadeHero2',9)">
                            <div class="flex justify-center items-center">
                                <img class="responsive" src="https://app.marketingmaster.io/upload/ecommerce/product_1_1633949519100069.JPG" />
                            </div>
                        </div>
                    </div>

                    <!--Block Big left-->
                    <div :class="active==10?'active':''" class="col-span-2 flex flex-col justify-center items-center">
                        <p class="w-full text-center text-xs">{{ i18n.t("Feature 3 Col") }}</p>
                        <div class="div element text-center rounded p-2 m-2 inline-block border-2 border-dashed border-grey-400" @click="activate('threeColFeature',10)">
                            <div class="flex justify-center items-center">
                                <img class="responsive" src="https://app.marketingmaster.io/upload/ecommerce/product_1_1633949518136446.JPG" />
                            </div>
                        </div>
                    </div>

                    <!--Block Big left-->
                    <div :class="active==11?'active':''" class="col-span-2 flex flex-col justify-center items-center">
                        <p class="w-full text-center text-xs">{{ i18n.t("Card Highlight") }}</p>
                        <div class="div element text-center rounded p-2 m-2 inline-block border-2 border-dashed border-grey-400" @click="activate('cardHighlight',11)">
                            <div class="flex justify-center items-center">
                                <img class="responsive" src="https://app.marketingmaster.io/upload/ecommerce/product_1_1633949519130372.JPG" />
                            </div>
                        </div>
                    </div>

                    <!--Block Big left-->
                    <div :class="active==12?'active':''" class="col-span-2 flex flex-col justify-center items-center">
                        <p class="w-full text-center text-xs">{{ i18n.t("Product Highlight Right") }}</p>
                        <div class="div element text-center rounded p-2 m-2 inline-block border-2 border-dashed border-grey-400" @click="activate('productsHighlightRight',12)">
                            <div class="flex justify-center items-center">
                                <img class="responsive" src="https://app.marketingmaster.io/upload/ecommerce/product_1_1633949519795177.JPG" />
                            </div>
                        </div>
                    </div>

                    <!--Block Big left-->
                    <div :class="active==13?'active':''" class="col-span-2 flex flex-col justify-center items-center">
                        <p class="w-full text-center text-xs">{{ i18n.t("Review Cards") }}</p>
                        <div class="div element text-center rounded p-2 m-2 inline-block border-2 border-dashed border-grey-400" @click="activate('reviewCards',13)">
                            <div class="flex justify-center items-center">
                                <img class="responsive" src="https://app.marketingmaster.io/upload/ecommerce/product_1_1633950540714142.JPG" />
                            </div>
                        </div>
                    </div>

                    <!--Block Big left-->
                    <div :class="active==14?'active':''" class="col-span-2 flex flex-col justify-center items-center">
                        <p class="w-full text-center text-xs">{{ i18n.t("Guarantees") }}</p>
                        <div class="div element text-center rounded p-2 m-2 inline-block border-2 border-dashed border-grey-400" @click="activate('guarantees',14)">
                            <div class="flex justify-center items-center">
                                <img class="responsive" src="https://app.marketingmaster.io/upload/ecommerce/product_1_1633954304209767.JPG" />
                            </div>
                        </div>
                    </div>


                </div>
            </vs-tab>
        </vs-tabs>

        <vs-input v-show="false" v-model="value" v-validate="'required'" name="Obj"/>
        <p v-if="errors.has('Obj')" class="text-danger text-sm">{{ i18n.t("Please select a Row Type.") }}</p>

    </div>
</template>

<script>
    import {i18n} from "@/i18n";
    import defaultDataGenerator from './defaultDataGenerator'
    export default {
      mixins: [defaultDataGenerator],
        data(){
            return {
                Obj: null,
                active: null,
                value: "",
                i18n
            }
        },
        computed: {

        },
        methods: {
          activate(block,key){
              this.active = key
              this.Obj = this.defaultDataGenerator[block]()
              this.value = uuidv4()
              Swal.resetValidationMessage();
          }
        },
        mounted(){
            var mountObj = this.$root?.$data?.Obj
            if(mountObj instanceof Object){
                this.Obj = {...this.Obj, ...___(mountObj)}
            }
        }
    }

</script>

<style lang="scss">
    #row-generator {
        .element {
            border-color: transparent;
            cursor: pointer;
        }
        .active {
            .element {
                border-color: rgba(var(--vs-primary), 1) !important;
            }
            .block-section {
                background-color: rgba(var(--vs-primary), 1) !important;
                border-radius: 3px;
            }
        }
        .element:hover {
        .block-section {
            background-color: rgba(var(--vs-primary), 1);
            border-radius: 3px;
        }
        }
        .element:hover svg {
            fill: rgba(var(--vs-primary), 1) !important;
        }
        .block-section-wrap {
            width: 52px;
            height: 52px;
        }
        .block-section {
            background-color: #0c0c0c;
            border-radius: 3px;
        }
    }
</style>