import initMMIOaxios from "@/utils/axios"
import { debounce } from 'lodash';

const leading = true;
const trailing = false;

const messengerActions = {
	// /////////////////////////////////////////////
	// Flow Builder
	// /////////////////////////////////////////////

	getBotSheetsAction: debounce(async function ({ commit }) {
		var payload = await ext_get_sheets_data()
		commit('BOTSHEETS', payload)
	}, 1000, { leading, trailing }),

	getFlowsAction: debounce(async function ({ commit, state }, options = {}) {
		__console.log("Get user Flows...");
		const axios = await initMMIOaxios();
		options.small = true;
		if ( !options?.page_id ) {
			options.page_id = state.ActivePage.page_table_id;
		}
		if (!options?.channel) {
			options.channel = state.ActivePage.channel;
		}
		if (!options.page_id || !options.channel) { return; }
		let enpoint = "/apis/ext_get_user_flows";
		if (_isObj(options)) {
			const params = new URLSearchParams(options).toString();
			enpoint = enpoint + "?" + params;
		}
		const { data } = await axios.get(enpoint);
		commit('FLOWS', data);
		window.messengerFlowsLoaded = true;
	}, 1000, { leading, trailing }),

	async getFlowsActionSingle(dummy, options = {}) {
		if (!options?.id) { return {}; }
		const axios = await initMMIOaxios();
		const opt = {
			id: options.id,
			postbacks_only: !!options?.postbacks_only ? 1 : 0,
		}
		const params = new URLSearchParams(opt).toString();
		const enpoint = "/apis/ext_get_user_flows?" + params;
		const { data } = await axios.get(enpoint);
		return Array.isArray(data) ? data[0] : {};
	},

	getTemplatesAction: debounce(async function ({ commit }) {
		var payload = await ext_get_user_templates_ajax()
		commit('TEMPLATES', payload)
	}, 1000, { leading, trailing }),

	getUserTagsAction: debounce(async function ({ commit }) {
		var payload = await get_contact_tags()
		commit('CONTACT_TAGS', payload)
	}, 1000, { leading, trailing }),

	getUserPagesAction: debounce(async function ({ commit, dispatch, state }) {
		var payload = await get_pages()
		commit('USER_PAGES', payload)
		commit('UPDATE_ACTIVE_USER_PAGES')
		dispatch("getPageOptions")
		try {
			const instagramAccounts = state.UserPages.filter(page => page.instagram_data && page.bot_enabled == 1)
			dispatch("executeUpdateActualUsageLog", { moduleName: "INSTAGRAM_BUSINESS_ACCOUNTS", data: instagramAccounts })
		} catch (e) { }
		dispatch("executeUpdateActualUsageLog", { moduleName: "FLOW_BUILDER_LIMIT_ON_NUMBER_OF_FLOWS_USER_CAN_CREATE" })
		dispatch("executeUpdateActualUsageLog", { moduleName: "FACEBOOK_PAGES" })
	}, 1000, { leading, trailing }),

	getUserGrowthToolsAction: debounce(async function ({ commit, dispatch }) {
		var payload = await _getUserGrowthToolsAction()
		commit('USER_GROWTH_TOOLS', payload);
		dispatch("executeUpdateActualUsageLog", { moduleName: "GROWTH_TOOL_CHECKBOX_PLUGIN" })
		dispatch("executeUpdateActualUsageLog", { moduleName: "GROWTH_TOOL_SEND_TO_MESSENGER" })
		dispatch("executeUpdateActualUsageLog", { moduleName: "GROWTH_TOOL_M_ME_LINKS" })
		dispatch("executeUpdateActualUsageLog", { moduleName: "GROWTH_TOOL_CUSTOMER_CHAT_PLUGIN" })
	}, 1000, { leading, trailing }),

	getUserPostbacks: debounce(async function ({ commit }) {
		var payload = await _getUserPostbacks()
		commit('USER_POSTBACKS', payload)
	}, 1000, { leading, trailing }),

	getUserMessengerBroadcasts: debounce(async function ({ commit, dispatch }, {pageTableId,page,limit,q}) {
    if (!pageTableId) return
		const axios = await initMMIOaxios()
		const {data: payload} = await axios.get(`/apis/get_broadcasts/${pageTableId}?page=${page}&limit=${limit}&q=${q}`);
		commit('USER_MESSENGER_BROADCASTS', payload.data)
		dispatch("executeUpdateActualUsageLog", { moduleName: "CHAT_BROADCAST" });
		return payload;
	}, 1000, { leading, trailing }),

	getChatSequences: debounce(async function ({ commit, dispatch }) {
		const axios = await initMMIOaxios()
		const { data } = await axios.get("/apis/get_messenger_sequences");
		commit("UPDATE_MESSENGER_SEQUENCES", data);
		dispatch("executeUpdateActualUsageLog", { moduleName: "CHAT_SEQUENCES" });
	}, 1000, { leading, trailing }),

	getPageOptions: debounce(async function ({ commit, state }) {
		let UsePages = state.UserPages
		if (!UsePages.length) { UsePages = state.userPagesAll }
		for (const Page of ___(UsePages)) {
			var _page = {};
			_page.label = Page.page_name;
			_page.value = Page.id;
			_page.id = Page.id;
			_page.access_token = Page.page_access_token;
			_page.page_id = Page.page_id;
			_page.bot_enabled = Page.bot_enabled == "1";
			_page.switch_disable = false;
			_page.valid_access_token = true;
			_page.facebook_rx_fb_user_info_id = Page.facebook_rx_fb_user_info_id
			_page.instagram_business_account = Page.instagram_business_account
			_page.logo = `https://graph.facebook.com/${Page.page_id}/picture?type=small`;
			if (!state.PageOptions?.[_page.id]) {
				if (!state.PageOptions?.[_page.id]?.category) {
					const axios = await initMMIOaxios()
					axios.post("/apis/get_page_details", _page).then(resp => {
						commit("UPDATE_PAGE_OPTIONS_SINGLE", resp.data)
					}).catch(e => __console.log(e));
				}
				commit("UPDATE_PAGE_OPTIONS_SINGLE", _page)
			}
		}
	}, 1000, { leading, trailing }),

	async getSinglePageOption({ commit, state }, payload = false) {
		const axios = await initMMIOaxios()
		var _page = {};
		if (payload instanceof Object) {
			_page = payload
		} else if (payload !== false) {
			const Page = ___(state.UserPages).find(page => page.id == payload)
			_page.label = Page.page_name;
			_page.value = Page.id;
			_page.id = Page.id;
			_page.access_token = Page.page_access_token;
			_page.page_id = Page.page_id;
			_page.bot_enabled = Page.bot_enabled == "1";
			_page.switch_disable = false;
			_page.valid_access_token = true;
			_page.facebook_rx_fb_user_info_id = Page.facebook_rx_fb_user_info_id
			_page.instagram_business_account = Page.instagram_business_account
			_page.logo = `https://graph.facebook.com/${Page.page_id}/picture?type=small`;
		}
		if (_page instanceof Object) {
			if (Object.entries(_page).length) {
				const resp = await axios.post("/apis/get_page_details", _page).catch(e => __console.log(e));
				__console.log("getSinglePageOption", resp.data)
				commit("UPDATE_PAGE_OPTIONS_SINGLE", resp.data)
			}
		}

	},

	async getCustomVariables({ commit }) {
		var payload = await _getCustomVariables()
		commit('UPDATE_CUSTOM_VARIABLES', payload)
	},

	async writeCustomVariables({ commit }, payload) {
		return await _writeCustomVariables(payload)
	},

	getMyOTN: debounce(async function ({ commit }) {
		var payload = await ext_get_my_otns()
		commit("UPDATE_OTN", payload)
	}, 1000, { leading, trailing }),

	refreshInstagramSettings({ state, dispatch }) {
		if (state.ActivePage.channel === 'instagram' && state.ActivePage.messages_enabled !== true) {
			Swal.fire({
				title: 'Please check Settings for this Instagram account',
				html: `
						<div class="mb-5">
							<p>We see there is one important setting that is not yet set up for your Instagram account. In order to use Automation, please enable access to Instagram Partner Messages.</p>
							<br/>
							<p>To enable it, please follow the steps below:<p/>
						</div>
						<video class="w-full aspect-video" src="https://app.marketingmaster.io/upload/video/allow-instagram-messages.mp4" preload="auto" controls="" loop="" autoplay=""></video>
						<div class="w-full">
							<ol class="list-decimal flex flex-col items-start">
								<li>Open your Instagram mobile app and select your Business account</li>
								<li>Go toMenu → Settings → Privacy → Messages → Connected Toolsand toggle ON "Allow Access to Messages"</li>
								<li>Then back to ${state.MMIO_DEFAULTS.BRAND_SHORT_NAME} and Click '👍‍‍ I Have Already Done This' button below.</li>
							</ol>
						</div>
					`,
				customClass: {
					popup: 'allow-insta-messages',
				},
				icon: 'info',
				confirmButtonText: '👍‍‍ I Have Already Done This',
				showCancelButton: true,
			}).then(value => {
				if (value.value) {
					dispatch("getSinglePageOption", state.ActivePage.page_table_id)
				}
			});
			return false;
		}
		return true;
	},

	getRecurringNotifications: debounce(async function ({ commit }) {
		const axios = await initMMIOaxios()
		const { data } = await axios.get("/apis/get_recurring_notifications");
		commit("UPDATE_RECURRING_CAMPAIGNS", data);
	}, 1000, { leading, trailing }),


}

export default messengerActions

//*************** HELPER FUNCTIONS ***************//

async function ext_get_sheets_data() {
    const axios = await initMMIOaxios()
    const { data: payload } = await axios.get("/apis/ext_get_sheets_data_dashboard");
    return payload
}

async function ext_get_user_templates_ajax() {
    const axios = await initMMIOaxios()
    const { data: payload } = await axios.get("/apis/ext_get_user_templates");
    return payload
}

async function get_contact_tags() {
    const axios = await initMMIOaxios()
    const { data: payload } = await axios.get("/apis_email/ext_populate_user_labels");
    return payload
}

async function get_pages() {
    const axios = await initMMIOaxios()
    const { data: payload } = await axios.get("/apis/get_pages_v2");
    return payload
}

async function _getUserGrowthToolsAction() {
	const axios = await initMMIOaxios()
	const { data } = await axios.get("/apis/get_growth_tools");
	return data;
}

async function _getUserPostbacks() {
    const axios = await initMMIOaxios()
    const { data: payload } = await axios.get("/apis/get_messenger_bot_postbacks");
    return payload
}

async function _writeCustomVariables(payload) {
    const axios = await initMMIOaxios()
    const { data } = await axios.post("/apis/write_custom_variable", payload);
    return data
}

async function _getCustomVariables() {
    const axios = await initMMIOaxios()
    const { data } = await axios.get("/apis/get_custom_variable");
    return data
}

async function ext_get_my_otns() {
    const axios = await initMMIOaxios()
    const { data } = await axios.get("/apis/ext_get_my_otns");
    return data
}
