export default {
    // /////////////////////////////////////////////
    // Email Flow Builder
    // /////////////////////////////////////////////

    EMAIL_SEQUENCES(state, val) {
        var output = [];
        try {
            output = Object.values(val).sort((a, b) => b.id - a.id).map(template => {
                template.name = decodeURIComponent(template.name);
                Object.seal(template)
                return template
            })
        } catch (e) { }
        state.Emails = output
    },

    EMAIL_REPORTS(state, val) {
        try {
            var themeColors = ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'];
            var _30DayReport = {
                series: [{
                    name: 'This Month',
                    data: val.data.month_emails_sent_data
                }, {
                    name: 'Last Month',
                    data: val.data.last_month_emails_sent_data
                }],
                chartOptions: {
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    colors: themeColors,
                    xaxis: {
                        type: 'datetime',
                        categories: val.data.month_emails_sent_label,
                    },
                    tooltip: {
                        x: {
                            format: 'M-d'
                        },

                    }
                }
            };
            var OpenRate = {
                series: [(val.data.open / val.data.sent) * 100],
                chartOptions: {
                    plotOptions: {
                        radialBar: {
                            startAngle: -135,
                            endAngle: 225,
                            hollow: {
                                margin: 0,
                                size: "70%",
                                background: "#fff",
                                image: undefined,
                                position: "front",
                                dropShadow: {
                                    enabled: true,
                                    top: 3,
                                    left: 0,
                                    blur: 4,
                                    opacity: 0.24
                                }
                            },
                            track: {
                                background: "#fff",
                                strokeWidth: "67%",
                                margin: 0, // margin is in pixels
                                dropShadow: {
                                    enabled: true,
                                    top: -3,
                                    left: 0,
                                    blur: 4,
                                    opacity: 0.35
                                }
                            },

                            dataLabels: {
                                show: true,
                                name: {
                                    offsetY: -10,
                                    show: true,
                                    color: "#888",
                                    fontSize: "17px"
                                },
                                value: {
                                    formatter: function (val) {
                                        return parseInt(val.toString(), 10).toString();
                                    },
                                    color: "#111",
                                    fontSize: "36px",
                                    show: true
                                }
                            }
                        }
                    },
                    fill: {
                        type: "gradient",
                        gradient: {
                            shade: "dark",
                            type: "horizontal",
                            shadeIntensity: 0.5,
                            gradientToColors: ["#ABE5A1"],
                            inverseColors: true,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 100]
                        }
                    },
                    stroke: {
                        lineCap: "round"
                    },
                    labels: ["Percent"]
                }
            };
            val.data._30DayReport = _30DayReport;
            val.data.negative_feedback_rating = Number(val.data.negative_feedback_rating);
            val.data.OpenRate = OpenRate;
            state.EmailReports = val.data;
        } catch (e) { }
    },

    CUSTOM_FORMS(state, val) {
        state.CustomForms = (Array.isArray(val) ? val : []).map(item => Object.seal(item));
    },

    EMAIL_FLOW_BUILDER_API_KEY(state, val) {
        state.EmailFlowBuilderAPIKey = val
    },

    CONTACT_LIST(state, val) {
        state.ContactList = (Array.isArray(val) ? val : []).map(item => Object.seal(item));
    },

    CONTACT_BOOKS(state, val) {
        let ContactBooks = [];
        try {
            ContactBooks = val.sort((a, b) => b.id - a.id).map(item => Object.seal(item));
        } catch (e) { }
        state.ContactBooks = ContactBooks
    },

    EMAIL_BROADCASTS(state, val) {
        state.EmailBroadcasts = val
    },

    EMAIL_TEMPLATE_UPDATE(state, val) {
        state.EmailTemplates = (Array.isArray(val) ? val : []).map(item => Object.seal(item));
    },

    OPTIN_TEMPLATE_UPDATE(state, val) {
        state.OptinTemplates = (Array.isArray(val) ? val : []).map(item => Object.seal(item));
    },
}