export default [
    // =============================================================================
    // ADMIN
    // =============================================================================     
    {
        path: '/admin',
        name: 'Admin Dashboard',
        component: () => import( /* webpackChunkName: "AdminDashboard" */ '@/views/Admin/AdminDashboard.vue'),
        slug: "admin",
        category: "Admin",
    },
    {
        path: '/admin/packages',
        name: 'Admin Packages',
        component: () => import( /* webpackChunkName: "AdminPackagesDashboard" */ '@/views/Admin/Packages/Dashboard.vue'),
        slug: "admin_packages",
        category: "Admin",
    },
    {
        path: '/admin/notifications',
        name: 'Notifications',
        component: () => import( /* webpackChunkName: "Notifications" */ '@/views/Admin/Notifications/Notifications.vue'),
        slug: "notifications",
        category: "Admin",
    },
    {
        path: '/admin/notifications/content',
        name: 'Content Notifications',
        component: () => import( /* webpackChunkName: "NotificationsContent" */ '@/views/Admin/Notifications/NotificationsContent.vue'),
        slug: "notifications_content",
        category: "Admin",
    },
    {
        path: '/whitelabel-settings',
        name: 'Whitelabel Settings',
        component: () => import( /* webpackChunkName: "WhitelabelSettings" */ '@/views/WhitelabelSettings/WhitelabelSettings.vue'),
        slug: 'whitelabel_settings',
        category: "Admin",
    },    
]