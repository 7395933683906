export default {
    // /////////////////////////////////////////////
    // LANDING PAGES
    // /////////////////////////////////////////////	

    UPDATE_LANDING_PAGES(state, val) {
        try {
            state.LandingPages = val.sort((a, b) => b.id - a.id).map(item => Object.seal(item));
            return true;
        } catch (e) { }
        state.LandingPages = val
    },
}