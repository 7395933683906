const ecom_states = {
    __storeDetails: [],
    __cartItems: [],
    __storeProducts: [],
    __storeCategories: [],
    __storeShipping: [],
    __cartInstance: [],
    __savedAddress: {},
    __coupons: [],
    __subscriberID: "",
    __userPages: [],
    __effectiveOffer: {},
    __currentOfferPath: "",
    __acceptedOffers: [],
    __finalOrderData: {},
    __storeOffers: [],
}

export default ecom_states