import initMMIOaxios from "@/utils/axios"

export default {

	async getBookingEvents({ commit, dispatch }) {
		const axios = await initMMIOaxios()
		const {data} = await axios.get("/apis_integrations/booking/events/get")
		commit("UPDATE_BOOKING_EVENTS", data)
		dispatch("executeUpdateActualUsageLog", { moduleName: "BOOKING_EVENTS" })
	},

	async getOpenAiEmbeddings({ commit, dispatch }) {
		const axios = await initMMIOaxios()
		const {data} = await axios.get("/apis_integrations/get_openai_embeddings")
		commit("UPDATE_OPENAI_EMBEDDINGS", data)
		dispatch("executeUpdateActualUsageLog", { moduleName: "OPENAI_EMBEDDINGS" })
	},

	async getOpenAIModels({ commit }) {
		const axios = await initMMIOaxios()
		const {data} = await axios.get("/apis_integrations/get_user_openai_models")
		commit("UPDATE_OPENAI_MODELS", data)
		return data
	}

}