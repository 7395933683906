import Vue from 'vue'
import Swal from "sweetalert2";
import store from "@/store"
import {i18n} from "@/i18n";
import router from '@/routes'

export default async function(component, options={}, Obj={}, data={}){

    //Change upload url for whitelabels
    Obj = changeUrlRecursive(Obj);

    var vm = {};
    var preConfirmValue = undefined;
    var vueSwalBasicOptions = {
        html: `<div id="vue-component-mount"></div>`,
        onBeforeOpen() {
            Swal.showLoading()
        },
        onOpen(){
            if (window.MMIO_DEFAULTS instanceof Object) {
                Vue.prototype.$MMIO_DEFAULTS = { ...window.MMIO_DEFAULTS }
            } else {
                Vue.prototype.$MMIO_DEFAULTS = {
                    BRAND_NAME: "Marketing Master IO",
                    BRAND_SHORT_NAME: "MMIO",
                    BRAND_DOMAIN: 'https://marketingmaster.io',
                    BRAND_LOGO: "https://developers.marketingmaster.io/logo.png",
                    IS_WHITELABEL: false
                }
            }
            vm = new Vue({
                store,
                i18n,
                router,
                data: {
                    Obj: Obj,
                    data: data
                },
                render: h => h(component)
            }).$mount('#vue-component-mount');
            setTimeout( () => { Swal.hideLoading() }, 100)
        },
        async preConfirm(){
            if(typeof vm.$children[0].preConfirm === 'function'){
                preConfirmValue = await vm.$children[0].preConfirm()
            }
            const isValid = await vm.$children[0].$validator.validateAll()
            if(!isValid){
                Swal.showValidationMessage(i18n.t("Error: Values are missing"))
            }
        },
    }
    options = {...vueSwalBasicOptions, ...options}
    const {value} = await Swal.fire(options)
    window.isConfirmVueSwal = !!value;
    if(!!value){
        if(typeof vm.$children[0].doConfirmBeforeDesroy == "function"){
            await vm.$children[0].doConfirmBeforeDesroy()
        }
    }
    vm.$destroy();
    Obj = preConfirmValue === undefined ? vm.$children[0].Obj : preConfirmValue;
    const finalObj = !!value ? Obj : {}
    return finalObj;
}

const changeUrlRecursive = (obj) => {
    if ( !_isObj(obj) ) { return obj; }
    for ( const key of Object.keys(obj) ) {
        if ( typeof obj[key] === "string" && typeof window.whiteLabelURL === "function" ) {
            obj[key] = window.whiteLabelURL(obj[key])
        } else if ( _isObj(obj[key]) ) {
            obj[key] = changeUrlRecursive(obj[key]);
        }
    }
    return obj;
}