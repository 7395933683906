import {i18n} from "@/i18n";
import {logoMap} from "@/utils/channeDataMap.js"
export default {
	embedProviders() {
		return [
			//dailymotion
			{
				name: 'dailymotion',
				url: /^dailymotion\.com\/video\/(\w+)/,
				html: match => {
					const id = match[1];

					return (
						'<div style="position: relative; padding-bottom: 100%; height: 0; ">' +
						`<iframe src="https://www.dailymotion.com/embed/video/${id}" ` +
						'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
						'frameborder="0" width="480" height="270" allowfullscreen allow="autoplay">' +
						'</iframe>' +
						'</div>'
					);
				}
			},
			//spotify
			{
				name: 'spotify',
				url: [
					/^open\.spotify\.com\/(artist\/\w+)/,
					/^open\.spotify\.com\/(album\/\w+)/,
					/^open\.spotify\.com\/(track\/\w+)/
				],
				html: match => {
					const id = match[1];

					return (
						'<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 126%;">' +
						`<iframe src="https://open.spotify.com/embed/${id}" ` +
						'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
						'frameborder="0" allowtransparency="true" allow="encrypted-media">' +
						'</iframe>' +
						'</div>'
					);
				}
			},
			//youtube
			{
				name: 'youtube',
				url: [
					/^(?:m\.)?youtube\.com\/watch\?v=([\w-]+)/,
					/^(?:m\.)?youtube\.com\/v\/([\w-]+)/,
					/^youtube\.com\/embed\/([\w-]+)/,
					/^youtu\.be\/([\w-]+)/,
					/youtube\.com\/watch\?v=(\w+)/,
				],
				html: match => {
					const id = match[1];

					return (
						'<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">' +
						`<iframe src="https://www.youtube.com/embed/${id}" ` +
						'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
						'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>' +
						'</iframe>' +
						'</div>'
					);
				}
			},
			//vimeo
			{
				name: 'vimeo',
				url: [
					/^vimeo\.com\/(\d+)/,
					/^vimeo\.com\/[^/]+\/[^/]+\/video\/(\d+)/,
					/^vimeo\.com\/album\/[^/]+\/video\/(\d+)/,
					/^vimeo\.com\/channels\/[^/]+\/(\d+)/,
					/^vimeo\.com\/groups\/[^/]+\/videos\/(\d+)/,
					/^vimeo\.com\/ondemand\/[^/]+\/(\d+)/,
					/^player\.vimeo\.com\/video\/(\d+)/
				],
				html: match => {
					const id = match[1];

					return (
						'<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">' +
						`<iframe src="https://player.vimeo.com/video/${id}" ` +
						'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
						'frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>' +
						'</iframe>' +
						'</div>'
					);
				}
			},
			//instagram
			{
				name: 'instagram',
				url: /^instagram\.com\/p\/(\w+)/
			},
			//twitter
			{
				name: 'twitter',
				url: /^twitter\.com/
			},
			//googleMaps
			{
				name: 'googleMaps',
				url: /^google\.com\/maps/
			},
			//flickr
			{
				name: 'flickr',
				url: /^flickr\.com/
			},
			//facebook
			{
				name: 'facebook',
				url: [
					/facebook\.com\/(\w+)+\/videos\/(\d+)/
				],
				html: match => {
					const fbMediaRul = encodeURIComponent(match[0]);
					return (
						'<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">' +
						`<iframe src="https://www.facebook.com/plugins/video.php?href=${fbMediaRul}" ` +
						'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
						'frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>' +
						'</iframe>' +
						'</div>'
					);
				}
			},
			//wistia
			{
				//wistia.com/medias/w9qsmecn6q
				name: 'wistia',
				url: [
					/wistia\.com\/medias\/(\w+)/
				],
				html: match => {
					const id = encodeURIComponent(match[1]);
					return (
						'<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">' +
						`<iframe src="https://fast.wistia.net/embed/iframe/${id}?videoFoam=true" ` +
						'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
						'frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>' +
						'</iframe>' +
						'</div>'
					);
				}
			},
			//Loom
			{
				//https://www.loom.com/share/0628d6fe32d945f2b0259990bfeab837
				name: 'loom',
				url: [
					/loom\.com\/share\/(\w+)/,
					/loom\.com\/embed\/(\w+)/
				],
				html: match => {
					const id = encodeURIComponent(match[1]);
					return (
						'<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">' +
						`<iframe src="https://www.loom.com/embed/${id}" ` +
						'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
						'frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>' +
						'</iframe>' +
						'</div>'
					);
				}
			}

		]
	},
	suitCaseOptions(state){
		return channel => {
			return state.suitCases.filter( item => item.channel == channel)
		}
	},
	//chatbot conditions
	getCOnditions(state, getters) {
		const tags = getters.GetContactTagsArray.
			filter(tag => (tag?.page_id == 0) || (tag?.page_id == state.ActivePage?.page_table_id));

		let customVarData = {};
		for ( const cv of state.CustomVariables.filter(cs => cs.is_native == 0)) {
			customVarData[cv.variable_key] = cv;
		}

		let commonCustomVars = {
			first_name: {
				name: i18n.t("First Name"),
				variable_key: "first_name",
			},
			last_name: {
				name: i18n.t("Last Name"),
				variable_key: "last_name",
			},
			subscribed_at: {
				name: i18n.t("Subscribed At"),
				variable_key: "subscribed_at",
			},
			last_reply: {
				name: i18n.t("Last Reply"),
				variable_key: "last_reply",
			},
		};

		let Rules = {
			tag: {
				id: "tag",
				name: i18n.t("Tag"),
				options: tags.map(tag => { return { text: tag.group_name, value: tag.id } }),
				qualifier: ["is", "is not"].map(item => { return { text: item, value: item } }),
				type: "choices"
			},
			name_contains: {
				id: "name_contains",
				name: i18n.t("Subscriber Name"),
				options: null,
				qualifier: ["contains", "does not contain"].map(item => { return { text: i18n.t(item), value: item } }),
				type: "input"
			},
			last_interaction: {
				id: "last_interaction",
				name: i18n.t("Last Interaction Time"),
				options: [{ text: i18n.t("24-Hour Rule"), value: "24-hour-rule" }],
				qualifier: ["within", "outside"].map(item => { return { text: i18n.t(item), value: item } }),
				type: "choices"
			},
		}
		return (channel) => {
			const messengerOnlyCustomVars = {
				subscribe_id: {
					name: i18n.t("Subscriber ID (PSID)"),
					variable_key: "subscribe_id",
				},
				locale: {
					name: i18n.t("Locale"),
					variable_key: "locale",
				},
				timezone: {
					name: i18n.t("Timezone"),
					variable_key: "timezone",
				},
			};
			const IGOnlyCustomVars = {
				subscribe_id: {
					name: i18n.t("Subscriber ID (PSID)"),
					variable_key: "subscribe_id",
				}
			};
			const GMBOnlyCustomVars = {
				conversation_id: {
					name: i18n.t("Conversation ID"),
					variable_key: "conversation_id",
				}
			};
			const WhatsAppOnlyCustomVars = {
				contact_id: {
					name: i18n.t("Contact ID"),
					variable_key: "contact_id",
				}
			};
			if (channel == 'messenger') {
				commonCustomVars = {...commonCustomVars, ...messengerOnlyCustomVars};
				Rules.gender = {
					id: "gender",
					name: i18n.t("Gender"),
					options: [{ text: i18n.t("Male"), value: "male" }, { text: i18n.t("Female"), value: "female" }],
					qualifier: ["is"].map(item => { return { text: item, value: item } }),
					type: "choices"
				}
			} else if (channel == 'instagram'){
				commonCustomVars = {...commonCustomVars, ...IGOnlyCustomVars};
			} else if (channel == 'gmb'){
				commonCustomVars = {...commonCustomVars, ...GMBOnlyCustomVars};
			} else if (channel == 'whatsapp'){
				commonCustomVars = {...commonCustomVars, ...WhatsAppOnlyCustomVars};
			}
			const customVarDataOptions = Object.values({...commonCustomVars, ...customVarData}).map(customVar => {
				return {
					id: "cv_" + customVar.variable_key,
					name: i18n.t(`customVarName (Custom Variable)`, {customVarName : customVar.name}),
					options: null,
					qualifier: [
						"is equal to",
						"is not equal to",
						"contains",
						"does not contain",
						"has value",
						"has no value",
						"is greater than",
						"is greater than or equal to",
						"is less than",
						"is less than or equal to",
						"is valid email",
						"is valid mobile#",
						"is number",
						"is alphabet",
					].map(item => { return { text: i18n.t(item), value: item } }),
					type: "input"
				}
			});
			return [...Object.values(Rules), ...___(customVarDataOptions)];
		}
	}
}