import ecom_actions from './ecommerce/actions';
import ecomActions from './actions/ecomActions';
import emailActions from './actions/emailActions';
import facebookAutomations from './actions/facebookAutomations';
import messengerActions from './actions/messengerActions';
import smsActions from './actions/smsActions';
import userAccountActions from './actions/userAccountActions';
import webview_actions from './webviews/actions';
import landing_pages from './actions/landingPages';
import gmbActions from './actions/gmbActions.js';
import whatsAppActions from './actions/whatsAppActions.js';
import omniChannelActions from './actions/omniChannelActions.js';
import utilities from './actions/utilities.js';
import moduleOpenAi from '@/store/openai/moduleOpenAi.js';
import telegramActions from '@/store/actions/telegramActions.js';
import common from "./actions/common"
import webWidget from "./actions/webWidget.js"

const actions = {

    // /////////////////////////////////////////////
    // COMPONENTS
    // /////////////////////////////////////////////

    // Vertical NavMenu
    updateVerticalNavMenuWidth({ commit }, width) {
      commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
    },

    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
      commit('UPDATE_STARRED_PAGE', payload)
    },

    // The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_MORE', list)
    },

    // /////////////////////////////////////////////
    // UI
    // /////////////////////////////////////////////

    toggleContentOverlay({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY')
    },
    updateTheme({ commit }, val) {
      commit('UPDATE_THEME', val)
	},
	

	//APP FEATURE ACTIONS
	...ecom_actions, //<-- For Front End Ecommerce Vuex Actions
	...ecomActions, 
	...emailActions,
	...facebookAutomations,
	...smsActions,
	...userAccountActions,
  ...messengerActions,
  ...webview_actions,
  ...landing_pages,
  ...gmbActions,
  ...whatsAppActions,
  ...telegramActions,
  ...utilities,
  ...omniChannelActions,
  ...common,
  ...webWidget,
  ...moduleOpenAi.actions
}

export default actions