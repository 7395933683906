//You must have import VueRouter above
import Vue from 'vue'
import Router from 'vue-router'
import $store from '@/store'
import {excempted_paths} from '@/utils/exemptedPaths.js'
import { lazyLoader } from './helperFunctions'
import { userHaveAccess } from "@/common/helpers/helpers.js";
import { userLastActiveHandler, visibilitychangeHandler } from "@/utils/userLastActive.js";

//Routes By Service
import admin from "./admin";
import contacts from "./contacts";
import ecommerce from "./ecommerce";
import emailMarketing from "./emailMarketing";
import googleMyBusiness from "./googleMyBusiness";
import homeDashboard from "./homeDashboard";
import integrations from "./integrations";
import messengerMarketing from "./messengerMarketing";
import packages from "./packages";
import smsMarketing from "./smsMarketing";
import socialMediaAutomation from "./socialMediaAutomation";
import supportCenter from "./supportCenter";
import userAccount from "./userAccount";
import utilities from "./utilities";
import whatsAppMarketing from "./whatsAppMarketing";
import omniChannel from "./omniChannel";
import telegramMarketing from "./telegramMarketing";
import webChatWidget from "./webChatWidget.js";

const MainLayoutRoutes = {
    path: '',
    component: () => import(/* webpackChunkName: "Main" */ '@/layouts/main/Main.vue'),
    children: [
        ...admin,
        ...contacts,
        ...ecommerce,
        ...emailMarketing,
        ...googleMyBusiness,
        ...homeDashboard,
        ...integrations,
        ...messengerMarketing,
        ...packages,
        ...smsMarketing,
        ...socialMediaAutomation,
        ...supportCenter,
        ...userAccount,
        ...utilities,
        ...whatsAppMarketing,
        ...omniChannel,
        ...telegramMarketing,
        ...webChatWidget
    ],
}

MainLayoutRoutes.children = MainLayoutRoutes.children.map(route => {

    var breadcrumb = [{ title: 'Home', url: '/dashboard' }]
    if (route.path.includes("ecommerce") && route.path !== '/ecommerce') {
        breadcrumb.push({ title: "Ecommerce", url: "/dashboard/ecommerce" })
    }
    if (route.path.includes("email-marketing") && route.path !== '/email-marketing') {
        breadcrumb.push({ title: "Email Marketing", url: "/dashboard/email" })
    }
    if (
        (route.path.includes("social-media-automations") || route.path.includes("social-media-automations"))
        &&
        route.path !== '/social-media-automations'
    ) {
        breadcrumb.push({ title: "Messenger Marketing", url: "/dashboard/social-media-automations" })
    }
    if (route.path.includes("sms-marketing") && route.path !== '/sms-marketing') {
        breadcrumb.push({ title: "SMS Marketing", url: "/dashboard/sms" })
    }
    breadcrumb.push({ title: route.name, active: true })
    var meta = {
        breadcrumb: breadcrumb,
        pageTitle: route.name,
        slug: route.slug,
    }
    route.meta = meta
    return route
})

// =============================================================================
// FLOW PREVIEW SHARING
// =============================================================================
const powerSharingRoutes = {
    path: '',
    component: () => import(/* webpackChunkName: "WebviewFront" */ '@/layouts/webviews/WebviewFront.vue'),
    children: [
        {
            path: '/flow-power-sharing/:flow_id',
            name: 'Flow Power Sharing',
            component: () => import(/* webpackChunkName: "Webview" */ '@/views/MessengerIG/FlowPowerShare/FlowPowerShare.vue'),
            slug: "flow_power_sharing",
            category: "Messenger Marketing",
        },
        {
            path: '/wa-flow-power-sharing/:flow_id',
            name: 'WhatsApp Flow Power Sharing',
            component: () => import(/* webpackChunkName: "FlowPowerShare" */ '@/views/WhatsApp/FlowPowerShare/FlowPowerShare.vue'),
            slug: "whatsapp_power_sharing",
            category: "WhatsApp Marketing",
        },
    ]
}

export { MainLayoutRoutes, powerSharingRoutes };

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        // =============================================================================
        // MAIN LAYOUT ROUTES
        // =============================================================================
        MainLayoutRoutes,

        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        {
            path: '',
            component: () => import(/* webpackChunkName: "FullPage" */ '@/layouts/full-page/FullPage.vue'),
            children: [
                // =============================================================================
                // PAGES
                // =============================================================================
                {
                    path: '/home/login',
                    name: 'Login',
                    component: () => import(/* webpackChunkName: "Login" */ '@/views/Pages/login/Login.vue')
                },
                {
                    path: '/home/error-404',
                    name: 'Error 404',
                    component: () => import(/* webpackChunkName: "Error404" */ '@/views/Pages/Error404.vue')
                },
                {
                    path: '/home/forgot-password',
                    name: 'Forgot Password',
                    component: () => import(/* webpackChunkName: "ForgotPassword" */ '@/views/Pages/ForgotPassword.vue'),
                },
                {
                    path: '/home/reset-password',
                    name: 'Reset Password',
                    component: () => import(/* webpackChunkName: "ResetPassword" */ '@/views/Pages/ResetPassword.vue'),
                },
                {
                    path: '/home/register',
                    name: 'Register',
                    component: () => import(/* webpackChunkName: "Register" */ '@/views/Pages/register/Register.vue'),
                },
            ]
        },

        // =============================================================================
        // WEBVIEWS FRONT END
        // =============================================================================
        {
            path: '',
            component: () => import(/* webpackChunkName: "WebviewFront" */ '@/layouts/webviews/WebviewFront.vue'),
            children: [
                {
                    path: '/webview',
                    name: 'Webview',
                    component: () => import(/* webpackChunkName: "Webview" */ '@/views/MessengerIG/Webview/Front/Webview.vue'),
                },
            ]
        },


        // =============================================================================
        // FLOW PREVIEW SHARING
        // =============================================================================
        powerSharingRoutes,

        // =============================================================================
        // BLANK PAGE LAYOUT
        // =============================================================================
        {
            path: '',
            component: () => import(/* webpackChunkName: "BlankPage" */ '@/layouts/Blank/BlankPage.vue'),
            children: [
                {
                    path: '/page-view/:path',
                    name: 'Page View',
                    component: () => import(/* webpackChunkName: "pageBuilderPreview" */ '@/components/page-builder-components/Components/pageBuilderPreview')
                },
                {
                    path: '/page-view/',
                    name: 'Page View Preview',
                    component: () => import(/* webpackChunkName: "pageBuilderPreview" */ '@/components/page-builder-components/Components/pageBuilderPreview')
                },
            ]
        },

        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: { name: "Error 404" }
        }
    ],
})

router.beforeEach(async (to, from, next) => {

    var isAuthenticated = false
    if (typeof $store?.state?.AppActiveUser?.logged_in != "undefined") {
        isAuthenticated = true
    }

    await lazyLoader()
    if (typeof window.jQuery !== "undefined") {
        if (typeof window.jQuery('.vs-popup-primary') !== "undefined") {
            window.jQuery('.vs-popup-primary').remove(); //<-- Force remove all opened pop-ups;
        }
    }

    const {path: pathname} = to
    const isExcepted = excempted_paths.some( path => path.includes(pathname))

    if (to.name == 'Reset Password' && window.accessTokenConfirmed === true) {
        next()
    } else if (to.name == 'Register' && MMIO_DEFAULTS.IS_WHITELABEL) {
        next({ name: 'Login' })
    } else if (!isExcepted && !isAuthenticated) {
        if (to.query?.close_after_login) {
            localStorage.setItem("close_after_login", "true")
        } else {
            localStorage.removeItem("close_after_login")
        }
        next({ name: 'Login' })
    } else if (to.name == 'Login' && isAuthenticated) {
        if (to.query?.close_after_login) {
            localStorage.setItem("close_after_login", "true")
        }
        next({ name: 'Main Dashboard' })
    } else if (
        !isExcepted
        &&
        !["Pricing", "Support Center", "Support Tickets", "Account"].includes(to.name)
        &&
        accountIsExpired($store.state.AppActiveUser.expired_date)
        &&
        isAuthenticated
        &&
        $store.state.AppActiveUser.userRole != "Admin"
        &&
        to.meta.slug != "no_access"
    ) {
        if (MMIO_DEFAULTS.IS_WHITELABEL) {
            next({ name: 'No Access' })
        } else {
            next({ name: 'Pricing' })
        }
    } else if (
        !isExcepted
        &&
        !userHaveAccess(to.name)
        &&
        to.name != "No Access"
    ) {
        next({ name: 'No Access' })
    } else if (to.name == "Live Chat") {
        if (!!$store.state?.ActivePage?.page_id) {
            const page_id = $store.state.ActivePage.page_id;
            $store.commit("UPDATE_FILTERED_ACCOUNTS", [page_id]);
            $store.commit("RESET_CHAT_SUBSCRIBER_LOG_MAP");
            $store.commit("UPDATE_PAGING_MAP", { account_id: page_id })
        }
        next({ name: 'Omni-channel Chat' })
    } else if (to.name == "WhatsApp Livechat") {
        if (!!$store.state?.ActiveWhatsAppAccount?.id) {
            const wa_id = $store.state.ActiveWhatsAppAccount.id;
            $store.commit("UPDATE_FILTERED_ACCOUNTS", [wa_id]);
            $store.commit("RESET_CHAT_SUBSCRIBER_LOG_MAP");
            $store.commit("UPDATE_PAGING_MAP", { account_id: wa_id })
        }
        next({ name: 'Omni-channel Chat' })
    } else if (to.name == "Widget Live Chat") {
        if (!!$store.state?.ActiveWhatsAppAccount?.widget_id) {
            const widget_id = $store.state.ActiveWhatsAppAccount.widget_id;
            $store.commit("UPDATE_FILTERED_ACCOUNTS", [widget_id]);
            $store.commit("RESET_CHAT_SUBSCRIBER_LOG_MAP");
            $store.commit("UPDATE_PAGING_MAP", { account_id: widget_id })
        }
        next({ name: 'Omni-channel Chat' })
    } else if (to.name == "Omni-channel Chat") {
        if (!$store.state.filteredAccounts.length) {
            next({ name: 'Omni-channel Chat Account Selection' })
        } else {
            next()
        }
    } else if (to.name == "Dashboard") {
        next({ name: 'Main Dashboard' })
    } else {
        if (to.query?.close_after_login) {
            localStorage.setItem("close_after_login", "true")
        }
        next()
    }

})

router.afterEach((to, from) => {

    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }

    const conLoading = document.querySelector('.con-vs-loading')
    if (conLoading) {
        conLoading.parentNode.removeChild(conLoading);
    }

    if (typeof dataLayer != "undefined") {
        dataLayer?.push({ 'event': 'route_changed' });
    }

    if (typeof triggerUserNotice != "undefined") {
        triggerUserNotice();
    }

    let isMMIOmy = ["my.marketingmaster.io", "mmio.my"/*,"localhost:8080"*/, "app.mmio.my"].includes(window.location.host);
    if (isMMIOmy && !isExcepted && (!$store.state.AppActiveUser?.custom_data?.mailing_address || !$store.state.AppActiveUser?.custom_data?.national_id)) {
        router.push({ name: 'My Account', params: { tab: "general" } })
    }

    //Conversion API Events

    // <------------ MMIO CAPI -------------------->
    var event = {
        event_name: "PageView",
    }

    if (typeof $store.state.AppActiveUser != "undefined") {
        var user = $store.state.AppActiveUser
        var name = user.displayName ? user.displayName.split(" ") : false
        if (name) { event.first_name = name[0]; event.last_name = name[1] }
        if (user.user_login_email) { event.email = user.user_login_email }
    }

    //Browser PIXEL Event
    if (typeof fbq != "undefined") {
        localStorage.setItem(`_fb_event_id_${event.event_name}`, uuidv4())
        fbq('track', event.event_name, { eventID: localStorage.getItem(`_fb_event_id_${event.event_name}`) });
    }

    //MMIO Conversion API
    $store.dispatch("fbq", event);
    visibilitychangeHandler()
    userLastActiveHandler()

})

export default router
