export default [
  // =============================================================================
  // Omni-channel Flows
  // =============================================================================
  {
    path: "/omni-channel",
    name: "Omni-channel Flows",
    component: () =>
      import(
        /* webpackChunkName: "OmniChannelDashboard" */ "@/views/OmniChannel/Dashboard.vue"
      ),
    slug: "omni_channel_flows",
    category: "Omni-channel",
  },
  {
    path: "/omni-channel/write",
    name: "Create Omni-channel Flows",
    component: () =>
      import(
        /* webpackChunkName: "OmniChannelCreate" */ "@/views/OmniChannel/Create.vue"
      ),
    slug: "omni_channel_flows_write",
    category: "Omni-channel",
  },
  {
    path: "/omni-channel/chat",
    name: "Omni-channel Chat",
    component: () =>
      import(
        /* webpackChunkName: "OmniChannelChatDashboard" */ "@/views/OmniChannel/Chat/Dashboard.vue"
      ),
    slug: "omni_channel_chat",
    category: "Omni-channel",
  },
  {
    path: "/omni-channel/chat-settings",
    name: "Omni-channel Chat Settings",
    component: () =>
      import(
        /* webpackChunkName: "OmniChannelChatSettings" */ "@/views/OmniChannel/Chat/settings/settings.vue"
      ),
    slug: "omni_channel_chat_settings",
    category: "Omni-channel",
  },
  {
    path: "/omni-channel/chat-account-selection",
    name: "Omni-channel Chat Account Selection",
    component: () =>
      import(
        /* webpackChunkName: "OmniChannelSelectAccounts" */ "@/views/OmniChannel/Chat/SelectAccounts/SelectAccounts.vue"
      ),
    slug: "omni_channel_chat_account_selection",
    category: "Omni-channel",
  },
  {
    path: "/omni-channel/events",
    name: "Events Subscriptions",
    component: () =>
      import(
        /* webpackChunkName: "OmniChannelSelectAccounts" */ "@/views/OmniChannel/Events/Events.vue"
      ),
    slug: "omni_channel_events",
    category: "Omni-channel",
  },
];
