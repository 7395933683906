export default [
    // =============================================================================
    // Email Marketing
    // =============================================================================
    {
        path: '/email-marketing/settings',
        name: 'Email Marketing Settings',
        component: () => import( /* webpackChunkName: "EmailSettings" */ '@/views/EmailFlowBuilder/EmailSettings.vue'),
        slug: 'email_marketing_settings',
        category: "Email Marketing",
    },
    {
        path: '/email-marketing/build',
        name: 'Email Flow Builder',
        component: () => import( /* webpackChunkName: "EmailFlowBuilderVersion2" */ '@/views/EmailFlowBuilder/EmailFlowBuilderVersions/EmailFlowBuilderVersion2.vue'),
        slug: 'email_flow_builder_dashboard',
        category: "Email Marketing",
    },
    {
        path: '/email-marketing',
        name: 'Email Flow Builder Dashboard',
        component: () => import( /* webpackChunkName: "EmailFlowBuilderDashboard" */ '@/views/EmailFlowBuilder/EmailFlowBuilderDashboard.vue'),
        slug: 'email_flow_builder_dashboard',
        category: "Email Marketing",
    },
    {
        path: '/email-marketing/custom-form-builder',
        name: 'Custom Form Builder',
        component: () => import( /* webpackChunkName: "EmailFlowBuilderFormBuilder" */ '@/views/EmailFlowBuilder/FormBuilder/EmailFlowBuilderFormBuilder.vue'),
        slug: 'email_campaign_custom_forms',
        category: "Email Marketing",
    },
    {
        path: '/email-marketing/integration',
        name: 'Custom Form Integrations',
        component: () => import( /* webpackChunkName: "EmailFlowBuilderCustomFormsTable" */ '@/views/EmailFlowBuilder/FormBuilder/EmailFlowBuilderCustomFormsTable.vue'),
        slug: 'email_campaign_custom_forms',
        category: "Email Marketing",
    },
    {
        path: '/email-marketing/reports',
        name: 'Email Campaign Reports',
        component: () => import( /* webpackChunkName: "EmailFlowBuilderReports" */ '@/views/EmailFlowBuilder/EmailFlowBuilderReports.vue'),
        slug: 'email_campaign_reports',
        category: "Email Marketing",
    },
    {
        path: '/email-marketing/broadcasting',
        name: 'Email Broadcasting',
        component: () => import( /* webpackChunkName: "EmailBroadCasting" */ '@/views/EmailFlowBuilder/BroadCasting/EmailBroadCasting.vue'),
        slug: 'email_broadcasting',
        category: "Email Marketing",
    },
    {
        path: '/email-marketing/broadcasting-dashboard',
        name: 'Email Broadcast Dashboard',
        component: () => import( /* webpackChunkName: "EmailBroadCastingDashboard" */ '@/views/EmailFlowBuilder/BroadCasting/EmailBroadCastingDashboard.vue'),
        slug: 'email_broadcasting',
        category: "Email Marketing",
    },
    {
        path: '/email-marketing/template-composer',
        name: 'Template Composer',
        component: () => import( /* webpackChunkName: "TemplateComposer" */ '@/views/EmailFlowBuilder/TemplateComposer/TemplateComposer.vue'),
        slug: 'template_composer',
        category: "Email Marketing",
    },
    {
        path: '/email-marketing/email-templates',
        name: 'Email Templates',
        component: () => import( /* webpackChunkName: "EmailTemplates" */ '@/views/EmailFlowBuilder/TemplateComposer/EmailTemplates.vue'),
        slug: 'email_templates',
        category: "Email Marketing",
    },
    {
        path: '/email-marketing/optin-templates',
        name: 'Optin Templates',
        component: () => import( /* webpackChunkName: "OptinTemplatesDashboard" */ '@/views/EmailFlowBuilder/OptinTemplates/Dashboard.vue'),
        slug: 'optin_templates',
        category: "Email Marketing",
    },
    {
        path: '/email-marketing/optin-templates/compose',
        name: 'Compose Optin Template',
        component: () => import( /* webpackChunkName: "OptinTemplatesCompose" */ '@/views/EmailFlowBuilder/OptinTemplates/Compose.vue'),
        slug: 'compose_optin_templates',
        category: "Email Marketing",
    },
    {
        path: '/landing-pages',
        name: 'Landing Pages',
        component: () => import( /* webpackChunkName: "LandingPageDashboard" */ '@/views/LandingPage/LandingPageDashboard.vue'),
        slug: 'landing_pages',
        category: "Email Marketing",
    },
    {
        path: '/landing-pages/create',
        name: 'Create Landing Page',
        component: () => import( /* webpackChunkName: "LandingPageCreate" */ '@/views/LandingPage/LandingPageCreate.vue'),
        slug: 'landing_pages',
        category: "Email Marketing",
    },    
]