import state from './moduleOpenAiState.js'
import mutations from './moduleOpenAiMutations.js'
import actions from './moduleOpenAiActions.js'
import getters from './moduleOpenAiGetters.js'

export default {
  state,
  mutations,
  actions,
  getters
}
