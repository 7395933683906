<template>
  <div v-if="$oChain(model,'type')" class="generic" :class="model.type=='row'?'flex justify-center':''" v-bind="model.type=='col'?{style:{flex:model.property.style.flex}}:{}"><!---->
    <component v-if="comp" :is="comp" :parentKey="parentKey" :modelKey="modelKey" class="inner" style="position:relative;"></component>
    <slot/>
  </div>
</template>

<script>
import Row from "./Row";
import Column from "./Column";
import Atomic from "./Atomic";
window.editedModel;
export default {
  name: "Generic",
  props: {
    modelKey: {
      type: String,
      default: ""
    },
    parentKey: null
  },
  computed: {
    comp() {
      try{
        if (this.model.type === "row" || this.model.type === "block") {
          return Row;
        } else if(this.model.type === "col"){
          return Column;
        }else {
          return Atomic;
        }
      }catch(e){}

    },
    editedModel(){
      var model = {...this.model}
      if(!(model?.property instanceof Object)){
        model.property = {
          props: {},
          class: [],
          attrs: {},
          domProps: {},
          style: {}
        }
      }
      if(!(model?.property?.props instanceof Object)){
        model.property.props = {}
      }
      if(!(model?.property?.style instanceof Object)){
        model.property.style = {}
      }
      if(!(model?.property?.domProps instanceof Object)){
        model.property.domProps = {}
      }
      return model
    },
    model(){
      return this.getModel(this.modelKey)
    }
  }, 
  methods: {
    getModel(item){
      return this.$clone(this.$store.getters.GetDomObjectContent(item))
    },
  }
}
</script>

<style scoped>
.generic {
  flex: 1;
  display: flex;
  align-items: stretch;
}

.inner {
  width: 100%;
}
</style>