export default [
    // =============================================================================
    // INTEGRATIONS
    // =============================================================================     
    {
        path: '/integrations',
        name: 'Integrations',
        component: () => import( /* webpackChunkName: "Integrations" */ '@/views/Integrations/Integrations.vue'),
        slug: 'integrations',
        category: "Integrations",
    },    
]