import initMMIOaxios from "@/utils/axios"

export default {

    async getTelegramAccounts({ commit, dispatch }) {
        const axios = await initMMIOaxios()
        const { data } = await axios.get("/apis_telegram/get_accounts")
        commit("UPDATE_TELEGRAM_ACCOUNTS", data);
        dispatch("executeUpdateActualUsageLog", { moduleName: "TELEGRAM_ACCOUNTS", data });
        return data;
    },

    async getTelegramFlows({ commit, dispatch }) {
        const axios = await initMMIOaxios()
        const { data } = await axios.get("/apis_telegram/get_flows?minimal=1");
        commit("UPDATE_TELEGRAM_FLOWS", data);
        dispatch("executeUpdateActualUsageLog", { moduleName: "NUMBER_OF_TELEGRAM_FLOWS_USER_CAN_CREATE", data });
        return data;
    },

    async getTelegramFlowsSingle(dummy, { id, postbacks_only }) {
        const axios = await initMMIOaxios();
        const { data } = await axios.get(`/apis_telegram/get_flows/${id}?postbacks_only=` + ((postbacks_only == true) ? 1 : 0));
        return data;
    },

    async getTelegramBroadcasts({ commit }) {
        const axios = await initMMIOaxios()
        const { data } = await axios.get("/apis_telegram/get_broadcasts");
        commit("UPDATE_TELEGRAM_BROADCASTS", data);
    },

    async getTelegramSequences({ commit }) {
        const axios = await initMMIOaxios()
        const { data } = await axios.get("/apis_telegram/get_sequences");
        commit("UPDATE_TELEGRAM_SEQUENCES", data);
    }
}