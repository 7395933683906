export default {
    //Ecommerce
    StoresAll: [],
    Stores: [],
    Products: [],
    Categories: [],
    Shipping: [],
    Coupons: [],
    Orders: [],
    ActiveStore: {},
    Notifications: [],

}