import { debounce } from 'lodash';
import initMMIOaxios from "@/utils/axios"

export default {


    getOmniChannelFlows: debounce(async function ({ commit, state }, options = {}) {
		const axios = await initMMIOaxios();
		options.small = true;
		let enpoint = "/apis_omnichannel/get_flows";
		if (_isObj(options)) {
			const params = new URLSearchParams(options).toString();
			enpoint = enpoint + "?" + params;
		}
		const { data } = await axios.get(enpoint);
		commit('UPDATE_OMNICHANNEL_FLOWS', data);
    }, 1000, { leading: true, trailing: false }),

	async getOmniChannelFlowsSingle(dummy, options = {}) {
		if (!options?.id) { return {}; }
		const axios = await initMMIOaxios();
		const opt = {
			id: options.id,
			postbacks_only: !!options?.postbacks_only ? 1 : 0,
			channel: options?.channel,
		}
		const params = new URLSearchParams(opt).toString();
		const enpoint = "/apis_omnichannel/get_flows?" + params;
		const { data } = await axios.get(enpoint);
		return Array.isArray(data) ? data[0] : {};
	},

}