export default {
    // /////////////////////////////////////////////
    // OPENAI EMBEDDINGS
    // /////////////////////////////////////////////

    UPDATE_OPENAI_EMBEDDINGS(state, payload) {
        state.OpenAiEmbeddings = payload.map(item => Object.seal(item));
    },

    UPDATE_OPENAI_MODELS(state, payload) {
        state.OpenAiModels = payload.map(item => Object.seal(item));
    },

    UPDATE_OPENAI_WEB_DATA_SOURCES(state, payload) {
        state.OpenAiWebScrapeDataSource = payload.map(item => Object.seal(item));
    },

}