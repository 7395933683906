export default [
    {
        path: '/web-chat-widget/accounts',
        name: 'Chat Widget Accounts',
        slug: 'web_widget_accounts',
        category: "Web Chat Widget",
        component: () => import(/* webpackChunkName: "WebChatDashboard" */ '@/views/WebChatWidget/Accounts/WebChatDashboard.vue'),
    },
    {
        path: '/web-chat-widget/accounts/write',
        name: 'Write Chat Widget Account',
        slug: 'web_widget_account_write',
        category: "Web Chat Widget",
        component: () => import(/* webpackChunkName: "WebChatAccountsCreate" */ '@/views/WebChatWidget/Accounts/WebChatAccountsCreate.vue'),
    },
    {
        path: '/web-chat-widget/flow-builder',
        name: 'Chat Widget Chatbots',
        slug: 'web_widget_flow_dashboard',
        category: "Web Chat Widget",
        component: () => import(/* webpackChunkName: "WebChatFlow" */ '@/views/WebChatWidget/Flow/WebChatFlow.vue'),
    },
    {
        path: '/web-chat-widget/flow-builder/create',
        name: 'Create Chat Widget Chatbots',
        slug: 'web_widget_flow_create',
        category: "Web Chat Widget",
        component: () => import(/* webpackChunkName: "FlowBuilderV2" */ '@/views/MessengerIG/FlowBuilderVersions/FlowBuilder.vue'),
        parent: { name: "Chatbot Flow Builder", url: "/web-chat-widget/flow-builder" },
    },
    {
        path: '/web-chat-widget/subscribers',
        name: 'Chat Widget Subscribers',
        slug: 'web_widget_subscribers',
        category: "Web Chat Widget",
        component: () => import(/* webpackChunkName: "WebChatSubscribers" */ '@/views/WebChatWidget/Subscribers/WebChatSubscribers.vue'),
    },
    {
        path: '/web-chat-widget/live-chat',
        name: 'Widget Live Chat',
        slug: 'web_widget_live_chat',
        category: "Web Chat Widget",
        component: () => import(/* webpackChunkName: "OmniChannelDashboard" */ '@/views/OmniChannel/Chat/Dashboard.vue'),
    },
    {
        path: '/web-chat-widget/defaults',
        name: 'Widget Defaults',
        slug: 'web_widget_defaults',
        category: "Web Chat Widget",
        component: () => import(/* webpackChunkName: "WebChatWidgetChatbotDefaults" */ '@/views/WebChatWidget/Defaults/ChatbotDefaults.vue'),
    }
]