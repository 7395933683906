export default [
    // =============================================================================
    // Social Media Automation
    // =============================================================================     
    {
        path: '/social-media-automations/comment-reply',
        name: 'Comment Auto Reply',
        component: () => import( /* webpackChunkName: "CommentReplyDashboard" */ '@/views/FacebookAutomation/CommentReplyDashboard.vue'),
        slug: 'comment_auto_reply',
        category: "Social Media Automation",
    },
    {
        path: '/social-media-automations/interest-finder',
        name: 'Interest Finder',
        component: () => import( /* webpackChunkName: "InterstFinder" */ '@/views/FacebookAutomation/FacebookAdsHelpers/InterstFinder.vue'),
        slug: 'insterest_finder',
        category: "Social Media Automation",
    },
    {
        path: '/social-media-automations/pre-recorded-livestream',
        name: 'Livestream',
        component: () => import( /* webpackChunkName: "LiveStream" */ '@/views/LiveStream/LiveStream.vue'),
        slug: 'live_stream',
        category: "Social Media Automation",
    },
    {
        path: '/post-randomizer',
        name: 'Post Randomizer',
        component: () => import( /* webpackChunkName: "PostRandomizerDashboard" */ '@/views/PostRandomizer/PostRandomizerDashboard.vue'),
        slug: 'post_randomizer',
        category: "Social Media Automation",
    },
    {
        path: '/post-randomizer/create',
        name: 'Create Post Randomizer',
        component: () => import( /* webpackChunkName: "PostRandomizerCreate" */ '@/views/PostRandomizer/PostRandomizerCreate.vue'),
        slug: 'post_randomizer',
        category: "Social Media Automation",
    },    
]