export default {
    GetOmniChannelFlows(state) {
		const OmniChannelFlows = state.OmniChannelFlows;
        //First, remove all null and undefined keys
		Object.keys(localStorage)
		.forEach(key => {
			if (key.includes('null') || key.includes('undefined')) {
				localStorage.removeItem(key)
			}
		})
		//Format valid local cache flows
		let cachedFlows = Object.keys(localStorage)
            .filter(key => key.includes('OmniFlowBuilderData') && !key.includes('Database') && !key.includes('null') && !key.includes('undefined'))
            .map(key => {
                return { ...JSON.parse(localStorage[key]), ...{ localkey: key } }
            })
            .map(cData => {
                if ((isNaN(Number(cData.post_id)) ? 0 : Number(cData.post_id)) < 1) {
                    let obj = {
                        last_updated: cData.last_updated,
                        name: decodeURIComponent(cData.name),
                        publish_status: "draft",
                        localkey: cData.localkey,
						created_at: cData.last_updated,
						suitcase_uid: 0,
                    }
                    if (cData.is_clone == 1) { obj.is_clone = 1; }
                    for (const key of Object.keys(cData)) {
                        if (["id", "nodes"].includes(key)) { continue; }
                        delete cData[key];
                    }
                    obj.flow_data = encodeURIComponent(JSON.stringify(cData));
                    return obj;
                } else {
                    return false
                }
            })
            .filter(item => item);
        let final = [...cachedFlows, ...___(OmniChannelFlows)];
        return final;
    }
}