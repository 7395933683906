export default [
    // =============================================================================
    // Packages
    // ============================================================================= 
    {
        path: '/home/buy_package',
        name: 'Pricing',
        component: () => import(/* webpackChunkName: "Packages" */ '@/views/Package/Packages.vue'),
        slug: "pricing",
        category: "Packages",
    },
    {
        path: '/home/penjana-thank-you',
        name: 'PenjanaBos Program Confirmation',
        component: () => import(/* webpackChunkName: "PenjanaThankYou" */ '@/views/Package/Parts/PenjanaThankYou.vue'),
        slug: "penjana_thank_you",
        category: "Packages",
    },    
]