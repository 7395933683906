export default [
    // =============================================================================
    // Support Center
    // =============================================================================     
    {
        path: '/support-center',
        name: 'Support Center',
        component: () => import( /* webpackChunkName: "SupportCenter" */ '@/views/SupportCenter/SupportCenter.vue'),
        slug: "support_center",
        category: "Support Center",
    },
    {
        path: '/support-center/ticket',
        name: 'Support Tickets',
        component: () => import( /* webpackChunkName: "CreateTicket" */ '@/views/SupportCenter/CreateTicket.vue'),
        slug: "support_center",
        category: "Support Center",
    },    
]