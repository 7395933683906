const ecom_mutations = {
    __CLEAR_DATA(state){
        if(getUrlParam("action") == "clear"){
            state.__storeDetails= []
            state.__cartItems= []
            state.__storeProducts= []
            state.__storeCategories= []
            state.__storeShipping= []
            state.__cartInstance= []
            state.__savedAddress = {}
            state.__coupons= []
            state.__subscriberID = ""
        }

    },
    __STORE_DETAILS(state, payload){
        if(payload.length){
            var tmp = payload[0]
            try{
                tmp.shipping_location = JSON.parse(tmp.shipping_location)
            }catch(e){}            
        }else{
            var tmp = {}
        }
        state.__storeDetails = tmp
    },
    __STORE_PRODUCTS(state, payload){
        var products = payload.map(prod => {
            prod.featured_images = prod.featured_images.split(",")
            prod.variants_data = JSON.parse(prod.variants_data)
            prod.gallery_keys = JSON.parse(prod.gallery_keys)
            return prod
        })
        state.__storeProducts = products
    },
    __STORE_CATEGORIES(state, payload){
        state.__storeCategories = payload
    },
    __STORE_SHIPPING(state, payload){
        state.__storeShipping = payload
    },
    __ADD_TO_CART(state, payload) {
        const cartItem = ___(payload)
        delete cartItem.reviews
        delete cartItem.variants_map
        delete cartItem.featured_images
        delete cartItem.gallery_keys
        delete cartItem.product_description
        var in_cart = state.__cartItems?.filter(cart => cart.cart_id == cartItem.cart_id) || []
        if(in_cart.length){
            state.__cartItems = state?.__cartItems?.map(item => {
                if(item.cart_id == cartItem.cart_id){
                    item.itemQuantity = Number(item.itemQuantity) + Number(cartItem.itemQuantity)
                }
                return item
            }) || []
        }else{
            state.__cartItems.push(cartItem)
        }
    },
    __UPDATE_CART_ITEM_QTY(state, event){
        state.__cartItems = state?.__cartItems?.map(item => {
            if(item.cart_id == event.cart_id){
                var temp = ___(item)
                item.itemQuantity = event.qty
                if(temp.has_variant == "0"){
                    if(typeof item.itemStock != 'number'){
                        item.itemStock = temp.stock_item
                    }
                    item.itemStock = Number(temp.stock_item) - Number(event.qty)
                }else{
                    var chosenVariant = temp.chosen_variant
                    temp.variants_data.map(_var => {
                        if(_var.id == chosenVariant.id){
                            if(typeof item.itemStock != 'number'){
                                item.itemStock = _var.stock
                            }
                            item.itemStock = Number(_var.stock) - Number(event.qty)
                        }
                        return _var
                    })

                }

                //__console.log("test.itemQuantity", test.itemQuantity, "test.stock_item", test.stock_item, "Number(test.stock_item) - Number(test.itemQuantity)", Number(test.stock_item) - Number(test.itemQuantity))
            }
            return item
        }) || []
    },
    __REMOVE_FROM_CART(state, cart_id){
        state.__cartItems = state.__cartItems?.filter(item => item.cart_id != cart_id) || []
    },
    __RESUME_CART_INSTANCE(state, payload){
        state.__cartItems = payload
    },
    __SAVE_ADDRESS(state, payload){
        state.__savedAddress = payload
    },
    __EMPTY_CART(state){
        state.__cartItems = []
    },
    __STORE_COUPONS(state, payload){
        state.__coupons = payload
    },
    __UPDATE_SUBSCRIBER_ID(state) { 
        if (getUrlParam("subscriber_id")) {
            state.__subscriberID = getUrlParam("subscriber_id");
        }        
    },
    __UPDATE_PRODUCT_DETAILS(state, payload) { 
        state.__productDetails = payload      
    },
    __UPDATE_USER_PAGES(state, payload) { 
        var __userPages = [];
        try{
            __userPages = payload.map( page => {
                page.slug = page.name.toLocaleLowerCase().replace(/[^a-zA-Z ]/g, "").replace(/ /g, "-")
                return page
            })
        }catch(e){}
        state.__userPages = __userPages
    },
    __UPDATE_EFFECTIVE_OFFER(state, payload){
        state.__effectiveOffer = payload;
    },
    __UPDATE_CURRENT_OFFER_PATH(state, payload){
        state.__currentOfferPath = payload;
    },
    __UPDATE_ACCEPTED_OFFERS(state, payload){
        state.__acceptedOffers = payload;
    },
    __UPDATE_FINAL_ORDER_DATA(state, payload){
        state.__finalOrderData = payload;
    },
    __UPDATE_STORE_OFFERS(state, payload){
        state.__storeOffers = payload;
    },    
}
export default ecom_mutations

function getUrlParam(_key) {
    if (history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        return searchParams.get(_key);
    }
}

function ___(val){
    return JSON.parse(JSON.stringify(val))
}