const logoMap = {
    'omnichannel-utility': "https://mmio-store.b-cdn.net/main/global.png",
    messenger: require("@/assets/svgs/messenger.svg"),
    instagram: require("@/assets/svgs/instagram.svg"),
    whatsapp: "https://mmio-store.b-cdn.net/whatsapp/whatsapp-logo.png",
    gmb: require('@/assets/images/google-messages.png'),
    telegram : "https://mmio-store.b-cdn.net/main/telegram.png",
    widget: "https://mmio-store.b-cdn.net/main/web-widget-icon-2.png",
    default: "https://mmio-store.b-cdn.net/main/common-channel-logo.png",
}

const chatbotPaths = [
    "/whatsapp/flows/create",
    "/social-media-automations/build",
    "/gmb/flows/create",
    "/email-marketing/build",
    "/omni-channel/write",
    "/telegram/flows/create",
    "/ecommerce/upsells/create",
    "/omni-channel/chat",
    "/web-chat-widget/flow-builder/create",
];

const channelMap = {
    "/whatsapp/flows/create": "whatsapp",
    "/social-media-automations/build": "fb/ig",
    "/gmb/flows/create": "gmb",
    "/email-marketing/build": "email",
    "/omni-channel/write": "omnichannel-utility",
    "/telegram/flows/create": "telegram",
    "/ecommerce/upsells/create": "funnels",
    "/web-chat-widget/flow-builder/create": "widget"
}

export {logoMap,chatbotPaths,channelMap};