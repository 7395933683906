<template>
    <div class="w-full">
        <div class="product-carousel-wrap">
            <swiper
                :options="swiperOptionThumbs"
                class="gallery-thumbs"
                ref="swiperThumbs"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                @reachEnd="endSlideHandler"
            >
                <swiper-slide v-for="(item, k) in productList" :key="k">
                    <ProductCard :data="item"/>
                </swiper-slide>
            </swiper>        
        </div>
    </div>
</template>

<script>

	import "swiper/dist/css/swiper.min.css";
	import { swiper, swiperSlide } from "vue-awesome-swiper";
    import "swiper/dist/css/swiper.min.css";
    import baseMixins from './baseMixins'
    import filterMixins from './shopFilter.js'
    import {debounce} from 'lodash'
    import ProductCard from "./ProductCard.vue"
    export default {
        mixins: [baseMixins, filterMixins],
        components: {
            swiper,
            swiperSlide,
            ProductCard
        },
        props: {
            data: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data(){
            return {
				swiperOptionThumbs: {
                    slidesPerView: 4,
					spaceBetween: 10,
					centeredSlides: false,
					slidesPerView: "auto",
					touchRatio: 0.2,
					slideToClickedSlide: true,
				},
                dummyProduct: {
                    product_name: "Amazing Dummy Product",
                    thumbnail: "https://via.placeholder.com/500",
                    has_variant: 0,
                    original_price: 100,
                    is_sale: 1,
                    sell_price: 80,
                    featured_images: [],
                }
            }
        },
        computed: {
            productList(){
                let output = [];
                if(this.data.type=="category"){
                    let category_id = this.data.value
                    output = this.activeProducts
                    .filter(p=>p.category_id.split(",").some(cat=>cat==category_id))
                    .filter(p=>p.is_display==1)
                }else if(this.data.type=="products"){
                    let products = this.data.value
                    __console.log({products})
                    output = this.activeProducts.filter(prod=>products.includes(prod.id))
                }
                if(!output.length){
                    for(let i of Array(12)){
                        output.push(this.dummyProduct)
                    }
                }
                __console.log({output})
                return output;
            },
            activeProducts(){
                return ___(this.$store.state.Products).filter(prod=>prod.store_id==this.$store.state.ActiveStore?.id)
            },
            categories(){
                return this.$store.state.Categories
            }
        },
        methods: {
            endSlideHandler: debounce(function(){
                //this.addProducts()
            },500)
        },
        mounted(){
            __console.log()
        }

    }
</script>
<style lang="scss" scoped>
    .swiper-slide {
        max-width: 250px;
    }
</style>