const momentJsLazyLoader = function(){
    //var moment = require('moment-timezone');
    //window.moment = moment

    if(typeof moment == "undefined"){return}

    window.userTimezone = getUserTimeZone();
    window.serverTimezone = 'Asia/Manila';

    //Server to User
    const serverToUserTz = function(dateStr) {

        // Create a date object from the string and its current timezone
        const validDateStr = window.isValidDateStr(dateStr) ? dateStr : (window.isValidDateStr(dateStr + " GMT+0800") ? dateStr + " GMT+0800" : null);
        let dateInOriginalZone = new Date(validDateStr);

        // Use Intl.DateTimeFormat to format the date in the target timezone
        let formatter = new Intl.DateTimeFormat('en-US', {
            timeZone: window.userTimezone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        });

        let dateParts = formatter.formatToParts(dateInOriginalZone);

        let formattedDate = `${dateParts.find(part => part.type === 'year').value}-${dateParts.find(part => part.type === 'month').value}-${dateParts.find(part => part.type === 'day').value} ${dateParts.find(part => part.type === 'hour').value - 1}:${dateParts.find(part => part.type === 'minute').value}:${dateParts.find(part => part.type === 'second').value}`;

        return formattedDate;
    }
    window.serverToUserTz = serverToUserTz;

    //User to Server
    const userToServerTZ = function(val, format='YYYY-MM-DD HH:mm:ss'){
        var baseDate    = moment.tz(moment(val,format).format(format), window.userTimezone);
        var targetDate = baseDate.clone().tz(serverTimezone);
        var output = targetDate.format(format)
        return output == 'Invalid date'?val:output;
    }
    window.userToServerTZ = userToServerTZ;
}

const getUserTimeZone = () => {
  try {
    const geoIPData = JSON.parse(localStorage.userGeoIpCache)
    return geoIPData.time_zone.name
  } catch (error) {}
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
export default momentJsLazyLoader
