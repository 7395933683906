import {i18n} from "@/i18n";
const BLOG_URL = "https://blog.marketingmaster.io/";

const Integrations = {
    "Email Integrations" : {
        name: "Email Integrations",
        items: [
            {
                name: "Amazon SES",
                logo: require("@/views/Integrations/logos/aws-ses-logo.png"),
                availability: true,
                fields: [
                    {   
                        label : i18n.t("region"),
                        type : "options",
                        options : [
                            {label:'US East (Ohio)', value:'us-east-2'},
                            {label:'US East (N. Virginia)', value:'us-east-1'},
                            {label:'US West (N. California)', value:'us-west-1'},
                            {label:'US West (Oregon)', value:'us-west-2'},
                            {label:'Africa (Cape Town)', value:'af-south-1'},
                            {label:'Asia Pacific (Hong Kong)', value:'ap-east-1'},
                            {label:'Asia Pacific (Mumbai)', value:'ap-south-1'},
                            {label:'Asia Pacific (Osaka)', value:'ap-northeast-3'},
                            {label:'Asia Pacific (Seoul)', value:'ap-northeast-2'},
                            {label:'Asia Pacific (Singapore)', value:'ap-southeast-1'},
                            {label:'Asia Pacific (Sydney)', value:'ap-southeast-2'},
                            {label:'Asia Pacific (Tokyo)', value:'ap-northeast-1'},
                            {label:'Canada (Central)', value:'ca-central-1'},
                            {label:'China (Beijing)', value:'cn-north-1'},
                            {label:'China (Ningxia)', value:'cn-northwest-1'},
                            {label:'Europe (Frankfurt)', value:'eu-central-1'},
                            {label:'Europe (Ireland)', value:'eu-west-1'},
                            {label:'Europe (London)', value:'eu-west-2'},
                            {label:'Europe (Milan)', value:'eu-south-1'},
                            {label:'Europe (Paris)', value:'eu-west-3'},
                            {label:'Europe (Stockholm)', value:'eu-north-1'},
                            {label:'Middle East (Bahrain)', value:'me-south-1'},
                            {label:'South America (São Paulo)', value:'sa-east-1'},
                            {label:'AWS GovCloud (US-East)', value:'us-gov-east-1'},
                            {label:'AWS GovCloud (US-West)', value:'us-gov-west-1'}
                        ]
                    },
                    {
                        label : "key",
                        type : "string",
                    },
                    {
                        label : "secret",
                        type : "password",
                    }                        
                ],
                instructon: BLOG_URL + "blog/how-to-integrate-amazon-ses-with-mmio"
            },                        
            {
                name: "SendGrid",
                logo: require("@/views/Integrations/logos/sendgrid.png"),
                availability: true,
                fields: [
                    {
                        label : "api_key",
                        type : "string",
                    },
                ],
                instructon: BLOG_URL + "blog/how-to-integrate-sendgrid-with-mmio"
            },
            {
                name: "SMTP",
                logo: require("@/views/Integrations/logos/smtp.jpg"),
                availability: true,
                fields: [
                    {
                        label : "default_from_email",
                        type : "string",
                    },
                    {
                        label : "username",
                        type : "string",
                    },
                    {
                        label : "password",
                        type : "password",
                    },
                    {
                        label : "host",
                        type : "string",
                    },
                    {
                        label : "port",
                        type : "string",
                    },
                    {
                        label : "secure",
                        type : "options",
                        options : [
                            {label:'TLS', value:'tls'},
                            {label:'SSL', value:'ssl'},
                            {label:'None', value:'none'},
                        ]
                    },                      
                ],
                instructon: BLOG_URL + "blog/how-to-integrate-gmail-with-mmio"
            }                       
        ]
    },
    "Ecommerce Integrations" : {
        name: "Ecommerce Integrations",
        items: [
            {
                name: "PayPal",
                logo: require("@/views/Integrations/logos/paypal.png"),
                availability: true,
                fields: [
                    {
                        label : "[Sandbox] Client-id",
                        type : "string",
                    },
                    {
                        label : "[Sandbox] Secret",
                        type : "string",
                    },
                    {
                        label : "[Live] Client-id",
                        type : "string",
                    },
                    {
                        label : "[Live] Secret",
                        type : "string",
                    },                     
                ],
                instructon: BLOG_URL + "blog/how-to-integrate-paypal-with-mmio"               
            },
            {
                name: "Stripe",
                logo: require("@/views/Integrations/logos/stripe.png"),
                availability: true,
                fields: [
                    {
                        label : "[Development] Publishable Key",
                        type : "string",
                    },
                    {
                        label : "[Development] Secret Key",
                        type : "string",
                    },
                    {
                        label : "[Live] Publishable Key",
                        type : "string",
                    },
                    {
                        label : "[Live] Secret Key",
                        type : "string",
                    },                     
                ],
                instructon: BLOG_URL + "blog/how-to-integrate-stripe-with-mmio"
            },
            {
                name: "Paymongo",
                logo: require("@/views/Integrations/logos/paymongo.png"),
                availability: true,
                fields: [
                    {
                        label : "[Test] Public Key",
                        type : "string",
                    },
                    {
                        label : "[Test] Secret Key",
                        type : "string",
                    },
                    {
                        label : "[Live] Public Key",
                        type : "string",
                    },
                    {
                        label : "[Live] Secret Key",
                        type : "string",
                    },                    
                ],
                instructon: BLOG_URL + "blog/how-to-integrate-paymongo-with-mmio"                       
            },
            {
                name: "Gkash",
                logo: require("@/views/Integrations/logos/Gkash.png"),
                availability: true,
                fields: [
                    {
                        label : "[Testing] Merchant ID",
                        type : "string",
                    },
                    {
                        label : "[Testing] Signature Key",
                        type : "string",
                    },
                    {
                        label : "[Live] Merchant ID",
                        type : "string",
                    },
                    {
                        label : "[Live] Signature Key",
                        type : "string",
                    },
                ],
                //instructon: BLOG_URL + "blog/how-to-integrate-stripe-with-mmio"
            },
            {
                name: "RazorPay",
                logo: require("@/views/Integrations/logos/razorpay.png"),
                availability: true,
                fields: [
                    {
                        label : "[Testing] Key ID",
                        type : "string",
                    },
                    {
                        label : "[Testing] Secret Key",
                        type : "string",
                    },
                    {
                        label : "[Live] Key ID",
                        type : "string",
                    },
                    {
                        label : "[Live] Secret Key",
                        type : "string",
                    },
                ],
                //instructon: BLOG_URL + "blog/how-to-integrate-stripe-with-mmio"
            },                
            {
                name: "WooCommerce",
                logo: require("@/views/Integrations/logos/woo.png"),
                availability: true,
                action: {
                    type: "redirect",
                    redirect: {
                        type: "internal",
                        value: "WooCommerce Plugins"
                    }
                }
            },
            {
                name: "Shopify",
                logo: require("@/views/Integrations/logos/shopify.png")
            },
            {
                name: "Lazada",
                logo: require("@/views/Integrations/logos/lazada.jpg")
            },
        ]
    },
    "Artificial Intelligence" : {
        name: "A.I. Integrations",
        items: [
            {
                name: "OpenAI",
                logo: require("@/views/Integrations/logos/openai.png"),
                availability: true,
                fields: [
                    {
                        label : "access_token",
                        type : "string",
                    }
                ],
                instructon: BLOG_URL + "blog/how-to-integrate-openai-with-mmio/"
            },
        ]
    },
    "Chatbot Integrations" : {
        name: "Chatbot Integrations",
        items: [
            {
                name: "SendFox",
                logo: require("@/views/Integrations/logos/SendFox.png"),
                availability: true,
                fields: [
                    {
                        label : "access_token",
                        type : "string",
                    }
                ],
                instructon: BLOG_URL + "blog/how-to-integrate-sendfox-with-mmio/"
            },
            {
                name: "MailChimp",
                logo: require("@/views/Integrations/logos/Mailchimp_logo.png"),
                availability: true,
                fields: [
                    {
                        label : "access_token",
                        type : "string",
                    }
                ],
                instructon: BLOG_URL + "blog/how-to-integrate-mailchimp-with-mmio/"
            },
            {
                name: "ActiveCampaign",
                logo: require("@/views/Integrations/logos/activecampaign.png"),
                availability: true,
                fields: [
                    {
                        label : "access_token",
                        type : "string",
                    },
                    {
                        label : "api_url",
                        type : "string",
                    }                        
                ],
                instructon: BLOG_URL + "blog/how-to-integrate-activecampaign-with-mmio/"
            },
            {
                name: "Klaviyo",
                logo: require("@/views/Integrations/logos/klaviyo-logo-1000.png"),
                availability: true,
                fields: [
                    {
                        label : "api_key",
                        type : "string",
                    }
                ],
                instructon: BLOG_URL + "blog/how-to-integrate-klaviyo-with-mmio/"
            },                
            {
                name: "GetResponse",
                logo: require("@/views/Integrations/logos/GetResponse.png"),
                availability: true,
                fields: [
                    {
                        label : "api_key",
                        type : "string",
                    }
                ],
                instructon: BLOG_URL + "blog/how-to-integrate-getresponse-with-mmio/"
            },
           
        ]
    },
    "Workflow Automations" : {
        name: "Workflow Automations",
        items: [
            {
                name: "Pabbly Connect",
                logo: require("@/views/Integrations/logos/pabbly-connect.png"),
                availability: true,
                action: {
                    type: "redirect",
                    redirect: {
                        type: "external",
                        value: "https://www.pabbly.com/connect/integrations/marketing-master-io/"
                    }
                },
                instructon: "https://www.pabbly.com/connect/integrations/marketing-master-io/"
            },
            {
                name: "Zapier",
                logo: require("@/views/Integrations/logos/zapier.png")
            },
            {
                name: "Integromat",
                logo: require("@/views/Integrations/logos/Integromat.png")
            },                        
        ]
    },
    "Whitelabel DNS Config" : {
        name: "Whitelabel DNS Config Provider",
        items: [
            {
                name: "CloudFlare",
                logo: require("@/views/Integrations/logos/cloudflare-logo.png"),
                availability: true,
                fields: [
                    {
                        label : "access_token",
                        type : "string",
                    },
                    {
                        label : "cloudflare_email",
                        type : "string",
                    }
                ],
                instructon: BLOG_URL + "blog/how-to-integrate-cloudflare-with-mmio/"
            }                      
        ]
    },
}

//Temporarily remove ChatGPT/OpenAI related functions for whitelabel
if ( window.MMIO_DEFAULTS?.IS_WHITELABEL === true ) {
    delete Integrations["Artificial Intelligence"]
}

export default {
    //Integrations
    Integrations: Object.values(Integrations),
    userIntegrations: [],
    ChatbotIntegrationEmailLists: {
        "SendFox": [],
        "MailChimp": [],
        "ActiveCampaign": [],
        "GetResponse": [],
        "Klaviyo": []
    },
}