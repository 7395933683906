import { debounce } from 'lodash';
import initMMIOaxios from "@/utils/axios"
const leading = true;
const trailing = false;

const ecomActions = {
	// /////////////////////////////////////////////
	// Ecommerce 
	// /////////////////////////////////////////////	

	getUserStores: debounce(async function ({ commit, dispatch }) {
		var payload = await get_user_stores()
		commit('USER_STORES_ALL', payload)
		commit('UPDATE_ACTIVE_STORE');
		dispatch("executeUpdateActualUsageLog", { moduleName: "E_COMMERCE_FEATURE_NUMBER_OF_STORES" })
	}, 1000, { leading, trailing }),

	getUserProducts: debounce(async function ({ commit }) {
		var payload = await get_user_products()
		commit('USER_PRODUCTS', payload)
	}, 1000, { leading, trailing }),

	getUserCategories: debounce(async function ({ commit }) {
		var payload = await get_user_categories()
		commit('USER_CATEGORIES', payload)
	}, 1000, { leading, trailing }),

	getUserShipping: debounce(async function ({ commit }) {
		var payload = await get_user_shipping()
		commit('USER_SHIPPING', payload)
	}, 1000, { leading, trailing }),

	getUserCoupons: debounce(async function ({ commit }) {
		var payload = await get_user_coupons()
		commit('USER_COUPONS', payload)
	}, 1000, { leading, trailing }),

	getUserOrders: debounce(async function ({ commit }) {
		var payload = await get_user_orders()
		commit('USER_ORDERS', payload)
	}, 1000, { leading, trailing }),

	getEcomNotifications: debounce(async function ({ commit }) {
		var payload = await get_user_notifications()
		commit('ECOM_NOTIFICATIONS', payload)
	}, 1000, { leading, trailing }),

	async encryptData({ state }, data) {
		if (typeof data != 'string') { return __console.log("data not a string") }
		const libSodiumLazyLoader = () => import(/* webpackChunkName: "libSodiumLazyLoader" */ "../../components/libSodiumLazyLoader")
		let m = await libSodiumLazyLoader();
		let fxns = m.default()
		const key = await fxns.getSecretKey()
		let encrypted = await fxns.encryptMessage(data, key)
		return encrypted;
	},

	async getUserPageBuilderPages({ commit, dispatch }) {
		const axios = await initMMIOaxios()
		const resp = await axios.get("/apis_pages/get_pages")
		__console.log("getUserPageBuilderPages --> ", resp)
		commit("UPDATE_PAGE_BUILDER_PAGES", resp.data)
		dispatch("executeUpdateActualUsageLog", { moduleName: "PAGE_BUILDER_LIMIT_ON_NUMBER_OF_PAGES_USER_CAN_CREATE" })
	}

}

export default ecomActions

//*************** HELPER FUNCTIONS ***************//

async function get_user_stores() {
	const axios = await initMMIOaxios()
	const {data} = await axios.get("/apis_ecom/store_main?action=get")
	return data;
}

async function get_user_products() {
	const axios = await initMMIOaxios()
	const {data} = await axios.post("/apis_ecom/product_main?action=get")
	return data;
}

async function get_user_categories() {
	const axios = await initMMIOaxios()
	const {data} = await axios.post("/apis_ecom/category_main?action=get")
	return data;
}

async function get_user_shipping() {
	const axios = await initMMIOaxios()
	const {data} = await axios.post("/apis_ecom/shipping_main?action=get")
	return data;
}

async function get_user_coupons() {
	const axios = await initMMIOaxios()
	const {data} = await axios.post("/apis_ecom/coupon_main?action=get")
	return data;
}

async function get_user_orders() {
	const axios = await initMMIOaxios()
	const {data} = await axios.post("/apis_ecom/orders_main?action=get")
	return data;
}

async function get_user_notifications() {
	const axios = await initMMIOaxios()
	const {data} = await axios.post("/apis_ecom/notification_main?action=get")
	return data;
}