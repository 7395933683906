export default [
    // =============================================================================
    // User Account
    // ============================================================================= 
    {
        path: '/my-account/user-settings',
        name: 'My Account',
        component: () => import(/* webpackChunkName: "UserSettings" */ '@/views/UserSettings/UserSettings.vue'),
        slug: "account_settings",
        category: "User Account",
    },
    {
        path: '/my-account/team-members',
        name: 'Team Members',
        component: () => import(/* webpackChunkName: "TeamMembers" */ '@/views/TeamMembers/TeamMembers.vue'),
        slug: "team_member",
        category: "User Account",
    },
    {
        path: '/my-account/no-access',
        name: 'No Access',
        component: () => import(/* webpackChunkName: "NoAccess" */ '@/views/Pages/NoAccess.vue'),
        slug: "no_access",
        category: "User Account",
    },
    {
        path: '/my-account/mmio-connect-terms-and-conditions',
        name: 'MMIO Connect Terms And Conditions',
        component: () => import(/* webpackChunkName: "NoAccess" */ '@/views/Admin/Connect/Terms.vue'),
        slug: "mmio_connect_terms_and_conditions",
        category: "User Account",
    }    
]