import initMMIOaxios from "@/utils/axios"
export default {
    get: async (url, config) => {
        const axios = await initMMIOaxios();
        return await axios.get(url, config);
    },
    post: async (url, config) => {
        const axios = await initMMIOaxios();
        return await axios.post(url, config);
    },
    delete: async (url, config) => {
        const axios = await initMMIOaxios();
        return await axios.delete(url, config);
    },
    put: async (url, config) => {
        const axios = await initMMIOaxios();
        return await axios.put(url, config);
    },
    patch: async (url, config) => {
        const axios = await initMMIOaxios();
        return await axios.patch(url, config);
    }
}