import axios from 'axios'

const isDevMode = typeof window.webpackHotUpdate === 'function';
const axiosInstance = axios.create({
    baseURL: isDevMode ? process.env.VUE_APP_DEV_SERVER_URL : process.env.VUE_APP_API_SERVER_URL
});

const ecom_actions = {

    async __getStoreDetails({ commit, state }, customQuery = false) {

        if (getUrlParam('store_id') || getUrlParam('store_id_db')) {
            var store_unique_id = getUrlParam('store_id')
            var store_id = getUrlParam('store_id_db')
            if (!store_unique_id && !store_id) { return false }
        }

        if (store_unique_id) {
            var data = { store_unique_id: store_unique_id }
        } else if (store_id) {
            var data = { id: store_id }
        } else if (customQuery instanceof Object) {
            var data = customQuery
        } else {
            return false
        }

        const payload = await get_store_details(data)
        commit('__STORE_DETAILS', payload)
        return payload;

    },
    async __getStoreProducts({ commit }, store_id) {
        const payload = await get_store_products(store_id)
        commit('__STORE_PRODUCTS', payload)
    },
    async __getStoreCategories({ commit }, store_id) {
        const payload = await get_store_categories(store_id)
        commit('__STORE_CATEGORIES', payload)
    },
    async __getStoreShipping({ commit }, store_id) {
        const payload = await get_store_shipping(store_id)
        commit('__STORE_SHIPPING', payload)
    },
    async __getCartInstance({ commit }) {
        var payload = await get_cart_instance()
        if (Object.entries(payload).length) { commit('__RESUME_CART_INSTANCE', payload) }
    },
    async __getCoupons({ commit }, store_id) {
        var payload = await get_coupons(store_id)
        commit('__STORE_COUPONS', payload)
    },
    async __getUserPages({ commit }, store_id) {
        var payload = await get_user_pages(store_id)
        commit('__UPDATE_USER_PAGES', payload)
    },

    async _fbq({ state }, events) {

        var storeMeta = state.__storeDetails?.store_meta?.capi_access_token
        var pixelID = state.__storeDetails?.fb_pixel_id

        if (!storeMeta || !pixelID) {
            return false;
        }

        var data = []
        if (Array.isArray(events)) {
            data = events
        } else {
            data.push(events)
        }
        data = data.map(item => {
            item.event_source_url = window.location.href
            if (getCookies._fbc) { item.fbc = getCookies._fbc }
            if (getCookies._fbp) { item.fbp = getCookies._fbp }
            if (localStorage.getItem(`_fb_event_id_${item.event_name}`)) { item.event_id = localStorage.getItem(`_fb_event_id_${item.event_name}`) }
            return item
        })
        axiosInstance.post("/apis_ecom/fb_capi_process_event_api", {
            events: data,
            access_token: storeMeta,
            pixel_id: pixelID,
        })
            .then(resp => {
                __console.log({ "eventData": data, "response": resp.data })
            })
            .catch(error => __console.log(error));
    },

    //Facebook pixel
    triggerFBpixel({ state, dispatch }, eventData) {

        var currency = state.__storeDetails.currency
        var value = Number(eventData.value).toFixed(2)
        var eventName = eventData.event_name
        localStorage.setItem(`_fb_event_id_${eventName}`, uuidv4())

        if (typeof fbq !== "undefined") {
            fbq("track", eventName, {
                currency: currency,
                value: value,
                eventID: localStorage.getItem(`_fb_event_id_${eventName}`)
            });
        }

        var ecomEvent = {
            currency: currency,
            value: value,
            event_name: eventName,
        }

        if (typeof state.__savedAddress != "undefined") {
            var saved = state.__savedAddress
            if (saved.buyer_first_name) { ecomEvent.first_name = saved.buyer_first_name }
            if (saved.buyer_last_name) { ecomEvent.last_name = saved.buyer_last_name }
            if (saved.buyer_email) { ecomEvent.email = saved.buyer_email }
            if (saved.buyer_city) { ecomEvent.city = saved.buyer_city }
            if (saved.buyer_mobile) { ecomEvent.phone = saved.buyer_mobile }
            if (saved.buyer_zip) { ecomEvent.zip = saved.buyer_zip }
            if (saved.buyer_state) { ecomEvent.state = saved.buyer_state }
        }

        //Conversion API
        dispatch("_fbq", ecomEvent)

    }

}

export default ecom_actions

function getUrlParam(_key) {
    if (history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        return searchParams.get(_key);
    }
}

function updateUrlParam(_key, value) {
    if (history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set(_key, value);
        let newurl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname +
            "?" +
            searchParams.toString();
        window.history.pushState({ path: newurl }, "", newurl);
    }
}

function deleteUrlParam(_param) {
    if (history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.delete(_param);
        let newurl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname +
            "?" +
            searchParams.toString();
        window.history.pushState({ path: newurl }, "", newurl);
    }
}

function get_store_details(data) {
    return new Promise(async (resolve, reject) => {
        axiosInstance.post('/apis_ecom/store_main?action=get', data).then(resp => {
            __console.log("get_store_details", resp.data)
            resolve(resp.data)
        }).catch(err => __console.log(err))
    })
}

function get_store_products(_id) {
    return new Promise(async (resolve, reject) => {

        axiosInstance.post('/apis_ecom/product_main?action=get', {
            store_id: _id
        }).then(resp => {
            __console.log("get_store_products", resp.data)
            resolve(resp.data)
        }).catch(err => __console.log(err))
    })
}

function get_store_categories(_id) {
    return new Promise(async (resolve, reject) => {
        axiosInstance.post('/apis_ecom/category_main?action=get', {
            store_id: _id
        }).then(resp => {
            __console.log("get_store_categories", resp.data)
            resolve(resp.data)
        }).catch(err => __console.log(err))
    })
}

function get_store_shipping(_id) {
    return new Promise(async (resolve, reject) => {

        axiosInstance.post('/apis_ecom/shipping_main?action=get', {
            store_id: _id
        }).then(resp => {
            __console.log("get_store_shipping", resp.data)
            resolve(resp.data)
        }).catch(err => __console.log(err))
    })
}

function get_cart_instance() {
    return new Promise(async (resolve, reject) => {
        axiosInstance.get("/apis_ecom/get_cart_instance").then((resp) => {
            resolve(resp.data)
        }).catch((error) => {
            __console.log(error)
            reject("An error occured")
        })
    })
}

function get_coupons(_id) {
    return new Promise(async (resolve, reject) => {
        axiosInstance.post("/apis_ecom/coupon_main?action=get", {
            store_id: _id
        }).then((resp) => {
            __console.log('get_coupons', resp.data)
            resolve(resp.data)
        }).catch((error) => {
            __console.log(error)
            reject("An error occured")
        })
    })
}

function get_user_pages(store_unique_id = false) {
    if (!store_unique_id && getUrlParam('store_id')) {
        store_unique_id = getUrlParam('store_id')
    } else if (!store_unique_id && !getUrlParam('store_id')) {
        return new Promise((resolve, reject) => { resolve([]) })
    }
    return new Promise(async (resolve, reject) => {
        axiosInstance.post("/apis_pages/get_pages", {
            store_unique_id: store_unique_id
        })
            .then(resp => {
                resolve(resp.data)
            })
            .catch(e => __console.log(e))
    })
}