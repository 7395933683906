export default {
    data(){
        return {
            offers: []
        }
    },
    watch: {

    },
    computed: {
        storeData() {
            return this.$store.state.__storeDetails;
        },
    },
    methods: {
        async getOffers(){
            const resp = await this.$http.post("/apis_ecom/get_offers", {
                store_id: this.storeData?.id
            })
            this.$store.commit("__UPDATE_STORE_OFFERS", resp.data)
        },
        initOffers(){
            const offers = this.$store.state.__storeOffers
            __console.log("[initOffers] offers --> ", offers)
            if(!Array.isArray(offers)){ return }
            var effectiveOffer = false;
            var priority = 0;
            for(const offer of offers){
                var conditionsData = offer.conditions
                var met_type = conditionsData.met_type
                var conditions = conditionsData.conditions
                var isMet = this.isConditionMet(conditions, met_type)
                __console.log("isMet --> ", isMet)
                if(isMet){
                    if(Number(offer.priority) > priority){
                        effectiveOffer = offer;
                        priority = Number(offer.priority);
                    }
                }
            }
            if(effectiveOffer instanceof Object){
                if(Object.entries(effectiveOffer).length){
                    this.$store.commit("__UPDATE_EFFECTIVE_OFFER", effectiveOffer);
                    this.$store.commit("__UPDATE_CURRENT_OFFER_PATH", effectiveOffer.map.root);
                    return true;
                }
            }
            this.$store.commit("__UPDATE_EFFECTIVE_OFFER", {})
            return false;
        },
        showOffer(){
            const that = this
            const path = this.$store.state.__currentOfferPath
            const store_id = this.storeData.store_unique_id
            _wait(this.$i18n.t("Processing Order..."))
            setTimeout( () => {
                that.$router.push({name: 'Ecom Offers', params: {store_id, path}})
            }, 50)
        },
        getOfferUrl(){
            const path = this.$store.state.__currentOfferPath
            const store_id = this.storeData.store_unique_id
            const resolve = this.$router.resolve({name: 'Ecom Offers', params: {store_id, path}})
            return resolve.href
        },
        calculateNewOrderDataFromAcceptedOffers(orderData, acceptedOffers){
            __console.log("acceptedOffers --> ", acceptedOffers);
            var totalOfferCost = 0;
            for(const acceptedOffer of acceptedOffers){
                var product = acceptedOffer.product
                if(!orderData.order_items.some(order => order?.uid == product.uid)){
                    var override = acceptedOffer.override
                    var chosen_variant = acceptedOffer.chosen_variant
                    product.override = override
                    product.product_name = "[Offer] " + product.product_name
                    product.itemQuantity = override.quantity
                    product.chosen_variant = chosen_variant
                    var overrideCostObj = this.overridePriceHelper(product);
                    var OfferCost = overrideCostObj.displayPrice
                    if(product.has_variant==1){
                        product.chosen_variant.isSales = true
                        product.chosen_variant.salesPrice = OfferCost
                    }else{
                        product.is_sale = "1"
                        product.sell_price = OfferCost
                    }
                    totalOfferCost = totalOfferCost + OfferCost*override.quantity
                    orderData.order_items.push(product)
                }
            }
            orderData.subtotal = Number(orderData.subtotal) + totalOfferCost
            orderData.order_amount = Number(orderData.order_amount) + totalOfferCost
            return orderData;
        },
        overridePriceHelper(productData){
            try{
                const overrideObj = productData.override
                let priceData = this.effectivePriceHelper(productData)
                const method = overrideObj.override.method
                const original = priceData.rejectedPrice
                var rejectedPrice = original
                switch(method){
                    case 'subtract':
                            var displayPrice = (priceData.displayPrice - overrideObj.override.value) > 0 ? priceData.displayPrice - overrideObj.override.value : 0
                        break;
                    case 'fixed':
                            var displayPrice = overrideObj.override.value
                        break;
                    case 'percentage_of_sale':
                            var displayPrice = priceData.displayPrice*(1-overrideObj.override.value/100)
                            __console.log('priceData.displayPrice', priceData.displayPrice, '(1-overrideObj.override.value/100)', (1-overrideObj.override.value/100))
                        break;
                    case 'percentage_of_original':
                            var displayPrice = original*(1-overrideObj.override.value/100)
                        break;
                }
                var rejectedPrice = original
                var savings = displayPrice - rejectedPrice
                var percentOff = Math.ceil(100*(1 - displayPrice/rejectedPrice))
                return {displayPrice, rejectedPrice, savings, percentOff};
            }catch(e){ __console.log("overridePriceHelper", e)}
            return false;
        },
        effectivePriceHelper(productData){
            if(productData.has_variant == '0'){
                var rejectedPrice = productData.original_price
                var displayPrice = (productData.is_sale == '1') ? productData.sell_price : productData.original_price;
            }else{
                var rejectedPrice = productData.chosen_variant?.origPrice;
                var displayPrice = productData.chosen_variant?.isSales ? productData.chosen_variant?.salesPrice : productData.chosen_variant?.origPrice;
            }
            displayPrice = displayPrice ? Number(displayPrice) : 0;
            rejectedPrice = rejectedPrice ? Number(rejectedPrice) : 0;
            return {rejectedPrice, displayPrice}
        },
        proceedToNextOffer(type){
            const that = this
            const effectiveOffer = ___(this.$store.state.__effectiveOffer)
            let path = effectiveOffer.map.lib[this.$store.state.__currentOfferPath][type]
            if(type === 'thank_you'){path="thank_you"};
            const store_id = this.storeData.store_unique_id
            const orderData = this.$store.state.__finalOrderData
            const nextOfferRouteName = path=="thank_you" ? 'Ecom Thankyou' : 'Ecom Offers'
            const params = path=="thank_you" ? orderData : {store_id, path}
            setTimeout( () => {
                that.$router.push({name: nextOfferRouteName, params})
            }, 50);
        },
    },
    created(){

    },
    mounted(){
        this.getOffers()
    }
}
