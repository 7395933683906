const _NavMenuItemsMap = {

    //Home
    home: {
        header: 'Home',
        url: "/",
        name: "Dashboard",
        slug: "dashboard",
        icon: "HomeIcon",
        path: "home"
    },

    videoTutorials: {
        header: 'Video Tutorials',
        name: 'Video Tutorials',
        action: "ssoLoginToMMIOAcademy",
        category: "Support Center",
        slug: "video-tutorials",
        icon: "la-youtube",
        iconPack: "lab",
        featherIcon: false,
    },

    support: {
        header: 'Support Center',
        i18n: 'Support Center',
        path: 'support',
        icon: "la-medkit",
        iconPack: "las",
        featherIcon: false,
        items: [
            {
                name: "Create/View Tickets",
                url: "/support-center",
                slug: "support_center",
                icon: "la-medkit",
                iconPack: "las",
                featherIcon: false,
                class: "theSupport"
            },
            {
                url: "https://help.marketingmaster.io/",
                target: "_blank",
                name: "Help Center",
                slug: "help_center",
                action: "external",
                category: "Support Center",
                icon: "HelpCircleIcon",
                class: "theFAQ",
            },
            {
                url: "https://docs.marketingmaster.io/",
                target: "_blank",
                name: "Documentation",
                slug: "documentation",
                action: "external",
                category: "Support Center",
                icon: "la-graduation-cap",
                iconPack: "las",
                featherIcon: false,
                class: "userGuide",
            }
        ]
    },

    'my-account': {
        header: 'My Account',
        i18n: 'My Account',
        path: 'my-account',
        icon: "SettingsIcon",
        items: [
            {
                url: "/my-account/user-settings",
                name: "Account Settings",
                slug: "account_settings",
                icon: "SettingsIcon",
                description: "Manage subscription, usage log, API keys"
            },
            {
                url: "/my-account/team-members",
                name: "Team Members",
                slug: "team_member",
                icon: "la-users",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: "/my-account/affiliate-program",
                name: "Affiliate",
                slug: "affiliate_program",
                icon: "la-hand-holding-usd",
                iconPack: "las",
                featherIcon: false,
            }
        ]
    },

    'omni-channel': {
        header: 'Omni-channel',
        i18n: 'Omni-channel',
        path: 'omni-channel',
        icon: "la-globe",
        iconPack: "las",
        featherIcon: false,
        tag: "Beta",
        type: "error",
        items: [
            {
                url: "/omni-channel",
                name: "Flows",
                slug: "omni_channel_flows",
                icon: "la-stream",
                iconPack: "las",
                featherIcon: false,
                description: "Manage Omni-channel Flows"
            },
            {
                url: "/omni-channel/chat",
                name: "Unified Chat",
                slug: "omni_channel_chat",
                icon: "la-comment-alt",
                iconPack: "las",
                featherIcon: false,
                description: "Unified chat for all channels and accounts"
            },
            {
                url: "/omni-channel/chat-settings",
                name: "Chat Settings",
                slug: "omni_channel_chat_settings",
                icon: "la-cog",
                iconPack: "las",
                featherIcon: false,
                description: "Configure Unified Chat settings"
            },
            {
              url: "/omni-channel/events",
              name: "Event Subscriptions",
              slug: "omni_channel_events",
              icon: "la-bomb",
              iconPack: "las",
              featherIcon: false,
              description: "Set an action to be triggered when an event occurs"
          },
        ]
    },

    //Social Media Marketing
    'social-media-automations': {
        header: 'Facebook & Instagram',
        i18n: 'Social Media',
        path: 'social-media-automations',
        icon: "FacebookIcon",
        items: [
            {
                url: '/social-media-automations',
                name: 'Chatbot Flow Builder',
                slug: 'flow_builder_dashboard',
                icon: "la-stream",
                iconPack: "las",
                featherIcon: false,
                highlight: true
            },
            {
                url: '/social-media-automations/comment-reply',
                name: 'Comment Auto Reply',
                slug: 'comment_auto_reply',
                icon: "MessageSquareIcon",
                highlight: true
            },
            {
                url: "/social-media-automations/facebook-accounts",
                name: "Import Social Media",
                slug: "account_import",
                icon: "la-plug",
                iconPack: "las",
                featherIcon: false,
                highlight: true
            },
            {
                url: '/post-randomizer',
                name: 'Post Randomizer',
                slug: 'post_randomizer',
                icon: "la-random",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/social-media-automations/pre-recorded-livestream',
                name: 'Livestream',
                slug: 'live_stream',
                iconPack: "las",
                featherIcon: false,
                icon: "la-video"
            },
            {
                url: '/social-media-automations/interest-finder',
                name: 'Interest Finder',
                slug: 'insterest_finder',
                icon: "la-searchengin",
                iconPack: "lab",
                featherIcon: false,
            },
            {
                url: '/social-media-automations/live-chat',
                name: 'Live Chat',
                slug: 'live_chat',
                icon: "la-facebook-messenger",
                iconPack: "lab",
                featherIcon: false,
            },
            {
                url: '/social-media-automations/subscribers',
                name: 'Subscribers',
                slug: 'messenger_subscribers',
                icon: 'UsersIcon',
            },
            {
                url: '/social-media-automations/growth-tools',
                name: 'Growth Tools',
                slug: 'growth_tools',
                icon: 'TrendingUpIcon',
            },
            {
                url: '/social-media-automations/broadcast',
                name: 'Chat Broadcast',
                slug: 'messenger_broadcast',
                icon: 'RadioIcon',
            },
            {
                url: '/social-media-automations/sequences',
                name: 'Chat Sequences',
                slug: 'messenger_sequence',
                icon: "la-fast-forward",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/social-media-automations/webview',
                name: 'Messenger Webview',
                slug: 'messenger_webview',
                icon: 'ExternalLinkIcon',
            },
            {
                url: '/social-media-automations/bot-tools/persistent-menu',
                name: 'Persistent Menu',
                slug: 'persistent_menu',
                icon: "la-bars",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/social-media-automations/welcome-message',
                name: 'Welcome Message',
                slug: 'welcome_message',
                icon: "la-door-open",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/social-media-automations/ice-breakers',
                name: 'Ice Breakers (FAQs)',
                slug: 'ice_breakers',
                icon: "la-question-circle",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/social-media-automations/get-started',
                name: 'Get Started',
                slug: 'get_started',
                icon: "la-comment-dots",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/social-media-automations/keywords',
                name: 'Keywords',
                slug: 'keywords',
                icon: "la-text-width",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/social-media-automations/defaults',
                name: 'Chatbot Defaults',
                slug: 'chatbot_defaults',
                icon: "la-cog",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/social-media-automations/recurring-notifications',
                name: 'Marketing Messages',
                slug: 'recurring_notifications',
                icon: "la-sync-alt",
                iconPack: "las",
                featherIcon: false,
            },
        ]
    },

    //Ecommerce
    ecommerce: {
        header: 'Ecommerce',
        i18n: 'ecommerce',
        path: 'ecommerce',
        icon: "ShoppingCartIcon",
        items: [
            {
                url: '/ecommerce',
                name: 'Create/Edit Store',
                slug: 'ecom_dashboard',
                icon: "la-tachometer-alt",
                iconPack: "las",
                featherIcon: false,
                highlight: true
            },
            {
                url: '/ecommerce/products',
                name: 'Products',
                slug: 'products',
                icon: "la-box",
                iconPack: "las",
                featherIcon: false,
                highlight: true
            },
            {
                url: '/ecommerce/categories',
                name: 'Categories',
                slug: 'categories',
                icon: "la-boxes",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/ecommerce/shipping',
                name: 'Shipping',
                slug: 'shipping',
                icon: "la-shipping-fast",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/ecommerce/orders',
                name: 'Orders',
                slug: 'orders',
                icon: "la-shopping-bag",
                iconPack: "las",
                featherIcon: false,
                highlight: true
            },
            {
                url: '/ecommerce/coupons',
                name: 'Coupons',
                slug: 'coupons',
                icon: "la-tag",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/ecommerce/reports',
                name: 'Ecom Reports',
                slug: 'ecommerce_reports',
                icon: "BarChart2Icon",
            },
            {
                url: '/ecommerce/checkout-settings',
                name: 'Checkout Settings',
                slug: 'checkout_settings',
                icon: "la-tools",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/ecommerce/notifications',
                name: 'Shop Notifications',
                slug: 'ecommerce_notifications',
                icon: "BellIcon",
            },
            {
                url: '/ecommerce/designer',
                name: 'Shop Designer',
                slug: 'shop_designer',
                icon: "la-pen-fancy",
                iconPack: "las",
                featherIcon: false
            },
            {
                url: '/ecommerce/upsells',
                name: 'Funnels',
                slug: 'shop_upsells',
                icon: "la-chevron-up",
                iconPack: "las",
                featherIcon: false
            },
            {
                url: '/page-builder',
                name: 'Shop Pages',
                slug: 'page_builder_dashboard',
                icon: "la-palette",
                iconPack: "las",
                featherIcon: false
            },
            {
                url: '/ecommerce/woo-plugins',
                name: 'WooCommerce Plugins',
                slug: 'woo_plugins',
                icon: "la-wordpress",
                iconPack: "lab",
                featherIcon: false
            },
        ]
    },

    //Social Media Marketing
    'native chat widget': {
        header: 'Web Chat Widget',
        i18n: 'web_chat_widget',
        path: 'web-chat-widget',
        icon: "la-code",
        iconPack: "las",
        featherIcon: false,
        tag: "Beta",
        type: "error",
        items: [
            {
                url: '/web-chat-widget/accounts',
                name: 'Accounts',
                slug: 'web_widget_accounts',
                icon: "la-briefcase",
                iconPack: "las",
                featherIcon: false,
                highlight: true
            },
            {
                url: '/web-chat-widget/flow-builder',
                name: 'Widget Chatbots',
                slug: 'web_widget_flow_dashboard',
                icon: "la-stream",
                iconPack: "las",
                featherIcon: false,
                highlight: true
            },
            {
                url: '/web-chat-widget/live-chat',
                name: 'Live Chat',
                slug: 'web_widget_live_chat',
                icon: "la-facebook-messenger",
                iconPack: "lab",
                featherIcon: false,
            },
            {
                url: '/web-chat-widget/subscribers',
                name: 'Subscribers',
                slug: 'web_widget_subscribers',
                icon: 'UsersIcon',
            },
            {
                url: '/web-chat-widget/defaults',
                name: 'Chatbot Defaults',
                slug: 'chatbot_defaults',
                icon: "la-cog",
                iconPack: "las",
                featherIcon: false,
            },
        ]
    },

    //Email marketing
    email: {
        header: 'Email Marketing',
        i18n: 'EmailMarketing',
        path: 'email',
        icon: "MailIcon",
        items: [
            {
                url: '/email-marketing/settings',
                name: 'Email Settings',
                slug: 'email_marketing_settings',
                icon: "SettingsIcon",
            },
            {
                url: '/email-marketing',
                name: 'Email Flow Builder',
                slug: 'email_flow_builder_dashboard',
                icon: "MailIcon",
                highlight: true
            },
            {
                url: '/email-marketing/broadcasting-dashboard',
                name: 'Email Broadcast',
                slug: 'email_broadcasting',
                icon: 'SendIcon',
                highlight: true
            },
            {
                url: '/email-marketing/reports',
                name: 'Email Reports',
                slug: 'email_campaign_reports',
                icon: 'PieChartIcon',
            },
            {
                url: '/email-marketing/integration',
                name: 'Custom Forms',
                slug: 'email_campaign_custom_forms',
                icon: 'LayoutIcon',
            },
            {
                url: '/landing-pages',
                name: 'Landing Pages',
                slug: 'landing_pages',
                icon: "la-pager",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/email-marketing/email-templates',
                name: 'Email Templates',
                slug: 'email_templates',
                icon: "la-pen-fancy",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/email-marketing/optin-templates',
                name: 'Optin Templates',
                slug: 'optin_templates',
                icon: "la-sign-in-alt",
                iconPack: "las",
                featherIcon: false,
            },
        ]
    },

    //SMS marketing
    sms: {
        header: 'SMS Marketing',
        i18n: 'SMSMarketing',
        path: 'sms',
        icon: "SmartphoneIcon",
        items: [
            {
                url: '/sms-marketing',
                name: 'Buy SMS Credits',
                slug: 'sms_dashboard',
                icon: "SmartphoneIcon",
                highlight: true
            },
            {
                url: '/sms-marketing/broadcasting-dashboard',
                name: 'SMS Broadcast',
                slug: 'sms_broadcast',
                icon: "la-bullhorn",
                iconPack: "las",
                featherIcon: false,
                highlight: true
            },
            {
                url: '/sms-marketing/sms-sequence',
                name: 'SMS Sequence',
                slug: 'sms_sequence',
                icon: "LayersIcon",
                highlight: true
            },
            {
                url: '/sms-marketing/sender-ids',
                name: 'My Sender IDs',
                slug: 'my_sender_ids',
                icon: "TagIcon",
            },
            {
                url: '/sms-marketing/sms-sent-history',
                name: 'SMS Sent History',
                slug: 'sms_sent_history',
                icon: "la-sim-card",
                iconPack: "las",
                featherIcon: false,
            },
        ]
    },

    //GMB marketing
    gmb: {
        header: 'Google My Business',
        i18n: 'Google My Business',
        path: 'gmb',
        icon: "la-google",
        iconPack: "lab",
        featherIcon: false,
        items: [
            {
                url: '/gmb/agents',
                name: 'Agents',
                slug: 'gmb_agents',
                icon: "la-robot",
                iconPack: "las",
                featherIcon: false,
                highlight: true
            },
            {
                url: '/gmb/flows',
                name: 'Flows',
                slug: 'gmb_flows',
                icon: "la-robot",
                icon: "la-stream",
                iconPack: "las",
                featherIcon: false,
                highlight: true
            },
            {
                url: '/gmb/brands',
                name: 'Brands',
                slug: 'gmb_brands',
                icon: "la-copyright",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/gmb/livechat',
                name: 'Livechat',
                slug: 'gmb_livechat',
                icon: "la-comment-alt",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/gmb/locations',
                name: 'Locations',
                slug: 'gmb_locations',
                icon: "la-map-marker-alt",
                iconPack: "las",
                featherIcon: false,
            },
        ]
    },

    //WhatsApp marketing
    whatsapp: {
        header: 'WhatsApp',
        i18n: 'WhatsApp',
        path: 'whatsapp',
        icon: "la-whatsapp",
        iconPack: "lab",
        featherIcon: false,
        tag: "🔥Hot",
        type: "warning",
        items: [
            {
                url: '/whatsapp/flows',
                name: 'Flows',
                slug: 'whatsapp_flows',
                icon: "la-stream",
                iconPack: "las",
                featherIcon: false,
                highlight: true
            },
            {
                url: '/whatsapp/broadcast',
                name: 'Broadcast',
                slug: 'whatsapp_broadcast',
                icon: "la-bullhorn",
                iconPack: "las",
                featherIcon: false,
                highlight: true
            },
            {
                url: '/whatsapp/accounts',
                name: 'Accounts',
                slug: 'whatsapp_accounts',
                icon: "la-briefcase",
                iconPack: "las",
                featherIcon: false,
                highlight: true
            },
            {
                url: '/whatsapp/templates',
                name: 'Templates',
                slug: 'whatsapp_templates',
                icon: "la-folder",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/whatsapp/wa-contacts',
                name: 'Contacts',
                slug: 'whatsapp_contacts',
                icon: "la-address-card",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/whatsapp/livechat',
                name: 'Livechat',
                slug: 'whatsapp_livechat',
                icon: "la-comment-alt",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/whatsapp/sequences',
                name: 'Sequences',
                slug: 'whatsapp_sequences',
                icon: "la-fast-forward",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/whatsapp/chatbot-defaults',
                name: 'Chatbot Defaults',
                slug: 'whatsapp_chatbot_defaults',
                icon: "la-cog",
                iconPack: "las",
                featherIcon: false,
            },
        ]
    },

    //Telegram marketing
    telegram: {
        header: 'Telegram',
        i18n: 'Telegram',
        path: 'telegram',
        icon: "la-telegram",
        iconPack: "lab",
        featherIcon: false,
        tag: "BETA",
        type: "error",
        items: [
            {
                url: '/telegram/flows',
                name: 'Flows',
                slug: 'telegram_flows',
                icon: "la-stream",
                iconPack: "las",
                featherIcon: false,
                highlight: true
            },
            {
                url: '/telegram/broadcast',
                name: 'Broadcast',
                slug: 'telegram_broadcast',
                icon: "la-bullhorn",
                iconPack: "las",
                featherIcon: false,
                highlight: true
            },
            {
                url: '/telegram/accounts',
                name: 'Accounts',
                slug: 'telegram_accounts',
                icon: "la-briefcase",
                iconPack: "las",
                featherIcon: false,
                highlight: true
            },
            {
                url: '/telegram/contacts',
                name: 'Contacts',
                slug: 'telegram_contacts',
                icon: "la-address-card",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/telegram/livechat',
                name: 'Livechat',
                slug: 'telegram_livechat',
                icon: "la-comment-alt",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/telegram/sequences',
                name: 'Sequences',
                slug: 'telegram_sequences',
                icon: "la-fast-forward",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/telegram/chatbot-defaults',
                name: 'Chatbot Defaults',
                slug: 'telegram_chatbot_defaults',
                icon: "la-cog",
                iconPack: "las",
                featherIcon: false,
            },
        ]
    },

    lms: {
        header: 'LMS (Addon)',
        name: 'LMS (Addon)',
        action: "ssoLoginToSmaartrIODashboard",
        category: "Addons",
        slug: 'lms',
        icon: "la-graduation-cap",
        iconPack: "las",
        featherIcon: false
    }

}

const _NavMenuItemsMap2 = {
    //Contacts
    contacts: {
        header: 'Contacts',
        i18n: 'contacts',
        path: 'contacts',
        icon: 'BookIcon',
        items: [
            {
                url: '/contacts/contact-books',
                name: 'Contact Books',
                slug: 'contact_books',
                icon: 'BookIcon',
            },
            {
                url: '/contacts/contact-list',
                name: 'My Contact List',
                slug: 'contact_list',
                icon: 'UsersIcon',
            },
            {
                url: '/contacts/contact-tags',
                name: 'My Contact Tags',
                slug: 'contact_tags',
                icon: 'TagIcon',
            },
        ]

    },

    //Utilities
    utilities: {
        header: 'Utilities',
        i18n: 'Utilities',
        path: 'utilities',
        icon: "la-tools",
        iconPack: "las",
        featherIcon: false,
        items: [
            {
                url: '/utilities/google-sheets',
                name: 'Google Sheets',
                slug: 'google_sheets',
                icon: "la-file-excel",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/utilities/custom-variables',
                name: 'Custom Variables',
                slug: 'custom_variables',
                icon: "la-code",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/utilities/bot-sheets',
                name: 'Bot Sheets',
                slug: 'bot_sheets',
                icon: "la-robot",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/utilities/booking',
                name: 'Booking',
                slug: 'booking',
                icon: "la-calendar",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/utilities/openai-embedding',
                name: 'OpenAI Embedding',
                slug: 'openai_embedding',
                icon: "la-digital-tachograph",
                iconPack: "las",
                featherIcon: false,
            },
        ]
    },

    //Integrations
    integrations: {
        header: 'Integrations',
        i18n: 'Integrations',
        path: 'integrations',
        url: '/integrations',
        name: 'Integrations',
        slug: 'integrations',
        icon: "la-cogs",
        iconPack: "las",
        featherIcon: false,
    },

    //Admin
    admin: {
        header: 'Admin',
        i18n: 'Admin',
        path: 'admin',
        icon: "la-user-cog",
        iconPack: "las",
        featherIcon: false,
        items: [
            {
                url: '/admin',
                name: 'Create/Edit Client Users',
                slug: 'admin',
                icon: "la-user-cog",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/admin/packages',
                name: 'Packages',
                slug: 'admin_packages',
                icon: "la-briefcase",
                iconPack: "las",
                featherIcon: false,
            },
            {
                url: '/whitelabel-settings',
                name: 'Whitelabel Settings',
                slug: 'whitelabel_settings',
                icon: "la-copyright",
                iconPack: "las",
                featherIcon: false,
            },
        ],
    },
}

if (!window.MMIO_DEFAULTS?.IS_WHITELABEL || window.MMIO_DEFAULTS?.COUNTRY === "MY") {
    const RequestReport = {
        name: "Requests/Roadmap",
        action: "ssoFeatureMonkeyRoadmap",
        category: "User Account",
        slug: "roadmap",
        icon: "la-sticky-note",
        iconPack: "las",
        featherIcon: false
    }
    _NavMenuItemsMap['my-account'].items.push(RequestReport);

    const MmioConnect = {
        name: "MMIO Connect",
        action: "ssoLoginToMMIOConnect",
        category: "Utilities",
        slug: "mmio_connect",
        icon: "la-network-wired",
        iconPack: "las",
        featherIcon: false
    }
    _NavMenuItemsMap2['utilities'].items.push(MmioConnect);

} else {

    delete _NavMenuItemsMap.videoTutorials
    delete _NavMenuItemsMap.lms

    //Temporarily remove OpenAI/ChatGPT related functions for Whitelabel
    _NavMenuItemsMap2['utilities'].items = _NavMenuItemsMap2['utilities'].items.filter(
        (item) => item.slug !== 'openai_embedding'
    )

}

const NavMenuItems = Object.values({ ..._NavMenuItemsMap, ..._NavMenuItemsMap2 })
export default NavMenuItems
