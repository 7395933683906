export default {
    // /////////////////////////////////////////////
    // TELEGRAM
    // /////////////////////////////////////////////

    UPDATE_TELEGRAM_FLOWS(state, payload) {
        state.TelegramFlows = payload.map(item => Object.seal(item));
    },

    UPDATE_TELEGRAM_ACCOUNTS(state, payload) {
        state.TelegramAccounts = payload.map(item => Object.seal(item));
    },

    UPDATE_ACTIVE_TELEGRAM(state, payload) {
        state.ActiveTelegramAccount = payload;
    },

    UPDATE_TELEGRAM_BROADCASTS(state, payload) {
        state.TelegramBroadcasts = payload.map(item => Object.seal(item));
    },

    UPDATE_TELEGRAM_SEQUENCES(state, payload) {
        state.TelegramSequences = payload.map(item => Object.seal(item));
    },
}