
import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import createPersistedState from "vuex-persistedstate";
import createMutationsSharer from "vuex-shared-mutations";

Vue.use(Vuex)

const persistedState = createPersistedState({
    reducer: state => ({
        AppActiveUser: state.AppActiveUser,
        UserPages: state.UserPages,
        userPagesAll: state.userPagesAll,
        ActivePage: state.ActivePage,
        ActiveStore: state.ActiveStore,
        ActiveInstagram: state.ActiveInstagram,
        userAccounts: state.userAccounts,
        domObject: state.domObject,
        PageOptions: state.PageOptions,
        navMenuItems: state.navMenuItems,
        ActiveWhatsAppAccount: state.ActiveWhatsAppAccount,
        ActiveAgent: state.ActiveAgent,
        ActiveTelegramAccount: state.ActiveTelegramAccount,
        ActiveWebWidgetAccount: state.ActiveWebWidgetAccount,
        filteredAccounts: state.filteredAccounts
    }),
})

export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    strict: process.env.NODE_ENV !== 'production',
    plugins: [
        persistedState,
        createMutationsSharer({ 
            predicate: [
                "UPDATE_PAGE_OPTIONS_SINGLE",
                "UPDATE_DOMOBJECT",
                "UPDATE_DOMOBJECT_SINGLE",
                "UPDATE_ACTIVE_STORE",
                "__STORE_DETAILS"
            ]
        })
    ]
})
