import {themeConfig,colors} from "@/../themeConfig.js"
import mmioStates from './mmio-states.js'
import ecom_states from './ecommerce/state'
import webview_states from './webviews/state'
import moduleOpenAi from '@/store/openai/moduleOpenAi.js'
// /////////////////////////////////////////////
// Helper
// /////////////////////////////////////////////

// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
const is_touch_device = () => {
  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  var mq = function(query) {
      return window.matchMedia(query).matches;
  }

  if (('ontouchstart' in window) || window.DocumentTouch) {
      return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}


// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

var state = {
	
    bodyOverlay             : false,
    isVerticalNavMenuActive : true,
    is_touch_device         : is_touch_device(),
    mainLayoutType          : themeConfig.mainLayoutType || "vertical",
    reduceButton            : themeConfig.sidebarCollapsed,
    verticalNavMenuWidth    : "default",
    verticalNavMenuItemsMin : false,
    scrollY                 : 0,
    theme                   : MMIO_DEFAULTS?.BRAND_THEME || "semi-dark",
    themePrimaryColor       : colors.primary,
    // Can be used to get current window with
    // Note: Above breakpoint state is for internal use of sidebar & navbar component
    windowWidth: null,
    SMSCountryRates: [],

}

state = {...state, ...mmioStates, ...ecom_states, ...webview_states, ...moduleOpenAi.state}

export default state