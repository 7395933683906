import Vue from 'vue'

export default {
    // /////////////////////////////////////////////
    // Facebook Automations
    // /////////////////////////////////////////////	

    COMMENT_AUTOMATIONS(state, val) {
        try {
            state.CommentAutomations = [...val].sort((a, b) => Number(b.id) - Number(a.id));
            return true;
        } catch (e) { }
        state.CommentAutomations = val
    },

    UPDATE_SINGLE_CAMPAIGN(state, { key, val }) {
        Vue.set(state.CommentAutomations, key, val);
    },

    COMMENT_AUTOMATIONS_UPDATE(state, val) {
        state.CommentAutomations = val
    },

    UPDATE_COMMENT_AUTOREPLY_POPUP(state, val) {
        state.WriteCommentAutoReplyActive = val
    },

    UPDATE_COMMENT_AUTOREPLY_DATA(state, val) {
        state.WriteCommentAutoReplyData = val
    },

    UPDATE_PAGE_FEEDS(state, val) {
        state.PageFeeds = val
    },

    COMMENT_AUTOMATION_TEMPLATES(state, { data }) {
        if (!data) { return }
        state.CommentAutomationTemplates = Object.values(data).map(item => Object.seal(item));
    },

    UPDATE_COMMENT_AUTOMATIONS_CACHE(state, post) {
        Vue.set(state.CommentAutomationsCache, post.post_id, post);
    },

    UPDATE_LIVESTREAM_SELLING_CONFIGS(state, payload) {
        state.LiveStreamSellingConfigs = (Array.isArray(payload) ? payload : []).map(item => Object.seal(item));
    },
}