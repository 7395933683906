export default {
	//Email Flow Builder
	GetEmails: state => {
		try {
			return state.Emails
		} catch (e) { }
		return state.Emails
	},
	GetEmailReportsData: state => state.EmailReports,
	GetCustomForms: state => {
		try {
			return state.CustomForms
		} catch (e) { }
		return state.CustomForms
	},
	GetEmailFlowBuilderAPIKey: state => state.EmailFlowBuilderAPIKey,
	GetContactList: state => state.ContactList,
	GetContactBooks: state => state.ContactBooks,
	GetContactTags: state => state.ContactTags,
	GetContactTagsArray: state => {
		var output = []
		try {
			output = Object.values(state.ContactTags)
		} catch (e) { __console.log(e) }
		return output;
	},

	//Email conditions
	getEmailCOnditions(state, getters) {

		var tags = getters.GetContactTagsArray
		var books = state.ContactBooks
		var Rules = [
			{
				id: "tag",
				name: "Contact Has Tag/Label",
				options: tags.map(tag => { return { text: tag.group_name, value: tag.id } }),
				qualifier: ["is", "is not"].map(item => { return { text: item, value: item } }),
				type: "choices",
				multiple: true,
			},
			{
				id: "book",
				name: "Part Of Contact Book",
				options: books.map(book => { return { text: book.type, value: book.id } }),
				qualifier: ["is", "is not"].map(item => { return { text: item, value: item } }),
				type: "choices",
				multiple: true,
			},
			{
				id: "clicked_link",
				name: "Clicked A Link From Prevous Email",
				options: [],
				qualifier: ["clicked links👉"].map(item => { return { text: item, value: item } }),
				type: "choices",
				multiple: true,
			},
			{
				id: "opened",
				name: "Opened Previous Email",
				options: false,
				qualifier: false,
				type: "simple"
			},
		]

		return Rules;
	},

	//Email conditions
	getEmailActions(state, getters) {

		var tags = getters.GetContactTagsArray
		var books = state.ContactBooks
		var emails = state.Emails

		var Rules = [
			{
				id: "tag",
				name: "Modify Contact Tag/Label",
				options: tags.map(tag => { return { text: tag.group_name, value: tag.id } }),
				command: ["add", "remove"].map(item => { return { text: item, value: item } }),
				type: "choices",
				multiple: true,
			},
			{
				id: "book",
				name: "Modify Contact Book",
				options: books.map(book => { return { text: book.type, value: book.id } }),
				command: ["add", "remove"].map(item => { return { text: item, value: item } }),
				type: "choices",
				multiple: true,
			},
			{
				id: "email_sequence",
				name: "Mofidy Email Sequence",
				options: emails.map(email => { return { text: email.name, value: email.id } }),
				command: ["add", "remove"].map(item => { return { text: item, value: item } }),
				type: "choices"
			}
		]

		return Rules;
	},

}