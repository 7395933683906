import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from "@/lang/en-US"

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: "en-US",
    messages: {
        'en-US': { ...en },
    }
});

export const loadedLanguages = ["en-US"];

export const setI18nLanguage = async (lang, load = true) => {
    if (load) {
        await loadLanguageAsync(lang);
        return lang;
    } else {
        i18n.locale = lang;
        // axios.defaults.headers.common['Accept-Language'] = lang
        (document.querySelector("html")).setAttribute("lang", lang);
        return lang;
    }
}

export const loadLanguageAsync = (lang) => {
    if (
        i18n.locale !== lang ||
        (!loadedLanguages.includes(lang))
    ) {
        if (!loadedLanguages.includes(lang)) {
            return import(
                /* webpackChunkName: "lang-[request]" */ `@/lang/${lang}`
            ).then(msgs => {
                i18n.setLocaleMessage(lang, msgs.default);
                loadedLanguages.push(lang);
                return setI18nLanguage(lang);
            });
        }
        return Promise.resolve(setI18nLanguage(lang, false));
    }
    return Promise.resolve(setI18nLanguage(lang, false));
}