<template>
    <div id="app" :class="vueAppClasses">
        <router-view @setAppClasses="setAppClasses" />
    </div>
</template>

<script>

import { mapActions } from "vuex";
import { excempted_paths } from '@/utils/exemptedPaths.js'
import momentJsLazyLoader from '@/components/momentJsLazyLoader'
import { setI18nLanguage } from "@/i18n"

export default {
    data() {
        return {
            vueAppClasses: [],
        };
    },
    watch: {
        "$store.state.theme"(val) {
            this.toggleClassInBody(val);
        },
        "$vs.rtl"(val) {
            document.documentElement.setAttribute("dir", val ? "rtl" : "ltr");
        }
    },
    methods: {
        ...mapActions([
            "getUserAccount"
        ]),
        toggleClassInBody(className) {
            if (className === "dark") {
                if (document.body.className.match("theme-semi-dark"))
                    document.body.classList.remove("theme-semi-dark");
                document.body.classList.add("theme-dark");
            } else if (className === "semi-dark") {
                if (document.body.className.match("theme-dark"))
                    document.body.classList.remove("theme-dark");
                document.body.classList.add("theme-semi-dark");
            } else {
                if (document.body.className.match("theme-dark"))
                    document.body.classList.remove("theme-dark");
                if (document.body.className.match("theme-semi-dark"))
                    document.body.classList.remove("theme-semi-dark");
            }
        },
        setAppClasses(classesStr) {
            this.vueAppClasses.push(classesStr);
        },
        handleWindowResize() {
            this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);

            // Set --vh property.
            document.documentElement.style.setProperty(
                "--vh",
                `${window.innerHeight * 0.01}px`
            );
        },
        handleScroll() {
            this.$store.commit("UPDATE_WINDOW_SCROLL_Y", window.scrollY);
        },
        async loadUserAccountMounted() {
            const { pathname } = window.location
            const isExcepted = excempted_paths.some(path => path.includes(pathname))
            if (pathname === "/" || !isExcepted) {
                window.userAccountMounted = true;
                this.$eventHub.$emit("execMountedFxns");
                const userdata = await this.getUserAccount();
                if (!_isObj(userdata)) {
                    this.$store.commit("LOGOUT", this);
                }
            }
        },
        loadScriptsAndStyles() {

            //Scripts
            this.$loadScript("https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.29.1/moment.min.js")
                .then(() => {
                    this.$loadScript("https://cdnjs.cloudflare.com/ajax/libs/moment-timezone/0.5.33/moment-timezone-with-data.min.js")
                        .then(() => {
                            momentJsLazyLoader()
                        });
                })

            this.loadUserAccountMounted();

            this.$loadScript("https://cdnjs.cloudflare.com/ajax/libs/svg.js/2.3.4/svg.min.js");
            this.$loadScript("https://mmio-store.b-cdn.net/main/md5.min.js");

            //Styles
            this.$loadStyle("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");
            this.$loadStyle("https://cdnjs.cloudflare.com/ajax/libs/line-awesome/1.3.0/font-awesome-line-awesome/css/all.min.css");
            this.$loadStyle("https://cdnjs.cloudflare.com/ajax/libs/line-awesome/1.3.0/line-awesome/css/line-awesome.min.css");
            this.$loadStyle("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");
            this.$loadStyle("https://unpkg.com/material-components-web@latest/dist/material-components-web.min.css");
            this.$loadStyle("https://fonts.googleapis.com/icon?family=Material+Icons");
            this.$loadStyle("https://mmio-store.b-cdn.net/css/vuesax.css");

        },
        async getUserGeoIpData(){
          if (window.userGeoIpCache) {
            return window.userGeoIpCache
          } else if (localStorage.getItem("userGeoIpCache")) {
            window.userGeoIpCache = JSON.parse(localStorage.getItem("userGeoIpCache"))
            return window.userGeoIpCache
          }
          const {data} = await this.$http.get("https://api.ipgeolocation.io/ipgeo?apiKey=ab9c6fb0aeae481598781f4e1fae8e60")
          if (data?.continent_name) {
            window.userGeoIpCache = data
            localStorage.setItem("userGeoIpCache", JSON.stringify(data))
            return window.userGeoIpCache
          }
          return {}
        }
    },
    beforeCreate() {
        localStorage.setItem("UserNotLogged", true)
    },
    async created() {

        const dir = this.$vs.rtl ? "rtl" : "ltr";
        document.documentElement.setAttribute("dir", dir);
        window.addEventListener("resize", this.handleWindowResize);
        window.addEventListener("scroll", this.handleScroll);

        try {
            if (!localStorage.getItem("referrer_source")) {
                var referrer = window.location.href.split("?")[1]
                localStorage.setItem("referrer_source", referrer)
            }
        } catch (e) { }

    },
    beforeMount() {
        setI18nLanguage(MMIO_DEFAULTS.LANGUAGE);
    },
    mounted() {

        this.toggleClassInBody(MMIO_DEFAULTS?.BRAND_THEME);
        this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);
        const vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document.
        if (getUrlParam("roadmap_redirect")) {
            localStorage.setItem("roadmap_redirect", getUrlParam("roadmap_redirect"))
        }

        document.documentElement.style.setProperty("--vh", `${vh}px`);
        if (getUrlParam("aff_id")) {
            localStorage.setItem("aff_id", getUrlParam("aff_id"))
        }

        this.loadScriptsAndStyles();
        window.loadUserAccountMountHandlers = this.loadUserAccountMounted;

        this.getUserGeoIpData();

    },
    destroyed() {
        window.removeEventListener("resize", this.handleWindowResize);
        window.removeEventListener("scroll", this.handleScroll);
    }
}

function getUrlParam(_key) {
    if (history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        return searchParams.get(_key);
    }
}

</script>
<style lang="scss">
.vs-sidebar--background {
    z-index: 991 !important;
}

@media only screen and (max-width: 1199px) {
    .vs-sidebar--background {
        z-index: 898 !important;
    }
}

.vx-navbar-wrapper {
    z-index: 897 !important;
}

.vs-sidebar.vs-sidebar-primary.vs-sidebar-parent {
    z-index: 899 !important;
}

.vs-sidebar.vs-sidebar-primary {
    z-index: 997 !important;
}

.con-vs-popup.vs-popup-primary {
    z-index: 998 !important;
}

.show-overlay #content-overlay {
    z-index: 897 !important;
}

.ps-container.scroll-area-v-nav-menu {
    padding-bottom: 50px !important;
}

.vs-sidebar-reduce .vs-sidebar--item i {
    padding: 0px !important;
}

.vs__selected,
.vs__dropdown-menu li {
    text-transform: capitalize;
}

.swal2-content {
    font-size: smaller !important;
}

.swal2-title {
    font-size: 1.2em !important;
}

.swal2-checkbox,
.swal2-file,
.swal2-input,
.swal2-radio,
.swal2-select,
.swal2-textarea {
    margin: 2px auto 7.5px auto;
}

#file_icon,
#img_icon,
#vid_icon {
    opacity: 0.5 !important;
}

.v-facebook-login {
    cursor: pointer !important;
}

.node {
    background-color: rgba(110, 136, 255, 0.8) !important;
    border: 2px solid #4e58bf;
}

.node:hover {
    -webkit-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
}

.node:hover::after {
    opacity: 1;
}

.NodeHasValue .node {
    background: rgba(86, 202, 13, 0.8) !important;
    border: 2px solid #7eff14 !important;
}

.NodeContainer .node.selected {
    border: 5px solid rgba(223, 219, 16, 0.8) !important;
}

.NodeHasValue .node:hover {
    opacity: 70% !important;
}

.disconnected .node {
    background: gray !important;
    border: transparent !important;
    opacity: 70% !important;
}

#ckeditor ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

#ckeditor li {
    display: list-item;
    text-align: -webkit-match-parent;
}

#ckeditor ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.swal2-textarea {
    max-width: 100%;
    min-width: 100%;
}

.swal2-container {
    z-index: 999 !important;
}

.vs-con-table {
    background: transparent !important;
}

.con-img-upload {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.dark {
    .swal2-range {
        background: #0A0E23 !important;
    }

    .swal2-content {
        color: rgb(249 250 251) !important;

        input,
        textarea {
            color: rgb(249 250 251) !important;
        }
    }
}


/*DARK THEME*/

.theme-dark .--page-wrap-user {
    background-color: #10163a !important;
}

.theme-dark .--page-name {
    color: rgb(249 250 251) !important;
}

.theme-dark .--page-stats {
    color: rgb(249 250 251) !important;
}

.theme-dark .--page-wrap-auto {
    background-color: #10163a !important;
}

.theme-dark .--insta-wrap-auto {
    background-color: #10163a !important;
}

.theme-dark .--insta-name {
    color: rgb(249 250 251) !important;
}

.theme-dark .--insta-stats {
    color: rgb(249 250 251) !important;
}

.theme-dark .swal2-popup {
    background: #0A0D23 !important;
}

/*SEMI-DARK THEME*/

.theme-semi-dark #nav-icons>a>i {
    color: rgb(249 250 251) !important;
    ;
}

.vs-component.vs-input.vs-con-input-label {
    width: 100% !important;
}

.node-selected>.nodeWrapper {
    -webkit-box-shadow: 0 0px 0 5px #ecc200, 0 2px 4px 10px rgb(0 0 0 / 10%) !important;
    box-shadow: 0 0px 0 5px #ecc200, 0 2px 4px 10px rgb(0 0 0 / 10%) !important;
}

.selection-mode {
    display: none !important;
    ;
}

#popper-tooltip #custom_var_wrap {
    font-size: 12px !important;
}

:not(body)>.con-vs-loading {
    position: absolute !important;
}
</style>
