import { debounce } from 'lodash';
import initMMIOaxios from "@/utils/axios"
const leading = true;
const trailing = false;

export default {

	getSuiteCases: debounce(async function ({ commit }) {
		const axios = await initMMIOaxios();
        const {data} = await axios.get("/apis/get_suitecase_groups")
		commit('UPDATE_SUITECASES', data)
	}, 1000, { leading, trailing }),

    async createSuiteCase({state},payload){
		const axios = await initMMIOaxios();
        const {data} = await axios.post("/apis/write_suitecase", payload);
        return data;
    },

    createSuiteCaseGroup: debounce(async function ({ state }, payload) {
		const axios = await initMMIOaxios();
        const {data} = await axios.post("/apis/write_suitecase_group", payload);
        return data;
    }, 1000, { leading, trailing }),
    
    async getFunctionsLibrary({commit}){
		const axios = await initMMIOaxios();
        const {data} = await axios.post("/apis_integrations/get_functions_library");
        commit('UPDATE_JAVSCRIPT_LIBRARY', data)
        return data;
    }

}