<template>
    <SkeletonTheme
        :color="$store.state.theme==='dark' ? '#10163A' : '#eeeeee'"
        :highlight="$store.state.theme==='dark' ? '#262C47' : '#f5f5f5'"
    >
        <Skeleton :height="height" v-bind="$attrs"></Skeleton>
    </SkeletonTheme>
</template>

<script>
	import { Skeleton,SkeletonTheme } from "vue-loading-skeleton";
    export default {
        name: "VxSkeleton",
        props: {
            height: {
                type: String,
                default: ""
            }
        },
		components: {
			Skeleton,
			SkeletonTheme,
        }
    }
</script>