import Currencies from '@/utils/Currencies'
import { i18n } from "@/i18n";
import { logoMap, channelMap } from "@/utils/channeDataMap"
import router from "@/routes"

const getPageConversionApiObj = () => {
    const _valueToSum = {
        dataType: 'float',
        description: i18n.t("The conversion value. Must be numeric. Example: 12.50")
    };
    const fb_content_id = {
        dataType: 'array',
        description: i18n.t("Content identifier(s), example: 1234,5678")
    };
    const fb_content_type = {
        dataType: 'enum',
        enumList: [
            { value: 'product', text: i18n.t('Product') },
            { value: 'product_group', text: i18n.t('Product Group') }
        ]
    }
    const fb_currency = {
        dataType: 'enum',
        enumList: Currencies.map(({ value, label }) => {
            return { value, text: label }
        })
    }
    const BooleanType = {
        dataType: 'enum',
        enumList: [
            { text: "True", value: 1 },
            { text: "False", value: 0 },
        ]
    }
    const StringType = {
        dataType: 'string',
    };
    const fb_registration_method = { ...StringType, description: i18n.t("Facebook, Email, Twitter, etc.") };
    const fb_level = { ...StringType, description: i18n.t("Level of a game") };
    const fb_search_string = { ...StringType, description: i18n.t("The text string that was searched for") };
    const fb_order_id = { ...StringType, description: i18n.t("Order ID identifier, ex: 543321") };
    const fb_payment_info_available = BooleanType;
    const fb_success = BooleanType;
    const pageEvents = {
        'CAPI Purchase': {
            fb_currency,
            _valueToSum
        },
        'CAPI Lead': {},
        'Contact': {},
        'CustomizeProduct': {},
        'Donate': {},
        'lead_submitted': {},
        'fb_mobile_achievement_unlocked': {
            fb_description: {
                dataType: 'string',
                description: i18n.t("A string description")
            }
        },
        'fb_mobile_activate_app': {},
        'fb_mobile_add_payment_info': {
            fb_success,
        },
        'fb_mobile_add_to_cart': {
            fb_content_type,
            fb_content_id,
            fb_currency,
            _valueToSum
        },
        'fb_mobile_add_to_wishlist': {
            fb_content_type,
            fb_content_id,
            fb_currency,
            _valueToSum
        },
        'fb_mobile_complete_registration': {
            fb_registration_method
        },
        'fb_mobile_content_view': {
            fb_content_type,
            fb_content_id,
            fb_currency,
            _valueToSum
        },
        'fb_mobile_initiated_checkout': {
            fb_content_type,
            fb_content_id,
            fb_currency,
            fb_payment_info_available,
            _valueToSum
        },
        'fb_mobile_level_achieved': {
            fb_level
        },
        'fb_mobile_purchase': {
            fb_content_type,
            fb_content_id,
            fb_currency,
            _valueToSum
        },
        'fb_mobile_rate': {
            fb_content_type,
            fb_content_id,
            _valueToSum
        },
        'fb_mobile_search': {
            fb_content_type,
            fb_search_string,
            fb_success,
        },
        'fb_mobile_spent_credits': {
            fb_content_type,
            fb_content_id,
            _valueToSum
        },
        'fb_mobile_tutorial_completion': {
            fb_success,
            fb_content_id
        },
        'FindLocation': {},
        'Schedule': {},
        'StartTrial': {
            fb_order_id,
            fb_currency,
            _valueToSum
        },
        'SubmitApplication': {},
        'Subscribe': {
            fb_order_id,
            fb_currency,
            _valueToSum
        },
    };
    return pageEvents;
}

export default {
    //Flow Builder
    GetFlows: state => {
        const activePages = Object.values(state.PageOptions).filter(p => p.bot_enabled);
        let output = state.Flows
        const route = router.app._route;
        const isWebChatWidget = route.path.includes("web-chat-widget");
        if (!isWebChatWidget) {
            output = output.filter(flow => activePages.some(page => page.id == flow.page_id) || Number(flow.page_id) == 0);
        }
        //First, remove all null and undefined keys
        Object.keys(localStorage)
            .forEach(key => {
                if (key.includes('null') || key.includes('undefined')) {
                    localStorage.removeItem(key)
                }
            })
        //Format valid local cache flows
        let cachedFlows = Object.keys(localStorage)
            .filter(key => key.includes('FlowBuilderData') && !key.includes('Database') && !key.includes('null') && !key.includes('undefined'))
            .map(key => {
                return { ...JSON.parse(localStorage[key]), ...{ localkey: key } }
            })
            .map(cData => {
                if (!cData?.name) {
                    localStorage.removeItem(cData.localkey);
                    return false;
                }
                if ((isNaN(Number(cData.post_id)) ? 0 : Number(cData.post_id)) < 1) {
                    let cacheFlowPage = activePages.find(page => page.page_id == cData.page_id) || {};
                    let cacheFlowIG = activePages.find(page => page.instagram_business_account == cData.page_id) || {};
                    let obj = {
                        fb_page_id: cData?.page_id || 0,
                        page_id: cacheFlowPage?.id || 0,
                        last_updated: cData.last_updated,
                        name: decodeURIComponent(cData.name),
                        publish_status: "draft",
                        localkey: cData.localkey,
                        channel: isWebChatWidget ? 'widget' : (_isObj(cacheFlowIG)) ? 'instagram' : 'messenger',
                        created_at: cData.last_updated,
                        suitcase_uid: 0,
                    }
                    if (cData.is_clone == 1) { obj.is_clone = 1; }
                    for (const key of Object.keys(cData)) {
                        if (["id", "nodes"].includes(key)) { continue; }
                        delete cData[key];
                    }
                    obj.flow_data = encodeURIComponent(JSON.stringify(cData));
                    return obj;
                } else {
                    return false
                }
            })
            .filter(item => (_isObj(item) && !!item.name && !!item.flow_data));
        let final = [...cachedFlows, ...___(output)];
        return final;
    },
    GetBotSheets: state => state.BotSheets,
    GetTemplates: state => state.Templates,
    GetStores: state => state.StoresAll,
    GetUserPages: state => {
        return state.UserPages.filter(Page => Page.bot_enabled == "1")
    },
    GetUserGrowthTools: state => {
        return state.UserGrowthTools
    },
    GetMessengerBroadcast: state => state.MessengerBroadcast,
    //Chatbot Actions
    getActionsMap(state, getters) {
        const tags = getters.GetContactTagsArray.
            filter(tag => (tag?.page_id == 0) || (tag?.page_id == state.ActivePage?.page_table_id));
        var SmsSequences = state.SMSSequences
        var EmailSequences = Array.isArray(state.Emails) ? state.Emails : [];
        var MessengerSequences = state.MessengerSequences.filter(seq => seq.page_id == state.ActivePage?.page_id);
        var WhatsAppSequences = state.WhatsAppSequences.filter(seq => seq.wa_id == state.ActiveWhatsAppAccount?.id);
        var customVarData = state.CustomVariables.filter(cs => cs.is_native == 0)
        var SubUsers = [...[{ name: state.AppActiveUser.displayName + ` ${i18n.t('(Owner)')}`, id: state.AppActiveUser.user_id }], ...___(state.SubUsers)]
        var OpenAiEmbeddings = state.OpenAiEmbeddings;
        var customVarDataOptions = customVarData.map(customVar => {
            return {
                id: "cv_" + customVar.variable_key,
                name: i18n.t(`Modify customVarName (Custom Variable)`, { customVarName: customVar.name }),
                options: null,
                command: ["set from quick-reply/button", "set new value", "remove value"].map(item => { return { text: i18n.t(item), value: item } }),
                type: "input",
            }
        });
        const pageConversionApiObj = getPageConversionApiObj();
        let Actions = [
            {
                id: "tag",
                name: i18n.t("Tag / Label"),
                options: tags.map(tag => { return { text: tag.group_name, value: tag.id } }),
                command: ["add", "remove"].map(item => { return { text: i18n.t(item), value: item } }),
                type: "choices",
                multiple: true,
            },
            {
                id: "activate_ai",
                name: i18n.t("Activate/Deactivate A.I. Conversation"),
                options: OpenAiEmbeddings.map(i => { return { text: i.name, value: i.uid, disabled: i.is_launched != 1 } }),
                command: ["activate", "deactivate"].map(item => { return { text: i18n.t(item), value: item } }),
                type: "choices"
            },
            {
                id: "human_takeover",
                name: i18n.t("Human Take-over"),
                command: ["second/s", "minute/s", "hour/s"].map(item => { return { text: i18n.t(item), value: item } }),
                type: "input",
                placeholder: i18n.t("Pause bot for how long?"),
                validation: "required|numeric"
            },
            {
                id: "resume_bot",
                name: i18n.t("Resume Bot"),
            },
            {
                id: "bot_subscription",
                name: i18n.t("Subscribe / Unsubscribe To Bot"),
                options: null,
                command: ["subscribe", "unsubscribe"].map(item => { return { text: i18n.t(item), value: item } }),
                type: "simple"
            },
            {
                id: "conversion_api_event",
                name: i18n.t("Trigger Conversion Event"),
                payload: pageConversionApiObj,
                command: Object.keys(pageConversionApiObj).map(item => { return { text: i18n.t(item), value: item } }),
                type: "configure"
            },
            {
                id: "messenger_sequence",
                name: i18n.t("Messenger Sequence"),
                options: MessengerSequences.map(chat => { return { text: chat.name, value: chat.id } }),
                command: ["add", "remove"].map(item => { return { text: i18n.t(item), value: item } }),
                type: "choices"
            },
            {
                id: "whatsapp_sequence",
                name: i18n.t("WhatsApp Sequence"),
                options: WhatsAppSequences.map(chat => { return { text: chat.name, value: chat.id } }),
                command: ["add", "remove"].map(item => { return { text: i18n.t(item), value: item } }),
                type: "choices"
            },
            {
                id: "email_sequence",
                name: i18n.t("Email Sequence"),
                options: EmailSequences.map(email => { return { text: email.name, value: email.webhook_url } }),
                command: ["add", "remove"].map(item => { return { text: i18n.t(item), value: item } }),
                type: "choices"
            },
            {
                id: "sms_sequence",
                name: i18n.t("SMS Sequence"),
                options: SmsSequences.map(sms => { return { text: sms.name, value: sms.id } }),
                command: ["add", "remove"].map(item => { return { text: item, value: item } }),
                type: "choices"
            },
            {
                id: "webhook",
                name: i18n.t("Trigger Webhook"),
                command: [i18n.t("Enter URL 👉")].map(item => { return { text: item, value: item } }),
                type: "input",
                placeholder: "E.g.: https://webhook-target.com/webhook/endpoint",
                validation: "required|url"
            },
            {
                id: "notify_admin_messenger",
                name: i18n.t("Notify Admin via Messenger"),
                command_multiple: true,
                command: SubUsers.map(usr => { return { text: usr.name, value: usr.id } }),
                label: i18n.t("Enter Notification Message"),
                type: "textarea",
                value: i18n.t("DEFAULT_ADMIN_MESSENGER_NOTIFY")
            },
            {
                id: "notify_admin_email",
                name: i18n.t("Notify Admin via Email"),
                command_multiple: true,
                command: SubUsers.map(usr => { return { text: usr.name, value: usr.id } }),
                label: i18n.t("Enter Notification Message"),
                type: "textarea",
                value: i18n.t("DEFAULT_ADMIN_EMAIL_NOTIFY")
            },
            {
                id: "notify_admin_sms",
                name: i18n.t("Notify Admin via SMS"),
                command_multiple: true,
                command: SubUsers.map(usr => { return { text: usr.name, value: usr.id } }),
                label: i18n.t("Enter Notification Message"),
                type: "textarea",
                value: i18n.t("DEFAULT_ADMIN_SMS_NOTIFY")
            },
            {
                id: "notify_admin_whatsapp",
                name: i18n.t("Notify Admin via WhatsApp"),
                command_multiple: true,
                command: SubUsers.map(usr => { return { text: usr.name, value: usr.id } }),
                label: i18n.t("Enter Notification Message"),
                type: "textarea",
                value: i18n.t("DEFAULT_ADMIN_WHATSAPP_NOTIFY")
            },
            {
                id: "disable_composer_input",
                name: i18n.t("Disable Composer Input"),
                options: null,
                command: null,
                type: "simple"
            },
            {
                id: "enable_composer_input",
                name: i18n.t("Enable Composer Input"),
                options: null,
                command: null,
                type: "simple"
            },
            {
                id: "set_dynamic_persistent_menu",
                name: i18n.t("Set Dynamic Persistent Menu"),
                value: [],
                command: ["Enable Composer Input", "Disable Composer Input"].map(item => { return { text: item, value: item } }),
                type: "configure"
            },
            {
                id: "remove_dynamic_persistent_menu",
                name: i18n.t("Remove Dynamic Persistent Menu"),
                options: null,
                command: null,
                type: "simple"
            },
        ]

        const userChatbotIntegrations = getters.getUserChatbotIntegrations
        if (userChatbotIntegrations.length) {
            var thirdPartyIntegration = [];
            userChatbotIntegrations.forEach(integration => {
                thirdPartyIntegration.push({
                    id: `third_party_integration_${integration.type}`,
                    name: i18n.t(`🔥 Add Email To integrationType List`, { integrationType: integration.type }),
                    options: state.ChatbotIntegrationEmailLists[integration.type],
                    command: ["execute"].map(item => { return { text: item, value: item } }),
                    type: "choices",
                    multiple: true,
                })
            })
            Actions = [...Actions, ...thirdPartyIntegration]
        }

        if (customVarDataOptions.length) {
            Actions = [...Actions, ...customVarDataOptions]
        }

        return Actions

    },
    getUserChatbotIntegrations(state) {
        const chatBotIntegrations = state.Integrations
            ?.find(_int => _int.name == "Chatbot Integrations")
            ?.items
            ?.map(item => item.name)
        if (!Array.isArray(chatBotIntegrations)) return []
        const userChatbotIntegrations = state.userIntegrations.filter(user_int => chatBotIntegrations.includes(user_int.type))
        return userChatbotIntegrations;
    },
    FlowOptions(state, getters) {
        let output = {};
        for (const omniFlow of Object.values(___(state.OmniChannelFlows)).reverse()) {
            try {
                if (!omniFlow.entry) { continue; }
                const flowItem = {
                    postback_id: omniFlow.entry,
                    label: omniFlow.name,
                    value: omniFlow.id,
                    data: omniFlow,
                    channel: 'omnichannel-utility'
                };
                if (!flowItem?.postback_id) { continue; }
                output[flowItem.postback_id] = flowItem;
            } catch (e) { __console.log(e) }
        }
        let channel = state.ActivePage.channel
        let accountID = state.ActivePage.page_table_id
        if ( window.location.pathname.includes("web-chat-widget") ) {
            channel = 'widget';
            accountID = state.ActiveWebWidgetAccount.id;
        }
        for (const Flow of getters.GetFlows) {
            try {
                if (!Flow.entry) { continue; }
                if (
                    Flow.page_id == accountID
                    &&
                    Flow.channel == channel
                ) {
                    const flowItem = {
                        postback_id: Flow.entry,
                        label: Flow.name,
                        value: Flow.id,
                        data: Flow,
                        channel: Flow.channel
                    };
                    if (!flowItem?.postback_id) { continue; }
                    output[flowItem.postback_id] = flowItem;
                }
            } catch (e) { __console.log(e) }
        }
        return Object.values(output);
    },
    getChannelIcon() {
        return (channel = 'omnichannel-utility') => {
            return logoMap?.[channel] || logoMap['omnichannel-utility'];
        }
    },
    openMessengerIgFlowEditor(state, getters) {
        return async ({ postback_id, flow_id }, context) => {
            try {

                //<-- Start UserPermissionCheck
                if (!getters.checkUserActionPermission(context.$route.name, ["create", "edit"])) {
                    return false;
                }
                // End UserPermissionCheck -->

                //initialize
                let ParamObj = {};
                const channel = state.ActivePage.channel;
                ParamObj.flow_name = `New ${capitalize(channel)} Flow#` + uuidv4();
                ParamObj.post_id = 0;
                ParamObj.page_id = state.ActivePage.page_id;
                ParamObj.channel = channel;
                localStorage.removeItem(
                    "FlowBuilderData" + ParamObj.post_id + ParamObj.page_id
                );
                let FlowHandler = "Flow Builder";
                if (
                    (typeof postback_id != 'undefined' && postback_id !== false)
                    ||
                    (typeof flow_id != 'undefined' && flow_id !== false)
                ) {

                    if (!postback_id && !flow_id) {
                        context.$vs.notify({
                            title: `Please select ${capitalize(channel)} Flow`,
                            color: "warning"
                        })
                        return false;
                    }

                    // Get flow data
                    const flow = getters.FlowOptions.find((f) => {
                        if (!!flow_id && f.value == flow_id) {
                            return f;
                        } else if (!!postback_id && f.postback_id == postback_id) {
                            return f;
                        }
                    });

                    context.$vs.loading();
                    let flowData;
                    let database;
                    if (flow.channel === 'omnichannel-utility') {
                        FlowHandler = "Create Omni-channel Flows";
                        flowData = await context.$store.dispatch("getOmniChannelFlowsSingle", { id: flow.value });
                        context.$vs.loading.close();
                        if (!_isObj(flowData)) {
                            context.$vs.notify({
                                title: "Selected Flow Does Not Exist",
                                text: "Please create a valid flow",
                                color: "warning"
                            })
                            return false;
                        }

                        //Set Query parameters
                        ParamObj.post_id = flowData.id;
                        ParamObj.flow_name = flowData.name;

                        //set the Flow Data here
                        database = "OmniFlowBuilderData-Database" + ParamObj.post_id;
                        localStorage.removeItem("OmniFlowBuilderData" + ParamObj.post_id);
                        flowData.flow_data = JSON.stringify(flowData.flow_data);

                    } else {

                        flowData = await context.$store.dispatch("getFlowsActionSingle", { id: flow.value });
                        context.$vs.loading.close();
                        if (!_isObj(flowData)) {
                            context.$vs.notify({
                                title: "Selected Flow Does Not Exist",
                                text: "Please create a valid flow",
                                color: "warning"
                            })
                            return false;
                        }

                        //Set Query parameters
                        ParamObj.post_id = flowData.id;
                        ParamObj.page_id = flowData.fb_page_id;
                        ParamObj.flow_name = flowData.name;
                        ParamObj.channel = flowData.channel;

                        //set the Flow Data here
                        database = "FlowBuilderData-Database" + flowData.id + flowData.fb_page_id;

                    }

                    const GlobalDataRaw = decodeURIComponent(flowData.flow_data);
                    localStorage.setItem(database, GlobalDataRaw);

                }

                let routeData = context.$router.resolve({
                    name: FlowHandler,
                    query: ParamObj
                });

                //Open Flow editor on a new window
                window.open(
                    routeData.href,
                    "_blank",
                    "toolbar=yes,scrollbars=yes,resizable=yes,top=30,left=250,width=1050,height=700"
                );
            } catch (e) { __console.log(e) }
        }
    },
    getChatbotStats(state) {
        return postbackid => {
            const stats = state.FlowStats[postbackid] || {
                sends: 0,
                deliveries: 0,
                reads: 0,
                clicks: {}
            }
            return {
                sends: stats.sends,
                deliveries: stats.sends > 0 ? Math.ceil((stats.deliveries / stats.sends) * 100) : 0,
                reads: stats.sends > 0 ? Math.ceil((stats.reads / stats.sends) * 100) : 0,
                clicks: stats.clicks,
            }
        }
    },
    getClickStats(state, getters) {
        return (parent, child, carouselIndex) => {
            const stats = getters.getChatbotStats(parent);
            const { clicks } = stats;
            let count = 0;
            if (_isObj(clicks?.["carousel"])) {
                const i = clicks?.["carousel"]?.[carouselIndex]?.[child];
                count = isNaN(Number(i)) ? 0 : Number(i);
            } else {
                count = isNaN(Number(clicks[child])) ? 0 : Number(clicks[child]);
            }
            return stats.sends > 0 ? Math.ceil((count / stats.sends) * 100) : 0;
        }
    },
}
