const currencies = [
    {value: 'AFN', label: 'Afghan Afghani'},
    {value: 'ALL', label: 'Albanian Lek'},
    {value: 'DZD', label: 'Algerian Dinar'},
    {value: 'AOA', label: 'Angolan Kwanza'},
    {value: 'ARS', label: 'Argentine Peso'},
    {value: 'AMD', label: 'Armenian Dram'},
    {value: 'AWG', label: 'Aruban Florin'},
    {value: 'AUD', label: 'Australian Dollar'},
    {value: 'AZN', label: 'Azerbaijani Manat'},
    {value: 'BSD', label: 'Bahamian Dollar'},
    {value: 'BHD', label: 'Bahraini Dinar'},
    {value: 'BDT', label: 'Bangladeshi Taka'},
    {value: 'BBD', label: 'Barbados Dollar'},
    {value: 'BYR', label: 'Belarusian Ruble'},
    {value: 'BZD', label: 'Belize Dollar'},
    {value: 'BMD', label: 'Bermudian Dollar'},
    {value: 'BTN', label: 'Bhutanese Ngultrum'},
    {value: 'BOB', label: 'Boliviano'},
    {value: 'BAM', label: 'Bosnia & Herzegovina Convertible Mark'},
    {value: 'BWP', label: 'Botswana Pula'},
    {value: 'BRL', label: 'Brazilian Real'},
    {value: 'BND', label: 'Brunei Dollar'},
    {value: 'BGN', label: 'Bulgarian Lev'},
    {value: 'BIF', label: 'Burundian Franc'},
    {value: 'XPF', label: 'CFP Franc'},
    {value: 'KHR', label: 'Cambodian Riel'},
    {value: 'CAD', label: 'Canadian Dollar'},
    {value: 'CVE', label: 'Cape Verde Escudo'},
    {value: 'KYD', label: 'Cayman Islands Dollar'},
    {value: 'XAF', label: 'Central African CFA Franc'},
    {value: 'CLP', label: 'Chilean Peso'},
    {value: 'COP', label: 'Colombian Peso'},
    {value: 'KMF', label: 'Comoro Franc'},
    {value: 'CDF', label: 'Congolese Franc'},
    {value: 'CRC', label: 'Costa Rican Colon'},
    {value: 'HRK', label: 'Croatian Kuna'},
    {value: 'CUC', label: 'Cuban Convertible Peso'},
    {value: 'CUP', label: 'Cuban Peso'},
    {value: 'CZK', label: 'Czech Koruna'},
    {value: 'DKK', label: 'Danish Krone'},
    {value: 'DJF', label: 'Djiboutian Franc'},
    {value: 'DOP', label: 'Dominican Peso'},
    {value: 'XCD', label: 'East Caribbean Dollar'},
    {value: 'EGP', label: 'Egyptian Pound'},
    {value: 'ERN', label: 'Eritrean Nakfa'},
    {value: 'ETB', label: 'Ethiopian Birr'},
    {value: 'EUR', label: 'Euro'},
    {value: 'FKP', label: 'Falkland Islands Pound'},
    {value: 'FJD', label: 'Fiji Dollar'},
    {value: 'GMD', label: 'Gambian Dalasi'},
    {value: 'GEL', label: 'Georgian Lari'},
    {value: 'GHS', label: 'Ghanaian Cedi'},
    {value: 'GIP', label: 'Gibraltar Pound'},
    {value: 'GTQ', label: 'Guatemalan Quetzal'},
    {value: 'GNF', label: 'Guinean Franc'},
    {value: 'GYD', label: 'Guyanese Dollar'},
    {value: 'HTG', label: 'Haitian Gourde'},
    {value: 'HNL', label: 'Honduran Lempira'},
    {value: 'HKD', label: 'Hong Kong Dollar'},
    {value: 'HUF', label: 'Hungarian Forint'},
    {value: 'ISK', label: 'Icelandic Króna'},
    {value: 'INR', label: 'Indian Rupee'},
    {value: 'IDR', label: 'Indonesian Rupiah'},
    {value: 'IRR', label: 'Iranian Rial'},
    {value: 'IQD', label: 'Iraqi Dinar'},
    {value: 'ILS', label: 'Israeli New Shekel'},
    {value: 'JMD', label: 'Jamaican Dollar'},
    {value: 'JPY', label: 'Japanese Yen'},
    {value: 'JOD', label: 'Jordanian Dinar'},
    {value: 'KZT', label: 'Kazakhstani Tenge'},
    {value: 'KES', label: 'Kenyan Shilling'},
    {value: 'KWD', label: 'Kuwaiti Dinar'},
    {value: 'KGS', label: 'Kyrgyzstani Som'},
    {value: 'LAK', label: 'Lao Kip'},
    {value: 'LBP', label: 'Lebanese Pound'},
    {value: 'LSL', label: 'Lesotho Loti'},
    {value: 'LRD', label: 'Liberian Dollar'},
    {value: 'LYD', label: 'Libyan Dinar'},
    {value: 'MOP', label: 'Macanese Pataca'},
    {value: 'MKD', label: 'Macedonian Denar'},
    {value: 'MGA', label: 'Malagasy Ariary'},
    {value: 'MWK', label: 'Malawian Kwacha'},
    {value: 'MYR', label: 'Malaysian Ringgit'},
    {value: 'MVR', label: 'Maldivian Rufiyaa'},
    {value: 'MRO', label: 'Mauritanian Ouguiya'},
    {value: 'MUR', label: 'Mauritian Rupee'},
    {value: 'MXV', label: 'Mexican Unidad De Inversion'},
    {value: 'MXN', label: 'Mexican Peso'},
    {value: 'MDL', label: 'Moldovan Leu'},
    {value: 'MNT', label: 'Mongolian Tögrög'},
    {value: 'MAD', label: 'Moroccan Dirham'},
    {value: 'MZN', label: 'Mozambican Metical'},
    {value: 'MMK', label: 'Myanmar Kyat'},
    {value: 'NAD', label: 'Namibian Dollar'},
    {value: 'NPR', label: 'Nepalese Rupee'},
    {value: 'ANG', label: 'Netherlands Antillean Guilder'},
    {value: 'BYN', label: 'New Belarusian Ruble'},
    {value: 'TWD', label: 'New Taiwan Dollar'},
    {value: 'NZD', label: 'New Zealand Dollar'},
    {value: 'NIO', label: 'Nicaraguan Córdoba'},
    {value: 'NGN', label: 'Nigerian Naira'},
    {value: 'XXX', label: 'No Currency'},
    {value: 'KPW', label: 'North Korean Won'},
    {value: 'NOK', label: 'Norwegian Krone'},
    {value: 'OMR', label: 'Omani Rial'},
    {value: 'PKR', label: 'Pakistani Rupee'},
    {value: 'PAB', label: 'Panamanian Balboa'},
    {value: 'PGK', label: 'Papua New Guinean Kina'},
    {value: 'PYG', label: 'Paraguayan Guaraní'},
    {value: 'PEN', label: 'Peruvian Sol'},
    {value: 'PHP', label: 'Philippine Peso'},
    {value: 'PLN', label: 'Polish Złoty'},
    {value: 'GBP', label: 'Pound Sterling'},
    {value: 'QAR', label: 'Qatari Riyal'},
    {value: 'CNY', label: 'Renminbi|Chinese Yuan'},
    {value: 'RON', label: 'Romanian Leu'},
    {value: 'RUB', label: 'Russian Ruble'},
    {value: 'RWF', label: 'Rwandan Franc'},
    {value: 'SHP', label: 'Saint Helena Pound'},
    {value: 'SVC', label: 'Salvadoran Colón'},
    {value: 'WST', label: 'Samoan Tala'},
    {value: 'SAR', label: 'Saudi Riyal'},
    {value: 'RSD', label: 'Serbian Dinar'},
    {value: 'SCR', label: 'Seychelles Rupee'},
    {value: 'SLL', label: 'Sierra Leonean Leone'},
    {value: 'SGD', label: 'Singapore Dollar'},
    {value: 'SBD', label: 'Solomon Islands Dollar'},
    {value: 'SOS', label: 'Somali Shilling'},
    {value: 'ZAR', label: 'South African Rand'},
    {value: 'KRW', label: 'South Korean Won'},
    {value: 'SSP', label: 'South Sudanese Pound'},
    {value: 'LKR', label: 'Sri Lankan Rupee'},
    {value: 'SDG', label: 'Sudanese Pound'},
    {value: 'SRD', label: 'Surinamese Dollar'},
    {value: 'SZL', label: 'Swazi Lilangeni'},
    {value: 'SEK', label: 'Swedish Krona'},
    {value: 'CHF', label: 'Swiss Franc'},
    {value: 'SYP', label: 'Syrian Pound'},
    {value: 'STD', label: 'São Tomé & Príncipe Dobra'},
    {value: 'TJS', label: 'Tajikistani Somoni'},
    {value: 'TZS', label: 'Tanzanian Shilling'},
    {value: 'THB', label: 'Thai Baht'},
    {value: 'TOP', label: 'Tongan Paʻanga'},
    {value: 'TTD', label: 'Trinidad & Tobago Dollar'},
    {value: 'TND', label: 'Tunisian Dinar'},
    {value: 'TRY', label: 'Turkish Lira'},
    {value: 'TMT', label: 'Turkmenistani Manat'},
    {value: 'UGX', label: 'Ugandan Shilling'},
    {value: 'UAH', label: 'Ukrainian Hryvnia'},
    {value: 'CLF', label: 'Unidad De Fomento'},
    {value: 'AED', label: 'United Arab Emirates Dirham'},
    {value: 'USD', label: 'United States Dollar'},
    {value: 'UYI', label: 'Uruguay Peso En Unidades Indexadas'},
    {value: 'UYU', label: 'Uruguayan Peso'},
    {value: 'UZS', label: 'Uzbekistan Som'},
    {value: 'VUV', label: 'Vanuatu Vatu'},
    {value: 'VEF', label: 'Venezuelan Bolívar'},
    {value: 'VND', label: 'Vietnamese đồng'},
    {value: 'XOF', label: 'West African CFA Franc'},
    {value: 'YER', label: 'Yemeni Rial'},
    {value: 'ZMW', label: 'Zambian Kwacha'},
    {value: 'ZWL', label: 'Zimbabwean Dollar'},    
]

export default currencies