const moduleNameToIdObj =  {
    "FACEBOOK_ACCOUNTS": 65,
    "FACEBOOK_COMMENT_AUTOMATION": 80,
    "LIVECHAT": 82,
    "PERSISTENT_MENU_REMOVE_MMIO_BRANDING": 198,
    "FACEBOOK_PAGES": 200,
    "CHAT_BROADCAST": 211,
    "GROWTH_TOOL_CHECKBOX_PLUGIN": 213,
    "GROWTH_TOOL_SEND_TO_MESSENGER": 214,
    "GROWTH_TOOL_M_ME_LINKS": 215,
    "GROWTH_TOOL_CUSTOMER_CHAT_PLUGIN": 217,
    "GROWTH_TOOL_MESSAGE_SEND": 218,
    "CHAT_SEQUENCES": 219,
    "CUSTOM_FORMS_MESSENGER_WEBVIEW_EMAIL_CUSTOM_FORMS": 261,
    "EMAIL_BROADCAST_CAMPAIGNS": 263,
    "SMS_BROADCASTS": 264,
    "E_COMMERCE_FEATURE_NUMBER_OF_STORES": 268,
    "ONE_TIME_NOTIFICATION_SEND": 275,
    "FLOW_BUILDER_LIMIT_ON_NUMBER_OF_FLOWS_USER_CAN_CREATE": 777,
    "FLOW_BUILDER_ALLOW_SUBSCRIBERS_TO_USE_TEMPLATES": 778,
    "EMAIL_FLOW_BUILDER_LIMIT_ON_NUMBER_OF_FLOWS_USER_CAN_CREATE": 888,
    "POST_RANDOMIZER_LIMIT_ON_NUMBER_OF_CAMPAIGNS_USER_CAN_CREATE": 999,
    "LANDING_PAGE_BUILDER_LIMIT_ON_NUMBER_OF_LANDING_PAGES_A_USER_CAN_CREATE": 1250,
    "ACCESS_TO_LANDING_PAGE_BUILDER": 1251,
    "LIVESTREAM_LIMIT_ON_NUMBER_OF_LIVESTREAM_USER_CAN_CREATE": 2222,
    "LIVESTREAM_ALLOW_USERS_TO_USE_LIVESTREAM": 2223,
    "TOTAL_EMAIL_MARKETING_CONTACT_LIST_SIZE_LIMIT": 2224,
    "TEAM_MEMBERS": 2225,
    "PAGE_BUILDER_LIMIT_ON_NUMBER_OF_PAGES_USER_CAN_CREATE": 2226,
    "E_COMMERCE_FEATURE_SHOP_DESIGNER": 2227,
    "MESSENGER_BOT_PAGE_SUBSCRIBERS_COUNT": 2228,
    "FACEBOOK_GROUPS": 2229,
    "INSTAGRAM_BUSINESS_ACCOUNTS": 2230,
    "BOT_TOOLS": 2231,
    "LIVESTREAM_DURATION": 2232,
    "LIVESTREAM_VIDEO_SIZE": 2233,
    "INSTAGRAM_COMMENT_AUTOMATION": 2234,
    "GLOBAL_COMMENT_AUTOMATION": 2235,
    "EXPORT_SUBSCRIBERS_IN_CSV_EXCEL": 2236,
    "COMMENT_AUTOMATION_NUMBER_OF_COMMENTS_AUTOMATED": 2237,
    "ECOMMERCE_LIMIT_ON_THE_NUMBER_OF_ORDERS": 2238,
    "ECOMMERCE_CUSTOM_DOMAIN": 2239,
    "ECOMMERCE_PRODUCT_REVIEWS": 2240,
    "ECOMMERCE_EXPORT_ORDERS_TO_CSV_EXCEL": 2241,
    "ECOMMERCE_SHOP_NOTIFICATIONS": 2242,
    "EMAIL_MARKETING_TEMPLATES_ACCESS": 2244,
    "SMS_SEQUENCES": 2245,
    "SMS_SENDER_ID": 2246,
    "GOOGLE_SHEETS_ACCESS": 2247,
    "TOTAL_EMAIL_SEND_LIMIT": 2248,
    "ECOMMERCE_LIMIT_ON_THE_NUMBER_OF_PRODUCTS": 2249,
    "EMAIL_MARKETING_LIMIT_ON_NUMBER_OF_TEMPLATES_USER_CAN_CREATE": 2250,
    "AI_GENERATED_CONTENT_LIMIT": 2251,
    "WHITELABEL_CNAME": 2252,
    "SUB_ACCOUNTS_CLIENT_ACCOUNTS": 2256,
    "SMS_CREDITS": 2262,
    "GMB_AGENTS": 2263,
    "NUMBER_OF_GMB_FLOWS_USER_CAN_CREATE": 2269,
    "WHATSAPP_ACCOUNTS": 2273,
    "NUMBER_OF_WHATSAPP_FLOWS_USER_CAN_CREATE": 2279,
    "NUMBER_OF_OMNICHANNEL_FLOWS_USER_CAN_CREATE": 2280,
    "BOOKING_EVENTS": 2282,
    "OPENAI_EMBEDDINGS": 2283,
    "OPENAI_EMBEDDINGS_WEB_URL_DATA_SOURCES": 2287,
    "EMBEDDINGS_ENTRY_LIMIT": 2284,
    "WEBCHAT_WIDGET_ACCOUNTS" : 2285,
    "WEBCHAT_WIDGET_FLOWS" : 2286,
}

const convertIdToModuleName = moduleId => {
    const IdToModuleName = {};
    for ( const moduleName of Object.keys(moduleNameToIdObj) ){
        const mId = moduleNameToIdObj[moduleName];
        IdToModuleName[mId] = moduleName;
    }
    return IdToModuleName?.[moduleId];
}

const convertModuleNameToId = moduleName => {
    return moduleNameToIdObj?.[moduleName];
}

export {convertIdToModuleName, convertModuleNameToId}