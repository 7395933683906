export default {
    //User
    AppActiveUser : {},
    accessTokenConfirmed: false,
    userGroups: [],
    userGroupsAll: [],
    userAccounts: [],
    userPagesAll: [],
    UserPages: [],
    UsageLog: [],
    TransactionHistory: [],
    Packages: [],
    Modules: [],    
    Tickets: [],
    SubUsers: [],
    userPushEvents: {},
    extraFeeManualPay: [],
}