import cartHandler from './cart'
import baseMixins from './baseMixins'
import StarRating from "vue-star-rating"
export default {
    mixins: [baseMixins],
    components: {
        StarRating
    },
    props: {
        data: {
            type: Object,
            default: () => {
                return {}
            },                
        }
    },
    data(){
        return {
            chosenVariant: {},
            chosenVariantID: "",    
            productQty: 1,
            isLoaded: false 
        }
    },
    computed: {
        productData(){
            return this.data
        },
        productImage() {
            if(!Object.entries(this.productData).length){
                return ""
            }	
            let prodImg = "";
            if (this.productData.has_variant == '0') {
                prodImg = this.productData.thumbnail;
            } else {
                var variants_data = this.productData.variants_data;
                variants_data = Array.isArray(variants_data) ? variants_data : JSON.parse(variants_data)
                var var_img = variants_data?.[0]?.imgFileName?.value;
                if(var_img){
                    prodImg = var_img;
                }else{
                    prodImg = this.productData.thumbnail;
                }	
            }
            return prodImg.includes("http") ? prodImg : AppServerUrl + "upload/ecommerce/" + prodImg
        },
        storeData(){
            return this.$store.state.ActiveStore
        },
        ecom(){
            let ecom = {...cartHandler};
            ecom.store = this.$store
            ecom.productData = this.productData;
            ecom.storeData = this.storeData;
            ecom.chosenVariant = this.chosenVariant;
            ecom.itemQuantity = this.productQty;
            return ecom;
        },
    },
    methods: {
        goToProduct(){
            //this.$nuxt.$router.push({name: 'product-productID', params: {productID: this.productData.seo_url} })
        },
        buyNow(){
            //this.$store.commit("UPDATE_CART_SIDEBAR_BLOCKER", true)
            //this.ecom.addToCart()
            //this.$nuxt.$router.push('/checkout')
        },
        addToCart(){
            /*if(this.productData.has_variant==1){
                this.goToProduct()
            }else{
                this.ecom.addToCart()
            }*/
        },
        fixMediaHeight(){

        }
    },
    mounted() {
        try{
            //Set default chosen variant
            if(this.productData.chosen_variant instanceof Object){
                if(Object.entries(this.productData.chosen_variant).length){
                    this.chosenVariant = this.productData.chosen_variant
                }
            }else{
                this.chosenVariant = this.productData.variants_data[0]
            }
        }catch(e){}
        this.ecom.averageRating()
    },
}