export default {
    // /////////////////////////////////////////////
    // SMS Marketing
    // /////////////////////////////////////////////

    SMS_BROADCASTS(state, val) {
        try {
            state.SMSBroadcasts = val.sort((a, b) => b.id - a.id).map(item => Object.seal(item));
            return true;
        } catch (e) { }
        state.SMSBroadcasts = val
    },

    SMS_COUNTRY_RATES(state, val) {
        state.SMSCountryRates = (Array.isArray(val) ? val : []).map(item => Object.seal(item));
    },

    SMS_USER_CREDITS(state, val) {
        state.UserCredits = val
    },

    SMS_SEQUENCES(state, val) {
        try {
            state.SMSSequences = val.sort((a, b) => b.id - a.id).map(item => Object.seal(item));
            return true;
        } catch (e) { }
        state.SMSSequences = val
    },

    UPDATE_SENDER_IDS(state, val) {
        state.SenderIDs = (Array.isArray(val) ? val : []).map(item => Object.seal(item));
    },
}