import Vue from 'vue'
import App from './App.vue'
import Vuesax from 'vuesax'
import Paginate from 'vuejs-paginate'
import Generic from './components/page-builder-components/Components/Generic.vue'
import router from './routes'
import store from '@/store'
import VueFriendlyIframe from 'vue-friendly-iframe'
import VeeValidate from 'vee-validate'
import copyText from 'vue-clipboard2'
import Swal from 'sweetalert2'
import { i18n } from '@/i18n'
import inithttpAxiosSecureObj from '@/utils/inithttpAxiosSecureObj'
import { VueHammer } from 'vue2-hammer'
import { v4 as uuidv4 } from 'uuid'

import '../themeConfig.js'
import '@/filters/filters.js'
import '@/globalComponents.js'
import '@/assets/css/mmio-tailwind.css'
import '@/assets/css/chat.css'
import '@/assets/scss/main.scss'

//import 'animate.css/animate.min.css'//test commit
//import 'font-awesome/css/font-awesome.css'
//import 'line-awesome/dist/line-awesome/css/line-awesome.min.css'
//import 'material-icons/iconfont/material-icons.css' //Material Icons
//import 'vuesax/dist/vuesax.css' // Vuesax

window.__console = { log: () => {} }

//Lazy Loading Global Vendor Libraries Here:
window.chats = []
window.moment = () => {
    return { tz: () => {}, toDate: () => {} }
}
const momentJsLazyLoader = () =>
    import(/* webpackChunkName: "momentJsLazyLoader" */ './components/momentJsLazyLoader')
momentJsLazyLoader().then((m) => {
    m.default()
})

import { debounce, union } from 'lodash'
window._ = { debounce, union }

window.jQuery = () => {}
const jQueryLazyLoader = () =>
    import(/* webpackChunkName: "jQueryLazyLoader" */ './components/jQueryLazyLoader')
jQueryLazyLoader().then((m) => {
    m.default()
})

//Axios
Vue.prototype.$http = inithttpAxiosSecureObj

//Uuid
window.uuidv4 = uuidv4

// Feather font icon
require('./assets/css/iconfont.css')

function ___(val) {
    if (!(val instanceof Object)) {
        return {}
    }
    return JSON.parse(JSON.stringify(val))
}

const isDevMode = typeof window.webpackHotUpdate === 'function'

window.___ = ___
window.Swal = Swal
window.GlobalData = []
window.NewNodex = ''
window.NewNodey = ''
window.editor = {}
window.MessageData = []
window.FinalSequence = []
window.AppServerUrl = `${process.env.VUE_APP_APP_SERVER_URL}/`
window.ApiServerUrl = isDevMode
    ? `${process.env.VUE_APP_DEV_SERVER_URL}/`
    : `${process.env.VUE_APP_API_SERVER_URL}/`
window.UserSaved = false
window.WhitelistUrlsObj = {
    domains: []
}
window.ComponentLoaderMain = ''
window.NodeSetUpinitiate = ''

function _wait(_title, _text) {
    let timerInterval
    Swal.fire({
        title: _title,
        text: _text,
        timerProgressBar: true,
        allowOutsideClick: false,
        onBeforeOpen: () => {
            Swal.showLoading()
            timerInterval = setInterval(() => {
                const content = Swal.getContent()
                if (content) {
                    const b = content.querySelector('b')
                    if (b) {
                        b.textContent = Swal.getTimerLeft()
                    }
                }
            }, 100)
        },
        onClose: () => {
            clearInterval(timerInterval)
        }
    })
}
window._wait = _wait

const _isObj = function (obj) {
    if (obj instanceof Object) {
        return !!Object.entries(obj).length
    }
    return false
}
window._isObj = _isObj

window.accountIsExpired = function (expired_date) {
    if (typeof expired_date !== 'string') {
        return true
    }
    expired_date = expired_date.substring(0, expired_date.length - 3)
    if (!window.moment) {
        return false
    }
    const expire_on = window.moment(expired_date, 'YYYY-MM-DD HH:mm').toDate() || false
    if (expire_on) {
        return !!(new Date() > expire_on)
    }
    return false
}

window.ObjectToStringParams = (obj) => {
    if (typeof obj !== 'object') {
        return ''
    }
    return new URLSearchParams(obj).toString()
}

/*String.prototype.replaceAll = function (str1, str2, ignore) {
    return this.replace(new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, "\\$&"), (ignore ? "gi" : "g")), (typeof (str2) == "string") ? str2.replace(/\$/g, "$$$$") : str2);
}*/

window.makeUnique = function (array, key) {
    try {
        let obj = {}
        for (const [i, item] of Object.entries(array)) {
            if (_isObj(item)) {
                obj[item[key]] = item
            } else {
                obj[item] = item
            }
        }
        return Object.values(obj)
    } catch (e) {
        return array
    }
}

window.isValidDateStr = function (dateStr) {
    try {
        const date = new Date(dateStr)
        return !isNaN(date.getTime())
    } catch (e) {}
    return false
}

window.trim = function (str, charlist) {
    let whitespace = [
        ' ',
        '\n',
        '\r',
        '\t',
        '\f',
        '\x0b',
        '\xa0',
        '\u2000',
        '\u2001',
        '\u2002',
        '\u2003',
        '\u2004',
        '\u2005',
        '\u2006',
        '\u2007',
        '\u2008',
        '\u2009',
        '\u200a',
        '\u200b',
        '\u2028',
        '\u2029',
        '\u3000'
    ].join('')
    let l = 0
    let i = 0
    str += ''
    if (charlist) {
        whitespace = (charlist + '').replace(/([[\]().?/*{}+$^:])/g, '$1')
    }
    l = str.length
    for (i = 0; i < l; i++) {
        if (whitespace.indexOf(str.charAt(i)) === -1) {
            str = str.substring(i)
            break
        }
    }
    l = str.length
    for (i = l - 1; i >= 0; i--) {
        if (whitespace.indexOf(str.charAt(i)) === -1) {
            str = str.substring(0, i + 1)
            break
        }
    }
    return whitespace.indexOf(str.charAt(0)) === -1 ? str : ''
}

window.getCookies = Object.fromEntries(
    document.cookie.split(';').map((item) => {
        var cookie_data = item.split('=')
        return [trim(cookie_data[0]), trim(cookie_data[1])]
    })
)

window.strReducer = (str, limit = 16) => {
    if (typeof str !== 'string') return str
    return str.substr(0, limit) + (str.length > limit ? '...' : '')
}

const sortObjectKeysAlphabetically = (obj) => {
    const keysArray = Object.keys(___(obj))
    keysArray.sort()
    const sortedObject = {}
    for (const key of keysArray) {
        sortedObject[key] = obj[key]
    }
    return sortedObject
}
window.sortObjectKeysAlphabetically = sortObjectKeysAlphabetically;

window.isObjectSame = (obj1, obj2) => {
    return JSON.stringify(sortObjectKeysAlphabetically(obj1)) === JSON.stringify(sortObjectKeysAlphabetically(obj2))
}

// VUE USE SECTION
Vue.use(Vuesax)
Vue.use(VueHammer)
Vue.use(VueFriendlyIframe)
Vue.use(VeeValidate)
Vue.use(copyText)

// VUE COMPONENT SECTION
Vue.component('paginate', Paginate)
Vue.component('generic', Generic)

// VUE PROTOTYPE SECTION
Vue.prototype.$clone = function (value) {
    if (typeof value != 'object') {
        return value
    }
    var obj = value
    var newObj
    if (obj instanceof Array) {
        newObj = []
    } else if (typeof obj == 'object') {
        newObj = {}
    }
    for (let k in obj) {
        let v = obj[k]

        if (obj instanceof Array) {
            newObj.push(this.$clone(v))
        } else if (typeof obj === 'object') {
            newObj[k] = this.$clone(v)
        } else {
            //should not arrive at this point
        }
    }
    return newObj
}
Vue.prototype.$eventHub = new Vue() // Global event bus
Vue.prototype.$oChain = (obj, ...rest) => {
    let tmp = obj
    for (let key in rest) {
        let name = rest[key]
        tmp = tmp?.[name]
    }
    return tmp || ''
}
const $whiteLabelURL = (url) => {
    const servers = [
        'https://s1.marketingmaster.io',
        'https://s2.marketingmaster.io',
        'https://s3.marketingmaster.io',
        'https://server.marketingmaster.io',
        'https://app.marketingmaster.io'
    ]
    if (window.MMIO_DEFAULTS?.BRAND_DOMAIN && window.MMIO_DEFAULTS?.IS_WHITELABEL) {
        for (const server of servers) {
            if (url.includes(server + '/upload')) {
                return url.replace(server, window.MMIO_DEFAULTS?.BRAND_DOMAIN)
            }
        }
    }
    return url
}
Vue.prototype.$whiteLabelURL = $whiteLabelURL
Vue.prototype.$vueSwalLoading = {}
Vue.prototype.$strReducer = strReducer

// VUE CONFIG SECTION
Vue.config.productionTip = false

const $loadScript = function (src, force = false, props = {}) {
    return new Promise(function (resolve, reject) {
        let existingEl = document.querySelector(`script[src="${src}"]`)
        if (existingEl && !force) {
            if (existingEl.classList.contains('is-loading')) {
                existingEl.addEventListener('load', resolve)
                existingEl.addEventListener('error', reject)
                existingEl.addEventListener('abort', reject)
            } else {
                resolve()
            }
            return
        }
        const el = document.createElement('script')
        el.type = 'text/javascript'
        el.async = true
        el.src = src
        if (props instanceof Object) {
            if (Object.keys(props).length) {
                for (let key of Object.keys(props)) {
                    el.setAttribute(key, props[key])
                }
            }
        }
        el.classList.add('is-loading')
        el.addEventListener('load', () => {
            el.classList.remove('is-loading')
            resolve()
        })
        el.addEventListener('error', reject)
        el.addEventListener('abort', reject)
        document.head.appendChild(el)
    })
}
const $loadStyle = function (href, force = false) {
    return new Promise(function (resolve, reject) {
        let existingEl = document.querySelector(`link[href="${href}"]`)
        if (existingEl && !force) {
            if (existingEl.classList.contains('is-loading')) {
                existingEl.addEventListener('load', resolve)
                existingEl.addEventListener('error', reject)
                existingEl.addEventListener('abort', reject)
            } else {
                resolve()
            }
            return
        }
        const el = document.createElement('link')
        el.rel = 'stylesheet'
        el.href = href
        el.type = 'text/css'
        el.classList.add('is-loading')
        el.addEventListener('load', () => {
            el.classList.remove('is-loading')
            resolve()
        })
        el.addEventListener('error', reject)
        el.addEventListener('abort', reject)
        document.head.appendChild(el)
    })
}
const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

window.capitalize = capitalize
window.$loadScript = $loadScript
window.whiteLabelURL = $whiteLabelURL
Vue.prototype.$loadScript = $loadScript

window.$loadStyle = $loadStyle
Vue.prototype.$loadStyle = $loadStyle

Vue.prototype.$_isObj = _isObj
if (window.MMIO_DEFAULTS instanceof Object) {
    let _MMIO_DEFAULTS = { ...window.MMIO_DEFAULTS }
    if (
        ['my.marketingmaster.io', 'mmio.my' /*,"localhost:8080"*/, 'app.mmio.my'].includes(
            window.location.host
        )
    ) {
        _MMIO_DEFAULTS.COUNTRY = 'MY'
    }
    Vue.prototype.$MMIO_DEFAULTS = _MMIO_DEFAULTS
} else {
    Vue.prototype.$MMIO_DEFAULTS = {
        BRAND_NAME: 'Marketing Master IO',
        BRAND_SHORT_NAME: 'MMIO',
        BRAND_DOMAIN: 'https://marketingmaster.io',
        BRAND_LOGO: 'https://developers.marketingmaster.io/logo.png',
        IS_WHITELABEL: false
    }
}

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App)
}).$mount('#app')
