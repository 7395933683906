<template>
    <div class="mmio-shape-divider" :class="classes">
            <svg v-if="type==1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1920 102">
                <g fill-rule="evenodd" transform="matrix(1 0 0 -1 0 102)">
                    <path fill-opacity=".457" d="M0,7.9621684 C293.239583,47.9621684 613.239583,54.6288351 960,27.9621684 C1306.76042,1.2955018 1626.76042,14.6288351 1920,67.9621684 L1920,102 L0,102 L0,7.9621684 Z" transform="matrix(-1 0 0 1 1920 0)"></path>
                    <path d="M0,37.9621684 C300.495844,88.578393 620.495844,88.578393 960,37.9621684 C1299.50416,-12.6540561 1619.50416,-12.6540561 1920,37.9621684 L1920,102 L0,102 L0,37.9621684 Z"></path>
                </g>
            </svg>

            <svg v-if="type==2" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1920 120">
                <path fill-rule="evenodd" d="M0,0 C320,60 640,90 960,90 C1280,90 1600,60 1920,0 L1920,120 L0,120 L0,0 Z" transform="matrix(1 0 0 -1 0 120)"></path>
            </svg>

            <svg v-if="type==3" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1920 120">
                <polygon fill-rule="evenodd" points="0 0 1920 0 1920 70 991.075 70 960 100 930.377 70 0 70"></polygon>
            </svg>

            <svg v-if="type==4" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1920 120">
                <polygon fill-rule="evenodd" points="0 0 1920 120 0 120" transform="matrix(1 0 0 -1 0 120)"></polygon>
            </svg>

            <svg v-if="type==5" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 217">
                <g fill-rule="evenodd" transform="rotate(180 960 108.5)">
                    <path d="M0,57.46875 C203.364583,135.217754 494.835938,156.564108 874.414062,121.507813 C1192.61198,-13.9827666 1541.14063,-35.3291208 1920,57.46875 L1920,207 L0,207 L0,57.46875 Z" opacity=".3"></path>
                    <path d="M0,79 C292.46875,165.453125 612.46875,165.453125 960,79 C1307.53125,-7.453125 1627.53125,-7.453125 1920,79 L1920,207 L0,207 L0,79 Z" opacity=".6"></path>
                    <path d="M0,89 C288.713542,146.786458 608.713542,146.786458 960,89 C1311.28646,31.2135417 1631.28646,31.2135417 1920,89 L1920,217 L0,217 L0,89 Z"></path>
                </g>
            </svg>

            <svg v-if="type==6" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1920 120">
                <polygon fill-rule="evenodd" points="0 0 1920 0 1920 120 1800 60 1680 120 1560 60 1440 120 1320 60 1200 120 1080 60 960 120 840 60 720 120 600 60 480 120 360 60 240 120 120 60 0 120"></polygon>
            </svg>

            <svg v-if="type==7" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1920 120">
                <path fill-rule="evenodd" d="M0,37.9621684 C300.495844,88.578393 620.495844,88.578393 960,37.9621684 C1299.50416,-12.6540561 1619.50416,-12.6540561 1920,37.9621684 L1920,102 L0,102 L0,37.9621684 Z" transform="matrix(1 0 0 -1 0 102)"></path>
            </svg>
    </div>
</template>

<script>
    export default {
        props: {
            type: {
                type: Number,
                default: 1
            },
            flip: {
                type: Boolean,
                default: false
            },
            invert: {
                type: Boolean,
                default: false
            },
            position:{
                type: String,
                default: "bottom"
            },
        },
        computed: {
            classes(){
                let classes = [];
                if(this.invert){
                    classes.push("mmio-invert-divider");
                }
                if(this.flip){
                    classes.push("mmio-flip-divider");
                }
                if(this.position == 'bottom'){
                    classes.push("mmio-bottom");
                }
                if(this.position == 'top'){
                    classes.push("mmio-top");
                }
                return classes.join(' ');
            }
        }
    }
</script>

<style>

.mmio-shape-divider svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
}

.mmio-bottom {
    transform: rotateX(180deg);
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 0px;
}

.mmio-bottom.mmio-invert-divider svg {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.mmio-bottom.mmio-flip-divider svg {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.mmio-bottom.mmio-invert-divider.mmio-flip-divider svg {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.mmio-top {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
}

.mmio-top.mmio-invert-divider svg {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.mmio-top.mmio-flip-divider svg {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.mmio-top.mmio-invert-divider.mmio-flip-divider svg {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}

</style>