import moduleOpenAi from '@/store/openai/moduleOpenAi.js'
import commentAutomation from "./getters/commentAutomation"
import emailFlowBuilder from "./getters/emailFlowBuilder"
import flowBuilder from "./getters/flowBuilder"
import gmb from "./getters/gmb"
import userAccount from "./getters/userAccount"
import whatsApp from "./getters/whatsApp"
import pageBuilder from "./getters/pageBuilder"
import common from "./getters/common"
import omniChannel from "./getters/omniChannel"
import telegram from "./getters/telegram"

const getters = {
	windowBreakPoint: state => {

		// This should be same as tailwind. So, it stays in sync with tailwind utility classes
		if (state.windowWidth >= 1200) return "xl"
		else if (state.windowWidth >= 992) return "lg"
		else if (state.windowWidth >= 768) return "md"
		else if (state.windowWidth >= 576) return "sm"
		else return "xs"
	},
	scrollbarTag: state => {
		return state.is_touch_device ? 'div' : 'VuePerfectScrollbar'
	},
	...pageBuilder,
	...whatsApp,
	...userAccount,
	...gmb,
	...flowBuilder,
	...emailFlowBuilder,
	...commentAutomation,
	...moduleOpenAi.getters,
	...common,
	...telegram,
	...omniChannel,
}

export default getters