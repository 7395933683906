export default [
    // =============================================================================
    // Telegram Marketing
    // ============================================================================= 
    {
        path: '/telegram/accounts',
        name: 'Telegram Accounts',
        component: () => import(/* webpackChunkName: "TelegramAccountsDashboard" */ '@/views/Telegram/Accounts.vue'),
        slug: 'telegram_accounts',
        category: "Telegram Marketing",
    },
    {
        path: '/telegram/flows',
        name: 'Telegram Flows',
        component: () => import(/* webpackChunkName: "TelegramFlowsDashboard" */ '@/views/Telegram/Flows/Dashboard.vue'),
        slug: 'telegram_flows',
        category: "Telegram Marketing",
    },
    {
        path: '/telegram/flows/create',
        name: 'Create Telegram Flows',
        component: () => import(/* webpackChunkName: "TelegramFlowsCreate" */ '@/views/Telegram/Flows/Create.vue'),
        slug: 'telegram_flows_create',
        category: "Telegram Marketing",
    },
    {
        path: '/telegram/contacts',
        name: 'Telegram Contacts',
        component: () => import(/* webpackChunkName: "TelegramContactsDashboard" */ '@/views/Telegram/Contacts/Dashboard.vue'),
        slug: 'telegram_contacts',
        category: "Telegram Marketing",
    },
    {
        path: '/telegram/livechat',
        name: 'Telegram Livechat',
        component: () => import( /* webpackChunkName: "BrandsDashboard" */ '@/views/Telegram/Livechat/Chat.vue'),
        slug: 'telegram_livechat',
        category: "Telegram Marketing",
    },
    {
        path: '/telegram/broadcast',
        name: 'Telegram Broadcast',
        component: () => import(/* webpackChunkName: "TelegramBroadcastDashboard" */ '@/views/Telegram/Broadcast/Dashboard.vue'),
        slug: 'telegram_broadcast',
        category: "Telegram Marketing",
    },
    {
        path: '/telegram/write-broadcast',
        name: 'Write Telegram Broadcast',
        component: () => import( /* webpackChunkName: "TelegramWriteBroadcastDashboard" */ '@/views/Telegram/Broadcast/Write.vue'),
        slug: 'telegram_write_broadcast',
        category: "Telegram Marketing",
    },
    {
        path: '/telegram/sequences',
        name: 'Telegram Sequences',
        component: () => import( /* webpackChunkName: "TelegramSequences" */ '@/views/Telegram/Sequences/SequencesAll.vue'),
        slug: 'telegram_sequences',
        category: "Telegram Marketing",
    },
    {
        path: '/telegram/sequences/write',
        name: 'Write Telegram Sequences',
        component: () => import( /* webpackChunkName: "TelegramWriteSequences" */ '@/views/Telegram/Sequences/SequencesCreate.vue'),
        slug: 'telegram_write_sequences',
        category: "Telegram Marketing",
    },
    {
        path: '/telegram/chatbot-defaults',
        name: 'Telegram Chatbot Defaults',
        component: () => import( /* webpackChunkName: "TelegramChatbotDefaults" */ '@/views/Telegram/Defaults/ChatbotDefaults.vue'),
        slug: 'telegram_chatbot_defaults',
        category: "Telegram Marketing",
    } 
]