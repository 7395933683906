import store from "@/store"

export default {
    // /////////////////////////////////////////////
    // Instagram
    // /////////////////////////////////////////////	

    UPDATE_ACTIVE_INSTAGRAM(state, val) {
        state.ActiveInstagram = Object.seal(val);
        store.dispatch("getFlowsAction", { channel: "instagram" });
    },

    UPDATE_INSTA_AUTOCOMMENT_CONFIG(state, val) {
        state.instaAutoCommentConfig = val;
    },
}