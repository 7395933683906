import NavMenuItems from '@/layouts/components/vertical-nav-menu/navMenuItems';
import { convertModuleNameToId } from "@/utils/modulesMap.js";
import {userHaveAccess} from "@/common/helpers/helpers.js";
import {i18n} from "@/i18n";
export default {
	// User account handlers
	isOnUsageLimit(state, getters) {
		return function (moduleName, usageData = [], total = null) {
			try {

				const moduleId = convertModuleNameToId(moduleName);
				var userType = state.AppActiveUser.userRole;
				if (userType == "Admin") {
					return { status: false, count: 0, limit: 0, unit: 'unlimited', notice: 'User is Admin' };
				}

				var date = new Date()
				var UsageMonth = date.getMonth()
				var UsageYear = date.getFullYear()
				UsageMonth = Number(UsageMonth) + 1;
				var packageInfo = state.AppActiveUser.package_info
				var monthlyLimit = JSON.parse(packageInfo.monthly_limit)
				var moduleData = ___(state.Modules).find(_m => _m.id == moduleId)

				var moduleIds = packageInfo.module_ids.split(",")
				if (!moduleIds.includes(String(moduleId))) {
					return { status: true, count: 0, limit: "no access", unit: "no access" }
				}

				//Module is unlimited usage
				if (moduleData.limit_enabled == "0") {
					return { status: false, count: 0, limit: "unlimited", unit: "unlimited" }
				}

				//count usage
				let count = getters.getLiveModuleUsageCount({ moduleName, data: usageData, total })
				if (count === false) {
					count = 0;
					var moduleUsage = ___(state.UsageLog.data).filter(usage => {
						if (moduleData.extra_text == "month") {
							if (usage.module_id == moduleId && usage.usage_month == UsageMonth && usage.usage_year == UsageYear) {
								return usage
							}
						}
						else if (moduleData.extra_text == "year") {
							if (usage.module_id == moduleId && usage.usage_year == UsageYear) {
								return usage
							}
						}
						else {
							if (usage.module_id == moduleId) {
								return usage
							}
						}
					});
					moduleUsage.forEach(_mod => {
						count = count + Number(_mod.usage_count)
					});
				}

				//default limitations
				var monthLimit = Number(monthlyLimit[moduleId])
				if (isNaN(monthLimit)) {
					return { status: true, count: 'no access', limit: 'no access', unit: false }
				}
				var defaultLimitText = monthLimit == 0 ? 'unlimited' : moduleData.extra_text
				var usageStatus = (count >= monthLimit && monthLimit != 0)
				return { status: usageStatus, count: count, limit: monthLimit, unit: defaultLimitText }

			} catch (e) {
				__console.log(e)
				return { status: false, count: 0 }
			}

		}
	},

	getLiveModuleUsageCount(state) {
		return function (payload) {
			var { moduleName, data, total } = payload;
			if (typeof total === "number") { return total;}
			data = Array.isArray(data) ? data : [];
			var usageMonth = moment().tz(serverTimezone).format("M");
			var usageYear = moment().tz(serverTimezone).format("Y");
			var format = 'YYYY-MM-DD HH:mm:ss'
			const activeFBPages = Object.values({ ...state.PageOptions })?.filter(page => page?.bot_enabled) || []
      let count = 0;
			switch (moduleName) {
				case "FACEBOOK_COMMENT_AUTOMATION":
					data = ___(state.CommentAutomations).filter(item => {
						if (
							activeFBPages.some(p => p.id == item.page_info_table_id)
							&&
							moment(item.last_updated_at, format).tz(serverTimezone).format("M") == usageMonth
							&&
							moment(item.last_updated_at, format).tz(serverTimezone).format("Y") == usageYear
						) {
							return item;
						}
					})
					break;
				case "INSTAGRAM_BUSINESS_ACCOUNTS":
					data = Object.values({ ...state.PageOptions }).filter(page => page.bot_enabled)
					break;
				case "FACEBOOK_ACCOUNTS":
					data = ___(state.userAccounts).filter(account => account.id != 7777777)
					break;
				case "FLOW_BUILDER_LIMIT_ON_NUMBER_OF_FLOWS_USER_CAN_CREATE":
					data = ___(state.Flows).filter(flow => activeFBPages.some(p => p.id == flow.page_id))
					break;
				case "E_COMMERCE_FEATURE_NUMBER_OF_STORES":
					data = ___(state.StoresAll)
					break;
				case "CHAT_BROADCAST":
					data = ___(state.MessengerBroadcast).filter(item => {
						if (
							activeFBPages.some(p => p.id == item.page_id)
							&&
							moment(item.date_created, format).tz(serverTimezone).format("M") == usageMonth
							&&
							moment(item.date_created, format).tz(serverTimezone).format("Y") == usageYear
						) {
							return item;
						}
					});
					break;
				case "GROWTH_TOOL_CHECKBOX_PLUGIN":
					data = ___(state.UserGrowthTools["messenger_bot_engagement_checkbox"]).filter(gt => activeFBPages.some(p => p.id == gt.page_id))
					break;
				case "GROWTH_TOOL_SEND_TO_MESSENGER":
					data = ___(state.UserGrowthTools["messenger_bot_engagement_send_to_msg"]).filter(gt => activeFBPages.some(p => p.id == gt.page_id))
					break;
				case "GROWTH_TOOL_M_ME_LINKS":
					data = ___(state.UserGrowthTools["messenger_bot_engagement_mme"]).filter(gt => activeFBPages.some(p => p.id == gt.page_id))
					break;
				case "GROWTH_TOOL_CUSTOMER_CHAT_PLUGIN":
					data = ___(state.UserGrowthTools["messenger_bot_engagement_2way_chat_plugin"]).filter(gt => activeFBPages.some(p => p.id == gt.page_id))
					break;
				case "CHAT_SEQUENCES":
					data = ___(state.MessengerSequences).filter(s => activeFBPages.some(p => p.page_id == s.page_id))
					break;
				case "CUSTOM_FORMS_MESSENGER_WEBVIEW_EMAIL_CUSTOM_FORMS":
					data = ___(state.CustomForms)
					break;
				case "TEAM_MEMBERS":
          data = ___(state.SubUsers)
          count = data.length - 1
					break;
				case "PAGE_BUILDER_LIMIT_ON_NUMBER_OF_PAGES_USER_CAN_CREATE":
					data = ___(state.PageBuilderPages).filter(item => item.is_template == 0)
					break;
				case "FACEBOOK_GROUPS":
					data = ___(state.userGroupsAll).filter(group => group.status == 1)
					break;
				case "LIVESTREAM_LIMIT_ON_NUMBER_OF_LIVESTREAM_USER_CAN_CREATE":
					data = data.filter(item => {
						if (
							moment(item.date_created, format).tz(serverTimezone).format("M") == usageMonth
							&&
							moment(item.date_created, format).tz(serverTimezone).format("Y") == usageYear
						) {
							return item;
						}
					})
					break;
				case "ECOMMERCE_LIMIT_ON_THE_NUMBER_OF_PRODUCTS":
					data = ___(state.Products)
					break;
				case "EMAIL_MARKETING_LIMIT_ON_NUMBER_OF_TEMPLATES_USER_CAN_CREATE":
					data = ___(state.EmailTemplates).filter(email => email.is_template == 0)
					break;
				case "WHATSAPP_ACCOUNTS":
					data = state.WhatsAppAccounts;
					break;
				case "NUMBER_OF_WHATSAPP_FLOWS_USER_CAN_CREATE":
					data = state.WhatsAppFlows;
					break;
				case "GMB_AGENTS":
					data = state.gmbAgents;
					break;
				case "NUMBER_OF_GMB_FLOWS_USER_CAN_CREATE":
					data = state.GmbFlows;
					break;
				case "NUMBER_OF_OMNICHANNEL_FLOWS_USER_CAN_CREATE":
					data = state.OmniChannelFlows;
					break;
				case "OPENAI_EMBEDDINGS":
					data = state.OpenAiEmbeddings;
			}
			return count > 0 ? count : (Array.isArray(data) ? data.length : false);
		}
	},

	getRoadmapRedirectUrl() {
		if (typeof generateFeatureMonkeyUserTokenUrl == "function") {
			return generateFeatureMonkeyUserTokenUrl();
		}
		return "https://dash.marketingmaster.io";
	},

	getPageAndInstagramOptions(state) {
		return function (has_insta = true) {
			const PageOptions
				=
				Object.values(___(state.PageOptions))
					.filter(Page => {
						const active_fb_account = state.AppActiveUser?.active_fb_account || state.AppActiveUser?.facebook_rx_fb_user_info
						if (
							active_fb_account == Page.facebook_rx_fb_user_info_id
							&&
							Page.bot_enabled
							&&
							!Page?.is_deleted
						) {
							Page.page_table_id = Page.id
							Page.main_page_id = Page.page_id
							return Page
						}
					})

			const InstagramOptions
				=
				___(PageOptions).filter(obj => {
					if (obj.instagram_data instanceof Object) {
						if (Object.entries(obj.instagram_data).length) {
							return obj
						}
					}
				}).map(obj => {
					const { instagram_data } = obj
					obj.main_page_id = obj.page_id
					obj.value = instagram_data.id
					obj.page_id = instagram_data.id
					obj.page_table_id = obj.id
					obj.page_name = instagram_data.username
					obj.label = instagram_data.username
					obj.logo = instagram_data.profile_picture_url
					obj.messages_enabled = instagram_data.messages_enabled
					obj.eligible = instagram_data.eligible
					obj.$isDisabled = !obj.eligible
					obj.channel = 'instagram'
					obj.id = instagram_data.id
					return obj
				})

			let options = [
				{
					channel: 'Facebook Pages',
					libs: ___(PageOptions).map(obj => {
						obj.channel = 'messenger'
						return obj
					}),
				},
			]

			if (InstagramOptions.length && has_insta) {
				options.push(
					{
						channel: 'Instagram Business',
						libs: InstagramOptions
					},
				)
			}
			return options
		}
	},

	isUserTheAdmin(state) {
		return state.MMIO_DEFAULTS?.ADMIN == state.AppActiveUser.user_id
	},

	externalRoutes() {
		let output = [];
		for (const navItem of NavMenuItems) {
			if (!!navItem.action) {
				output.push({
					id: uuidv4(),
					label: navItem.name,
					value: navItem.slug,
					category: navItem.category,
					permissions: {
						read: false,
						edit: false,
						create: false,
						delete: false,
					}
				});
			} else if (Array.isArray(navItem.items)) {
				for (const item of navItem.items) {
					if (!!item.action) {
						output.push({
							id: uuidv4(),
							label: item.name,
							value: item.slug,
							category: item.category,
							permissions: {
								read: false,
								edit: false,
								create: false,
								delete: false,
							}
						});
					}
				}
			}
		}
		return output;
	},

	mmioMainOnlyRoutes() {
		return [
			"affiliate_program",
		];
	},

	filterNavItems(state, getters) {
		const AppActiveUser = state.AppActiveUser;
		const externalRoutes = getters.externalRoutes;
		const mmioMainOnlyRoutes = getters.mmioMainOnlyRoutes;
		return items => {
			return items.filter(feature => {

				//When Whitelabel mode, remove external routes and mmioonly routes
				if (
					!!MMIO_DEFAULTS.IS_WHITELABEL
					||
					(AppActiveUser.referred_by != 1 && !!MMIO_DEFAULTS.IS_WHITELABEL)
					||
					AppActiveUser.is_proxy == 1
				) {
					if (!!externalRoutes.some(route => route.value == feature.slug)) {
						return false;
					}
					if (!!mmioMainOnlyRoutes.some(route => route == feature.slug)) {
						return false;
					}
				}

				if (!Array.isArray(feature.submenu)) {
					return userHaveAccess(feature.slug);
				}

				return feature.submenu.some(subMenu => {
					return userHaveAccess(subMenu.slug);
				});

			})
		}
	},

    checkUserActionPermission() {
		return (identifier, action='read', showPopup=true ) => {
			const actionList = Array.isArray(action) ? action : [action];
			for ( const actionItem of actionList ) {
				const permissionResult = userHaveAccess(identifier, actionItem);
				if (!permissionResult) {
					if (showPopup) {
						Swal.fire(
							i18n.t("ACTION DENIED!"),
							i18n.t("Sorry, your account does not have permission for this action."),
							"error"
						);
					}
					return permissionResult;
				}
			}
			return true;
		}
    },
}
