import Vue from 'vue'

export default {
    // /////////////////////////////////////////////
    // Ecommerce
    // /////////////////////////////////////////////

    USER_STORES_ALL(state, val) {
        state.StoresAll = (Array.isArray(val) ? val : []).map(item => Object.seal(item));
    },

    USER_PRODUCTS(state, val) {
        try {
            state.Products = val.sort((a, b) => b.id - a.id).map(item => Object.seal(item));
            return true;
        } catch (e) { }
        state.Products = val
    },

    USER_CATEGORIES(state, val) {
        try {
            state.Categories = val.sort((a, b) => b.id - a.id).map(item => Object.seal(item));
            return true;
        } catch (e) { }
        state.Categories = val
    },

    USER_SHIPPING(state, val) {
        try {
            state.Shipping = val.sort((a, b) => b.id - a.id).map(item => Object.seal(item));
            return true;
        } catch (e) { }
        state.Shipping = val
    },

    USER_COUPONS(state, val) {
        try {
            state.Coupons = val.sort((a, b) => b.id - a.id).map(item => Object.seal(item));
            return true;
        } catch (e) { }
        state.Coupons = val
    },

    USER_ORDERS(state, val) {
        try {
            state.Orders = val.sort((a, b) => b.id - a.id).map(item => Object.seal(item));
            return true;
        } catch (e) { }
        state.Orders = val
    },

    UPDATE_ACTIVE_STORE(state, val = false) {
        try {
            if (val instanceof Object) {
                if (Object.entries(val).length) {
                    state.ActiveStore = val
                }
            } else if (state.ActiveStore?.id) {
                var ActiveStore = { ...state.ActiveStore }
                if (typeof ActiveStore?.shipping_location == "string") {
                    ActiveStore.shipping_location = JSON.parse(ActiveStore?.shipping_location || "[]")
                }
                Vue.set(state.ActiveStore, "shipping_location", ActiveStore.shipping_location)
            }
        } catch (e) { __console.log(e) }
    },

    ECOM_NOTIFICATIONS(state, val) {
        try {
            state.Notifications = val.sort((a, b) => b.id - a.id).map(item => Object.seal(item));
            return true;
        } catch (e) { }
        state.Notifications = val
    },
}