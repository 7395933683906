/* eslint-disable */

import pages from '../vertical-nav-menu/navMenuItems.js'
var pages_data = []

export default {
  pages: {
      key: "title",
      data: finalGetSearchData(pages)
    }
}

function finalGetSearchData(){
  pages_data = getSearchData(pages)
  return pages_data;
}

function getSearchData(values){
  if(!values){return false }
  values.forEach(page => {
    if(typeof page.items != "undefined"){
      if(page.items.length){
        getSearchData(page.items)
      }    
    }else if(typeof page.submenu !== "undefined"){
      if(page.submenu.length){
        getSearchData(page.submenu)
      }        
    }else{
      page.title = page.name
      pages_data.push(page)
    }
  })
  return pages_data;
}

/* eslint-enable */
