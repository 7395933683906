import {i18n} from "@/i18n"

export default {
	//GMB Handlers
	getGMBActionsMap(state, getters) {

		var tags = getters.GetContactTagsArray
		var SmsSequences = state.SMSSequences
		var EmailSequences = Array.isArray(state.Emails) ? state.Emails : [];
		var customVarData = state.CustomVariables.filter(cs => cs.is_native == 0)
		var SubUsers = [...[{ name: state.AppActiveUser.displayName + ' (Owner)', id: state.AppActiveUser.user_id }], ...___(state.SubUsers)]
		var OpenAiEmbeddings = state.OpenAiEmbeddings;
		var customVarDataOptions = customVarData.map(customVar => {
			return {
				id: "cv_" + customVar.variable_key,
				name: "Modify " + customVar.name + " (Custom Variable)",
				options: null,
				command: ["set from quick-reply/button", "set new value", "remove value"].map(item => { return { text: item, value: item } }),
				type: "input",
			}
		});
		var Actions = [
			{
				id: "tag",
				name: "Tag / Label",
				options: tags.map(tag => { return { text: tag.group_name, value: tag.id } }),
				command: ["add", "remove"].map(item => { return { text: item, value: item } }),
				type: "choices",
				multiple: true,
			},
			{
				id: "activate_ai",
				name: i18n.t("Activate/Deactivate A.I. Conversation"),
				options: OpenAiEmbeddings.map(i => { return { text: i.name, value: i.uid, disabled: i.is_launched != 1 } }),
				command: ["activate", "deactivate"].map(item => { return { text: i18n.t(item), value: item } }),
				type: "choices"
			},
			{
				id: "bot_subscription",
				name: "Subscribe / Unsubscribe To Bot",
				options: null,
				command: ["subscribe", "unsubscribe"].map(item => { return { text: item, value: item } }),
				type: "simple"
			},
			{
				id: "webhook",
				name: "Trigger Webhook",
				command: ["Enter URL 👉"].map(item => { return { text: item, value: item } }),
				type: "input",
				placeholder: "E.g.: https://webhook-target.com/webhook/endpoint",
				validation: "required|url"
			},
			{
				id: "email_sequence",
				name: "Email Sequence",
				options: EmailSequences.map(email => { return { text: email.name, value: email.webhook_url } }),
				command: ["add", "remove"].map(item => { return { text: item, value: item } }),
				type: "choices"
			},
			{
				id: "sms_sequence",
				name: "SMS Sequence",
				options: SmsSequences.map(sms => { return { text: sms.name, value: sms.id } }),
				command: ["add", "remove"].map(item => { return { text: item, value: item } }),
				type: "choices"
			},
			{
				id: "notify_admin_email",
				name: "Notify Admin via Email",
				command_multiple: true,
				command: SubUsers.map(usr => { return { text: usr.name, value: usr.id } }),
				label: "Enter Notification Message",
				type: "textarea",
				value: `Hello {{admin_name}}\n\n Your chatbot subscriber named {{first_name}} with id#{{subscriber_id}} has triggered an Admin notification action. \n\n You might want to check your inbox now.`
			},
			{
				id: "notify_admin_sms",
				name: "Notify Admin via SMS",
				command_multiple: true,
				command: SubUsers.map(usr => { return { text: usr.name, value: usr.id } }),
				label: "Enter Notification Message",
				type: "textarea",
				value: `Hello {{admin_name}}\n\n Your chatbot subscriber named {{first_name}} with id#{{subscriber_id}} has triggered an Admin notification action. \n\n You might want to check your inbox now.`
			},
		]

		const userChatbotIntegrations = getters.getUserChatbotIntegrations
		if (userChatbotIntegrations.length) {
			var thirdPartyIntegration = [];
			userChatbotIntegrations.forEach(integration => {
				thirdPartyIntegration.push({
					id: `third_party_integration_${integration.type}`,
					name: `🔥 Add Email To ${integration.type} List`,
					options: state.ChatbotIntegrationEmailLists[integration.type],
					command: ["execute"].map(item => { return { text: item, value: item } }),
					type: "choices",
					multiple: true,
				})
			})
			Actions = [...Actions, ...thirdPartyIntegration]
		}

		if (customVarDataOptions.length) {
			Actions = [...Actions, ...customVarDataOptions]
		}

		return Actions

	},
	GmbFlowOptions(state) {
		let output = {};
		for ( const omniFlow of Object.values(___(state.OmniChannelFlows)).reverse()) {
            try{
                if(!omniFlow.entry){continue;}
				const flowItem = {
					text: omniFlow.name,
					value: omniFlow.id,
					channel: 'omnichannel-utility',
					data: omniFlow
				};
				if(!flowItem?.value){continue;}
				output[flowItem.value] = flowItem;
            }catch(e){__console.log(e)}
		}	
        for(const Flow of state.GmbFlows.filter( flow => flow.agent_table_id == state.ActiveAgent.id)){
			const flowItem = {
				text: decodeURIComponent(Flow.name),
				value: Flow.id,
				channel: 'gmb',
				data: Flow
			};
			if(!flowItem?.value){continue;}
			output[flowItem.value] = flowItem;
        }
		return Object.values(output);
	},
}