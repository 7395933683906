async function lazyLoader() {

    if (!window.moment) {
        const momentJsLazyLoader = () => import(/* webpackChunkName: "momentJsLazyLoader" */ "@/components/momentJsLazyLoader")
        let m1 = await momentJsLazyLoader()
        m1.default()
    }

    if (!window._) {
        const lodashLazyLoader = () => import(/* webpackChunkName: "lodashLazyLoader" */ "@/components/lodashLazyLoader")
        let m2 = await lodashLazyLoader()
        m2.default()
    }

    if (!window.jQuery) {
        const jQueryLazyLoader = () => import(/* webpackChunkName: "jQueryLazyLoader" */ "@/components/jQueryLazyLoader")
        let m3 = await jQueryLazyLoader()
        m3.default()
    }

}

export {lazyLoader};