//const { SodiumPlus, CryptographyKey } = require('sodium-plus');

export async function getSecretKey(){
    if(typeof CryptographyKey != "undefined"){
        return CryptographyKey.from(
            process.env.VUE_APP_SECRET_KEY,
            'hex'
        );
    }
}

export async function encryptMessage(message) {
    try{
        if(typeof SodiumPlus != "undefined"){
            const key = await getSecretKey();
            const sodium = await SodiumPlus.auto();
            let nonce = await sodium.randombytes_buf(24);
            let encrypted = await sodium.crypto_secretbox(message, nonce, key);
            var output = nonce.toString('hex') + encrypted.toString('hex');
            return output
        }
    }catch(e){}
    return message;
}

export async function decryptMessage(hash) {
    try{
        if(typeof SodiumPlus != "undefined"){
            const key = await getSecretKey();
            const sodium = await SodiumPlus.auto();
            let nonce = await sodium.sodium_hex2bin(hash.substring(0,48));
            let ciphertext = await sodium.sodium_hex2bin(hash.substring(48));
            let decrypted = await sodium.crypto_secretbox_open(ciphertext, nonce, key);
            return decrypted.toString('utf-8');
        }
    }catch(e){}
    return hash;
}

export async function hashMessage(message){
    if(typeof SodiumPlus === "undefined"){return}
    const key = await getSecretKey();
    if(typeof message === "object"){
        message = JSON.stringify(message);
    }
    return await encryptMessage( message, key);
}