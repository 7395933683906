export default {
    //Omni-channel Flows
    OmniChannelFlows: [],
    allowedMenuItems: [],

    //Omnichannel Chat
    chatSubscribersMap: {},
    chatLogMessages: {},
    chatSettings: {},
    chatConversationsPagingMap: {},
    filteredAccounts: [],
    selectedSubscriber: {},
    selectedSubscriberData: {},
}
