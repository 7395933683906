<script>

    import Flex from "./Flex.vue";
    export default {
      name: "Row",
      components: {
        Flex
      },
      props: { 
        modelKey: {
          type: String,
          default: ""
        },
        parentKey: null
      },
      render: function (createElement){
        let model = {...this.model}
        model.property.props.direction = "row"
        model.property.props.modelKey = model.key
        model.property.props.parentKey = this.parentKey
        return createElement(Flex, model.property)
      },
      computed: {
        model(){
          return this.getModel(this.modelKey)
        }
      },
      methods: {
        getModel(item){
          return this.$clone((this.$store.getters.GetDomObjectContent(item) || {}))
        },
      }
    };

</script>

<style scoped>
</style>