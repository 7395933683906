import {debounce} from 'lodash'
export default {
    props: {
        mode: null,
        category: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data(){
        return {
            filter: {
                category: 'all',
                price_max: null,
                price_min: null,
                limit: 10,
                offset: 0,
                category: null,
                order_by: null,
                order_type: null,
                q: ""
            },
            price: {
                price_max: null,
                price_min: null,                    
            },
            range: []
        }
    },
    watch: {
        range(val){
            this.filter.price_min = val[0]
            this.filter.price_max = val[1]
        }
    },
    computed: {
        categories(){
            return [...[{id: 'all', category_name: "All"}], ...this.$store.state.categories]
        }
    },
    methods: {
        async chooseCategory(item, mode="replace"){
            this.filter.category = item.id
            this.filter.offset = 0
            let filter = {...this.filter}
            filter.category = this.filter.category !== 'all' ? this.filter.category : null
            filter.mode = mode;
            this.$emit("loading", true)
            await this.$store.dispatch("getProductListPaginated", filter)
            this.$emit("loading", false);
        },
        async searchProducts(){
            this.filter.offset = 0
            this.filter = {...this.filter, ...this.price}
            let filter = {...this.filter}
            filter.mode = "replace";
            filter.category = this.filter.category !== 'all' ? this.filter.category : null
            this.$emit("loading", true)
            await this.$store.dispatch("getProductListPaginated", filter)
            this.$emit("loading", false);
        },            
        async addProducts(){
            let filter = {...this.filter}
            filter.mode = "add"
            filter.offset = filter.offset + filter.limit
            filter.category = this.filter.category !== 'all' ? this.filter.category : null
            await this.$store.dispatch("getProductListPaginated", filter)
            this.filter.offset = filter.offset;
        },
        handleMinPrice: debounce(function(){                
            Number(this.price.price_min) < 0 ? this.price.price_min=0 : ''
            this.price.price_min = Number(this.price.price_min)
        },1000),
        handleMaxPrice: debounce(function(){                
            Number(this.price.price_max) <= Number(this.price.price_min) ? this.price.price_max=Number(this.price.price_min)+1 :''
            Number(this.price.price_max) < 0 ? this.price.price_max=0 :''
            this.price.price_max = Number(this.price.price_max)
        },1000),
    },
    mounted() {
        if(this.mode=="category"){
            if(this.category instanceof Object){
                if(Object.entries(this.category).length){
                    //this.chooseCategory(this.category);
                    return;
                }
            }
        }
        //this.chooseCategory({id:'all'})
    },
}