import {v4 as uuidv4} from 'uuid'
var currencyFormatter = require('currency-formatter');
export default {
    productData: {},
    storeData: {},
    chosenVariant: {},
    itemQuantity: 1,
    store: {},
    getPrice() {
        let {displayPrice, rejectedPrice} = this.effectivePriceHelper()
        if(this.isLoading && !displayPrice){
            return false;
        }
        var output = currencyFormatter.format(displayPrice, { code: this.storeData.currency });
        if(rejectedPrice){
            output+=` <span style="font-size:80%!important;text-decoration:line-through;color:rgba(255,0,0,.7)">${currencyFormatter.format(rejectedPrice, { code: this.storeData.currency })}</span>`
        }
        return output
    },
    format(price){
        return currencyFormatter.format(price, { code: this.storeData.currency });
    },    
    effectivePriceHelper(){
        if (this.productData.has_variant == '0') {
            var rejectedPrice = (this.productData.is_sale == '1')? this.productData.original_price : 0;
            var displayPrice = (this.productData.is_sale == '1') ? this.productData.sell_price : this.productData.original_price;
            displayPrice = displayPrice ? Number(displayPrice) : 0;
        } else {
            var rejectedPrice = this.chosenVariant?.isSales ? this.chosenVariant?.origPrice : 0;
            var displayPrice = this.chosenVariant?.isSales ? this.chosenVariant?.salesPrice : this.chosenVariant?.origPrice;
            displayPrice = displayPrice ? Number(displayPrice) : 0;
        }
        return {
            rejectedPrice: Number(rejectedPrice),
            displayPrice: displayPrice
        }
    },
    addToCart(){
        let product = ___(this.productData)
        if(!(product instanceof Object)){return}
        if(!Object.entries(product).length){return}
        let cartQty = this.$store.state.cart?.[product.id] instanceof Object ? this.$store.state.cart?.[product.id]?.itemQuantity : 0
        product.itemQuantity = Number(cartQty) + (isNaN(Number(this.itemQuantity)) ? 0 : Number(this.itemQuantity))
        if(product.has_variant==1){
            product.chosen_variant = ___(this.chosenVariant)
        }else{
            product.chosen_variant = {}
        }
        delete product.product_description
        delete product.reviews
        delete product.gallery_keys
        delete product.featured_images
        product.uid = uuidv4()
        const {displayPrice} = this.effectivePriceHelper()
        this.$store.dispatch("triggerFBpixel", {
            value: displayPrice,
            event_name: "AddToCart"
        });
        this.$store.commit("ADD_TO_CART", product)
    },
    updateCartItem(product){
        if(!(product instanceof Object)){return}
        if(!Object.entries(product).length){return}
        this.$store.commit("ADD_TO_CART", ___(product))
    },
    removeFromCart(product){
        if(!(product instanceof Object)){return false}
        if(!Object.entries(product).length){return false}
        let key;
        if(product.has_variant==1){
            key = product.chosen_variant.id
        }else{
            key = product.id
        }
        this.$store.commit('REMOVE_FROM_CART', key);
    },
    cartKey(){
        let product = this.productData
        if(!(product instanceof Object)){return false}
        if(!Object.entries(product).length){return false}
        let key;
        if(product.has_variant==1){
            key = product.chosen_variant?.id ? product.chosen_variant?.id : this.chosenVariant?.id
        }else{
            key = product.id
        }
        return key;
    },
    isInCart(){
        return this.$store.state.cart?.[this.cartKey()] instanceof Object
    },
    getCartItem(){
        let product = this.productData
        if(!(product instanceof Object)){return false}
        if(!Object.entries(product).length){return false}
        let key;
        if(product.has_variant==1){
            key = product.chosen_variant?.id ? product.chosen_variant?.id : this.chosenVariant?.id
        }else{
            key = product.id
        }                      
        return this.$store.state.cart?.[key]
    },
    getItemTotal(){
        let {displayPrice} = this.effectivePriceHelper()
        return displayPrice * (isNaN(Number(this.itemQuantity)) ? 0 : Number(this.itemQuantity));
    },
    averageRating(){
        const reviewsData = Array.isArray(this.productData.reviews) ? this.productData.reviews : []
        return reviewsData.map(item => item.rating).reduce(function(acc, val) { return acc + val; }, 0)/reviewsData.length
    },
    cartQty(){
        return this.isInCart() ? Number((this.$store.state.cart?.[this.cartKey()]?.itemQuantity)) : 0;
    },
    currentStock(qty=false){
        if(qty){qty = this.cartQty()}
        let getOutput = () => {
            if(this.productData.has_variant==1){
                if(this.chosenVariant instanceof Object){
                    if(Object.entries(this.chosenVariant).length){
                        return Number(this.chosenVariant?.stock) - qty
                    }
                }
                return Number(this.productData?.variants_data?.[0]?.stock) - qty
            }else{
                return Number(this.productData?.stock_item) - qty
            }
        }
        let output = getOutput();
        return isNaN(output) ? 0 : output<=0 ? 0 : output;
    },
    isDisabled(type='cart'){
        const qty = type=='cart' ? this.cartQty() : isNaN(Number(this.itemQuantity)) ? 0 : Number(this.itemQuantity)
        const stocks = this.currentStock(qty)
        const isZeroStocks = stocks <= 0;
        return this.productData.stock_prevent_purchase==1 && isZeroStocks
    }
}

//Do not remove this
const ___ = (obj) => {try{return JSON.parse(JSON.stringify(obj))}catch(e){}return obj}