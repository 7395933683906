import Vue from 'vue'

export default {
    // /////////////////////////////////////////////
    // GMB
    // /////////////////////////////////////////////	

    UPDATE_GMB_BRANDS(state, payload) {
        if (!Array.isArray(payload)) return false;
        state.gmbBrands = payload.map(item => Object.seal(item));
    },

    UPDATE_GMB_AGENTS(state, payload) {
        if (!Array.isArray(payload)) return false;
        state.gmbAgents = payload.map(item => Object.seal(item));
    },

    UPDATE_ACTIVE_GMB_AGENT(state, payload) {
        state.ActiveAgent = payload
    },

    UPDATE_GMB_FLOWS(state, payload) {
        if (!Array.isArray(payload)) return false;
        state.GmbFlows = payload.map(item => Object.seal(item));
    },

    UPDATE_GMB_REPRESENTATIVES(state, payload) {
        if (!Array.isArray(payload)) return false;
        state.GmbRepresentatives = payload.map(item => Object.seal(item));
    },

    UPDATE_GMB_LOCATIONS(state, payload) {
        if (!Array.isArray(payload)) return false;
        state.gmbLocations = payload.map(item => Object.seal(item));
    },

    UPDATE_GMBFLOW_ENABLE_STATUS(state, { id, value }) {
        const index = state.GmbFlows.findIndex(f => f.id == id)
        if (index < 0) return
        const flow = state.GmbFlows[index]
        flow.is_enabled = value
        Vue.set(state.GmbFlows, index, flow)
    },
}