import initMMIOaxios from "@/utils/axios"
import store from "@/store"

export async function userLastActiveHandler() {
    if ( typeof window._mmioUserLastActiveInterval !== 'undefined' ) return
    if ( !_isObj( store.state.AppActiveUser ) ) { __console.log("No active user found."); return; }
    window._mmioUserLastActiveInterval = setInterval(async () => {
        if ( window['document:visibilityState:visible'] !== true ) return false;
        const axios = await initMMIOaxios()
        const resp = await axios.post("/apis_user/update_user_last_active")
        __console.log("userLastActiveHandler --> ", resp)
    }, 1000 * 30)
}

export async function visibilitychangeHandler() {
    if ( typeof window?.['document:visibilityState:visible'] !== 'undefined' ) return
    document.addEventListener("visibilitychange", function() {
        if (document.visibilityState === 'visible') {
            window['document:visibilityState:visible'] = true
            __console.log("visibilitychangeHandler --> ", document.visibilityState)
        } else if (document.visibilityState === 'hidden') {
            window['document:visibilityState:visible'] = false
            __console.log("visibilitychangeHandler --> ", document.visibilityState)
        }
    });
}
