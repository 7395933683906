export default {
	//Telegram Handlers
	GetUpdatedActiveTelegramAccount(state) {
		return state.TelegramAccounts.find(a => a.id === state.ActiveTelegramAccount.id)
	},
    TelegramFlowOptions(state){
        let output = {};
		for ( const omniFlow of Object.values(___(state.OmniChannelFlows)).reverse()) {
            try{
                if(!omniFlow.entry){continue;}
				const flowItem = {
					text: omniFlow.name,
					value: omniFlow.id,
					channel: 'omnichannel-utility',
					data: omniFlow
				};
				if(!flowItem?.value){continue;}
				output[flowItem.value] = flowItem;
            }catch(e){__console.log(e)}
		}		
        for(const Flow of state.TelegramFlows.filter( flow => flow.tele_id == state.ActiveTelegramAccount.id)){
			const flowItem = {
				text: decodeURIComponent(Flow.name),
				value: Flow.id,
				channel: 'telegram',
				data: Flow
			};
			if(!flowItem?.value){continue;}
			output[flowItem.value] = flowItem;
        }
        return Object.values(output);
    },
	openTelegramFlowEditor(state,getters) {
		return async ({postback_id,flow_id},context) => {
			try{

				//<-- Start UserPermissionCheck
				if ( !getters.checkUserActionPermission(context.$route.name,["create","edit"]) ) {
					return false;
				}
				// End UserPermissionCheck -->

				//initialize
				let ParamObj = {};
				const channel = state.ActivePage.channel;
				ParamObj.flow_name = `New ${capitalize(channel)} Flow#` + uuidv4();
				ParamObj.post_id  = 0;
				ParamObj.page_id = state.ActivePage.page_id;
				ParamObj.channel = channel;
				localStorage.removeItem(
					"TeleFlowBuilderData" + ParamObj.post_id + ParamObj.page_id
				);
				let FlowHandler = "Flow Builder";
				if(
					(typeof postback_id != 'undefined' && postback_id !== false)
					||
					(typeof flow_id != 'undefined' && flow_id !== false)
				){

					if(!postback_id && !flow_id){
						context.$vs.notify({
							title: `Please select ${capitalize(channel)} Flow`,
							color: "warning"
						})
						return false;
					}

					// Get flow data
					const flow = getters.FlowOptions.find((f) => {
						if (!!flow_id && f.value == flow_id) {							
							return f;
						} else if(!!postback_id && f.postback_id == postback_id) {
							return f;
						}
					});

					context.$vs.loading();
					let flowData;
					let database;
					if (flow.channel === 'omnichannel-utility') {
						FlowHandler = "Create Omni-channel Flows";
						flowData = await context.$store.dispatch("getOmniChannelFlowsSingle", {id: flow.value});    
						context.$vs.loading.close();
						if(!_isObj(flowData)){
							context.$vs.notify({
								title: "Selected Flow Does Not Exist",
								text: "Please create a valid flow",
								color: "warning"
							})
							return false;
						}

						//Set Query parameters
						ParamObj.post_id = flowData.id;
						ParamObj.flow_name = flowData.name;

						//set the Flow Data here
						database = "OmniFlowBuilderData-Database" + ParamObj.post_id;
						localStorage.removeItem("OmniFlowBuilderData" + ParamObj.post_id);

					} else {

						flowData = await context.$store.dispatch( "getFlowsActionSingle", { id: flow.value} );
						context.$vs.loading.close();
						if(!_isObj(flowData)){
							context.$vs.notify({
								title: "Selected Flow Does Not Exist",
								text: "Please create a valid flow",
								color: "warning"
							})
							return false;
						}

						//Set Query parameters
						ParamObj.post_id = flowData.id;
						ParamObj.page_id = flowData.fb_page_id;
						ParamObj.flow_name = flowData.name;
						ParamObj.channel = flowData.channel;

						//set the Flow Data here
						database = "TeleFlowBuilderData-Database" + flowData.id + flowData.fb_page_id;
						

					}

					const GlobalDataRaw = decodeURIComponent(flowData.flow_data);
					localStorage.setItem(database, GlobalDataRaw);

				}

				let routeData = context.$router.resolve({
					name: FlowHandler,
					query: ParamObj
				});

				//Open Flow editor on a new window
				window.open(
					routeData.href,
					"_blank",
					"toolbar=yes,scrollbars=yes,resizable=yes,top=30,left=250,width=1050,height=700"
				);				
			}catch(e){ __console.log(e) }
		}
	},
    GetTelegramFlows(state) {
		const TelegramFlows = state.TelegramFlows;
        //First, remove all null and undefined keys
		Object.keys(localStorage)
		.forEach(key => {
			if (key.includes('null') || key.includes('undefined')) {
				localStorage.removeItem(key)
			}
		})
		//Format valid local cache flows
		let cachedFlows = Object.keys(localStorage)
            .filter(key => key.includes('TeleFlowBuilderData') && !key.includes('Database') && !key.includes('null') && !key.includes('undefined'))
            .map(key => {
                return { ...JSON.parse(localStorage[key]), ...{ localkey: key } }
            })
            .map(cData => {
                if ((isNaN(Number(cData.post_id)) ? 0 : Number(cData.post_id)) < 1) {
                    let obj = {
                        tele_id: cData?.tele_id || 0,
                        last_updated: cData.last_updated,
                        name: decodeURIComponent(cData.name),
                        publish_status: "draft",
                        localkey: cData.localkey,
						created_at: cData.last_updated,
						suitcase_uid: 0,
                    }
                    if (cData.is_clone == 1) { obj.is_clone = 1; }
                    for (const key of Object.keys(cData)) {
                        if (["id", "nodes"].includes(key)) { continue; }
                        delete cData[key];
                    }
                    obj.flow_data = encodeURIComponent(JSON.stringify(cData));
                    return obj;
                } else {
                    return false
                }
            })
            .filter(item => item);
        let final = [...cachedFlows, ...___(TelegramFlows)];
        return final;
    },
}