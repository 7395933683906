// axios
import axios from "axios";
import { getSecretKey, encryptMessage } from "./sodium-plus";
import router from "@/routes/index.js";
import store from "@/store";
import { excempted_paths } from "@/utils/exemptedPaths.js";
import { v4 as uuidv4 } from "uuid";
import { debounce } from "lodash";
let axiosInstance;

if (!localStorage.getItem("browser_uid")) {
  var uuid = uuidv4();
  localStorage.setItem("browser_uid", uuid);
}

async function initMMIOaxios() {
  const key = await getSecretKey();
  await waitForSodiumPlus();
  let encrypted = await encryptMessage(
    localStorage.getItem("browser_uid") + "_" + new Date().getTime(),
    key
  );
  const isDevMode = typeof window.webpackHotUpdate === "function";
  let baseURL = isDevMode
    ? process.env.VUE_APP_DEV_SERVER_URL
    : process.env.VUE_APP_API_SERVER_URL;
  const { continent_name } = getUserGeoIP();
  if (!isDevMode && continent_name === "Asia") {
    baseURL = process.env.VUE_APP_ASIA_SERVER_URL;
  }
  axiosInstance = axios.create({
    baseURL,
    withCredentials: false,
    headers: {
      "Session-key": encrypted,
      _Cookie: document.cookie,
    },
  });
  axiosInstance.interceptors.response.use(
    theInterceptorHandler,
    globalErrorHandler
  );
  return axiosInstance;
}

const getUserGeoIP = () => {
  try {
    if (!localStorage.getItem("userGeoIpCache")) return {};
    window.userGeoIpCache = JSON.parse(localStorage.getItem("userGeoIpCache"));
    return window.userGeoIpCache;
  } catch (e) {}
  return {};
};

const generateLogoutHandler = () => {
  return window._?.debounce(async function () {
    await Swal.fire({
      title: "Your Login Session Was Invalidated.",
      text: "Please login again.",
      icon: "error",
      confirmButtonText: '<i class="las la-sign-in-alt"></i> Login Again Now',
    });
    _wait("", "");
    localStorage.removeItem("userInfo");
    let axiosInstance = await initMMIOaxios();
    const { data } = await axiosInstance.post("/apis_login/logout_action");
    if (data.status) {
      store.commit("LOGOUT");
      const loginRoute = router.resolve("/home/login");
      window.location.href = loginRoute.href;
    }
  }, 1500);
};

function theInterceptorHandler(resp) {
  if (resp.data?.status == 5) {
    const { pathname } = window.location;
    const isExcepted = excempted_paths.some((path) => path.includes(pathname));
    if (!isExcepted) {
      if (typeof window.logoutHandler === "undefined") {
        window.logoutHandler = generateLogoutHandler();
      } else if (typeof window.logoutHandler === "function") {
        window.logoutHandler();
      }
    }
  } else {
    return resp;
  }
}

function globalErrorHandler(e) {
  __console.log(e);
  return {
    data: { error: "Something went wrong with the request" },
  };
}

window.loadSodiumPlusDebounce = debounce(
  function () {
    __console.log("⚠️ Requesting sodium-plus...");
    return window.$loadScript(
      "https://mmio-store.b-cdn.net/js/sodium-plus.min.js"
    );
  },
  250,
  { leading: true, trailing: false }
);

async function waitForSodiumPlus() {
  while (
    typeof CryptographyKey === "undefined" ||
    typeof SodiumPlus === "undefined"
  ) {
    await window.loadSodiumPlusDebounce();
    await sleep(100);
  }
  return true;
}

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export default initMMIOaxios;
