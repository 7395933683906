import Vue from 'vue'
import VxTooltip from './layouts/components/vx-tooltip/VxTooltip.vue'
import VxCard  from './components/vx-card/VxCard.vue'
import VxList  from './components/vx-list/VxList.vue'
import FeatherIcon  from './components/FeatherIcon.vue'
import VxInputGroup  from './components/vx-input-group/VxInputGroup.vue'
import VxSkeleton from "./components/VxSkeleton.vue";

const lazyImage = () => import("./components/lazyImage.vue");
const PageSelector = () => import("./components/UserPagesSelector.vue");
const StoreSelector = () => import("./components/UserStoresSelector.vue");
const GmbAgentSelector = () => import("./components/GmbAgentSelector.vue");
const InstagramSelector = () => import("./components/InstagramSelector.vue");
const WhatsAppSelector = () => import("./components/UserWhatsAppSelector.vue");
const TelegramSelector = () => import("./components/UserTelegramSelector.vue");
const WebWidgetSelector = () => import("./components/UserWebWidgetSelector.vue");
const MMIOFbLogin = () => import("./components/MMIOFbLogin.vue");
const SlimSelectMain = () => import("./components/SlimSelectMain.vue");
const UnlayerEditor = () => import("./components/UnlayerEditor.vue");
const MissingComponentNotice = () => import("./components/MissingComponentNotice.vue");
const ComponentNoticeStatic = () => import("./components/ComponentNoticeStatic.vue");
const Suitcase = () => import("./components/suitcase/suitcase.vue");
const VxBreadcrumb = () => import('./layouts/components/VxBreadcrumb.vue');

//Custom Table
const vxTable = () => import("@/components/vx-table/vxTable.vue");
const vxTh = () => import("@/components/vx-table/vxTh.vue");
const vxTr = () => import("@/components/vx-table/vxTr.vue");
const vxTd = () => import("@/components/vx-table/vxTd.vue");


Vue.component(VxTooltip.name, VxTooltip)
Vue.component(VxCard.name, VxCard)
Vue.component(VxList.name, VxList)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(VxInputGroup.name, VxInputGroup)
Vue.component("VxBreadcrumb", VxBreadcrumb)
Vue.component("LazyImage", lazyImage)
Vue.component("PageSelector", PageSelector)
Vue.component("StoreSelector", StoreSelector)
Vue.component("GmbAgentSelector", GmbAgentSelector)
Vue.component("InstagramSelector", InstagramSelector)
Vue.component("WhatsAppSelector", WhatsAppSelector)
Vue.component("TelegramSelector", TelegramSelector)
Vue.component("WebWidgetSelector", WebWidgetSelector)
Vue.component("MMIOFbLogin", MMIOFbLogin)
Vue.component("SlimSelectMain", SlimSelectMain)
Vue.component("UnlayerEditor", UnlayerEditor)
Vue.component("MissingComponentNotice", MissingComponentNotice)
Vue.component("ComponentNoticeStatic", ComponentNoticeStatic)
Vue.component("Suitcase", Suitcase)
Vue.component("VxSkeleton", VxSkeleton)


//vx-table
Vue.component("vx-table", vxTable)
Vue.component("vx-th", vxTh)
Vue.component("vx-tr", vxTr)
Vue.component("vx-td", vxTd)

// v-select component
import vSelect from 'vue-select'

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('feather-icon', {
      props: {
        icon: 'XIcon',
        svgClasses: 'w-4 h-4 mt-1'
      }
    })
  },
  OpenIndicator: {
    render: createElement => createElement('feather-icon', {
      props: {
        icon: 'ChevronDownIcon',
        svgClasses: 'w-5 h-5'
      }
    })
  }
})

Vue.component(vSelect)