export default {
    computed: {
        storeData(){
            return this.$___(this.$store.state.storeData)
        },
        orderData(){
            return this.$___(this.$store.state.orderData)
        },
        theme(){
            return this.storeData?.store_design?.color_mode ? 'theme-dark' : 'default'
        }                
    },
    methods: {
        goToPage(item){
            let path;
            this.active=false
            switch(item?.path){
                case "Ecom Shop":
                    path = '/shop';
                break;
                case "Ecom Checkout":
                    path = '/checkout';
                break;
                case "Ecom Cart":
                    path = '/cart';
                break;
                case "Ecom Home":
                    path = '/';
                break;
                default:
                    path = `/page/${item.path}`;
                break;
            }
            this.$nuxt.$router.push(path)
        }
    },    
}