export default [
    // =============================================================================
    // Home / Dashboard
    // =============================================================================        
    {
        path: '/',
        name: 'Main Dashboard',
        component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/Dashboard.vue'),
        slug: "dashboard",
        category: "Home / Dashboard",
    },
    {
        path: '/dashboard/:path',
        name: 'Dashboard Navigation',
        component: () => import(/* webpackChunkName: "NavigationDashboard" */ '@/views/NavigationDashboard.vue'),
        slug: "dashboard_navigation",
        category: "Home / Dashboard",
    },
    {
        path: '/my-account/affiliate-program',
        name: 'Affiliate Program',
        component: () => import(/* webpackChunkName: "Affiliate" */ '@/views/Affiliate/Affiliate.vue'),
        slug: "affiliate_program",
        category: "Home / Dashboard",
    }    
]