import initMMIOaxios from "@/utils/axios"

const smsActions = {
	async getSMSBroadcasts({ commit }) {
		var payload = await get_sms_broadcasts()
		commit('SMS_BROADCASTS', payload)
	},
	async getSMSCountryRates({ commit, state }) {
		var data = {
			user_id: state.AppActiveUser.user_id
		}
		var payload = await get_sms_country_rates(data)
		return payload;
	},
	async getUserCredits({ commit }, that) {
        that.$vs.loading({
            container: '#div-with-loading',
            scale: 0.6
        });		
		var payload = await get_user_credits()
		that.$vs.loading.close('#div-with-loading > .con-vs-loading')
		commit('SMS_USER_CREDITS', payload)
	},
	async getSMSSequences({ commit, state }) {
		var payload = await get_sms_sequences({user_id: state.AppActiveUser.user_id})
		commit('SMS_SEQUENCES', payload)
	},
	async getSenderIDS({ commit, state }){
		const axios = await initMMIOaxios()
		const resp = await axios.get("/apis_sms/get_sender_ids")
		if(Array.isArray(resp.data)){
			commit("UPDATE_SENDER_IDS", resp.data)
		}
	}
}

export default smsActions

//*************** HELPER FUNCTIONS ***************//

async function get_sms_broadcasts() {
	const axios = await initMMIOaxios()
	const {data} = await axios.post("/apis_sms/get_sms_broadcasts")
	return data;
}

async function get_sms_country_rates(payload) {
	const axios = await initMMIOaxios()
	const {data} = await axios.post("/apis_sms/csfu_get_sms_country_rates_api", payload)
	return data;
}

async function get_user_credits(payload) {
	const axios = await initMMIOaxios()
	const {data} = axios.post("/apis_sms/get_user_credits", payload)
	return data;
}

async function get_sms_sequences(payload) {
	const axios = await initMMIOaxios()
	const {data} = axios.post("/apis_sms/get_sms_sequences",payload)
	return Array.isArray(data?.data) ? data.data : [];
}