export default {
    // ////////////////////////////////////////////
    // UI
    // ////////////////////////////////////////////

    TOGGLE_CONTENT_OVERLAY(state, val) {
        state.bodyOverlay = val
    },
    UPDATE_PRIMARY_COLOR(state, val) {
        state.themePrimaryColor = val
    },
    UPDATE_THEME(state, val) {
        state.theme = val
    },
    UPDATE_WINDOW_WIDTH(state, width) {
        state.windowWidth = width
    },
    UPDATE_WINDOW_SCROLL_Y(state, val) {
        state.scrollY = val
    },
    UPDATE_POPPER_NAV(state, val) {
        state.popperToggle = val
    },
}