export default [
    // =============================================================================
    // WhatsApp Marketing
    // ============================================================================= 
    {
        path: '/whatsapp/accounts',
        name: 'WhatsApp Accounts',
        component: () => import(/* webpackChunkName: "WhatsAppAccountsDashboard" */ '@/views/WhatsApp/Accounts.vue'),
        slug: 'whatsapp_accounts',
        category: "WhatsApp Marketing",
    },
    {
        path: '/whatsapp/flows',
        name: 'WhatsApp Flows',
        component: () => import(/* webpackChunkName: "WhatsAppFlowsDashboard" */ '@/views/WhatsApp/Flows/Dashboard.vue'),
        slug: 'whatsapp_flows',
        category: "WhatsApp Marketing",
    },
    {
        path: '/whatsapp/flows/create',
        name: 'Create WhatsApp Flows',
        component: () => import(/* webpackChunkName: "WhatsAppFlowsCreate" */ '@/views/WhatsApp/Flows/Create.vue'),
        slug: 'whatsapp_flows_create',
        category: "WhatsApp Marketing",
    },
    {
        path: '/whatsapp/templates',
        name: 'WhatsApp Templates',
        component: () => import(/* webpackChunkName: "WhatsAppTemplatesDashboard" */ '@/views/WhatsApp/Templates/Dashboard.vue'),
        slug: 'whatsapp_templates',
        category: "WhatsApp Marketing",
    },
    {
        path: '/whatsapp/wa-contacts',
        name: 'WhatsApp Contacts',
        component: () => import(/* webpackChunkName: "WhatsAppContactsDashboard" */ '@/views/WhatsApp/Contacts/Dashboard.vue'),
        slug: 'whatsapp_contacts',
        category: "WhatsApp Marketing",
    },
    {
        path: '/whatsapp/livechat',
        name: 'WhatsApp Livechat',
        component: () => import( /* webpackChunkName: "BrandsDashboard" */ '@/views/WhatsApp/Livechat/Chat.vue'),
        slug: 'whatsapp_livechat',
        category: "WhatsApp Marketing",
    },
    {
        path: '/whatsapp/broadcast',
        name: 'WhatsApp Broadcast',
        component: () => import(/* webpackChunkName: "WhatsAppBroadcastDashboard" */ '@/views/WhatsApp/Broadcast/Dashboard.vue'),
        slug: 'whatsapp_broadcast',
        category: "WhatsApp Marketing",
    },
    {
        path: '/whatsapp/write-broadcast',
        name: 'Write WhatsApp Broadcast',
        component: () => import( /* webpackChunkName: "WhatsAppWriteBroadcastDashboard" */ '@/views/WhatsApp/Broadcast/Write.vue'),
        slug: 'whatsapp_write_broadcast',
        category: "WhatsApp Marketing",
    },
    {
        path: '/whatsapp/sequences',
        name: 'WhatsApp Sequences',
        component: () => import( /* webpackChunkName: "WhatsAppSequences" */ '@/views/WhatsApp/Sequences/SequencesAll.vue'),
        slug: 'whatsapp_sequences',
        category: "WhatsApp Marketing",
    },
    {
        path: '/whatsapp/sequences/write',
        name: 'Write WhatsApp Sequences',
        component: () => import( /* webpackChunkName: "WhatsAppWriteSequences" */ '@/views/WhatsApp/Sequences/SequencesCreate.vue'),
        slug: 'whatsapp_write_sequences',
        category: "WhatsApp Marketing",
    },
    {
        path: '/whatsapp/chatbot-defaults',
        name: 'WhatsApp Chatbot Defaults',
        component: () => import( /* webpackChunkName: "WhatsChatbotDefaults" */ '@/views/WhatsApp/Defaults/ChatbotDefaults.vue'),
        slug: 'whatsapp_chatbot_defaults',
        category: "WhatsApp Marketing",
    } 
]