export default {
    //Messenger Bot Flow Builder States
    Flows: [],
    BotSheets: [],
    Templates: [],
    UserGrowthTools: [],
    ContactTags: [],
    UserPostBacks: [],
    MessengerBroadcast: [],
    CurrentContextMenu: [],
    AncestryRecordsMultiPage: {},
    ActivePage: {
        logo: "https://graph.facebook.com/125244318876371/picture?type=small",
        label: "Choose Your Page Here",
        value : "",
        page_id : "",
        access_token : "",
        logo : "",
        cover : "",
        category : "",
    },
    PageOptions: {},
    MessengerSequences: [],
    CustomVariables: [],
    OTN: [],
    PersistentMenuCache: {},
    PostbackQrMap: {},
    IsFlowReadOnly: false,
    FlowStats: {},
    RecurringCampaigns: [],
    persistentMenuItemSettingsClose: "",
    //Email Flow Builder States
    Emails: [],
    EmailReports: {},
    CustomForms: [],
    EmailFlowBuilderAPIKey: {},
    ContactList: [],
    ContactBooks: [],
    EmailBroadcasts: [],
    LandingPages: [],
    EmailTemplates: [],
    OptinTemplates: [],

    //Instagram
    ActiveInstagram: {},
    instaAutoCommentConfig: {},

}