export default {
  "Wait, are you sure?": "Wait, are you sure?",
  "Assign only to Online agents": "Assign only to Online agents",
  "Create Event Subscription": "Create Event Subscription",
  "Configure Event Subscription:": "Configure Event Subscription:",
  "Set Event Subscription Name:": "Set Event Subscription Name:",
  "Name is required": "Name is required",
  "Choose Event": "Choose Event",
  "Event is required": "Event is required",
  "Set Action": "Set Action",
  "Action is required": "Action is required",
  "Select Flow To Send": "Select Flow To Send",
  "Select Flow Message": "Select Flow Message",
  "Message is required": "Message is required",
  "Flow is required": "Flow is required",
  "Select Custom Variables": "Select Custom Variables",
  "Unset custom variables": "Unset custom variables",
  "Add custom variable": "Add custom variable",
  "User data is required" : "User data is required",
  "Select Tags": "Select Tags",
  "Set Webhook URL:": "Set Webhook URL:",
  "Webhook URL is required": "Webhook URL is required",
  "Event Type": "Event Type",
  "Date Modified": "Date Modified",
  "Edit Event Subscription": "Edit Event Subscription",
  "Delete Event Subscription": "Delete Event Subscription",
  "Unpublish": "Unpublish",
  "Publish": "Publish",
  "Events Subscriptions": "Events Subscriptions",
  "Select Action": "Select Action",
  "Confirm Changes": "Confirm Changes",
  "Getting websites from Cloudflare at page WebsitePage...": "Getting websites from Cloudflare at page {WebsitePage}...",
  "Please select a subscriber to chat with first.": "Please select a subscriber to chat with first.",
  "Loading..." : "Loading...",
  "Refresh Chat Log" : "Refresh Chat Log",
  "Refresh Subscriber List" : "Refresh Subscriber List",
  "Event Subscriptions": "Event Subscriptions",
  "Error!" : "Error!",
  "Facebook SDK not loaded!" : "Facebook SDK not loaded!",
}
