import Vue from 'vue'

export default {
    // /////////////////////////////////////////////
    // WHATSAPP
    // /////////////////////////////////////////////

    UPDATE_WHATSAPP_FLOWS(state, payload) {
        state.WhatsAppFlows = payload.map(item => Object.seal(item));
    },

    UPDATE_WHATSAPP_ACCOUNTS(state, payload) {
        state.WhatsAppAccounts = payload.map(item => Object.seal(item));
    },

    UPDATE_WHATSAPP_TEMPLATES(state, payload) {
        state.WhatsAppTemplates = payload.map(item => Object.seal(item));
    },

    UPDATE_ACTIVE_WHATSAPP(state, payload) {
        state.ActiveWhatsAppAccount = payload;
    },

    UPDATE_WHATSAPP_BROADCASTS(state, payload) {
        state.WhatsAppBroadcasts = payload.map(item => Object.seal(item));
    },

    UPDATE_WHATSAPP_SEQUENCES(state, payload) {
        state.WhatsAppSequences = payload.map(item => Object.seal(item));
    },

    UPDATE_WAFLOW_ENABLE_STATUS(state, { id, value }) {
        const index = state.WhatsAppFlows.findIndex(f => f.id == id)
        if (index < 0) return
        const flow = state.WhatsAppFlows[index]
        flow.is_enabled = value
        Vue.set(state.WhatsAppFlows, index, flow)
    },
}