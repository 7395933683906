export default [
    // =============================================================================
    // Contacts
    // =============================================================================    
    {
        path: '/contacts/contact-list',
        name: 'Contact List',
        component: () => import( /* webpackChunkName: "ContactList" */ '@/views/Contacts/ContactList.vue'),
        slug: 'contact_list',
        category: "Contacts",
    },
    {
        path: '/contacts/contact-books',
        name: 'Contact Books',
        component: () => import( /* webpackChunkName: "ContactBooks" */ '@/views/Contacts/ContactBooks.vue'),
        slug: 'contact_books',
        category: "Contacts",
    },
    {
        path: '/contacts/contact-tags',
        name: 'Contact Tags',
        component: () => import( /* webpackChunkName: "ContactTagsAll" */ '@/views/Contacts/ContactTagsAll.vue'),
        slug: 'contact_tags',
        category: "Contacts",
    },    
]