export default [
    // =============================================================================
    // Utilities
    // =============================================================================     
    {
        path: '/utilities/google-sheets',
        name: 'Google Sheets',
        component: () => import( /* webpackChunkName: "GoogleSheets" */ '@/views/Utilities/GoogleSheets.vue'),
        slug: 'google_sheets',
        category: "Utilities",
    },
    {
        path: '/utilities/custom-variables',
        name: 'Custom Variables',
        component: () => import( /* webpackChunkName: "CustomVariables" */ '@/views/Utilities/CustomVariables.vue'),
        slug: 'custom_variables',
        category: "Utilities",
    },
    {
        path: '/utilities/bot-sheets',
        name: 'Bot Sheets',
        component: () => import( /* webpackChunkName: "BotSheets" */ '@/views/Utilities/BotSheets.vue'),
        slug: 'bot_sheets',
        category: "Utilities",
    },
    {
        path: '/utilities/booking',
        name: 'Booking',
        component: () => import( /* webpackChunkName: "BotSheets" */ '@/views/Utilities/Booking/index.vue'),
        slug: 'booking',
        category: "Utilities",
    },
    {
        path: '/utilities/openai-embedding',
        name: 'OpenAI Embedding',
        component: () => import( /* webpackChunkName: "BotSheets" */ '@/views/Utilities/OpenEmbedding/index.vue'),
        slug: 'openai_embedding',
        category: "Utilities",
    },
    {
        path: '/utilities/openai-embedding/:uid',
        name: 'Embedding Training',
        component: () => import( /* webpackChunkName: "BotSheets" */ '@/views/Utilities/OpenEmbedding/Embedding.vue'),
        slug: 'openai_embedding_dataset',
        category: "Utilities",
    },
]