export const excempted_path_names = [
    "Login",
    "Forgot Password",
    "Reset Password",
    "Error 404",
    "Register",
    "Webview",
    "Page View",
    "Flow Power Sharing"
]

export const excempted_paths = [
  "/home/login",
  "/home/error-404",
  "/home/forgot-password",
  "/home/reset-password",
  "/home/register",
  "/webview",
  "/webview/",
  "/page-view",
  "/page-view/",
  "flow-power-sharing",
  "/flow-power-sharing/"
]
