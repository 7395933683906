import Vue from 'vue'

export default {
    // /////////////////////////////////////////////
    // PAGE BUILDER
    // /////////////////////////////////////////////	

    UPDATE_DOMOBJECT(state, domObject) {
        if (Object.entries(domObject).length) {
            let { entities, result } = domObject
            Vue.set(state.domObject, 'result', result);
            try {
                for (const [key, value] of Object.entries(entities.contents)) {
                    entities.contents[key] = _addBuilderEvents(value)
                }
            } catch (e) { }
            Vue.set(state.domObject.entities, 'contents', entities.contents);
            Vue.set(state.domObject.entities, 'root', entities.root);
        } else {
            state.domObject = { result: "", entities: { root: {}, contents: {} } }
        }
    },

    UPDATE_DOMOBJECT_SINGLE(state, val) {
        let isRoot = state.domObject.result == val.key
        if (isRoot) {
            Vue.set(state.domObject.entities.root, val.key, _addBuilderEvents(val));
        } else {
            if (val.type == "block") {
                let clone = { ...state.domObject }
                const { model, domObjectContents } = addBlockEntriesRecursive(state, val);
                domObjectContents[model.key] = _addBuilderEvents(model);
                clone.entities.contents = { ...clone.entities.contents, ...domObjectContents }
                state.domObject = clone
                return;
            }
            Vue.set(state.domObject.entities.contents, val.key, _addBuilderEvents(val));
        }
    },

    DELETE_DOMOBJECT_SINGLE(state, _key) {
        let clone = ___(state.domObject)
        const contents = { ...clone.entities.contents }
        clone.entities.contents = {}
        for (const [key, value] of Object.entries(contents)) {
            if (_key == key || !key || key == 'undefined') { continue }
            clone.entities.contents[key] = _addBuilderEvents(value)
        }
        __console.log(___(clone.entities.contents))
        state.domObject = clone
    },

    UPDATE_DOMOBJECT_VIEWER(state, val) {
        //__console.log("[Triggered Mutation] UPDATE_DOMOBJECT_VIEWER")
        state.domObjectViewer = val
    },

    UPDATE_DOMOBJECT_SINGLE_VIEWER(state, val) {
        //__console.log("[Triggered Mutation] UPDATE_DOMOBJECT_SINGLE_VIEWER")
        var clone = ___(state.domObjectViewer)
        clone.entities.contents[val.key] = val
        try {
            for (const [key, value] of Object.entries(clone.entities.contents)) {
                clone.entities.contents[key] = _addBuilderEvents(value)
            }
        } catch (e) { }
        state.domObjectViewer = clone
    },

    UPDATE_PAGE_BUILDER_PAGES(state, val) {
        state.PageBuilderPages = val
    },
}

function addBlockEntriesRecursive(state, model, domObjectContents = {}) {
    let entries = model?.entries || {}
    delete model.entries;
    __console.log(Object.entries(entries).length, entries)
    if (Object.entries(entries).length) {
        try {
            for (let [key, value] of Object.entries(entries)) {
                let uid = state.domObject.entities.contents[key] instanceof Object ? uuidv4() : value.key
                value.parent_key = model.key
                let result = addBlockEntriesRecursive(state, value, domObjectContents); //<-- recursively add entries to domObject state
                value = result.model
                domObjectContents = { ...domObjectContents, ...result.domObjectContents }
                //Update parent contents
                let newContents = model.contents.filter(r => r != uid)
                newContents.push(uid);
                model.contents = newContents;
                //Add to Contents
                //Vue.set(state.domObject.entities.contents, uid, _addBuilderEvents(value));
                domObjectContents[uid] = _addBuilderEvents(value)
            }
        } catch (e) { __console.log(e) }
    }
    return { model, domObjectContents };
}