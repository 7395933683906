export default {
  buildConversationTrail(state){
      return function(nodeID){
        if(editor instanceof Object){

            let conversationTrail = []
            var currentNodeID = nodeID
            do{
                var node = editor.nodes.find(n=>n.id==currentNodeID)
                var parentNodeOutput = node?.inputs.get("num")?.connections?.[0]?.output || {}
                currentNodeID = parentNodeOutput?.node?.id
                if(parentNodeOutput?.key?.includes("num")){
                    if(parentNodeOutput?.node?.data?.text){
                        conversationTrail.unshift({
                            Agent: parentNodeOutput?.node?.data?.text
                        })
                    }
                }else if(parentNodeOutput?.key?.includes("button") || parentNodeOutput?.key?.includes("quick")){
                    let type = parentNodeOutput?.key?.includes("button") ? 'Button' : 'QuickReply'    
                    conversationTrail.unshift({
                        Human: "I want to " + parentNodeOutput?.node?.data?.[type]?.[parentNodeOutput?.key]?.text
                    })
                    
                    if(parentNodeOutput?.node?.data?.text){
                        conversationTrail.unshift({
                            Agent: parentNodeOutput?.node?.data?.text
                        })
                    }                                                      
                }
            }while(currentNodeID)
            if(!Array.isArray(conversationTrail)){return ""}            
            let output = "";
            let temp = "";
            let conversationTrailTmp = [];
            for(const entry of conversationTrail.reverse()){
                var key = Object.keys(entry)[0]
                var value = entry[key]
                temp += '\n'+`${key}: ${value}`;
                var tokens = (temp?.length || "")/4
                if(tokens < 750){
                    conversationTrailTmp.push(entry)
                }else{
                    break;
                }
            }

            output = "";
            for(const entry of conversationTrailTmp.reverse()){
                var key = Object.keys(entry)[0]
                var value = entry[key]
                output += '\n'+`${key}: ${value}`;
            }
            return output;
            
        }
      }
  }
}
