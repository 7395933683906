export default {

    UPDATE_CHAT_WIDGET_ACCOUNTS(state, payload){
        state.WebWidgetAccounts = payload.map(item => Object.seal(item));
    },

    UPDATE_CHAT_WIDGET_FLOWS(state, payload){
        state.WebWidgetFlows = payload.map(item => Object.seal(item));
    },

    UPDATE_ACTIVE_WEB_WIDGET_ACCOUNT(state, account){
        state.ActiveWebWidgetAccount = account
    },

    UPDATE_WEB_WIDGET_ACCOUNT_TMP(state, account){
        state.webWidgetAccountObjTmp = account
    },
    
}