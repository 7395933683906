<template>
  <div class="Flex relative" v-on="($oChain(model,'property','on')||{})" :style="!theContents.length?'min-height:200px':''" :class="effectiveClass">

    <bgDividers
      v-if="(model.type=='block'||model.type=='row') && $oChain(model,'property','props','top','type')"
      :type="$oChain(model,'property','props','top','type')"
      position="top"
      :style="`color:${$oChain(model,'property','props','top','color')};height:${$oChain(model,'property','props','top','height')}px`"
      :invert="!!$oChain(model,'property','props','top','invert')"
    />

    <span v-if="$oChain(model,'property','props','overlayColor','hex8')" class="--overlay" :style="`background-color:${model.property.props.overlayColor.hex8};`"></span>

    <span v-if="!isRoot" class="--type-marker justify-center items-center">
      <span class="bg-royalblue-400">{{model.type=='col'?'Column':'Row'}}</span>
      <span @click="deleteModel" class="bg-danger text-md cursor-pointer duplicator" title="Duplicate"><i class="las la-trash-alt"></i></span>
      <span @click="duplicator" class="bg-warning text-md cursor-pointer duplicator" title="Delete"><i class="las la-copy duplicator"></i></span>
    </span>

    <div v-if="!isRoot&&(model.type=='col'||model.type=='row'||model.type=='block')&&!model.contents.length" @click="addResource(true)" class="add-content">+</div>

    <div v-if="!isRoot&&(model.type=='block'||model.type=='row'||model.type=='content')" class="w-full add-resource-wrap flex justify-center items-center">
      <span @click="addResource" class="add-resource text-xs">Add {{model.type=='row'||model.type=='block'?'Row':'Content'}}</span>
    </div>

    <drop-list
      :items="theContents"
      class="dl"
      :class="model.type=='row'||model.type=='block'?`w-full h-full`:model.type=='col'?!theContents.length?'w-full h-full':'':''"
      :style="{flexDirection: direction}"
      @insert="onInsert"
      @reorder="onReorder"
      mode="cut"
      :row="direction === 'row'"
      :column="direction === 'column'"
      :accepts-data="acceptedDataHandler"
    >
      <template v-slot:item="{item}">
        <drag tag="generic" :parentKey="model.key" :modelKey="item" :data="getModel(item)" :key="item" @cut="remove(item)" >
          <template v-slot:drag-image>
            <div class="drag-image">DRAG</div>
          </template>
        </drag>
      </template>
      <template v-slot:feedback="{}">
        <div class="feedback" :key="'feedback'"/>
      </template>
      <template v-slot:reordering-feedback="{item}">
        <div class="reordering-feedback" :key="key(item,'3').key+'feedback'"/>
      </template>
    </drop-list>

    <bgDividers
      v-if="(model.type=='block'||model.type=='row') && $oChain(model,'property','props','bot','type')"
      :type="$oChain(model,'property','props','bot','type')"
      position="bottom"
      :style="`color:${$oChain(model,'property','props','bot','color')};height:${$oChain(model,'property','props','bot','height')}px`"
      :invert="!!$oChain(model,'property','props','bot','invert')"
    />

  </div>
</template>

<script>

import { Drag, DropList } from "vue-easy-dnd";
import Generic from './Generic'
import {debounce} from "lodash"
import VueSwal from '@/components/VueSwal'
import rowGenerator from '@/views/Ecommerce/PageBuilder/Parts/rowGenerator'
import contentGenerator from '@/views/Ecommerce/PageBuilder/Parts/contentGenerator'
import defaultDataGenerator from '@/views/Ecommerce/PageBuilder/Parts/defaultDataGenerator'
import bgDividers from './Parts/bgDividers'

export default {
  name: "Flex",
  mixins: [defaultDataGenerator],
  components: {
    Drag,
    DropList,
    Generic,
    bgDividers
  },
  props: {
    modelKey: {
        type: String,
        default: ""
    },
    direction: String,
    parentKey: null
  },
  data(){
    return {
      modelData: null
    }
  },
  computed: {
    theContents(){
      return this.model.contents.filter(item => typeof item === 'string' )
    },
    effectiveClass(){
      var ColClass = [];
      switch(this.model.type){
        case "col":
          if(this.model.colAlign){
              switch(this.model.colAlign){
                  case "top":
                      ColClass.push("flex flex-col justify-start")
                      break;
                  case "middle":
                      ColClass.push("flex flex-col justify-center")
                      break;
                  case "bottom":
                      ColClass.push("flex flex-col justify-end")
                      break;                                                                        
              }
          }
          if(this.model.property.props.is_card){
            ColClass.push("is-card")
          }
          ColClass.push("--type-col")
          break;
        case "block":
        case "row":
          ColClass.push("--type-row flex items-stretch")
          break;
        case "content":
          ColClass.push("--type-content")
          break;                    
      }
      return ColClass.join(" ")
    },
    isRoot(){
      return this.$store.state.domObject.result == this.model.key
    },
    model(){
      return this.$clone((this.$store.getters.GetDomObjectContent(this.modelKey) || {}))
    }
  },
  methods: {
    key(item, key){
      return item
    },
    getModel(item){
      var model = this.$clone((this.$store.getters.GetDomObjectContent(item) || {}))
      model.parent_key = this.modelKey
      return model
    },
    onInsert({data,index}) {
      this.insert(data,index)
    },
    onReorder(event) {
      var clone = this.$clone(this.model)
      event.apply(clone.contents);
      this.$store.commit("UPDATE_DOMOBJECT_SINGLE", clone)
    },
    remove(item) {
      var clone = this.$clone(this.model)
      clone.contents = clone.contents.filter(i=>i!=item)
      this.$store.commit("UPDATE_DOMOBJECT_SINGLE", clone)
      this.$store.commit("DELETE_DOMOBJECT_SINGLE", item)
    },
    duplicator(){      
      _duplicateAction(this.parentKey, this.model)
    },
    acceptedDataHandler(val){
        var limit = 12;
        var that = this
        try{            
            var parent = this.getModel(val.parent_key)
            var used = 0;
            parent.contents.forEach( key => {
                var columnData = that.getModel(key)
                try{
                    used += Number(columnData.property.style.flex.match(/\d+/)[0])
                }catch(e){
                    used += 1
                }
            })
            limit -= used
        }catch(e){}
        var output = limit > 0
        if(!output){
          this.limitNotify()
        }
        return output
    },
    limitNotify: debounce(function(){
        this.$vs.notify({
          title: "Column Width Limit Reached!",
          text: "You can only have a total of 12 column width units. Please reduce your column with to add more columns.",
          color: "danger"
        })
    },1000),
    insert(data,index=false,parent=false){
        if(!parent){
          data.parent_key = this.model.key
        }else{
          data.parent_key = this.parentKey
        }
        this.$store.commit("UPDATE_DOMOBJECT_SINGLE", data )
        var clone = parent ? this.getModel(this.parentKey) : this.$clone(this.model)
        if(parent){
          index = clone.contents.findIndex(key => key == this.model.key) + 1
        }else if(index === false){
          index = clone.contents.length
        }
        clone.contents.splice(index, 0, data.key);
        this.$store.commit("UPDATE_DOMOBJECT_SINGLE", clone )
    },
    async addResource(mode=false){
      let component = contentGenerator;
      let type = "CONTENT";
      let appendToParent = false
      if(this.model.type=='row' || this.model.type=='block'){
        component = rowGenerator;
        type = "ROW";
        appendToParent = true;
        if(mode===true){
          const result = await Swal.fire({
            title: "How Many Columns?",
            input: "number",
            inputAttributes: {
              min: 1,
              max: 12,
              step: 1
            },
            inputValidator(value){
              if(value<1 || value>12){
                return "Sorry, you have entered an unsupported column quantity. Enter between 1 to 12."
              }
            }
          })
          let colData = {...this.colData}
          for(let i of Array(Number(result.value))){
            colData.key = uuidv4()
            this.insert(colData,0);
            __console.log({colData})
          }
          return false;
        }
      }

      VueSwal(component, {
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: `👍 INSERT ${type}`
        }).then( ObjValue => {
          if(Object.entries(ObjValue).length){
            __console.log({ObjValue})
            this.insert(ObjValue,false,appendToParent)
          }
      })
    },
    async deleteModel(){
        _deleteModel(this.model)
    }

  }
}

</script>

<style scoped>
  .dl {
    display: flex;
    align-items: stretch;
    z-index: 2;
  }

  .--type-marker, .add-content, .add-resource-wrap {
    z-index: 3;
  }

  .drag-image {
    background-color: orangered;
    width: 75px;
    height: 75px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .reordering-feedback,
  .feedback {
    flex: 0 0 0;
    outline: 1px solid blue;
    align-self: stretch;
  }

  .drag-source {
    outline: 2px dashed black;
  }
  .--overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 1;    
  } 
</style>