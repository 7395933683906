import initMMIOaxios from "@/utils/axios"

const whatsAppActions = {

    async getWhatsAppAccounts({ commit, dispatch }) {
        const axios = await initMMIOaxios()
        const { data } = await axios.get("/apis_whatsapp/get_accounts")
        commit("UPDATE_WHATSAPP_ACCOUNTS", data);
        dispatch("executeUpdateActualUsageLog", { moduleName: "WHATSAPP_ACCOUNTS", data });
        return data;
    },

    async getWhatsAppFlows({ commit, dispatch }) {
        const axios = await initMMIOaxios()
        const { data } = await axios.get("/apis_whatsapp/get_flows?minimal=1");
        commit("UPDATE_WHATSAPP_FLOWS", data);
        dispatch("executeUpdateActualUsageLog", { moduleName: "NUMBER_OF_WHATSAPP_FLOWS_USER_CAN_CREATE", data });
        return data;
    },

    async getWhatsAppFlowsSingle(dummy, { id, postbacks_only }) {
        const axios = await initMMIOaxios();
        const { data } = await axios.get(`/apis_whatsapp/get_flows/${id}?postbacks_only=` + ((postbacks_only == true) ? 1 : 0));
        return data;
    },

    async getWhatsAppTemplates({ commit, state }, wa_id = false) {
        wa_id = !!wa_id ? wa_id : state.ActiveWhatsAppAccount.id;
        if (!wa_id) {return [];}
        const axios = await initMMIOaxios()
        const { data } = await axios.get("/apis_whatsapp/get_templates/" + wa_id)
        commit("UPDATE_WHATSAPP_TEMPLATES", data);
        return data;
    },

    async getWhatsAppBroadcasts({ commit }) {
        const axios = await initMMIOaxios()
        const { data } = await axios.get("/apis_whatsapp/get_broadcasts");
        commit("UPDATE_WHATSAPP_BROADCASTS", data);
    },

    async getWhatsAppSequences({ commit }) {
        const axios = await initMMIOaxios()
        const { data } = await axios.get("/apis_whatsapp/get_sequences");
        commit("UPDATE_WHATSAPP_SEQUENCES", data);
    },

}
export default whatsAppActions;