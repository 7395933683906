export default [
    // =============================================================================
    // Messenger Marketing
    // ============================================================================= 
    {
        path: '/social-media-automations',
        name: 'Flow Builder Dashboard',
        component: () => import(/* webpackChunkName: "FlowBuilderDashboard" */ '@/views/MessengerIG/FlowBuilderDashboard.vue'),
        slug: 'flow_builder_dashboard',
        category: "Messenger Marketing",
    },
    {
        path: '/social-media-automations/facebook-accounts',
        name: 'Facebook Accounts',
        component: () => import(/* webpackChunkName: "FacebookAccounts" */ '@/views/ImportSocialMedia/FacebookAccounts.vue'),
        slug: "account_import",
        category: "Messenger Marketing",
    },
    {
        path: '/social-media-automations/build',
        name: 'Flow Builder',
        component: () => import(/* webpackChunkName: "FlowBuilderV2" */ '@/views/MessengerIG/FlowBuilderVersions/FlowBuilder.vue'),
        parent: { name: "Flow Builder Dashboard", url: "/social-media-automations" },
        slug: 'flow_builder',
        category: "Messenger Marketing",
    },
    {
        path: '/social-media-automations/subscribers',
        name: 'Messenger Subscribers',
        component: () => import(/* webpackChunkName: "SubscribersDashboard" */ '@/views/MessengerIG/Subscribers/SubscribersDashboard.vue'),
        slug: 'messenger_subscribers',
        category: "Messenger Marketing",
    },
    {
        path: '/social-media-automations/growth-tools',
        name: 'Growth Tools',
        component: () => import(/* webpackChunkName: "GrowthToolsDashboard" */ '@/views/MessengerIG/GrowthTools/GrowthToolsDashboard.vue'),
        slug: 'growth_tools',
        category: "Messenger Marketing",
    },
    {
        path: '/social-media-automations/growth-tools/growth-tool-editor',
        name: 'Growth Tool Editor',
        component: () => import(/* webpackChunkName: "GrowthToolEditory" */ '@/views/MessengerIG/GrowthTools/GrowthToolEditor.vue'),
        slug: 'growth_tools',
        category: "Messenger Marketing",
    },
    {
        path: '/social-media-automations/growth-tools/checkbox',
        name: 'Checkbox Plugin',
        component: () => import( /* webpackChunkName: "CheckBox" */ '@/views/MessengerIG/GrowthTools/CheckBox.vue'),
        slug: 'growth_tools',
        category: "Messenger Marketing",
    },
    {
        path: '/social-media-automations/broadcast',
        name: 'Messenger Broadcast',
        component: () => import( /* webpackChunkName: "MessengerBroadcastDashboard" */ '@/views/MessengerIG/Broadcast/MessengerBroadcastDashboard.vue'),
        slug: 'messenger_broadcast',
        category: "Messenger Marketing",
    },
    {
        path: '/social-media-automations/broadcast/editor',
        name: 'Messenger Broadcast Editor',
        component: () => import( /* webpackChunkName: "BroadcastEditor" */ '@/views/MessengerIG/Broadcast/BroadcastEditor.vue'),
        slug: 'messenger_broadcast',
        category: "Messenger Marketing",
    },
    {
        path: '/social-media-automations/bot-tools/persistent-menu',
        name: 'Persistent Menu',
        component: () => import( /* webpackChunkName: "PersistentMenu" */ '@/views/MessengerIG/BotTools/PersistentMenu/PersistentMenu.vue'),
        slug: 'persistent_menu',
        category: "Messenger Marketing",
    },
    {
        path: '/social-media-automations/webview',
        name: 'Messenger Webview',
        component: () => import( /* webpackChunkName: "WebviewAll" */ '@/views/MessengerIG/Webview/WebviewAll.vue'),
        slug: 'messenger_webview',
        category: "Messenger Marketing",
    },
    {
        path: '/social-media-automations/webview/create',
        name: 'Create Messenger Webview',
        component: () => import( /* webpackChunkName: "WebviewCreate" */ '@/views/MessengerIG/Webview/WebviewCreate.vue'),
        slug: 'messenger_webview',
        category: "Messenger Marketing",
    },
    {
        path: '/social-media-automations/live-chat',
        name: 'Live Chat',
        component: () => import( /* webpackChunkName: "Chat" */ '@/views/MessengerIG/Chat/Chat.vue'),
        slug: 'live_chat',
        category: "Messenger Marketing",
    },
    {
        path: '/social-media-automations/welcome-message',
        name: 'Welcome Message',
        component: () => import( /* webpackChunkName: "WelcomeMessage" */ '@/views/MessengerIG/BotTools/WelcomeMessage/WelcomeMessage.vue'),
        slug: 'welcome_message',
        category: "Messenger Marketing",
    },
    {
        path: '/social-media-automations/ice-breakers',
        name: 'Ice Breakers',
        component: () => import( /* webpackChunkName: "IceBreakers" */ '@/views/MessengerIG/BotTools/IceBreakers/IceBreakers.vue'),
        slug: 'ice_breakers',
        category: "Messenger Marketing",
    },
    {
        path: '/social-media-automations/get-started',
        name: 'Get Started',
        component: () => import( /* webpackChunkName: "GetStarted" */ '@/views/MessengerIG/BotTools/GetStarted/GetStarted.vue'),
        slug: 'get_started',
        category: "Messenger Marketing",
    },
    {
        path: '/social-media-automations/keywords',
        name: 'Keywords',
        component: () => import( /* webpackChunkName: "Keywords" */ '@/views/MessengerIG/BotTools/Keywords/Keywords.vue'),
        slug: 'keywords',
        category: "Messenger Marketing",
    },
    {
        path: '/social-media-automations/sequences',
        name: 'Messenger Sequences',
        component: () => import( /* webpackChunkName: "SequencesAll" */ '@/views/MessengerIG/Sequences/SequencesAll.vue'),
        slug: 'messenger_sequence',
        category: "Messenger Marketing",
    },
    {
        path: '/social-media-automations/sequences/create',
        name: 'Create Messenger/IG Sequence',
        component: () => import( /* webpackChunkName: "SequencesCreate" */ '@/views/MessengerIG/Sequences/SequencesCreate.vue'),
        slug: 'messenger_sequence',
        category: "Messenger Marketing",
    },
    {
        path: '/social-media-automations/defaults',
        name: 'Chatbot Defaults',
        component: () => import( /* webpackChunkName: "ChatbotDefaults" */ '@/views/MessengerIG/Defaults/ChatbotDefaults.vue'),
        slug: 'chatbot_defaults',
        category: "Messenger Marketing",
    },
    {
        path: '/social-media-automations/recurring-notifications',
        name: 'Marketing Messages',
        component: () => import( /* webpackChunkName: "RecurringNotifications" */ '@/views/MessengerIG/RecurringNotifications/Dashboard.vue'),
        slug: 'recurring_notifications',
        category: "Messenger Marketing",
    },    
]