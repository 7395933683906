import { v4 as uuidv4 } from 'uuid'
const RootKey = uuidv4()
import {i18n} from "@/i18n";

export default {
    data(){
        return {
            modelRaw: {
                key: RootKey,
                property: {
                  style: {},
                  class: []
                },
                type: "col",
                contents: [defaultDataGenerator.rowData(defaultDataGenerator.colData())]
            },
            //Grid
            colData : defaultDataGenerator.colData(),
            rowData : defaultDataGenerator.rowData(),
            block : defaultDataGenerator.block(),
            block1 : defaultDataGenerator.block1(),
            block2 : defaultDataGenerator.block2(),
            block3 : defaultDataGenerator.block3(),
            block4 : defaultDataGenerator.block4(),
            block5 : defaultDataGenerator.block5(),
            block6 : defaultDataGenerator.block6(),

            //Contents
            textData : defaultDataGenerator.textData(),
            imageData : defaultDataGenerator.imageData(),
            buttonData : defaultDataGenerator.buttonData(),
            videoData : defaultDataGenerator.videoData(),
            timerData : defaultDataGenerator.timerData(),
            productsData : defaultDataGenerator.productsData(),
            productsCarousel : defaultDataGenerator.productsCarousel(),
            formData : defaultDataGenerator.formData(),

            //Pre-made blocks
            premadeHero: defaultDataGenerator.premadeHero(),
            premadeHero2: defaultDataGenerator.premadeHero2(),
            threeColFeature: defaultDataGenerator.threeColFeature(),
            cardHighlight: defaultDataGenerator.cardHighlight(),
            productsHighlightRight: defaultDataGenerator.productsHighlightRight(),
            reviewCards: defaultDataGenerator.reviewCards(),
            guarantees: defaultDataGenerator.guarantees(),

            defaultDataGenerator: defaultDataGenerator
        }
    },
    methods: {
        drag(val){
            switch(val.data.type){
              case "col":
                this.colData = defaultDataGenerator.colData()
                break;
              case "block":

                this.block = defaultDataGenerator.block()
                this.block1 = defaultDataGenerator.block1()
                this.block2 = defaultDataGenerator.block2()
                this.block3 = defaultDataGenerator.block3()
                this.block4 = defaultDataGenerator.block4()
                this.block5 = defaultDataGenerator.block5()
                this.block6 = defaultDataGenerator.block6()
                this.premadeHero = defaultDataGenerator.premadeHero()
                this.premadeHero2 = defaultDataGenerator.premadeHero2()
                this.threeColFeature = defaultDataGenerator.threeColFeature()
                this.cardHighlight = defaultDataGenerator.cardHighlight()
                this.productsHighlightRight = defaultDataGenerator.productsHighlightRight()
                this.reviewCards = defaultDataGenerator.reviewCards()
                this.guarantees = defaultDataGenerator.guarantees()

                break;
              case "row":
                this.rowData = defaultDataGenerator.rowData()
                break;
              default:
                switch(true){
                  case ["p","span","h1","h2","h3","h4","h5"].includes(val.data.tag):
                    this.textData = defaultDataGenerator.textData()
                  break;
                  case ["img"].includes(val.data.tag):
                    this.imageData = defaultDataGenerator.imageData()
                  break;
                  case ["button"].includes(val.data.tag):
                    this.buttonData = defaultDataGenerator.buttonData()
                  break;
                  case ["button"].includes(val.data.tag):
                    this.buttonData = defaultDataGenerator.buttonData()
                  break;
                  case ["timer"].includes(val.data.tag):
                    this.timerData = defaultDataGenerator.timerData()
                  break;
                  case ["video"].includes(val.data.tag):
                    this.videoData = defaultDataGenerator.videoData()
                  break;
                  case ["products"].includes(val.data.tag):
                    this.productsData = defaultDataGenerator.productsData()
                  break;
                  case ["products-carousel"].includes(val.data.tag):
                    this.productsCarousel = defaultDataGenerator.productsCarousel()
                  break;
                }
                break;
            }
          },
    },
}

const defaultDataGenerator  = {

  colData(){
    return {
        contents: [],
        colAlign: "top",
        property: {
            style: {
                backgroundImage: "",
                backgroundPosition: "35% 23%",
                backgroundSize: "cover",
                height: "",
                marginTop: "5px",
                marginLeft: "5px",
                marginBottom: "5px",
                marginRight: "5px",
                paddingTop: "5px",
                paddingLeft: "5px",
                paddingBottom: "5px",
                paddingRight: "5px",
            },
            attrs: {},
            class: [],
        },
        key: uuidv4(),
        type: "col"
    }
  },

  rowData(colData=false){
    let rowData = {
        contents: [],
        property: {
            style: {
                backgroundImage: "",
                backgroundPosition: "35% 23%",
                backgroundSize: "cover",
                marginTop: "0px",
                marginLeft: "0px",
                marginBottom: "0px",
                marginRight: "0px",
                paddingTop: "0px",
                paddingLeft: "0px",
                paddingBottom: "0px",
                paddingRight: "0px",
            },
            attrs: {},
            class: [],
        },
        childWidth: {
          sm: 1,
          md: 1,
          lg: 1,
        },
        key: uuidv4(),
        type: "row"
    }
    if(colData instanceof Object){
        colData.parent_key = rowData.key
        rowData.contents.push(colData)
        rowData.parent_key = RootKey
    }
    return rowData;
  },

  block(){
    let row = this.rowData();
    row.entries = {}
    for(let col of [1]){
      let colObj = this.colData();
      row.contents.push(colObj.key)
      row.entries[colObj.key] = colObj
    }
    row.type = "block"
    _addBuilderEvents(row)
    return row;
  },

  block1(){
    let row = this.rowData();
    row.entries = {}
    for(let col of [1,2]){
      let colObj = this.colData();
      colObj.parent_key = row.key
      row.contents.push(colObj.key)
      row.entries[colObj.key] = colObj
    }
    row.type = "block"
    _addBuilderEvents(row)
    return row;
  },

  block2(){
    let row = this.rowData();
    row.entries = {}
    for(let col of [2,1]){
      let colObj = this.colData();
      colObj.parent_key = row.key
      colObj.property.style.flex = col
      row.contents.push(colObj.key)
      row.entries[colObj.key] = colObj
    }
    row.type = "block"
    row.childWidth = {
      sm: 1,
      md: 2,
      lg: 2,
    }
    _addBuilderEvents(row)
    return row;
  },

  block3(){
    let row = this.rowData();
    row.entries = {}
    for(let col of [1,2]){
      let colObj = this.colData();
      colObj.parent_key = row.key
      colObj.property.style.flex = col
      row.contents.push(colObj.key)
      row.entries[colObj.key] = colObj
    }
    row.type = "block"
    row.childWidth = {
      sm: 1,
      md: 2,
      lg: 2,
    }
    _addBuilderEvents(row)
    return row;
  },

  block4(){
    let row = this.rowData();
    row.entries = {}
    for(let col of [1,1,1]){
      let colObj = this.colData();
      colObj.parent_key = row.key
      colObj.property.style.flex = col
      row.contents.push(colObj.key)
      row.entries[colObj.key] = colObj
    }
    row.type = "block"
    row.childWidth = {
      sm: 1,
      md: 3,
      lg: 3,
    }
    _addBuilderEvents(row)
    return row;
  },

  block5(){
    let row = this.rowData();
    row.entries = {}
    for(let col of [1,2,1]){
      let colObj = this.colData();
      colObj.parent_key = row.key
      colObj.property.style.flex = col
      row.contents.push(colObj.key)
      row.entries[colObj.key] = colObj
    }
    row.type = "block"
    row.childWidth = {
      sm: 1,
      md: 3,
      lg: 3,
    }
    _addBuilderEvents(row)
    return row;
  },

  block6(){
    let row = this.rowData();
    row.entries = {}
    for(let col of [1,1,1,1]){
      let colObj = this.colData();
      colObj.parent_key = row.key
      colObj.property.style.flex = col
      row.contents.push(colObj.key)
      row.entries[colObj.key] = colObj
    }
    row.type = "block"
    row.childWidth = {
      sm: 1,
      md: 2,
      lg: 4,
    }
    _addBuilderEvents(row)
    return row;
  },

  textData(){
    return {
      tag: "p",
      property: {
          class: [],
          attrs: {},
          domProps: {
              innerHTML: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
          },
          style: {
              marginTop: "10px",
              marginLeft: "10px",
              marginBottom: "10px",
              marginRight: "10px",
              paddingTop: "10px",
              paddingLeft: "10px",
              paddingBottom: "10px",
              paddingRight: "10px",
          }
      },
      key: uuidv4(),
      type: "content",
    }
  },

  imageData(){
    return {
      tag: "img",
      property: {
          class: [],
          attrs: {
            src: "http://via.placeholder.com/300"
          },
          domProps: {},
          style: {
              marginTop: "10px",
              marginLeft: "10px",
              marginBottom: "10px",
              marginRight: "10px",
              paddingTop: "10px",
              paddingLeft: "10px",
              paddingBottom: "10px",
              paddingRight: "10px",
          }
      },
      key: uuidv4(),
      type: "content",
    }
  },

  buttonData(){
    return {
      tag: "button",
      property: {
          class: [],
          attrs: {},
          domProps: {
              innerHTML: i18n.t("Click Me!"),
          },
          style: {
              marginTop: "10px",
              marginLeft: "10px",
              marginBottom: "10px",
              marginRight: "10px",
              paddingTop: "10px",
              paddingLeft: "10px",
              paddingBottom: "10px",
              paddingRight: "10px",
          }
      },
      key: uuidv4(),
      type: "content",
    }
  },

  timerData(){
    return {
      tag: "timer",
      property: {
          class: [],
          attrs: {},
          domProps: {},
          style: {
              marginTop: "10px",
              marginLeft: "10px",
              marginBottom: "10px",
              marginRight: "10px",
              paddingTop: "10px",
              paddingLeft: "10px",
              paddingBottom: "10px",
              paddingRight: "10px",
          },
          props: {
              deadline: 15,
              delay: 5,
              showHours: true,
              showMinutes: true,
              showSeconds: true,
              showDays: false,
              displayLabels: true,
              colors: {
                  topColor : '#cca900',
                  botColor : '#ffdc00',
                  backColor : '#222',
                  backBotColor : '#393939',
                  labelSlotColor : '#fff'
              }
          }
      },
      key: uuidv4(),
      type: "content",
    }
  },

  videoData(){
    return {
      tag: "video",
      property: {
          class: [],
          attrs: {},
          domProps: {
            innerHTML: `<div style="--aspect-ratio: 16/9;"> <iframe src="https://www.youtube.com/embed/hUs-YL_ddt8" width="1600" height="900" frameborder="0" > </iframe></div>`
          },
          style: {
              marginTop: "10px",
              marginLeft: "10px",
              marginBottom: "10px",
              marginRight: "10px",
              paddingTop: "10px",
              paddingLeft: "10px",
              paddingBottom: "10px",
              paddingRight: "10px",
          }
      },
      key: uuidv4(),
      type: "content",
    }
  },

  productsData(){
    return {
      tag: "products",
      property: {
          class: [],
          attrs: {},
          domProps: {},
          style: {
              marginTop: "10px",
              marginLeft: "10px",
              marginBottom: "10px",
              marginRight: "10px",
              paddingTop: "10px",
              paddingLeft: "10px",
              paddingBottom: "10px",
              paddingRight: "10px",
          },
          props: {
            items: [
                {
                    uid: uuidv4(),
                    product_id: '',
                    override: {
                        method: 'percentage_of_original',
                        value: 10
                    },
                    quantity: 1
                }
            ],
            mode: 'horizontal',
            showDescription: false,
            textConfigs: {
                accept: `<h3><span style="color:hsl(0,0%,100%);">${i18n.t('Yes! Add this to my order')}</span></h3><p><span style="color:hsl(0,0%,100%);">${i18n.t('We will ship this our in the same package')}</span></p>`,
                reject: `<p>${i18n.t("❌ No thanks, I don't want to take advantage of this one-time offer")}</p>`
            }
          }
      },
      key: uuidv4(),
      type: "content",
    }
  },

  productsCarousel(){
    return {
      tag: "products-carousel",
      property: {
          class: [],
          attrs: {},
          domProps: {},
          style: {
              marginTop: "10px",
              marginLeft: "10px",
              marginBottom: "10px",
              marginRight: "10px",
              paddingTop: "10px",
              paddingLeft: "10px",
              paddingBottom: "10px",
              paddingRight: "10px",
              maxWidth: "1050px",
          },
          props: {
            align: "center",
            data: {
              type: "category",
              value: ""
            }
          }
      },
      key: uuidv4(),
      type: "content",
    }
  },

  formData(){
    return {
      tag: "form",
      property: {
          class: [],
          attrs: {},
          domProps: {},
          style: {
              marginTop: "10px",
              marginLeft: "10px",
              marginBottom: "10px",
              marginRight: "10px",
              paddingTop: "10px",
              paddingLeft: "10px",
              paddingBottom: "10px",
              paddingRight: "10px",
          },
          props: {
            customFields: [],
            button: {
                color: {hex8:"success"},
                cta: i18n.t("SUBMIT"),
                size: "default",
                type: "filled",
                class: "",
                secondaryColor: {hex8:"success"},
                position: "start",
                fullWidth: false,
            },
            action: {
                redirect: false,
                redirect_mode: false,
                isUrl: false,
                success: i18n.t("You have successfully submitted your details."),
                failed: i18n.t("Something went wrong. Please try again."),
                action_url: "",
                redirect_url: "",
            },
            formDesign: {
                bgColor: {
                    hex8: "transparent"
                },
                fontColor: {
                    hex8: "#0C0C0C"
                }
            }
        }
      },
      key: uuidv4(),
      type: "content",
    }
  },

// Pre-made Blocks

  premadeHero(){
    return {
      "contents": [],
      "property": {
          "style": {
              "paddingTop": "0px",
              "paddingRight": "0px",
              "paddingBottom": "0px",
              "paddingLeft": "0px",
              "marginTop": "0px",
              "marginRight": "0px",
              "marginBottom": "0px",
              "marginLeft": "0px",
              "backgroundImage": "url(https://server.marketingmaster.io/upload/apis/1/180471_1633590808.jpg)",
              "backgroundPosition": "35% 23%",
              "backgroundSize": "cover",
              "minHeight": "100vh!important"
          },
          "attrs": [],
          "on": {},
          "nativeOn": [],
          "directives": [],
          "scopedSlots": [],
          "props": [],
          "domProps": []
      },
      "childWidth": {
          "sm": 1,
          "md": 1,
          "lg": 1
      },
      "entries": [
        {
          "contents": [],
          "entries": [
              {
                "tag": "p",
                "property": {
                    "class": [],
                    "attrs": [],
                    "domProps": {
                        "innerHTML": `<p style="text-align:center;"><span style="color:hsl(0,0%,100%);">${i18n.t("DON'T MISS TODAY'S FEATURED DEALS")}</span></p><h2 style="text-align:center;"><span style="color:hsl(0,0%,100%);font-size:50px;"><strong>${i18n.t("Start A Day With Coffee")}</strong></span></h2><p style="text-align:center;"><span style="color:hsl(0,0%,100%);">${i18n.t("Here to bring your life to the next level")}</span></p>`
                    },
                    "style": {
                        "paddingTop": "10px",
                        "paddingRight": "10px",
                        "paddingBottom": "10px",
                        "paddingLeft": "10px",
                        "marginTop": "10px",
                        "marginRight": "10px",
                        "marginBottom": "10px",
                        "marginLeft": "10px"
                    },
                    "on": {},
                    "nativeOn": [],
                    "directives": [],
                    "scopedSlots": [],
                    "props": []
                },
                "key": uuidv4(),
                "type": "content",
                "contents": []
              },
              {
                "tag": "button",
                "property": {
                    "class": ["text-shadow-lg"],
                    "attrs": [],
                    "domProps": {
                        "innerHTML": `<h2><span style="color:hsl(0, 0%, 100%);">${i18n.t('SHOP NOW!')}</span></h2>`
                    },
                    "style": {
                        "paddingTop": "10px",
                        "paddingRight": "10px",
                        "paddingBottom": "10px",
                        "paddingLeft": "10px",
                        "marginTop": "10px",
                        "marginRight": "10px",
                        "marginBottom": "10px",
                        "marginLeft": "10px"
                    },
                    "on": {},
                    "nativeOn": [],
                    "directives": [],
                    "scopedSlots": [],
                    "props": {
                        "align": "center",
                        "size": "large",
                        "color": "white",
                        "type": "border",
                        "action": {
                            "mode": false,
                            "to": {
                                "id": "shop",
                                "name": "Shop",
                                "path": "Ecom Shop"
                            },
                            "isUrl": false,
                            "url": ""
                        }
                    }
                },
                "key": uuidv4(),
                "type": "content",
                "contents": []
              }
          ],
          "colAlign": "middle",
          "property": {
              "style": {
                  "paddingTop": "5px",
                  "paddingRight": "5px",
                  "paddingBottom": "5px",
                  "paddingLeft": "5px",
                  "marginTop": "5px",
                  "marginRight": "5px",
                  "marginBottom": "5px",
                  "marginLeft": "5px",
                  "backgroundImage": "",
                  "backgroundPosition": "35% 23%",
                  "backgroundSize": "cover",
                  "height": ""
              },
              "attrs": [],
              "class": [],
              "on": {},
              "nativeOn": [],
              "directives": [],
              "scopedSlots": [],
              "props": {
                  "direction": "column"
              },
              "domProps": []
          },
          "key": uuidv4(),
          "type": "col",
          "tag": ""
        }
      ],
      "key": uuidv4(),
      "type": "block",
      "tag": "",
      "label": "Hero Banner"
    }
  },

  premadeHero2(){
    return {
      "contents": [],
      "property": {
          "style": {
              "paddingTop": "0px",
              "paddingRight": "0px",
              "paddingBottom": "0px",
              "paddingLeft": "0px",
              "marginTop": "0px",
              "marginRight": "0px",
              "marginBottom": "0px",
              "marginLeft": "0px",
              "backgroundImage": "url(https://server.marketingmaster.io/upload/apis/1/734179_1633612426.jpg)",
              "backgroundPosition": "35% 23%",
              "backgroundSize": "cover",
              "minHeight": "100vh!important"
          },
          "attrs": [],
          "class": [],
          "on": {},
          "nativeOn": [],
          "directives": [],
          "scopedSlots": [],
          "props": [],
          "domProps": []
      },
      entries: [
        {
          "contents": [],
          "colAlign": "middle",
          "property": {
              "style": {
                  "paddingTop": "5px",
                  "paddingRight": "5px",
                  "paddingBottom": "5px",
                  "paddingLeft": "5px",
                  "marginTop": "5px",
                  "marginRight": "5px",
                  "marginBottom": "5px",
                  "marginLeft": "5px",
                  "backgroundImage": "",
                  "backgroundPosition": "35% 23%",
                  "backgroundSize": "cover",
                  "height": "",
                  "flex": "6"
              },
              "attrs": [],
              "class": [],
              "on": {},
              "nativeOn": [],
              "directives": [],
              "scopedSlots": [],
              "props": {
                  "direction": "column"
              },
              "domProps": []
          },
          entries: [
            {
              "contents": [],
              "property": {
                  "style": {
                      "paddingTop": "0px",
                      "paddingRight": "0px",
                      "paddingBottom": "0px",
                      "paddingLeft": "0px",
                      "marginTop": "0px",
                      "marginRight": "0px",
                      "marginBottom": "0px",
                      "marginLeft": "0px",
                      "backgroundImage": "",
                      "backgroundPosition": "35% 23%",
                      "backgroundSize": "cover",
                      "maxWidth": "1050px"
                  },
                  "attrs": [],
                  "class": [],
                  "on": {},
                  "nativeOn": [],
                  "directives": [],
                  "scopedSlots": [],
                  "props": [],
                  "domProps": []
              },
              entries: [
                {
                  "contents": [],
                  "colAlign": "middle",
                  "property": {
                      "style": {
                          "paddingTop": "5px",
                          "paddingRight": "5px",
                          "paddingBottom": "5px",
                          "paddingLeft": "5px",
                          "marginTop": "5px",
                          "marginRight": "5px",
                          "marginBottom": "5px",
                          "marginLeft": "5px",
                          "backgroundImage": "",
                          "backgroundPosition": "35% 23%",
                          "backgroundSize": "cover",
                          "height": ""
                      },
                      "attrs": [],
                      "class": [
                          "mmio-7c37c393-f11f-4155-a4fa-83f04fb4c50f",
                          "mmio-page-builder-entity"
                      ],
                      "on": {},
                      "nativeOn": [],
                      "directives": [],
                      "scopedSlots": [],
                      "props": [],
                      "domProps": []
                  },
                  entries: [
                    {
                      "tag": "p",
                      "property": {
                          "class": [],
                          "attrs": {},
                          "domProps": {
                              "innerHTML": i18n.t("Do you want real meat HTML template...")
                          },
                          "style": {
                              "paddingTop": "0px",
                              "paddingRight": "0px",
                              "paddingBottom": "0px",
                              "paddingLeft": "0px",
                              "marginTop": "0px",
                              "marginRight": "0px",
                              "marginBottom": "0px",
                              "marginLeft": "0px",
                              "color": "#FFFFFFFF",
                              "fontSize": "40px",
                              "fontFamily": "Raleway"
                          },
                          "on": {},
                          "nativeOn": {},
                          "directives": [],
                          "scopedSlots": {},
                          "props": {}
                      },
                      "key": uuidv4(),
                      "type": "content",
                      "contents": []
                    },
                    {
                      "tag": "p",
                      "property": {
                          "class": [],
                          "attrs": {},
                          "domProps": {
                              "innerHTML": `<p>${i18n.t('Only Today 30% Discount. Supplied by nature, grown by nature lovers.')}</p>`
                          },
                          "style": {
                              "paddingTop": "0px",
                              "paddingRight": "0px",
                              "paddingBottom": "0px",
                              "paddingLeft": "0px",
                              "marginTop": "0px",
                              "marginRight": "0px",
                              "marginBottom": "0px",
                              "marginLeft": "0px",
                              "color": "#FFFFFFFF",
                              "fontSize": {}
                          },
                          "on": {},
                          "nativeOn": {},
                          "directives": [],
                          "scopedSlots": {},
                          "props": {}
                      },
                      "key": uuidv4(),
                      "type": "content",
                      "contents": []
                    },
                    {
                      "tag": "button",
                      "property": {
                          "class": ["text-shadow-sm"],
                          "attrs": [],
                          "domProps": {
                              "innerHTML": `<p><strong>${i18n.t('SHOP NOW!')}</strong></p>`
                          },
                          "style": {
                              "paddingTop": "10px",
                              "paddingRight": "10px",
                              "paddingBottom": "10px",
                              "paddingLeft": "10px",
                              "marginTop": "10px",
                              "marginRight": "10px",
                              "marginBottom": "10px",
                              "marginLeft": "10px",
                              "color": "#FFFFFFFF",
                              "fontSize": "30px"
                          },
                          "on": {},
                          "nativeOn": [],
                          "directives": [],
                          "scopedSlots": [],
                          "props": {
                              "size": "large",
                              "color": "warning",
                              "type": "filled",
                              "action": {
                                  "mode": false,
                                  "to": {
                                      "id": "shop",
                                      "name": "Shop",
                                      "path": "Ecom Shop"
                                  },
                                  "isUrl": false,
                                  "url": ""
                              }
                          }
                      },
                      "key": uuidv4(),
                      "type": "content",
                      "contents": []
                    }
                  ],
                  "key": uuidv4(),
                  "type": "col",
                  "tag": ""
                }
              ],
              "childWidth": {
                  "sm": 1,
                  "md": 1,
                  "lg": 1
              },
              "key": uuidv4(),
              "type": "block"
            }
          ],
          "key": uuidv4(),
          "type": "col",
          "tag": ""
        }
      ],
      "childWidth": {
          "sm": 1,
          "md": 1,
          "lg": 1
      },
      "key": uuidv4(),
      "type": "block",
      "tag": "",
      "label": "Hero Banner 2"
    }
  },

  reviewCards(){
    return {
      "contents": [],
      "property": {
          "style": {
              "paddingTop": "0px",
              "paddingRight": "0px",
              "paddingBottom": "0px",
              "paddingLeft": "0px",
              "marginTop": "0px",
              "marginRight": "0px",
              "marginBottom": "0px",
              "marginLeft": "0px",
              "backgroundImage": "",
              "backgroundPosition": "35% 23%",
              "backgroundSize": "cover",
              "minHeight": "50vh!important"
          },
          "attrs": [],
          "class": [],
          "on": {},
          "nativeOn": [],
          "directives": [],
          "scopedSlots": [],
          "props": [],
          "domProps": []
      },
      "childWidth": {
          "sm": 1,
          "md": 1,
          "lg": 1
      },
      entries: [
        {
          "contents": [],
          "colAlign": "top",
          "property": {
              "style": {
                  "backgroundImage": "",
                  "backgroundPosition": "35% 23%",
                  "backgroundSize": "cover",
                  "height": "",
                  "marginTop": "5px",
                  "marginLeft": "5px",
                  "marginBottom": "5px",
                  "marginRight": "5px",
                  "paddingTop": "5px",
                  "paddingLeft": "5px",
                  "paddingBottom": "5px",
                  "paddingRight": "5px"
              },
              "attrs": {},
              "class": [],
              "on": {},
              "nativeOn": {},
              "directives": [],
              "scopedSlots": {},
              "props": {},
              "domProps": {}
          },
          "key": uuidv4(),
          "type": "col",
          "tag": "",
          "entries": [
              {
                  "contents": [],
                  "property": {
                      "style": {
                          "paddingTop": "0px",
                          "paddingRight": "10px",
                          "paddingBottom": "0px",
                          "paddingLeft": "10px",
                          "marginTop": "0px",
                          "marginRight": "10px",
                          "marginBottom": "0px",
                          "marginLeft": "10px",
                          "backgroundImage": "",
                          "backgroundPosition": "35% 23%",
                          "backgroundSize": "cover"
                      },
                      "attrs": [],
                      "class": [],
                      "on": {},
                      "nativeOn": [],
                      "directives": [],
                      "scopedSlots": [],
                      "props": {
                          "direction": "row"
                      },
                      "domProps": []
                  },
                  "childWidth": {
                      "sm": 1,
                      "md": 3,
                      "lg": 3
                  },
                  "key": uuidv4(),
                  "type": "row",
                  "tag": "",
                  "entries": [
                      {
                          "contents": [],
                          "colAlign": "top",
                          "property": {
                              "style": {
                                  "paddingTop": "0px",
                                  "paddingRight": "10px",
                                  "paddingBottom": "0px",
                                  "paddingLeft": "10px",
                                  "marginTop": "0px",
                                  "marginRight": "10px",
                                  "marginBottom": "10px",
                                  "marginLeft": "10px",
                                  "backgroundImage": "",
                                  "backgroundPosition": "35% 23%",
                                  "backgroundSize": "cover",
                                  "height": ""
                              },
                              "attrs": [],
                              "class": [],
                              "on": {},
                              "nativeOn": [],
                              "directives": [],
                              "scopedSlots": [],
                              "props": {
                                  "direction": "column",
                                  "is_card": true
                              },
                              "domProps": []
                          },
                          "key": uuidv4(),
                          "type": "col",
                          "tag": "",
                          "entries": [
                              {
                                  "tag": "img",
                                  "property": {
                                      "class": [],
                                      "attrs": {
                                          "src": "https://burst.shopifycdn.com/photos/gold-zipper-on-black-fashion-backpack.jpg?width=373&format=pjpg&exif=0&iptc=0"
                                      },
                                      "domProps": [],
                                      "style": {
                                          "paddingTop": "10px",
                                          "paddingRight": "10px",
                                          "paddingBottom": "10px",
                                          "paddingLeft": "10px",
                                          "marginTop": "0px",
                                          "marginRight": "0px",
                                          "marginBottom": "0px",
                                          "marginLeft": "0px"
                                      },
                                      "on": {},
                                      "nativeOn": [],
                                      "directives": [],
                                      "scopedSlots": [],
                                      "props": []
                                  },
                                  "key": uuidv4(),
                                  "type": "content",
                                  "contents": []
                              },
                              {
                                  "tag": "p",
                                  "property": {
                                      "class": [],
                                      "attrs": [],
                                      "domProps": {
                                          "innerHTML": i18n.t("Review card HTML template...")
                                      },
                                      "style": {
                                          "paddingTop": "10px",
                                          "paddingRight": "10px",
                                          "paddingBottom": "10px",
                                          "paddingLeft": "10px",
                                          "marginTop": "10px",
                                          "marginRight": "10px",
                                          "marginBottom": "10px",
                                          "marginLeft": "10px"
                                      },
                                      "on": {},
                                      "nativeOn": [],
                                      "directives": [],
                                      "scopedSlots": [],
                                      "props": []
                                  },
                                  "key": uuidv4(),
                                  "type": "content",
                                  "contents": []
                              }
                          ]
                      },
                      {
                          "contents": [],
                          "colAlign": "top",
                          "property": {
                              "style": {
                                  "paddingTop": "0px",
                                  "paddingRight": "10px",
                                  "paddingBottom": "0px",
                                  "paddingLeft": "10px",
                                  "marginTop": "0px",
                                  "marginRight": "10px",
                                  "marginBottom": "10px",
                                  "marginLeft": "10px",
                                  "backgroundImage": "",
                                  "backgroundPosition": "35% 23%",
                                  "backgroundSize": "cover",
                                  "height": ""
                              },
                              "attrs": [],
                              "class": [],
                              "on": {},
                              "nativeOn": [],
                              "directives": [],
                              "scopedSlots": [],
                              "props": {
                                  "direction": "column",
                                  "is_card": true
                              },
                              "domProps": []
                          },
                          "key": uuidv4(),
                          "type": "col",
                          "tag": "",
                          "entries": [
                              {
                                  "tag": "img",
                                  "property": {
                                      "class": [],
                                      "attrs": {
                                          "src": "https://burst.shopifycdn.com/photos/wood-leather-watches.jpg?width=373&format=pjpg&exif=0&iptc=0"
                                      },
                                      "domProps": [],
                                      "style": {
                                          "paddingTop": "10px",
                                          "paddingRight": "10px",
                                          "paddingBottom": "10px",
                                          "paddingLeft": "10px",
                                          "marginTop": "0px",
                                          "marginRight": "0px",
                                          "marginBottom": "0px",
                                          "marginLeft": "0px"
                                      },
                                      "on": {},
                                      "nativeOn": [],
                                      "directives": [],
                                      "scopedSlots": [],
                                      "props": []
                                  },
                                  "key": uuidv4(),
                                  "type": "content",
                                  "contents": []
                              },
                              {
                                  "tag": "p",
                                  "property": {
                                      "class": [],
                                      "attrs": [],
                                      "domProps": {
                                          "innerHTML": i18n.t("Review card HTML template...")
                                      },
                                      "style": {
                                          "paddingTop": "10px",
                                          "paddingRight": "10px",
                                          "paddingBottom": "10px",
                                          "paddingLeft": "10px",
                                          "marginTop": "10px",
                                          "marginRight": "10px",
                                          "marginBottom": "10px",
                                          "marginLeft": "10px"
                                      },
                                      "on": {},
                                      "nativeOn": [],
                                      "directives": [],
                                      "scopedSlots": [],
                                      "props": []
                                  },
                                  "key": uuidv4(),
                                  "type": "content",
                                  "contents": []
                              }
                          ]
                      },
                      {
                          "contents": [],
                          "colAlign": "top",
                          "property": {
                              "style": {
                                  "paddingTop": "0px",
                                  "paddingRight": "10px",
                                  "paddingBottom": "0px",
                                  "paddingLeft": "10px",
                                  "marginTop": "0px",
                                  "marginRight": "10px",
                                  "marginBottom": "10px",
                                  "marginLeft": "10px",
                                  "backgroundImage": "",
                                  "backgroundPosition": "35% 23%",
                                  "backgroundSize": "cover",
                                  "height": ""
                              },
                              "attrs": [],
                              "class": [],
                              "on": {},
                              "nativeOn": [],
                              "directives": [],
                              "scopedSlots": [],
                              "props": {
                                  "direction": "column",
                                  "is_card": true
                              },
                              "domProps": []
                          },
                          "key": uuidv4(),
                          "type": "col",
                          "tag": "",
                          "entries": [
                              {
                                  "tag": "img",
                                  "property": {
                                      "class": [],
                                      "attrs": {
                                          "src": "https://burst.shopifycdn.com/photos/bright-red-purse-with-gold.jpg?width=373&format=pjpg&exif=0&iptc=0"
                                      },
                                      "domProps": [],
                                      "style": {
                                          "paddingTop": "10px",
                                          "paddingRight": "10px",
                                          "paddingBottom": "10px",
                                          "paddingLeft": "10px",
                                          "marginTop": "0px",
                                          "marginRight": "0px",
                                          "marginBottom": "0px",
                                          "marginLeft": "0px"
                                      },
                                      "on": {},
                                      "nativeOn": [],
                                      "directives": [],
                                      "scopedSlots": [],
                                      "props": []
                                  },
                                  "key": uuidv4(),
                                  "type": "content",
                                  "contents": []
                              },
                              {
                                  "tag": "p",
                                  "property": {
                                      "class": [],
                                      "attrs": [],
                                      "domProps": {
                                          "innerHTML": i18n.t("Review card HTML template...")
                                      },
                                      "style": {
                                          "paddingTop": "10px",
                                          "paddingRight": "10px",
                                          "paddingBottom": "10px",
                                          "paddingLeft": "10px",
                                          "marginTop": "10px",
                                          "marginRight": "10px",
                                          "marginBottom": "10px",
                                          "marginLeft": "10px"
                                      },
                                      "on": {},
                                      "nativeOn": [],
                                      "directives": [],
                                      "scopedSlots": [],
                                      "props": []
                                  },
                                  "key": uuidv4(),
                                  "type": "content",
                                  "contents": []
                              }
                          ]
                      }
                  ]
              },
              {
                  "contents": [],
                  "property": {
                      "style": {
                          "paddingTop": "0px",
                          "paddingRight": "10px",
                          "paddingBottom": "0px",
                          "paddingLeft": "10px",
                          "marginTop": "0px",
                          "marginRight": "10px",
                          "marginBottom": "0px",
                          "marginLeft": "10px",
                          "backgroundImage": "",
                          "backgroundPosition": "35% 23%",
                          "backgroundSize": "cover"
                      },
                      "attrs": [],
                      "class": [],
                      "on": {},
                      "nativeOn": [],
                      "directives": [],
                      "scopedSlots": [],
                      "props": {
                          "direction": "row"
                      },
                      "domProps": []
                  },
                  "childWidth": {
                      "sm": 1,
                      "md": 3,
                      "lg": 3
                  },
                  "key": uuidv4(),
                  "type": "row",
                  "tag": "",
                  "entries": [
                      {
                          "contents": [],
                          "colAlign": "top",
                          "property": {
                              "style": {
                                  "paddingTop": "0px",
                                  "paddingRight": "10px",
                                  "paddingBottom": "0px",
                                  "paddingLeft": "10px",
                                  "marginTop": "0px",
                                  "marginRight": "10px",
                                  "marginBottom": "10px",
                                  "marginLeft": "10px",
                                  "backgroundImage": "",
                                  "backgroundPosition": "35% 23%",
                                  "backgroundSize": "cover",
                                  "height": ""
                              },
                              "attrs": [],
                              "class": [],
                              "on": {},
                              "nativeOn": [],
                              "directives": [],
                              "scopedSlots": [],
                              "props": {
                                  "direction": "column",
                                  "is_card": true
                              },
                              "domProps": []
                          },
                          "key": uuidv4(),
                          "type": "col",
                          "tag": "",
                          "entries": [
                              {
                                  "tag": "img",
                                  "property": {
                                      "class": [],
                                      "attrs": {
                                          "src": "https://burst.shopifycdn.com/photos/gold-zipper-on-black-fashion-backpack.jpg?width=373&format=pjpg&exif=0&iptc=0"
                                      },
                                      "domProps": [],
                                      "style": {
                                          "paddingTop": "10px",
                                          "paddingRight": "10px",
                                          "paddingBottom": "10px",
                                          "paddingLeft": "10px",
                                          "marginTop": "0px",
                                          "marginRight": "0px",
                                          "marginBottom": "0px",
                                          "marginLeft": "0px"
                                      },
                                      "on": {},
                                      "nativeOn": [],
                                      "directives": [],
                                      "scopedSlots": [],
                                      "props": []
                                  },
                                  "key": uuidv4(),
                                  "type": "content",
                                  "contents": []
                              },
                              {
                                  "tag": "p",
                                  "property": {
                                      "class": [],
                                      "attrs": [],
                                      "domProps": {
                                          "innerHTML": i18n.t("Review card HTML template...")
                                      },
                                      "style": {
                                          "paddingTop": "10px",
                                          "paddingRight": "10px",
                                          "paddingBottom": "10px",
                                          "paddingLeft": "10px",
                                          "marginTop": "10px",
                                          "marginRight": "10px",
                                          "marginBottom": "10px",
                                          "marginLeft": "10px"
                                      },
                                      "on": {},
                                      "nativeOn": [],
                                      "directives": [],
                                      "scopedSlots": [],
                                      "props": []
                                  },
                                  "key": uuidv4(),
                                  "type": "content",
                                  "contents": []
                              }
                          ]
                      },
                      {
                          "contents": [],
                          "colAlign": "top",
                          "property": {
                              "style": {
                                  "paddingTop": "0px",
                                  "paddingRight": "10px",
                                  "paddingBottom": "0px",
                                  "paddingLeft": "10px",
                                  "marginTop": "0px",
                                  "marginRight": "10px",
                                  "marginBottom": "10px",
                                  "marginLeft": "10px",
                                  "backgroundImage": "",
                                  "backgroundPosition": "35% 23%",
                                  "backgroundSize": "cover",
                                  "height": ""
                              },
                              "attrs": [],
                              "class": [],
                              "on": {},
                              "nativeOn": [],
                              "directives": [],
                              "scopedSlots": [],
                              "props": {
                                  "direction": "column",
                                  "is_card": true
                              },
                              "domProps": []
                          },
                          "key": uuidv4(),
                          "type": "col",
                          "tag": "",
                          "entries": [
                              {
                                  "tag": "img",
                                  "property": {
                                      "class": [],
                                      "attrs": {
                                          "src": "https://burst.shopifycdn.com/photos/wood-leather-watches.jpg?width=373&format=pjpg&exif=0&iptc=0"
                                      },
                                      "domProps": [],
                                      "style": {
                                          "paddingTop": "10px",
                                          "paddingRight": "10px",
                                          "paddingBottom": "10px",
                                          "paddingLeft": "10px",
                                          "marginTop": "0px",
                                          "marginRight": "0px",
                                          "marginBottom": "0px",
                                          "marginLeft": "0px"
                                      },
                                      "on": {},
                                      "nativeOn": [],
                                      "directives": [],
                                      "scopedSlots": [],
                                      "props": []
                                  },
                                  "key": uuidv4(),
                                  "type": "content",
                                  "contents": []
                              },
                              {
                                  "tag": "p",
                                  "property": {
                                      "class": [],
                                      "attrs": [],
                                      "domProps": {
                                          "innerHTML": i18n.t("Review card HMTL template...")
                                      },
                                      "style": {
                                          "paddingTop": "10px",
                                          "paddingRight": "10px",
                                          "paddingBottom": "10px",
                                          "paddingLeft": "10px",
                                          "marginTop": "10px",
                                          "marginRight": "10px",
                                          "marginBottom": "10px",
                                          "marginLeft": "10px"
                                      },
                                      "on": {},
                                      "nativeOn": [],
                                      "directives": [],
                                      "scopedSlots": [],
                                      "props": []
                                  },
                                  "key": uuidv4(),
                                  "type": "content",
                                  "contents": []
                              }
                          ]
                      },
                      {
                          "contents": [],
                          "colAlign": "top",
                          "property": {
                              "style": {
                                  "paddingTop": "0px",
                                  "paddingRight": "10px",
                                  "paddingBottom": "0px",
                                  "paddingLeft": "10px",
                                  "marginTop": "0px",
                                  "marginRight": "10px",
                                  "marginBottom": "10px",
                                  "marginLeft": "10px",
                                  "backgroundImage": "",
                                  "backgroundPosition": "35% 23%",
                                  "backgroundSize": "cover",
                                  "height": ""
                              },
                              "attrs": [],
                              "class": [],
                              "on": {},
                              "nativeOn": [],
                              "directives": [],
                              "scopedSlots": [],
                              "props": {
                                  "direction": "column",
                                  "is_card": true
                              },
                              "domProps": []
                          },
                          "key": uuidv4(),
                          "type": "col",
                          "tag": "",
                          "entries": [
                              {
                                  "tag": "img",
                                  "property": {
                                      "class": [],
                                      "attrs": {
                                          "src": "https://burst.shopifycdn.com/photos/bright-red-purse-with-gold.jpg?width=373&format=pjpg&exif=0&iptc=0"
                                      },
                                      "domProps": [],
                                      "style": {
                                          "paddingTop": "10px",
                                          "paddingRight": "10px",
                                          "paddingBottom": "10px",
                                          "paddingLeft": "10px",
                                          "marginTop": "0px",
                                          "marginRight": "0px",
                                          "marginBottom": "0px",
                                          "marginLeft": "0px"
                                      },
                                      "on": {},
                                      "nativeOn": [],
                                      "directives": [],
                                      "scopedSlots": [],
                                      "props": []
                                  },
                                  "key": uuidv4(),
                                  "type": "content",
                                  "contents": []
                              },
                              {
                                  "tag": "p",
                                  "property": {
                                      "class": [],
                                      "attrs": [],
                                      "domProps": {
                                        "innerHTML": i18n.t("Review card HMTL template...")
                                      },
                                      "style": {
                                          "paddingTop": "10px",
                                          "paddingRight": "10px",
                                          "paddingBottom": "10px",
                                          "paddingLeft": "10px",
                                          "marginTop": "10px",
                                          "marginRight": "10px",
                                          "marginBottom": "10px",
                                          "marginLeft": "10px"
                                      },
                                      "on": {},
                                      "nativeOn": [],
                                      "directives": [],
                                      "scopedSlots": [],
                                      "props": []
                                  },
                                  "key": uuidv4(),
                                  "type": "content",
                                  "contents": []
                              }
                          ]
                      }
                  ]
              }
          ]
        }
      ],
      "key": uuidv4(),
      "type": "block",
      "tag": "",
    }
  },

  threeColFeature(){
    return {
      "contents": [],
      "property": {
          "style": {
              "paddingTop": "0px",
              "paddingRight": "0px",
              "paddingBottom": "0px",
              "paddingLeft": "0px",
              "marginTop": "0px",
              "marginRight": "0px",
              "marginBottom": "0px",
              "marginLeft": "0px",
              "backgroundImage": "",
              "backgroundPosition": "35% 23%",
              "backgroundSize": "cover",
              "minHeight": "50vh!important"
          },
          "attrs": [],
          "class": [],
          "on": {},
          "nativeOn": [],
          "directives": [],
          "scopedSlots": [],
          "props": [],
          "domProps": []
      },
      "childWidth": {
          "sm": 1,
          "md": 3,
          "lg": 3
      },
      entries: [
        {
          "contents": [],
          "colAlign": "middle",
          "property": {
              "style": {
                  "paddingTop": "5px",
                  "paddingRight": "5px",
                  "paddingBottom": "5px",
                  "paddingLeft": "5px",
                  "marginTop": "0px",
                  "marginRight": "0px",
                  "marginBottom": "0px",
                  "marginLeft": "0px",
                  "backgroundImage": "url(https://server.marketingmaster.io/upload/apis/1/969568_1633620198.jpg)",
                  "backgroundPosition": "35% 23%",
                  "backgroundSize": "cover",
                  "height": "",
                  "flex": 1
              },
              "attrs": [],
              "class": [],
              "on": {},
              "nativeOn": [],
              "directives": [],
              "scopedSlots": [],
              "props": {
                  "direction": "column"
              },
              "domProps": []
          },
          entries: [
            {
              "tag": "p",
              "property": {
                  "class": [],
                  "attrs": [],
                  "domProps": {
                      "innerHTML": "<h3 style=\"margin-left:0px;\">$12</h3><h3 style=\"margin-left:0px;\"><strong>Chicken</strong><br><span style=\"color:rgb(255,194,34);\"><strong>Fried</strong></span></h3>"
                  },
                  "style": {
                      "paddingTop": "10px",
                      "paddingRight": "10px",
                      "paddingBottom": "10px",
                      "paddingLeft": "10px",
                      "marginTop": "10px",
                      "marginRight": "10px",
                      "marginBottom": "10px",
                      "marginLeft": "10px"
                  },
                  "on": {},
                  "nativeOn": [],
                  "directives": [],
                  "scopedSlots": [],
                  "props": []
              },
              "key": uuidv4(),
              "type": "content",
              "contents": []
            },
            {
              "tag": "button",
              "property": {
                  "class": [],
                  "attrs": [],
                  "domProps": {
                      "innerHTML": `<p>${i18n.t('Order Now')}</p>`
                  },
                  "style": {
                      "paddingTop": "10px",
                      "paddingRight": "10px",
                      "paddingBottom": "10px",
                      "paddingLeft": "10px",
                      "marginTop": "10px",
                      "marginRight": "10px",
                      "marginBottom": "10px",
                      "marginLeft": "10px"
                  },
                  "on": {},
                  "nativeOn": [],
                  "directives": [],
                  "scopedSlots": [],
                  "props": {
                      "size": "default",
                      "color": "white",
                      "type": "line",
                      "action": {
                          "mode": false,
                          "to": {
                              "id": "shop",
                              "name": "Shop",
                              "path": "Ecom Shop"
                          },
                          "isUrl": false,
                          "url": ""
                      }
                  }
              },
              "key": uuidv4(),
              "type": "content",
              "contents": []
            }
          ],
          "key": uuidv4(),
          "type": "col",
          "tag": ""
        },
        {
          "contents": [],
          "colAlign": "middle",
          "property": {
              "style": {
                  "paddingTop": "5px",
                  "paddingRight": "5px",
                  "paddingBottom": "5px",
                  "paddingLeft": "5px",
                  "marginTop": "0px",
                  "marginRight": "0px",
                  "marginBottom": "0px",
                  "marginLeft": "0px",
                  "backgroundImage": "url(https://server.marketingmaster.io/upload/apis/1/311290_1633620283.jpg)",
                  "backgroundPosition": "35% 23%",
                  "backgroundSize": "cover",
                  "height": "",
                  "flex": 1
              },
              "attrs": [],
              "class": [],
              "on": {},
              "nativeOn": [],
              "directives": [],
              "scopedSlots": [],
              "props": {
                  "direction": "column"
              },
              "domProps": []
          },
          entries: [
            {
              "tag": "p",
              "property": {
                  "class": [ ],
                  "attrs": [],
                  "domProps": {
                      "innerHTML": i18n.t("From 9$ Burger Beef HTML template...")
                  },
                  "style": {
                      "paddingTop": "10px",
                      "paddingRight": "10px",
                      "paddingBottom": "10px",
                      "paddingLeft": "10px",
                      "marginTop": "10px",
                      "marginRight": "10px",
                      "marginBottom": "10px",
                      "marginLeft": "10px"
                  },
                  "on": {},
                  "nativeOn": [],
                  "directives": [],
                  "scopedSlots": [],
                  "props": []
              },
              "key": uuidv4(),
              "type": "content",
              "contents": []
            },
            {
              "tag": "button",
              "property": {
                  "class": [],
                  "attrs": [],
                  "domProps": {
                      "innerHTML": `<p>${i18n.t('Order Now')}</p>`
                  },
                  "style": {
                      "paddingTop": "10px",
                      "paddingRight": "10px",
                      "paddingBottom": "10px",
                      "paddingLeft": "10px",
                      "marginTop": "10px",
                      "marginRight": "10px",
                      "marginBottom": "10px",
                      "marginLeft": "10px"
                  },
                  "on": {},
                  "nativeOn": [],
                  "directives": [],
                  "scopedSlots": [],
                  "props": {
                      "size": "default",
                      "color": "white",
                      "type": "line",
                      "action": {
                          "mode": false,
                          "to": {
                              "id": "shop",
                              "name": "Shop",
                              "path": "Ecom Shop"
                          },
                          "isUrl": false,
                          "url": ""
                      }
                  }
              },
              "key": uuidv4(),
              "type": "content",
              "contents": [],
            }
          ],
          "key": uuidv4(),
          "type": "col",
          "tag": ""
        },
        {
          "contents": [],
          "colAlign": "middle",
          "property": {
              "style": {
                  "paddingTop": "5px",
                  "paddingRight": "5px",
                  "paddingBottom": "5px",
                  "paddingLeft": "5px",
                  "marginTop": "0px",
                  "marginRight": "0px",
                  "marginBottom": "0px",
                  "marginLeft": "0px",
                  "backgroundImage": "url(https://server.marketingmaster.io/upload/apis/1/190699_1633620301.jpg)",
                  "backgroundPosition": "35% 23%",
                  "backgroundSize": "cover",
                  "height": "",
                  "flex": 1
              },
              "attrs": [],
              "class": [],
              "on": {},
              "nativeOn": [],
              "directives": [],
              "scopedSlots": [],
              "props": {
                  "direction": "column"
              },
              "domProps": []
          },
          entries: [
            {
              "tag": "p",
              "property": {
                  "class": [],
                  "attrs": [],
                  "domProps": {
                      "innerHTML": i18n.t("From 11$ Sandwich HTML template...")
                  },
                  "style": {
                      "paddingTop": "10px",
                      "paddingRight": "10px",
                      "paddingBottom": "10px",
                      "paddingLeft": "10px",
                      "marginTop": "10px",
                      "marginRight": "10px",
                      "marginBottom": "10px",
                      "marginLeft": "10px"
                  },
                  "on": {},
                  "nativeOn": [],
                  "directives": [],
                  "scopedSlots": [],
                  "props": []
              },
              "key": uuidv4(),
              "type": "content",
              "contents": []
            },
            {
              "tag": "button",
              "property": {
                  "class": [],
                  "attrs": [],
                  "domProps": {
                    "innerHTML": `<p>${i18n.t('Order Now')}</p>`
                  },
                  "style": {
                      "paddingTop": "10px",
                      "paddingRight": "10px",
                      "paddingBottom": "10px",
                      "paddingLeft": "10px",
                      "marginTop": "10px",
                      "marginRight": "10px",
                      "marginBottom": "10px",
                      "marginLeft": "10px"
                  },
                  "on": {},
                  "nativeOn": [],
                  "directives": [],
                  "scopedSlots": [],
                  "props": {
                      "size": "default",
                      "color": "white",
                      "type": "line",
                      "action": {
                          "mode": false,
                          "to": {
                              "id": "shop",
                              "name": "Shop",
                              "path": "Ecom Shop"
                          },
                          "isUrl": false,
                          "url": ""
                      }
                  }
              },
              "key": uuidv4(),
              "type": "content",
              "contents": [],
            }
          ],
          "key": uuidv4(),
          "type": "col",
          "tag": ""
        }
      ],
      "key": uuidv4(),
      "type": "block",
      "tag": "",
    }
  },

  cardHighlight(){
    return {
      "contents": [],
      "property": {
          "style": {
              "paddingTop": "0px",
              "paddingRight": "0px",
              "paddingBottom": "0px",
              "paddingLeft": "0px",
              "marginTop": "0px",
              "marginRight": "0px",
              "marginBottom": "0px",
              "marginLeft": "0px",
              "backgroundImage": "url(https://server.marketingmaster.io/upload/apis/1/107241_1633620526.jpg)",
              "backgroundPosition": "35% 23%",
              "backgroundSize": "cover",
              "minHeight": "75vh!important"
          },
          "attrs": [],
          "class": [],
          "on": {},
          "nativeOn": [],
          "directives": [],
          "scopedSlots": [],
          "props": [],
          "domProps": []
      },
      "childWidth": {
          "sm": 1,
          "md": 4,
          "lg": 4
      },
      "key": uuidv4(),
      "type": "block",
      "tag": "",
      "label": "Banner 2",
      "entries": [
          {
              "contents": [],
              "colAlign": "top",
              "property": {
                  "style": {
                      "backgroundImage": "",
                      "backgroundPosition": "35% 23%",
                      "backgroundSize": "cover",
                      "height": "",
                      "marginTop": "5px",
                      "marginLeft": "5px",
                      "marginBottom": "5px",
                      "marginRight": "5px",
                      "paddingTop": "5px",
                      "paddingLeft": "5px",
                      "paddingBottom": "5px",
                      "paddingRight": "5px"
                  },
                  "attrs": [],
                  "class": [],
                  "on": {},
                  "nativeOn": [],
                  "directives": [],
                  "scopedSlots": [],
                  "props": [],
                  "domProps": []
              },
              "key": uuidv4(),
              "type": "col",
              "tag": ""
          },
          {
              "contents": [],
              "colAlign": "middle",
              "property": {
                  "style": {
                      "paddingTop": "5px",
                      "paddingRight": "5px",
                      "paddingBottom": "5px",
                      "paddingLeft": "5px",
                      "marginTop": "5px",
                      "marginRight": "5px",
                      "marginBottom": "5px",
                      "marginLeft": "5px",
                      "backgroundImage": "",
                      "backgroundPosition": "35% 23%",
                      "backgroundSize": "cover",
                      "height": "",
                      "flex": "3"
                  },
                  "attrs": [],
                  "class": [],
                  "on": {},
                  "nativeOn": [],
                  "directives": [],
                  "scopedSlots": [],
                  "props": {
                      "is_card": false
                  },
                  "domProps": []
              },
              "key": uuidv4(),
              "type": "col",
              "tag": "",
              "entries": [
                  {
                      "contents": [],
                      "property": {
                          "style": {
                              "paddingTop": "0px",
                              "paddingRight": "0px",
                              "paddingBottom": "0px",
                              "paddingLeft": "0px",
                              "marginTop": "0px",
                              "marginRight": "0px",
                              "marginBottom": "0px",
                              "marginLeft": "0px",
                              "backgroundImage": "",
                              "backgroundPosition": "35% 23%",
                              "backgroundSize": "cover",
                              "maxHeight": "25vh"
                          },
                          "attrs": [],
                          "class": [],
                          "on": {},
                          "nativeOn": [],
                          "directives": [],
                          "scopedSlots": [],
                          "props": [],
                          "domProps": []
                      },
                      "childWidth": {
                          "sm": 1,
                          "md": 1,
                          "lg": 1
                      },
                      "key": uuidv4(),
                      "type": "block",
                      "tag": "",
                      "entries": [
                          {
                              "contents": [],
                              "colAlign": "middle",
                              "property": {
                                  "style": {
                                      "paddingTop": "5px",
                                      "paddingRight": "5px",
                                      "paddingBottom": "5px",
                                      "paddingLeft": "5px",
                                      "marginTop": "5px",
                                      "marginRight": "5px",
                                      "marginBottom": "5px",
                                      "marginLeft": "5px",
                                      "backgroundImage": "",
                                      "backgroundPosition": "35% 23%",
                                      "backgroundSize": "cover",
                                      "height": ""
                                  },
                                  "attrs": [],
                                  "class": [],
                                  "on": {},
                                  "nativeOn": [],
                                  "directives": [],
                                  "scopedSlots": [],
                                  "props": {
                                      "is_card": true,
                                      "direction": "column"
                                  },
                                  "domProps": []
                              },
                              "key": uuidv4(),
                              "type": "col",
                              "tag": "",
                              "entries": [
                                  {
                                      "tag": "p",
                                      "property": {
                                          "class": [],
                                          "attrs": [],
                                          "domProps": {
                                              "innerHTML": i18n.t("AIR FRYER CHICKEN BREAST HTML template...")
                                          },
                                          "style": {
                                              "paddingTop": "10px",
                                              "paddingRight": "10px",
                                              "paddingBottom": "10px",
                                              "paddingLeft": "10px",
                                              "marginTop": "10px",
                                              "marginRight": "10px",
                                              "marginBottom": "10px",
                                              "marginLeft": "10px"
                                          },
                                          "on": {},
                                          "nativeOn": [],
                                          "directives": [],
                                          "scopedSlots": [],
                                          "props": []
                                      },
                                      "key": uuidv4(),
                                      "type": "content",
                                      "contents": []
                                  },
                                  {
                                      "tag": "button",
                                      "property": {
                                          "class": [],
                                          "attrs": [],
                                          "domProps": {
                                              "innerHTML": `<p>${i18n.t('SHOP NOW')}</p>`
                                          },
                                          "style": {
                                              "paddingTop": "10px",
                                              "paddingRight": "10px",
                                              "paddingBottom": "10px",
                                              "paddingLeft": "10px",
                                              "marginTop": "10px",
                                              "marginRight": "10px",
                                              "marginBottom": "10px",
                                              "marginLeft": "10px"
                                          },
                                          "on": {},
                                          "nativeOn": [],
                                          "directives": [],
                                          "scopedSlots": [],
                                          "props": {
                                              "size": "large",
                                              "color": "dark",
                                              "type": "filled",
                                              "action": {
                                                  "mode": false,
                                                  "to": {
                                                      "id": "shop",
                                                      "name": "Shop",
                                                      "path": "Ecom Shop"
                                                  },
                                                  "isUrl": false,
                                                  "url": ""
                                              }
                                          }
                                      },
                                      "key": uuidv4(),
                                      "type": "content",
                                      "contents": []
                                  }
                              ]
                          }
                      ]
                  }
              ]
          },
          {
              "contents": [],
              "colAlign": "top",
              "property": {
                  "style": {
                      "backgroundImage": "",
                      "backgroundPosition": "35% 23%",
                      "backgroundSize": "cover",
                      "height": "",
                      "marginTop": "5px",
                      "marginLeft": "5px",
                      "marginBottom": "5px",
                      "marginRight": "5px",
                      "paddingTop": "5px",
                      "paddingLeft": "5px",
                      "paddingBottom": "5px",
                      "paddingRight": "5px"
                  },
                  "attrs": [],
                  "class": [],
                  "on": {},
                  "nativeOn": [],
                  "directives": [],
                  "scopedSlots": [],
                  "props": [],
                  "domProps": []
              },
              "key": uuidv4(),
              "type": "col",
              "tag": ""
          },
          {
              "contents": [],
              "colAlign": "top",
              "property": {
                  "style": {
                      "backgroundImage": "",
                      "backgroundPosition": "35% 23%",
                      "backgroundSize": "cover",
                      "height": "",
                      "marginTop": "5px",
                      "marginLeft": "5px",
                      "marginBottom": "5px",
                      "marginRight": "5px",
                      "paddingTop": "5px",
                      "paddingLeft": "5px",
                      "paddingBottom": "5px",
                      "paddingRight": "5px"
                  },
                  "attrs": [],
                  "class": [],
                  "on": {},
                  "nativeOn": [],
                  "directives": [],
                  "scopedSlots": [],
                  "props": [],
                  "domProps": []
              },
              "key": uuidv4(),
              "type": "col",
              "tag": ""
          }
      ]
    }
  },

  productsHighlightRight(){
    return {
      "contents": [],
      "property": {
          "style": {
              "paddingTop": "0px",
              "paddingRight": "0px",
              "paddingBottom": "0px",
              "paddingLeft": "0px",
              "marginTop": "0px",
              "marginRight": "0px",
              "marginBottom": "0px",
              "marginLeft": "0px",
              "backgroundImage": "",
              "backgroundPosition": "35% 23%",
              "backgroundSize": "cover"
          },
          "attrs": [],
          "class": [],
          "on": {},
          "nativeOn": [],
          "directives": [],
          "scopedSlots": [],
          "props": [],
          "domProps": []
      },
      "childWidth": {
          "sm": 1,
          "md": 2,
          "lg": 2
      },
      "key": uuidv4(),
      "type": "block",
      "tag": "",
      "label": "Banner 3",
      "entries": [
          {
              "contents": [],
              "colAlign": "top",
              "property": {
                  "style": {
                      "paddingTop": "5px",
                      "paddingRight": "5px",
                      "paddingBottom": "5px",
                      "paddingLeft": "5px",
                      "marginTop": "0px",
                      "marginRight": "0px",
                      "marginBottom": "0px",
                      "marginLeft": "0px",
                      "backgroundImage": "url(https://server.marketingmaster.io/upload/apis/1/113852_1633620904.jpg)",
                      "backgroundPosition": "35% 23%",
                      "backgroundSize": "cover",
                      "height": ""
                  },
                  "attrs": [],
                  "class": [],
                  "on": {},
                  "nativeOn": [],
                  "directives": [],
                  "scopedSlots": [],
                  "props": [],
                  "domProps": []
              },
              "key": uuidv4(),
              "type": "col",
              "tag": ""
          },
          {
              "contents": [],
              "colAlign": "top",
              "property": {
                  "style": {
                      "backgroundImage": "",
                      "backgroundPosition": "35% 23%",
                      "backgroundSize": "cover",
                      "height": "",
                      "marginTop": "5px",
                      "marginLeft": "5px",
                      "marginBottom": "5px",
                      "marginRight": "5px",
                      "paddingTop": "5px",
                      "paddingLeft": "5px",
                      "paddingBottom": "5px",
                      "paddingRight": "5px"
                  },
                  "attrs": [],
                  "class": [],
                  "on": {},
                  "nativeOn": [],
                  "directives": [],
                  "scopedSlots": [],
                  "props": [],
                  "domProps": []
              },
              "key": uuidv4(),
              "type": "col",
              "tag": "",
              "entries": [
                  {
                      "contents": [],
                      "property": {
                          "style": {
                              "paddingTop": "0px",
                              "paddingRight": "0px",
                              "paddingBottom": "0px",
                              "paddingLeft": "0px",
                              "marginTop": "0px",
                              "marginRight": "0px",
                              "marginBottom": "0px",
                              "marginLeft": "0px",
                              "backgroundImage": "",
                              "backgroundPosition": "35% 23%",
                              "backgroundSize": "cover"
                          },
                          "attrs": [],
                          "class": [],
                          "on": {},
                          "nativeOn": [],
                          "directives": [],
                          "scopedSlots": [],
                          "props": [],
                          "domProps": []
                      },
                      "childWidth": {
                          "sm": 1,
                          "md": 2,
                          "lg": 2
                      },
                      "key": uuidv4(),
                      "type": "block",
                      "tag": "",
                      "entries": [
                          {
                              "contents": [],
                              "colAlign": "top",
                              "property": {
                                  "style": {
                                      "paddingTop": "0px",
                                      "paddingRight": "0px",
                                      "paddingBottom": "0px",
                                      "paddingLeft": "0px",
                                      "marginTop": "0px",
                                      "marginRight": "0px",
                                      "marginBottom": "0px",
                                      "marginLeft": "0px",
                                      "backgroundImage": "",
                                      "backgroundPosition": "35% 23%",
                                      "backgroundSize": "cover",
                                      "height": ""
                                  },
                                  "attrs": [],
                                  "class": [],
                                  "on": {},
                                  "nativeOn": [],
                                  "directives": [],
                                  "scopedSlots": [],
                                  "props": [],
                                  "domProps": []
                              },
                              "key": uuidv4(),
                              "type": "col",
                              "tag": "",
                              "entries": [
                                  {
                                      "tag": "img",
                                      "property": {
                                          "class": [],
                                          "attrs": {
                                              "src": "https://server.marketingmaster.io/upload/apis/1/186414_1633620992.jpg"
                                          },
                                          "domProps": [],
                                          "style": {
                                              "paddingTop": "0px",
                                              "paddingRight": "0px",
                                              "paddingBottom": "0px",
                                              "paddingLeft": "0px",
                                              "marginTop": "0px",
                                              "marginRight": "0px",
                                              "marginBottom": "0px",
                                              "marginLeft": "0px"
                                          },
                                          "on": {},
                                          "nativeOn": [],
                                          "directives": [],
                                          "scopedSlots": [],
                                          "props": []
                                      },
                                      "key": uuidv4(),
                                      "type": "content",
                                      "contents": []
                                  },
                                  {
                                      "tag": "p",
                                      "property": {
                                          "class": [],
                                          "attrs": [],
                                          "domProps": {
                                              "innerHTML": i18n.t("Yummy Burgers HTML template...")
                                          },
                                          "style": {
                                              "paddingTop": "0px",
                                              "paddingRight": "0px",
                                              "paddingBottom": "0px",
                                              "paddingLeft": "0px",
                                              "marginTop": "0px",
                                              "marginRight": "0px",
                                              "marginBottom": "0px",
                                              "marginLeft": "0px"
                                          },
                                          "on": {},
                                          "nativeOn": [],
                                          "directives": [],
                                          "scopedSlots": [],
                                          "props": {
                                              "align": "left"
                                          }
                                      },
                                      "key": uuidv4(),
                                      "type": "content",
                                      "contents": []
                                  },
                                  {
                                      "tag": "button",
                                      "property": {
                                          "class": [],
                                          "attrs": [],
                                          "domProps": {
                                              "innerHTML": `<p>${i18n.t('SHOP NOW')}</p>`
                                          },
                                          "style": {
                                              "paddingTop": "10px",
                                              "paddingRight": "10px",
                                              "paddingBottom": "10px",
                                              "paddingLeft": "10px",
                                              "marginTop": "0px",
                                              "marginRight": "0px",
                                              "marginBottom": "0px",
                                              "marginLeft": "0px"
                                          },
                                          "on": {},
                                          "nativeOn": [],
                                          "directives": [],
                                          "scopedSlots": [],
                                          "props": {
                                              "size": "default",
                                              "color": "dark",
                                              "type": "filled",
                                              "action": {
                                                  "mode": false,
                                                  "to": {
                                                      "id": "shop",
                                                      "name": "Shop",
                                                      "path": "Ecom Shop"
                                                  },
                                                  "isUrl": false,
                                                  "url": ""
                                              }
                                          }
                                      },
                                      "key": uuidv4(),
                                      "type": "content",
                                      "contents": []
                                  }
                              ]
                          },
                          {
                              "contents": [],
                              "colAlign": "top",
                              "property": {
                                  "style": {
                                      "paddingTop": "0px",
                                      "paddingRight": "0px",
                                      "paddingBottom": "0px",
                                      "paddingLeft": "0px",
                                      "marginTop": "0px",
                                      "marginRight": "0px",
                                      "marginBottom": "0px",
                                      "marginLeft": "0px",
                                      "backgroundImage": "",
                                      "backgroundPosition": "35% 23%",
                                      "backgroundSize": "cover",
                                      "height": ""
                                  },
                                  "attrs": [],
                                  "class": [],
                                  "on": {},
                                  "nativeOn": [],
                                  "directives": [],
                                  "scopedSlots": [],
                                  "props": [],
                                  "domProps": []
                              },
                              "key": uuidv4(),
                              "type": "col",
                              "tag": "",
                              "entries": [
                                  {
                                      "tag": "img",
                                      "property": {
                                          "class": [],
                                          "attrs": {
                                              "src": "https://server.marketingmaster.io/upload/apis/1/163732_1633623479.jpg"
                                          },
                                          "domProps": [],
                                          "style": {
                                              "paddingTop": "0px",
                                              "paddingRight": "0px",
                                              "paddingBottom": "0px",
                                              "paddingLeft": "0px",
                                              "marginTop": "0px",
                                              "marginRight": "0px",
                                              "marginBottom": "0px",
                                              "marginLeft": "0px"
                                          },
                                          "on": {},
                                          "nativeOn": [],
                                          "directives": [],
                                          "scopedSlots": [],
                                          "props": []
                                      },
                                      "key": uuidv4(),
                                      "type": "content",
                                      "contents": []
                                  },
                                  {
                                      "tag": "p",
                                      "property": {
                                          "class": [],
                                          "attrs": [],
                                          "domProps": {
                                              "innerHTML": i18n.t("Taco Cake HTML template...")
                                          },
                                          "style": {
                                              "paddingTop": "0px",
                                              "paddingRight": "0px",
                                              "paddingBottom": "0px",
                                              "paddingLeft": "0px",
                                              "marginTop": "0px",
                                              "marginRight": "0px",
                                              "marginBottom": "0px",
                                              "marginLeft": "0px"
                                          },
                                          "on": {},
                                          "nativeOn": [],
                                          "directives": [],
                                          "scopedSlots": [],
                                          "props": {
                                              "align": "left"
                                          }
                                      },
                                      "key": uuidv4(),
                                      "type": "content",
                                      "contents": []
                                  },
                                  {
                                      "tag": "button",
                                      "property": {
                                          "class": [],
                                          "attrs": [],
                                          "domProps": {
                                              "innerHTML": `<p>${i18n.t('SHOP NOW')}</p>`
                                          },
                                          "style": {
                                              "paddingTop": "10px",
                                              "paddingRight": "10px",
                                              "paddingBottom": "10px",
                                              "paddingLeft": "10px",
                                              "marginTop": "0px",
                                              "marginRight": "0px",
                                              "marginBottom": "0px",
                                              "marginLeft": "0px"
                                          },
                                          "on": {},
                                          "nativeOn": [],
                                          "directives": [],
                                          "scopedSlots": [],
                                          "props": {
                                              "size": "default",
                                              "color": "dark",
                                              "type": "filled",
                                              "action": {
                                                  "mode": false,
                                                  "to": {
                                                      "id": "shop",
                                                      "name": "Shop",
                                                      "path": "Ecom Shop"
                                                  },
                                                  "isUrl": false,
                                                  "url": ""
                                              }
                                          }
                                      },
                                      "key": uuidv4(),
                                      "type": "content",
                                      "contents": []
                                  }
                              ]
                          }
                      ]
                  },
                  {
                      "contents": [],
                      "property": {
                          "style": {
                              "paddingTop": "0px",
                              "paddingRight": "0px",
                              "paddingBottom": "0px",
                              "paddingLeft": "0px",
                              "marginTop": "0px",
                              "marginRight": "0px",
                              "marginBottom": "0px",
                              "marginLeft": "0px",
                              "backgroundImage": "",
                              "backgroundPosition": "35% 23%",
                              "backgroundSize": "cover"
                          },
                          "attrs": [],
                          "class": [],
                          "on": {},
                          "nativeOn": [],
                          "directives": [],
                          "scopedSlots": [],
                          "props": [],
                          "domProps": []
                      },
                      "childWidth": {
                          "sm": 1,
                          "md": 2,
                          "lg": 2
                      },
                      "key": uuidv4(),
                      "type": "block",
                      "tag": "",
                      "entries": [
                          {
                              "contents": [],
                              "colAlign": "top",
                              "property": {
                                  "style": {
                                      "paddingTop": "0px",
                                      "paddingRight": "0px",
                                      "paddingBottom": "0px",
                                      "paddingLeft": "0px",
                                      "marginTop": "0px",
                                      "marginRight": "0px",
                                      "marginBottom": "0px",
                                      "marginLeft": "0px",
                                      "backgroundImage": "",
                                      "backgroundPosition": "35% 23%",
                                      "backgroundSize": "cover",
                                      "height": ""
                                  },
                                  "attrs": [],
                                  "class": [],
                                  "on": {},
                                  "nativeOn": [],
                                  "directives": [],
                                  "scopedSlots": [],
                                  "props": [],
                                  "domProps": []
                              },
                              "key": uuidv4(),
                              "type": "col",
                              "tag": "",
                              "entries": [
                                  {
                                      "tag": "img",
                                      "property": {
                                          "class": [],
                                          "attrs": {
                                              "src": "https://server.marketingmaster.io/upload/apis/1/912232_1633621287.jpg"
                                          },
                                          "domProps": [],
                                          "style": {
                                              "paddingTop": "0px",
                                              "paddingRight": "0px",
                                              "paddingBottom": "0px",
                                              "paddingLeft": "0px",
                                              "marginTop": "0px",
                                              "marginRight": "0px",
                                              "marginBottom": "0px",
                                              "marginLeft": "0px"
                                          },
                                          "on": {},
                                          "nativeOn": [],
                                          "directives": [],
                                          "scopedSlots": [],
                                          "props": []
                                      },
                                      "key": uuidv4(),
                                      "type": "content",
                                      "contents": []
                                  },
                                  {
                                      "tag": "p",
                                      "property": {
                                          "class": [],
                                          "attrs": [],
                                          "domProps": {
                                              "innerHTML": i18n.t("Experience Delight HTML template...")
                                          },
                                          "style": {
                                              "paddingTop": "0px",
                                              "paddingRight": "0px",
                                              "paddingBottom": "0px",
                                              "paddingLeft": "0px",
                                              "marginTop": "0px",
                                              "marginRight": "0px",
                                              "marginBottom": "0px",
                                              "marginLeft": "0px"
                                          },
                                          "on": {},
                                          "nativeOn": [],
                                          "directives": [],
                                          "scopedSlots": [],
                                          "props": {
                                              "align": "left"
                                          }
                                      },
                                      "key": uuidv4(),
                                      "type": "content",
                                      "contents": []
                                  },
                                  {
                                      "tag": "button",
                                      "property": {
                                          "class": [],
                                          "attrs": [],
                                          "domProps": {
                                              "innerHTML": `<p>${i18n.t('SHOP NOW')}</p>`
                                          },
                                          "style": {
                                              "paddingTop": "10px",
                                              "paddingRight": "10px",
                                              "paddingBottom": "10px",
                                              "paddingLeft": "10px",
                                              "marginTop": "0px",
                                              "marginRight": "0px",
                                              "marginBottom": "0px",
                                              "marginLeft": "0px"
                                          },
                                          "on": {},
                                          "nativeOn": [],
                                          "directives": [],
                                          "scopedSlots": [],
                                          "props": {
                                              "size": "default",
                                              "color": "dark",
                                              "type": "filled",
                                              "action": {
                                                  "mode": false,
                                                  "to": {
                                                      "id": "shop",
                                                      "name": "Shop",
                                                      "path": "Ecom Shop"
                                                  },
                                                  "isUrl": false,
                                                  "url": ""
                                              }
                                          }
                                      },
                                      "key": uuidv4(),
                                      "type": "content",
                                      "contents": []
                                  }
                              ]
                          },
                          {
                              "contents": [],
                              "colAlign": "top",
                              "property": {
                                  "style": {
                                      "paddingTop": "0px",
                                      "paddingRight": "0px",
                                      "paddingBottom": "0px",
                                      "paddingLeft": "0px",
                                      "marginTop": "0px",
                                      "marginRight": "0px",
                                      "marginBottom": "0px",
                                      "marginLeft": "0px",
                                      "backgroundImage": "",
                                      "backgroundPosition": "35% 23%",
                                      "backgroundSize": "cover",
                                      "height": ""
                                  },
                                  "attrs": [],
                                  "class": [],
                                  "on": {},
                                  "nativeOn": [],
                                  "directives": [],
                                  "scopedSlots": [],
                                  "props": [],
                                  "domProps": []
                              },
                              "key": uuidv4(),
                              "type": "col",
                              "tag": "",
                              "entries": [
                                  {
                                      "tag": "img",
                                      "property": {
                                          "class": [],
                                          "attrs": {
                                              "src": "https://server.marketingmaster.io/upload/apis/1/123207_1633623504.jpg"
                                          },
                                          "domProps": [],
                                          "style": {
                                              "paddingTop": "0px",
                                              "paddingRight": "0px",
                                              "paddingBottom": "0px",
                                              "paddingLeft": "0px",
                                              "marginTop": "0px",
                                              "marginRight": "0px",
                                              "marginBottom": "0px",
                                              "marginLeft": "0px"
                                          },
                                          "on": {},
                                          "nativeOn": [],
                                          "directives": [],
                                          "scopedSlots": [],
                                          "props": []
                                      },
                                      "key": uuidv4(),
                                      "type": "content",
                                      "contents": []
                                  },
                                  {
                                      "tag": "p",
                                      "property": {
                                          "class": [],
                                          "attrs": [],
                                          "domProps": {
                                              "innerHTML": i18n.t("Chicken Strip Diner HTML template...")
                                          },
                                          "style": {
                                              "paddingTop": "0px",
                                              "paddingRight": "0px",
                                              "paddingBottom": "0px",
                                              "paddingLeft": "0px",
                                              "marginTop": "0px",
                                              "marginRight": "0px",
                                              "marginBottom": "0px",
                                              "marginLeft": "0px"
                                          },
                                          "on": {},
                                          "nativeOn": [],
                                          "directives": [],
                                          "scopedSlots": [],
                                          "props": {
                                              "align": "left"
                                          }
                                      },
                                      "key": uuidv4(),
                                      "type": "content",
                                      "contents": []
                                  },
                                  {
                                      "tag": "button",
                                      "property": {
                                          "class": [],
                                          "attrs": [],
                                          "domProps": {
                                              "innerHTML": `<p>${i18n.t('SHOP NOW')}</p>`
                                          },
                                          "style": {
                                              "paddingTop": "10px",
                                              "paddingRight": "10px",
                                              "paddingBottom": "10px",
                                              "paddingLeft": "10px",
                                              "marginTop": "0px",
                                              "marginRight": "0px",
                                              "marginBottom": "0px",
                                              "marginLeft": "0px"
                                          },
                                          "on": {},
                                          "nativeOn": [],
                                          "directives": [],
                                          "scopedSlots": [],
                                          "props": {
                                              "size": "default",
                                              "color": "dark",
                                              "type": "filled",
                                              "action": {
                                                  "mode": false,
                                                  "to": {
                                                      "id": "shop",
                                                      "name": "Shop",
                                                      "path": "Ecom Shop"
                                                  },
                                                  "isUrl": false,
                                                  "url": ""
                                              }
                                          }
                                      },
                                      "key": uuidv4(),
                                      "type": "content",
                                      "contents": []
                                  }
                              ]
                          }
                      ]
                  }
              ]
          }
      ]
    }
  },

  guarantees(){
    return {
      "contents": [],
      "property": {
          "style": {
              "paddingTop": "0px",
              "paddingRight": "0px",
              "paddingBottom": "0px",
              "paddingLeft": "0px",
              "marginTop": "0px",
              "marginRight": "0px",
              "marginBottom": "0px",
              "marginLeft": "0px",
              "backgroundImage": "",
              "backgroundPosition": "35% 23%",
              "backgroundSize": "cover"
          },
          "attrs": [],
          "class": [],
          "on": {},
          "nativeOn": [],
          "directives": [],
          "scopedSlots": [],
          "props": [],
          "domProps": []
      },
      "childWidth": {
          "sm": 1,
          "md": 2,
          "lg": 2
      },
      "key": uuidv4(),
      "type": "block",
      "tag": "",
      "label": "Guarantees",
      "entries": [
        {
          "contents": [],
          "colAlign": "top",
          "property": {
              "style": {
                  "paddingTop": "0px",
                  "paddingRight": "0px",
                  "paddingBottom": "0px",
                  "paddingLeft": "0px",
                  "marginTop": "0px",
                  "marginRight": "0px",
                  "marginBottom": "0px",
                  "marginLeft": "0px",
                  "backgroundImage": "",
                  "backgroundPosition": "35% 23%",
                  "backgroundSize": "cover",
                  "height": ""
              },
              "attrs": {},
              "class": [],
              "on": {},
              "nativeOn": {},
              "directives": [],
              "scopedSlots": {},
              "props": {},
              "domProps": {}
          },
          "key": uuidv4(),
          "type": "col",
          "tag": "",
          "entries": [
              {
                  "contents": [],
                  "property": {
                      "style": {
                          "paddingTop": "10px",
                          "paddingRight": "0px",
                          "paddingBottom": "10px",
                          "paddingLeft": "0px",
                          "marginTop": "0px",
                          "marginRight": "10px",
                          "marginBottom": "0px",
                          "marginLeft": "10px",
                          "backgroundImage": "",
                          "backgroundPosition": "35% 23%",
                          "backgroundSize": "cover",
                          "maxWidth": "1050px!important"
                      },
                      "attrs": [],
                      "class": [],
                      "on": {},
                      "nativeOn": [],
                      "directives": [],
                      "scopedSlots": [],
                      "props": {
                          "direction": "row"
                      },
                      "domProps": []
                  },
                  "childWidth": {
                      "sm": 1,
                      "md": 2,
                      "lg": 2
                  },
                  "key": uuidv4(),
                  "type": "row",
                  "tag": "",
                  "entries": [
                      {
                          "contents": [],
                          "colAlign": "top",
                          "property": {
                              "style": {
                                  "paddingTop": "10px",
                                  "paddingRight": "10px",
                                  "paddingBottom": "10px",
                                  "paddingLeft": "10px",
                                  "marginTop": "10px",
                                  "marginRight": "10px",
                                  "marginBottom": "10px",
                                  "marginLeft": "10px",
                                  "backgroundImage": "",
                                  "backgroundPosition": "35% 23%",
                                  "backgroundSize": "cover",
                                  "height": ""
                              },
                              "attrs": [],
                              "class": [],
                              "on": {},
                              "nativeOn": [],
                              "directives": [],
                              "scopedSlots": [],
                              "props": {
                                  "direction": "column"
                              },
                              "domProps": []
                          },
                          "key": uuidv4(),
                          "type": "col",
                          "tag": "",
                          "entries": [
                              {
                                  "contents": [],
                                  "property": {
                                      "style": {
                                          "paddingTop": "0px",
                                          "paddingRight": "0px",
                                          "paddingBottom": "0px",
                                          "paddingLeft": "0px",
                                          "marginTop": "0px",
                                          "marginRight": "0px",
                                          "marginBottom": "0px",
                                          "marginLeft": "0px",
                                          "backgroundImage": "",
                                          "backgroundPosition": "35% 23%",
                                          "backgroundSize": "cover"
                                      },
                                      "attrs": [],
                                      "class": [],
                                      "on": {},
                                      "nativeOn": [],
                                      "directives": [],
                                      "scopedSlots": [],
                                      "props": {
                                          "direction": "row"
                                      },
                                      "domProps": []
                                  },
                                  "childWidth": {
                                      "sm": 1,
                                      "md": 1,
                                      "lg": 2
                                  },
                                  "key": uuidv4(),
                                  "type": "row",
                                  "tag": "",
                                  "entries": [
                                      {
                                          "contents": [],
                                          "colAlign": "middle",
                                          "property": {
                                              "style": {
                                                  "paddingTop": "0px",
                                                  "paddingRight": "0px",
                                                  "paddingBottom": "0px",
                                                  "paddingLeft": "0px",
                                                  "marginTop": "0px",
                                                  "marginRight": "0px",
                                                  "marginBottom": "0px",
                                                  "marginLeft": "0px",
                                                  "backgroundImage": "",
                                                  "backgroundPosition": "35% 23%",
                                                  "backgroundSize": "cover",
                                                  "height": ""
                                              },
                                              "attrs": [],
                                              "class": [],
                                              "on": {},
                                              "nativeOn": [],
                                              "directives": [],
                                              "scopedSlots": [],
                                              "props": {
                                                  "direction": "column"
                                              },
                                              "domProps": []
                                          },
                                          "key": uuidv4(),
                                          "type": "col",
                                          "tag": "",
                                          "entries": [
                                              {
                                                  "tag": "img",
                                                  "property": {
                                                      "class": [],
                                                      "attrs": {
                                                          "src": "https://app.marketingmaster.io/upload/apis/1/164500_1624935959.png"
                                                      },
                                                      "domProps": [],
                                                      "style": {
                                                          "paddingTop": "0px",
                                                          "paddingRight": "0px",
                                                          "paddingBottom": "0px",
                                                          "paddingLeft": "0px",
                                                          "marginTop": "0px",
                                                          "marginRight": "0px",
                                                          "marginBottom": "0px",
                                                          "marginLeft": "0px",
                                                          "maxWidth": "150px!important"
                                                      },
                                                      "on": {},
                                                      "nativeOn": [],
                                                      "directives": [],
                                                      "scopedSlots": [],
                                                      "props": {
                                                          "align": []
                                                      }
                                                  },
                                                  "key": uuidv4(),
                                                  "type": "content",
                                                  "contents": []
                                              }
                                          ]
                                      },
                                      {
                                          "contents": [],
                                          "colAlign": "middle",
                                          "property": {
                                              "style": {
                                                  "paddingTop": "0px",
                                                  "paddingRight": "0px",
                                                  "paddingBottom": "0px",
                                                  "paddingLeft": "0px",
                                                  "marginTop": "0px",
                                                  "marginRight": "0px",
                                                  "marginBottom": "0px",
                                                  "marginLeft": "0px",
                                                  "backgroundImage": "",
                                                  "backgroundPosition": "35% 23%",
                                                  "backgroundSize": "cover",
                                                  "height": "",
                                                  "flex": "2"
                                              },
                                              "attrs": [],
                                              "class": [],
                                              "on": {},
                                              "nativeOn": [],
                                              "directives": [],
                                              "scopedSlots": [],
                                              "props": {
                                                  "direction": "column"
                                              },
                                              "domProps": []
                                          },
                                          "key": uuidv4(),
                                          "type": "col",
                                          "tag": "",
                                          "entries": [
                                              {
                                                  "tag": "p",
                                                  "property": {
                                                      "class": [],
                                                      "attrs": [],
                                                      "domProps": {
                                                          "innerHTML": i18n.t("Complete Satisfaction HTML template...")
                                                      },
                                                      "style": {
                                                          "paddingTop": "10px",
                                                          "paddingRight": "10px",
                                                          "paddingBottom": "10px",
                                                          "paddingLeft": "10px",
                                                          "marginTop": "10px",
                                                          "marginRight": "10px",
                                                          "marginBottom": "10px",
                                                          "marginLeft": "10px"
                                                      },
                                                      "on": {},
                                                      "nativeOn": [],
                                                      "directives": [],
                                                      "scopedSlots": [],
                                                      "props": []
                                                  },
                                                  "key": uuidv4(),
                                                  "type": "content",
                                                  "contents": []
                                              }
                                          ]
                                      }
                                  ]
                              }
                          ]
                      },
                      {
                          "contents": [],
                          "colAlign": "top",
                          "property": {
                              "style": {
                                  "paddingTop": "10px",
                                  "paddingRight": "10px",
                                  "paddingBottom": "10px",
                                  "paddingLeft": "10px",
                                  "marginTop": "10px",
                                  "marginRight": "10px",
                                  "marginBottom": "10px",
                                  "marginLeft": "10px",
                                  "backgroundImage": "",
                                  "backgroundPosition": "35% 23%",
                                  "backgroundSize": "cover",
                                  "height": ""
                              },
                              "attrs": [],
                              "class": [],
                              "on": {},
                              "nativeOn": [],
                              "directives": [],
                              "scopedSlots": [],
                              "props": {
                                  "direction": "column"
                              },
                              "domProps": []
                          },
                          "key": uuidv4(),
                          "type": "col",
                          "tag": "",
                          "entries": [
                              {
                                  "contents": [],
                                  "property": {
                                      "style": {
                                          "paddingTop": "0px",
                                          "paddingRight": "0px",
                                          "paddingBottom": "0px",
                                          "paddingLeft": "0px",
                                          "marginTop": "0px",
                                          "marginRight": "0px",
                                          "marginBottom": "0px",
                                          "marginLeft": "0px",
                                          "backgroundImage": "",
                                          "backgroundPosition": "35% 23%",
                                          "backgroundSize": "cover"
                                      },
                                      "attrs": [],
                                      "class": [],
                                      "on": {},
                                      "nativeOn": [],
                                      "directives": [],
                                      "scopedSlots": [],
                                      "props": {
                                          "direction": "row"
                                      },
                                      "domProps": []
                                  },
                                  "childWidth": {
                                      "sm": 1,
                                      "md": 1,
                                      "lg": 2
                                  },
                                  "key": uuidv4(),
                                  "type": "row",
                                  "tag": "",
                                  "entries": [
                                      {
                                          "contents": [],
                                          "colAlign": "middle",
                                          "property": {
                                              "style": {
                                                  "paddingTop": "0px",
                                                  "paddingRight": "0px",
                                                  "paddingBottom": "0px",
                                                  "paddingLeft": "0px",
                                                  "marginTop": "0px",
                                                  "marginRight": "0px",
                                                  "marginBottom": "0px",
                                                  "marginLeft": "0px",
                                                  "backgroundImage": "",
                                                  "backgroundPosition": "35% 23%",
                                                  "backgroundSize": "cover",
                                                  "height": ""
                                              },
                                              "attrs": [],
                                              "class": [],
                                              "on": {},
                                              "nativeOn": [],
                                              "directives": [],
                                              "scopedSlots": [],
                                              "props": {
                                                  "direction": "column"
                                              },
                                              "domProps": []
                                          },
                                          "key": uuidv4(),
                                          "type": "col",
                                          "tag": "",
                                          "entries": [
                                              {
                                                  "tag": "img",
                                                  "property": {
                                                      "class": [],
                                                      "attrs": {
                                                          "src": "https://app.marketingmaster.io/upload/apis/1/303363_1624932178.png"
                                                      },
                                                      "domProps": [],
                                                      "style": {
                                                          "paddingTop": "0px",
                                                          "paddingRight": "0px",
                                                          "paddingBottom": "0px",
                                                          "paddingLeft": "0px",
                                                          "marginTop": "0px",
                                                          "marginRight": "0px",
                                                          "marginBottom": "0px",
                                                          "marginLeft": "0px",
                                                          "maxWidth": "150px!important"
                                                      },
                                                      "on": {},
                                                      "nativeOn": [],
                                                      "directives": [],
                                                      "scopedSlots": [],
                                                      "props": {
                                                          "align": "center"
                                                      }
                                                  },
                                                  "key": uuidv4(),
                                                  "type": "content",
                                                  "contents": []
                                              }
                                          ]
                                      },
                                      {
                                          "contents": [],
                                          "colAlign": "middle",
                                          "property": {
                                              "style": {
                                                  "paddingTop": "0px",
                                                  "paddingRight": "0px",
                                                  "paddingBottom": "0px",
                                                  "paddingLeft": "0px",
                                                  "marginTop": "0px",
                                                  "marginRight": "0px",
                                                  "marginBottom": "0px",
                                                  "marginLeft": "0px",
                                                  "backgroundImage": "",
                                                  "backgroundPosition": "35% 23%",
                                                  "backgroundSize": "cover",
                                                  "height": "",
                                                  "flex": "2"
                                              },
                                              "attrs": [],
                                              "class": [],
                                              "on": {},
                                              "nativeOn": [],
                                              "directives": [],
                                              "scopedSlots": [],
                                              "props": {
                                                  "direction": "column"
                                              },
                                              "domProps": []
                                          },
                                          "key": uuidv4(),
                                          "type": "col",
                                          "tag": "",
                                          "entries": [
                                              {
                                                  "tag": "p",
                                                  "property": {
                                                      "class": [],
                                                      "attrs": [],
                                                      "domProps": {
                                                          "innerHTML": i18n.t("Refund Guarantee HTML template...")
                                                      },
                                                      "style": {
                                                          "paddingTop": "10px",
                                                          "paddingRight": "10px",
                                                          "paddingBottom": "10px",
                                                          "paddingLeft": "10px",
                                                          "marginTop": "10px",
                                                          "marginRight": "10px",
                                                          "marginBottom": "10px",
                                                          "marginLeft": "10px"
                                                      },
                                                      "on": {},
                                                      "nativeOn": [],
                                                      "directives": [],
                                                      "scopedSlots": [],
                                                      "props": {
                                                          "align": []
                                                      }
                                                  },
                                                  "key": uuidv4(),
                                                  "type": "content",
                                                  "contents": []
                                              }
                                          ]
                                      }
                                  ]
                              }
                          ]
                      }
                  ]
              },
              {
                  "contents": [],
                  "property": {
                      "style": {
                          "paddingTop": "10px",
                          "paddingRight": "0px",
                          "paddingBottom": "10px",
                          "paddingLeft": "0px",
                          "marginTop": "0px",
                          "marginRight": "10px",
                          "marginBottom": "0px",
                          "marginLeft": "10px",
                          "backgroundImage": "",
                          "backgroundPosition": "35% 23%",
                          "backgroundSize": "cover",
                          "maxWidth": "1050px!important"
                      },
                      "attrs": [],
                      "class": [],
                      "on": {},
                      "nativeOn": [],
                      "directives": [],
                      "scopedSlots": [],
                      "props": {
                          "direction": "row"
                      },
                      "domProps": []
                  },
                  "childWidth": {
                      "sm": 1,
                      "md": 2,
                      "lg": 2
                  },
                  "key": uuidv4(),
                  "type": "row",
                  "tag": "",
                  "entries": [
                      {
                          "contents": [],
                          "colAlign": "top",
                          "property": {
                              "style": {
                                  "paddingTop": "10px",
                                  "paddingRight": "10px",
                                  "paddingBottom": "10px",
                                  "paddingLeft": "10px",
                                  "marginTop": "10px",
                                  "marginRight": "10px",
                                  "marginBottom": "10px",
                                  "marginLeft": "10px",
                                  "backgroundImage": "",
                                  "backgroundPosition": "35% 23%",
                                  "backgroundSize": "cover",
                                  "height": ""
                              },
                              "attrs": [],
                              "class": [],
                              "on": {},
                              "nativeOn": [],
                              "directives": [],
                              "scopedSlots": [],
                              "props": {
                                  "direction": "column"
                              },
                              "domProps": []
                          },
                          "key": uuidv4(),
                          "type": "col",
                          "tag": "",
                          "entries": [
                              {
                                  "contents": [],
                                  "property": {
                                      "style": {
                                          "paddingTop": "0px",
                                          "paddingRight": "0px",
                                          "paddingBottom": "0px",
                                          "paddingLeft": "0px",
                                          "marginTop": "0px",
                                          "marginRight": "0px",
                                          "marginBottom": "0px",
                                          "marginLeft": "0px",
                                          "backgroundImage": "",
                                          "backgroundPosition": "35% 23%",
                                          "backgroundSize": "cover"
                                      },
                                      "attrs": [],
                                      "class": [],
                                      "on": {},
                                      "nativeOn": [],
                                      "directives": [],
                                      "scopedSlots": [],
                                      "props": {
                                          "direction": "row"
                                      },
                                      "domProps": []
                                  },
                                  "childWidth": {
                                      "sm": 1,
                                      "md": 1,
                                      "lg": 2
                                  },
                                  "key": uuidv4(),
                                  "type": "row",
                                  "tag": "",
                                  "entries": [
                                      {
                                          "contents": [],
                                          "colAlign": "middle",
                                          "property": {
                                              "style": {
                                                  "paddingTop": "0px",
                                                  "paddingRight": "0px",
                                                  "paddingBottom": "0px",
                                                  "paddingLeft": "0px",
                                                  "marginTop": "0px",
                                                  "marginRight": "0px",
                                                  "marginBottom": "0px",
                                                  "marginLeft": "0px",
                                                  "backgroundImage": "",
                                                  "backgroundPosition": "35% 23%",
                                                  "backgroundSize": "cover",
                                                  "height": ""
                                              },
                                              "attrs": [],
                                              "class": [],
                                              "on": {},
                                              "nativeOn": [],
                                              "directives": [],
                                              "scopedSlots": [],
                                              "props": {
                                                  "direction": "column"
                                              },
                                              "domProps": []
                                          },
                                          "key": uuidv4(),
                                          "type": "col",
                                          "tag": "",
                                          "entries": [
                                              {
                                                  "tag": "img",
                                                  "property": {
                                                      "class": [],
                                                      "attrs": {
                                                          "src": "https://app.marketingmaster.io/upload/apis/1/303363_1624932178.png"
                                                      },
                                                      "domProps": [],
                                                      "style": {
                                                          "paddingTop": "0px",
                                                          "paddingRight": "0px",
                                                          "paddingBottom": "0px",
                                                          "paddingLeft": "0px",
                                                          "marginTop": "0px",
                                                          "marginRight": "0px",
                                                          "marginBottom": "0px",
                                                          "marginLeft": "0px",
                                                          "maxWidth": "150px!important"
                                                      },
                                                      "on": {},
                                                      "nativeOn": [],
                                                      "directives": [],
                                                      "scopedSlots": [],
                                                      "props": {
                                                          "align": "center"
                                                      }
                                                  },
                                                  "key": uuidv4(),
                                                  "type": "content",
                                                  "contents": []
                                              }
                                          ]
                                      },
                                      {
                                          "contents": [],
                                          "colAlign": "middle",
                                          "property": {
                                              "style": {
                                                  "paddingTop": "0px",
                                                  "paddingRight": "0px",
                                                  "paddingBottom": "0px",
                                                  "paddingLeft": "0px",
                                                  "marginTop": "0px",
                                                  "marginRight": "0px",
                                                  "marginBottom": "0px",
                                                  "marginLeft": "0px",
                                                  "backgroundImage": "",
                                                  "backgroundPosition": "35% 23%",
                                                  "backgroundSize": "cover",
                                                  "height": "",
                                                  "flex": "2"
                                              },
                                              "attrs": [],
                                              "class": [],
                                              "on": {},
                                              "nativeOn": [],
                                              "directives": [],
                                              "scopedSlots": [],
                                              "props": {
                                                  "direction": "column"
                                              },
                                              "domProps": []
                                          },
                                          "key": uuidv4(),
                                          "type": "col",
                                          "tag": "",
                                          "entries": [
                                              {
                                                  "tag": "p",
                                                  "property": {
                                                      "class": [],
                                                      "attrs": [],
                                                      "domProps": {
                                                          "innerHTML": i18n.t("100% Secure Checkout HTML template...")
                                                      },
                                                      "style": {
                                                          "paddingTop": "10px",
                                                          "paddingRight": "10px",
                                                          "paddingBottom": "10px",
                                                          "paddingLeft": "10px",
                                                          "marginTop": "10px",
                                                          "marginRight": "10px",
                                                          "marginBottom": "10px",
                                                          "marginLeft": "10px"
                                                      },
                                                      "on": {},
                                                      "nativeOn": [],
                                                      "directives": [],
                                                      "scopedSlots": [],
                                                      "props": []
                                                  },
                                                  "key": uuidv4(),
                                                  "type": "content",
                                                  "contents": []
                                              }
                                          ]
                                      }
                                  ]
                              }
                          ]
                      },
                      {
                          "contents": [],
                          "colAlign": "top",
                          "property": {
                              "style": {
                                  "backgroundImage": "",
                                  "backgroundPosition": "35% 23%",
                                  "backgroundSize": "cover",
                                  "height": "",
                                  "marginTop": "10px",
                                  "marginLeft": "10px",
                                  "marginBottom": "10px",
                                  "marginRight": "10px",
                                  "paddingTop": "10px",
                                  "paddingLeft": "10px",
                                  "paddingBottom": "10px",
                                  "paddingRight": "10px"
                              },
                              "attrs": [],
                              "class": [],
                              "on": {},
                              "nativeOn": [],
                              "directives": [],
                              "scopedSlots": [],
                              "props": {
                                  "direction": "column"
                              },
                              "domProps": []
                          },
                          "key": uuidv4(),
                          "type": "col",
                          "tag": "",
                          "entries": [
                              {
                                  "contents": [],
                                  "property": {
                                      "style": {
                                          "paddingTop": "0px",
                                          "paddingRight": "0px",
                                          "paddingBottom": "0px",
                                          "paddingLeft": "0px",
                                          "marginTop": "0px",
                                          "marginRight": "0px",
                                          "marginBottom": "0px",
                                          "marginLeft": "0px",
                                          "backgroundImage": "",
                                          "backgroundPosition": "35% 23%",
                                          "backgroundSize": "cover"
                                      },
                                      "attrs": [],
                                      "class": [],
                                      "on": {},
                                      "nativeOn": [],
                                      "directives": [],
                                      "scopedSlots": [],
                                      "props": {
                                          "direction": "row"
                                      },
                                      "domProps": []
                                  },
                                  "childWidth": {
                                      "sm": 1,
                                      "md": 1,
                                      "lg": 2
                                  },
                                  "key": uuidv4(),
                                  "type": "row",
                                  "tag": "",
                                  "entries": [
                                      {
                                          "contents": [],
                                          "colAlign": "middle",
                                          "property": {
                                              "style": {
                                                  "paddingTop": "0px",
                                                  "paddingRight": "0px",
                                                  "paddingBottom": "0px",
                                                  "paddingLeft": "0px",
                                                  "marginTop": "0px",
                                                  "marginRight": "0px",
                                                  "marginBottom": "0px",
                                                  "marginLeft": "0px",
                                                  "backgroundImage": "",
                                                  "backgroundPosition": "35% 23%",
                                                  "backgroundSize": "cover",
                                                  "height": ""
                                              },
                                              "attrs": [],
                                              "class": [],
                                              "on": {},
                                              "nativeOn": [],
                                              "directives": [],
                                              "scopedSlots": [],
                                              "props": {
                                                  "direction": "column"
                                              },
                                              "domProps": []
                                          },
                                          "key": uuidv4(),
                                          "type": "col",
                                          "tag": "",
                                          "entries": [
                                              {
                                                  "tag": "img",
                                                  "property": {
                                                      "class": [],
                                                      "attrs": {
                                                          "src": "https://app.marketingmaster.io/upload/apis/1/795270_1624935832.png"
                                                      },
                                                      "domProps": [],
                                                      "style": {
                                                          "paddingTop": "0px",
                                                          "paddingRight": "0px",
                                                          "paddingBottom": "0px",
                                                          "paddingLeft": "0px",
                                                          "marginTop": "0px",
                                                          "marginRight": "0px",
                                                          "marginBottom": "0px",
                                                          "marginLeft": "0px",
                                                          "maxWidth": "150px!important"
                                                      },
                                                      "on": {},
                                                      "nativeOn": [],
                                                      "directives": [],
                                                      "scopedSlots": [],
                                                      "props": {
                                                          "align": []
                                                      }
                                                  },
                                                  "key": uuidv4(),
                                                  "type": "content",
                                                  "contents": []
                                              }
                                          ]
                                      },
                                      {
                                          "contents": [],
                                          "colAlign": "middle",
                                          "property": {
                                              "style": {
                                                  "paddingTop": "0px",
                                                  "paddingRight": "0px",
                                                  "paddingBottom": "0px",
                                                  "paddingLeft": "0px",
                                                  "marginTop": "0px",
                                                  "marginRight": "0px",
                                                  "marginBottom": "0px",
                                                  "marginLeft": "0px",
                                                  "backgroundImage": "",
                                                  "backgroundPosition": "35% 23%",
                                                  "backgroundSize": "cover",
                                                  "height": "",
                                                  "flex": "2"
                                              },
                                              "attrs": [],
                                              "class": [],
                                              "on": {},
                                              "nativeOn": [],
                                              "directives": [],
                                              "scopedSlots": [],
                                              "props": {
                                                  "direction": "column"
                                              },
                                              "domProps": []
                                          },
                                          "key": uuidv4(),
                                          "type": "col",
                                          "tag": "",
                                          "entries": [
                                              {
                                                  "tag": "p",
                                                  "property": {
                                                      "class": [],
                                                      "attrs": [],
                                                      "domProps": {
                                                          "innerHTML": i18n.t("Free Shipping HTML template...")
                                                      },
                                                      "style": {
                                                          "paddingTop": "10px",
                                                          "paddingRight": "10px",
                                                          "paddingBottom": "10px",
                                                          "paddingLeft": "10px",
                                                          "marginTop": "10px",
                                                          "marginRight": "10px",
                                                          "marginBottom": "10px",
                                                          "marginLeft": "10px"
                                                      },
                                                      "on": {},
                                                      "nativeOn": [],
                                                      "directives": [],
                                                      "scopedSlots": [],
                                                      "props": []
                                                  },
                                                  "key": uuidv4(),
                                                  "type": "content",
                                                  "contents": []
                                              }
                                          ]
                                      }
                                  ]
                              }
                          ]
                      }
                  ]
              }
          ]
        }
      ]
    }
  },

}
