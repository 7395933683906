import { debounce } from 'lodash';
import initMMIOaxios from "@/utils/axios"
const leading = true;
const trailing = false;
const gmbActions = {

    getGmbBrands: debounce(async function ({ commit, state, dispatch }, options = {}) {
        const axios = await initMMIOaxios()
        const { data } = await axios.get("/apis_gmb/get_brands")
        commit("UPDATE_GMB_BRANDS", data);
        return data;
    }, 1000, { leading, trailing }),

    getGmbAgents: debounce(async function ({ commit, state, dispatch }, options = {}) {
        const axios = await initMMIOaxios()
        const { data } = await axios.get("/apis_gmb/get_agents")
        commit("UPDATE_GMB_AGENTS", data);
        dispatch("executeUpdateActualUsageLog", { moduleName: "GMB_AGENTS", data });
        return data;
    }, 1000, { leading, trailing }),

	getGmbFlows: debounce(async function ({ commit, state, dispatch }, options = {}) {
		__console.log("Get user Flows...");
		const axios = await initMMIOaxios();
		options.small = true;
		options.agent_id = state.ActiveAgent.id;
		if (!options.agent_id) { return; }
		let enpoint = "/apis_gmb/get_flows";
		if (_isObj(options)) {
			const params = new URLSearchParams(options).toString();
			enpoint = enpoint + "?" + params;
		}
		const { data } = await axios.get(enpoint);
        commit("UPDATE_GMB_FLOWS", data);
        dispatch("executeUpdateActualUsageLog", { moduleName: "NUMBER_OF_GMB_FLOWS_USER_CAN_CREATE", data });
	}, 1000, { leading, trailing }),

	async getGmbFlowsSingle(dummy, options = {}) {
		if (!options?.id) { return {}; }
		const axios = await initMMIOaxios();
		const opt = {
			id: options.id,
			postbacks_only: !!options?.postbacks_only ? 1 : 0,
		}
		const params = new URLSearchParams(opt).toString();
		const enpoint = "/apis_gmb/get_flows?" + params;
		const { data } = await axios.get(enpoint);
		return Array.isArray(data) ? data[0] : {};
	},

    async getRepresentatives({ commit }) {
        const axios = await initMMIOaxios()
        const { data } = await axios.get("/apis_gmb/get_representatives")
        commit("UPDATE_GMB_REPRESENTATIVES", data);
        return data;
    },

    async getLocations({ commit, state }) {
        const agent_id = state.ActiveAgent?.agent_id
        if (!agent_id) { return }
        const axios = await initMMIOaxios()
        const { data } = await axios.get("/apis_gmb/get_locations?agent_id=" + agent_id)
        commit("UPDATE_GMB_LOCATIONS", data);
        return data;
    },

}
export default gmbActions;