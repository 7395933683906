import initMMIOaxios from "@/utils/axios"

const emailActions = {
	// /////////////////////////////////////////////
	// Email Flow Builder
	// /////////////////////////////////////////////	

	async getEmailSequencesAction({ commit }) {
		var payload = await ext_get_user_sequences()
		commit('EMAIL_SEQUENCES', payload)
	},

	async getEmailReportsAction({ commit }) {
		var payload = await ext_get_email_reports()
		commit('EMAIL_REPORTS', payload)
	},

	async getCustomFormsAction({ commit, dispatch }) {
		var payload = await get_integration_forms()
		commit('CUSTOM_FORMS', payload)
		return await dispatch("executeUpdateActualUsageLog", { moduleName: "CUSTOM_FORMS_MESSENGER_WEBVIEW_EMAIL_CUSTOM_FORMS" })
	},

	async getEmailFlowBuilderAPIKey({ commit }) {
		var payload = await get_api_key()
		commit('EMAIL_FLOW_BUILDER_API_KEY', payload)
	},

	async writeEmailFlowBuilderAPIKey({ commit }) {
		var payload = await write_api_key()
		commit('EMAIL_FLOW_BUILDER_API_KEY', payload)
	},

	async getContactListAction({ commit }) {
		var payload = await get_contact_list()
		commit('CONTACT_LIST', payload)
	},

	async getContactBooksAction({ commit }) {
		var payload = await get_contact_books()
		commit('CONTACT_BOOKS', payload)
	},

	async getEmailBroadcasts({ commit }, query = {}) {
		const axios = await initMMIOaxios()
		const { data: payload } = await axios.post("/apis_email/get_email_broadcasts", query);
		commit('EMAIL_BROADCASTS', payload)
	},

	async getEmailTemplates({ commit }) {
		const payload = await get_email_templates()
		commit("EMAIL_TEMPLATE_UPDATE", payload)
	},

	async getOptinTemplates({ commit }) {
		const axios = await initMMIOaxios()
		const { data: payload } = await axios.get("/apis_email/get_optin_templates");
		commit("OPTIN_TEMPLATE_UPDATE", payload);
	},

	async getEmailTemplateData({ commit }, id) {
		const axios = await initMMIOaxios()
		const { data } = await axios.get(`/apis_email/get_email_template_data/${id}`);
		return data;
	},

}

export default emailActions

//*************** HELPER FUNCTIONS ***************//

function ___(val) {
	return JSON.parse(JSON.stringify(val))
}

async function ext_get_user_sequences(type_raw) {
    const axios = await initMMIOaxios()
    const { data: payload } = await axios.get("/apis_email/ext_get_user_sequences");
    return payload
}

async function ext_get_email_reports() {
    const axios = await initMMIOaxios()
    const { data: payload } = await axios.get("/apis_email/ext_get_email_reports");
    return payload
}

async function get_integration_forms() {
    const axios = await initMMIOaxios()
    const { data: payload } = await axios.get("/apis_email/integration_forms");
    return payload
}

async function get_api_key() {
    const axios = await initMMIOaxios()
    const { data: payload } = await axios.get("/apis_email/get_api_key");
    return payload
}

async function write_api_key() {
    const axios = await initMMIOaxios()
    const { data: payload } = await axios.post("/apis_email/api_key_writer");
    return payload
}

async function get_contact_books() {
    const axios = await initMMIOaxios()
    const { data: payload } = await axios.get("/apis_email/ext_populate_user_contact_book");
    return payload
}

async function get_email_templates() {
    const axios = await initMMIOaxios()
    const { data: payload } = await axios.get("/apis_email/get_email_templates?minimal=1");
    return payload
}