import Swal from "sweetalert2";
import {i18n} from "@/i18n";
import store from "@/store"
import {MainLayoutRoutes,powerSharingRoutes} from '@/routes/index.js';
import initMMIOaxios from "@/utils/axios"

export function compress(file, width, height) {
	return new Promise((resolve, reject) => {
		const fileName = file.name;
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = (event) => {
			const img = new Image();
			img.src = event.target.result;
			img.onload = () => {
				const elem = document.createElement("canvas");
				elem.width = width;
				elem.height = height;
				const ctx = elem.getContext("2d");
				// img.width and img.height will contain the original dimensions
				ctx.drawImage(img, 0, 0, width, height);
				ctx.canvas.toBlob(
					(blob) => {
						const Resizedfile = new File([blob], fileName, {
							type: "image/jpeg",
							lastModified: Date.now(),
						});
						reader.readAsDataURL(Resizedfile);
						reader.onload = (e) => {
							resolve(e.target.result);
						};
					},
					"image/jpeg",
					1
				);
			}
			reader.onerror = (error) => __console.log(error);
		};
	});
}

export function urltoFile(url, filename, mimeType) {
	return fetch(url)
		.then(function (res) {
			return res.arrayBuffer();
		})
		.then(function (buf) {
			return new File([buf], filename, { type: mimeType });
		});
}

export async function compressImage(img_url, width, height, mimeType) {
	var imgFile = await urltoFile(img_url, "profile.jpg", mimeType);
	var imgFileResized = await compress(imgFile, width, height);
	return imgFileResized
}

export function convertImgDimension(targetW, width, height) {
	var ratio = targetW / width;
	var targetH = height * ratio;
	return { w: targetW, h: targetH };
}

export function handleErrors(response) {
	if (!response.ok) {
		return false;
	}
	return response.arrayBuffer();
}

export function shopUrl(theStore) {
	try {
		const ECOM_DOMAIN = MMIO_DEFAULTS?.ECOM_DOMAIN ? MMIO_DEFAULTS?.ECOM_DOMAIN : "marketingmasterio.net";
		return `https://${theStore.store_alias}.${ECOM_DOMAIN}/shop`;
	} catch (e) {}
	return (
		window.location.origin + `/ecommerce/${theStore.store_unique_id}/shop`
	);
}

export function productUrl(theStore, item) {
	try {
		const ECOM_DOMAIN = MMIO_DEFAULTS?.ECOM_DOMAIN ? MMIO_DEFAULTS?.ECOM_DOMAIN : "marketingmasterio.net";
		return `https://${theStore.store_alias}.${ECOM_DOMAIN}/product/${item.id}`;
	} catch (e) {}
	return (
		window.location.origin +
		"/ecommerce/product?store_id=" +
		theStore.store_unique_id +
		"&product_id=" +
		item.id
	);
}

export function categoryUrl(theStore, item) {
	try {
		const ECOM_DOMAIN = MMIO_DEFAULTS?.ECOM_DOMAIN ? MMIO_DEFAULTS?.ECOM_DOMAIN : "marketingmasterio.net";
		return `https://${theStore.store_alias}.${ECOM_DOMAIN}/category/${item.id}`;
	} catch (e) {}
	return (
		window.location.origin +
		"/ecommerce/shop?store_id=" +
		theStore.store_unique_id +
		"&category_id=" +
		item.id
	);
}

export function insertWord(sentence, word, index) {
	if(!sentence) return word;
	const pair = Array.from(sentence);
	pair.splice(index, 0, word);
	return pair.join("");
}

export function userHaveAccess(identifier, action='read' ) {
	if ( !store.state.AppActiveUser ) { return false; }
	if (store.state.AppActiveUser.user_id == 1) { return true; }
	const controllableRoutes = [
		...(Array.isArray(MainLayoutRoutes?.children) ? MainLayoutRoutes.children : []),
		...(Array.isArray(powerSharingRoutes?.children) ? powerSharingRoutes.children : []),
	];
	if (Array.isArray(store.state.AppActiveUser.access_level)) {
		//Get external routes
		const externalRoutes = store.getters.externalRoutes;
		const externalRoute = externalRoutes.find(route => route.value == identifier);
		//Get internal routes
		const internalRoute = controllableRoutes.find(menuItem => menuItem.name == identifier || menuItem.path == identifier || menuItem.slug == identifier );
		const route = externalRoute || internalRoute;
		const accessLevel = store.state.AppActiveUser.access_level.find( access => access.value == route?.slug || access.value == identifier );
		if (!accessLevel) { return false }
		return !!accessLevel?.permissions?.[action];
	}
	return false;
}

export function getCustomVarsToMake() {
	var customVarsToMake = {};
	Object.values(editor.nodes).forEach(node => {
		try{

			/*For Actions node*/
			if ( _isObj(node?.data?.actions) ) {
				for (const key of Object.keys(node.data.actions)) {
					if (node.data.actions[key].action_id.startsWith('cv_')) {
						const varKey = node?.data?.actions?.[key]?.action_id.replace("cv_", "");
						customVarsToMake[varKey] = true;
					}
				}
			}

			/*For Conditions node*/
			if ( _isObj(node?.data?.conditions) ) {
				for (const key of Object.keys(node.data.conditions)) {
					if (node.data.conditions[key].rule_id.startsWith('cv_')) {
						const varKey = node.data.conditions[key].rule_id.replace("cv_", "");
						customVarsToMake[varKey] = true;
					}
				}
			}

			/*For Function and UserInput node*/
			if (node?.data?.variable_key) {
				customVarsToMake[node.data.variable_key] = true;
			}

			/*For HTTPS and BotSheetsApi*/
			if ( Array.isArray(node.data?.map) ) {
				for (const item of node.data.map) {
					customVarsToMake[item.value] = true;
				}
			}

			/*All instances of {{custom_vars}}*/
			const matchIterator = JSON.stringify(node.data).matchAll(/{{[a-z0-9|\-_ :]+}}/g);
			for( const match of matchIterator) {
				const variableRaw = match[0].match(/[a-z0-9|\-_ ]+/)[0];
				const varArr = variableRaw.split("|");
				const variableKey = varArr[0];
				if (!!variableKey.trim()) {
					customVarsToMake[variableKey.trim()] = true;
				}
			}

			/*For date picker*/
			if ( _isObj(node?.data?.Button) ) {
				for (const key of Object.keys(node.data.Button)) {
					if ( _isObj(node.data.Button[key]?.date_picker?.assignments) ) {
						const assignments = node.data.Button[key].date_picker.assignments;
						for (const key of Object.keys(assignments)) {
							const variableKey = assignments[key];
							if (!!variableKey.trim()) {
								customVarsToMake[variableKey.trim()] = true;
							}
						}
					}
				}
			}

		}catch(e){}
	});
	return Object.keys(customVarsToMake)
}

export async function verify(from_email,isUserCredentials=false) {
	const axios = await initMMIOaxios();
	const { data } = await axios.post(
		"/apis_email/ext_is_verified_from_email",
		{from_email, is_user_credentials: isUserCredentials}
	);
	if ( !!data?.error ) {
		Swal.fire(i18n.t("Something went wrong!"), i18n.t(data?.error), "error");
		return false;
	}
	return data;
}

export async function send_verification(from_email,isUserCredentials=false) {
	_wait("📨 Sending verification email...");
	const axios = await initMMIOaxios();
	const { data } = await axios.post(
		"/apis_email/ext_send_verification_email",
		{from_email, is_user_credentials: isUserCredentials}
	);
	if ( !!data?.error ) {
		Swal.fire(i18n.t("Something went wrong!"), i18n.t(data?.error), "error");
	} else if (data?.status === true) {
		Swal.fire(i18n.t("Verification Email Sent!"), i18n.t("Please check your email inbox for the verification link..."), "success");
	} else {
		Swal.fire(i18n.t("Ooopssss!"), i18n.t("Something went wrong. Please try again or create a support ticket"), "error");
	}
	return data;
}

export function getStoreLogo(store) {
	if ( (String(store?.store_logo) ).includes("http") ) {
		return store.store_logo;
	}
	return store?.store_logo
		? AppServerUrl + "upload/ecommerce/" + store.store_logo
		: "";
}

export function defaultEcomConfig(store_id = null) {
	return {
		store_id,
		limit: 200,
		offset: 0,
		category: null,
		price_max: null,
		price_min: null,
		orderBy: "id",
		order_type: "desc",
		q: null,
		fields: ["id", "product_name", "product_description", "thumbnail", "display_price"]
	}
}

export const generateEcomSourceObj = (store_id = null) => {

	let theStore = null;
	if ( store_id ) {
		theStore = getStore(store_id);
	} else if ( store.state.StoresAll.length ) {
		theStore = store.state.StoresAll[0]
	}

	let productUrlStr = "";
	if ( _isObj(theStore) ) {
		store_id = theStore.id;
		productUrlStr = productUrl(theStore, {id: "{{id}}"})
	}
	let map = [
		{
			value: "Title",
			key: "{{product_name}}"
		},
		{
			value: "Text",
			key: "{{display_price}} - {{product_description}}"
		},
		{
			value: "Image",
			key: "https://app.marketingmaster.io/upload/ecommerce/{{thumbnail}}"
		},
		{
			value: "Image Redirect",
			key: productUrlStr
		}
	]
	if ( productUrlStr ) {
		map.push({
			value: "Button",
			key: {
				buttontype: "weburl",
				url: productUrlStr,
				text: i18n.t("View Product")
			}
		})
	}
	return {
		type: "POST",
		url: ApiServerUrl + "apis_ecom/get_products_filtered",
		headers: [
			{
				key: "Content-Type",
				value: "application/json"
			}
		],
		params: [],
		body: "",
		map,
		subscribe_id: "",
		ecom: defaultEcomConfig(store_id),
		filters: {},
		displayType: "products"
	}
}

export const generateEcomSourceCategoryObj = (store_id = null) => {
	let theStore = null;
	if ( store_id ) {
		theStore = getStore(store_id);
	} else if ( store.state.StoresAll.length ) {
		theStore = store.state.StoresAll[0]
	}
	let categoryUrlStr = "";
	if ( _isObj(theStore) ) {
		store_id = theStore.id;
		categoryUrlStr = categoryUrl(theStore, {id: "{{id}}"})
	}
	let map = [
		{
			value: "Title",
			key: "{{category_name}}"
		},
		{
			value: "Text",
			key: ""
		},
		{
			value: "Image",
			key: "https://app.marketingmaster.io/upload/ecommerce/{{category_image}}"
		},
		{
			value: "Image Redirect",
			key: categoryUrlStr
		}
	]
	if ( categoryUrlStr ) {
		map.push({
			value: "Button",
			key: {
				buttontype: "weburl",
				url: categoryUrlStr,
				text: i18n.t("View Category")
			}
		})
	}
	return {
		type: "POST",
		url: ApiServerUrl + "apis_ecom/category_main",
		headers: [
			{
				key: "Content-Type",
				value: "application/json"
			}
		],
		params: [
			{
				key: "action",
				value: "get"
			}
		],
		body: "",
		map,
		subscribe_id: "",
		ecom: {store_id},
		filters: {},
		displayType: "categories"
	}
}

export const generateBotsheetSourceObj = (is_dynamic_carousel = false) => {
	return {
		type: "POST",
		url: ApiServerUrl + "apis_integrations/bot_sheets_api/read",
		headers: [
			{
				key: "Content-Type",
				value: "application/json"
			}
		],
		params: [],
		body: "",
		map: [],
		subscribe_id: "",
		botsheetsapi: {
			sheet_id: "",
			sheet_name: "",
			operation: "",
			value: "",
			range: "",
			index: "",
			column: "",
			type: "full",
			is_dynamic_carousel,
		}
	}
}

export const generateHttpSourceObj = () => {
	return {
        type: "GET",
        url: "",
        headers: [],
        params: [],
        body: "",
        map: []        
    }
}

export const makeid = (length=20) => {
    let result           = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function generateButtonObj(platform='messenger') {
	const dateFormattingOptions = generateFormattingOptions();
	const map = {
		telegram: {
			flow: "",
			buttontype: "next_step",
			payload: "",
			ecom: "",
			webview: "",
			url: "",
			call: "",
			text: i18n.t("My Button Title")
		},
		messenger: {
			flow: "",
			buttontype: "next_step",
			payload: "",
			ecom: "",
			webview: "",
			url: "",
			call: "",
			text: i18n.t("My Button Title")
		},
		instagram: {
			flow: "",
			buttontype: "next_step",
			payload: "",
			ecom: "",
			webview: "",
			url: "",
			call: "",
			text: i18n.t("My Button Title")
		},
		gmb: {
			flow: "",
			buttontype: "next_step",
			payload: "",
			ecom: "",
			webview: "",
			url: "",
			call: "",
			text: i18n.t("My Button Title"),
		},
		whatsapp: {
			flow: "",
			payload: "",
			buttontype: "next_step",
			text: i18n.t("My Button Title")
		},
		whatsapp_row: {
			flow: "",
			buttontype: "",
			payload: "",
			description: "",
			title: "",
		}
	}

	let ButtonObj = map?.[platform] || map.messenger;

	let common = {
		booking: "",
		date_picker: {
			type: "single",
			included: "date",
			date_formatting: {
				index: "0",
				value: dateFormattingOptions[0]
			},
			assignments: {
				'Start Date': ""
			}
		}
	}
	
	let persistentPayload = {
		enble_persistent_payload: false,
		persistent_payload: {
			variable: "",
			value: ""
		}
	}

	if ( !platform.includes("whatsapp") ) {
		ButtonObj = {...ButtonObj, ...common}
	}

	ButtonObj = {...ButtonObj, ...persistentPayload}

	return ButtonObj

}

export function generateQuickReplyObj() {
	return {
		flow: "",
		buttontype: "next_step",
		select_postback: "",
		text: i18n.t("My Button Title"),
		payload: "",
		enble_persistent_payload: false,
		persistent_payload: {
			variable: "",
			value: ""
		}
	}
}

export const generateFormattingOptions = (included='date') => {

	const weekday = ['long','short','narrow'];
	const year = ['numeric','2-digit'];
	const month = [...weekday, ...year];
	const day = year;

	let options = [];
	if ( included == 'date' ) {
		options = cartesian(year,month,weekday,day)
		.map( option => {
			return {
				year: option[0],
				month: option[1],
				weekday: option[2],
				day: option[3]
			}
		});
	} else if ( included == 'date-and-time' ) {
		options = cartesian(year,month,weekday,day)
		.map( option => {
			return {
				year: option[0],
				month: option[1],
				weekday: option[2],
				day: option[3],
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit'
			}
		});
	}

	return options;

}

function cartesian(...args) {
	var r = [], max = args.length-1;
	function helper(arr, i) {
		for (var j=0, l=args[i].length; j<l; j++) {
			var a = arr.slice(0); // clone arr
			a.push(args[i][j]);
			if (i==max)
				r.push(a);
			else
				helper(a, i+1);
		}
	}
	helper([], 0);
	return r;
}

const getStore = (store_id) => {
	if ( !store_id ) return {}
	return store.state.StoresAll.find( store => store.id === store_id) || {}
}

export const nativeDateFormat = (date) => {
	if ( !(date instanceof Date) ) return "";
	return date.toLocaleString("en-US", {month:'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
}