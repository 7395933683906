import { debounce } from 'lodash'
import { convertModuleNameToId } from '@/utils/modulesMap.js'
import { MainLayoutRoutes, powerSharingRoutes } from '@/routes/index.js'
import { userHaveAccess } from '@/common/helpers/helpers.js'
import initMMIOaxios from '@/utils/axios'

const controllableRoutes = [
    ...(Array.isArray(MainLayoutRoutes?.children) ? MainLayoutRoutes.children : []),
    ...(Array.isArray(powerSharingRoutes?.children) ? powerSharingRoutes.children : [])
]

const leading = true
const trailing = false
const userAccountActions = {
    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////

    updateUserInfo({ commit }, payload) {
        commit('UPDATE_USER_INFO', payload)
    },

    getUserGroups: debounce(
        async function ({ commit, dispatch }) {
            var payload = await get_user_groups()
            commit('UPDATE_USER_GROUPS', payload)
            commit('UPDATE_ACTIVE_USER_GROUPS')
            dispatch('executeUpdateActualUsageLog', { moduleName: 'FACEBOOK_GROUPS' })
        },
        1000,
        { leading, trailing }
    ),

    getUserAccounts: debounce(
        async function ({ commit, dispatch }) {
            var payload = await get_user_accounts()
            commit('UPDATE_USER_ACCOUNTS', payload)
            dispatch('executeUpdateActualUsageLog', { moduleName: 'FACEBOOK_ACCOUNTS' })
            return payload
        },
        5000,
        { leading, trailing }
    ),

    isOnUsageLimit({ getters }, moduleName) {
        return getters.isOnUsageLimit(moduleName)
    },

    async checkModuleUsage({ state, getters, dispatch }, payload) {
        //await dispatch("getUsageLog");
        var moduleName = payload
        var usageData = []
        if (_isObj(payload)) {
            var { moduleName, usageData, total } = payload
        }
        const moduleId = convertModuleNameToId(moduleName)
        var obj = {}
        var userType = state.AppActiveUser.user_type
        if (userType == 'Admin') {
            return { status: true, result: { limit: 1000, count: 0 } }
        }
        var packageInfo = state.AppActiveUser.package_info
        var moduleIds = packageInfo.module_ids.split(',')
        if (!moduleIds.includes(String(moduleId))) {
            obj.status = false
            obj.result = { limit: 0, count: 0 }
            obj.notice = `Your ${packageInfo.package_name} package does not have access to this feature. Please upgrade your account.`
            return obj
        }
        var result = getters.isOnUsageLimit(moduleName, usageData, total)
        if (result.status) {
            obj.status = false
            obj.result = result
            obj.notice = `Your ${packageInfo.package_name} package have reached the limit for this feature. You have already used-up ${result.count}/${result.limit}. Please upgrade your account to do more.`
            return obj
        }
        return { status: true, result }
    },

    getPackages: debounce(
        async function ({ commit }) {
            var payload = await get_packages()
            commit('PACKAGES', payload)
        },
        1000,
        { leading, trailing }
    ),

    getModules: debounce(
        async function ({ commit }) {
            var payload = await get_modules()
            commit('MODULES', payload)
        },
        1000,
        { leading, trailing }
    ),

    getUsageLog: debounce(
        async function ({ commit }) {
            var payload = await get_usage_log()
            commit('USAGE_LOG', payload)
        },
        1000,
        { leading, trailing }
    ),

    async getTransactionHistory({ commit }) {
        var payload = await get_transaction_history()
        commit('TRANSACTION_HISTORY', payload.data)
    },
    getUserAccount: debounce(
        async function ({ commit, state }) {
            try {
                const axios = await initMMIOaxios()
                const { data: payload } = await axios.get('/apis_user/get_user_account')
                if (payload.status > 0) {
                    var userdata = payload.session
                    userdata.uid = userdata.user_id
                    userdata.displayName = userdata.username
                    userdata.about = ''
                    userdata.photoURL = userdata.brand_logo
                    userdata.status = 'online'
                    userdata.userRole = userdata.user_type
                    if (!state.AppActiveUser.active_fb_account) {
                        userdata.active_fb_account = userdata.facebook_rx_fb_user_info
                    } else {
                        userdata.active_fb_account = JSON.parse(
                            JSON.stringify(state.AppActiveUser.active_fb_account)
                        )
                    }
                    commit('UPDATE_USER_INFO', userdata)
                    return userdata
                }
            } catch (e) {}
            commit('UPDATE_USER_INFO', {})
            return {}
        },
        1000,
        { leading, trailing }
    ),
    async getUserTickets({ commit, state }) {
        var data = { user_id: state.AppActiveUser.user_id }
        var payload = await get_user_tickets(data)
        commit('UPDATE_USER_TICKETS', payload)
    },

    async updateActualUsageLog({ state }, { moduleName, usage }) {
        try {
            const module_id = convertModuleNameToId(moduleName)
            const payload = {
                user_id: state.AppActiveUser.user_id,
                module_id,
                type:
                    state.Modules.find((item) => item.id == module_id).extra_text == 'month'
                        ? 'monthly'
                        : 'bulk',
                usage: usage
            }
            const hashKey = hashObject(payload)
            if (typeof window[hashKey] !== 'undefined') {
                __console.log('Cancelled ~> ', payload)
                return {}
            }
            const axios = await initMMIOaxios()
            const { data } = await axios.post('/apis_user/update_actual_usage_log', payload)
            registerTransientWindowData(hashKey, true)
            return data
        } catch (e) {
            __console.log('updateActualUsageLog error', e)
        }
    },

    executeUpdateActualUsageLog({ dispatch, getters }, payload) {
        const { moduleName } = payload
        const module_id = convertModuleNameToId(moduleName)
        var _func = function () {
            let usage = getters.getLiveModuleUsageCount(payload)
            if (usage !== false) {
                dispatch('updateActualUsageLog', { moduleName, usage })
            }
        }

        eval(`
        if(window.timer_${module_id}){ clearTimeout(window.timer_${module_id}) }
        window.timer_${module_id} = setTimeout( _func, 2000 );`)
    },

    filterSubUserPermissions({ state, commit, getters }, navMenuItems) {
        const AppActiveUser = ___(state.AppActiveUser)
        const data = ___(navMenuItems)
            .map((item) => {
                if (Array.isArray(item.items)) {
                    if (item.items.length) {
                        item.items = getters.filterNavItems(item.items)
                    }
                }
                return item
            })
            .filter((item) => {
                let hasAccess = userHaveAccess(item.slug)

                //If sidebar nav menu has items
                if (Array.isArray(item.items) && item.items.length > 0) {
                    hasAccess = item.items.some((menuItem) => userHaveAccess(menuItem.slug))
                }

                if (item.header == 'Admin' && AppActiveUser.referred_by != 1) {
                    hasAccess = false
                }

                //if no access level is set, then allow access
                //if user have access - return the menu item
                if (hasAccess || !Array.isArray(AppActiveUser.access_level)) {
                    return item
                }
            })
        commit('UPDATE_MENULIST', data)
    },

    userActionPermission({ state }, { permission, path }) {
        return userHaveAccess(path, permission)
    },

    getUserNotifications: debounce(
        async function ({ commit }) {
            const axios = await initMMIOaxios()
            axios
                .get('/apis_notification/get_notifications')
                .then((resp) => {
                    __console.log(resp.data)
                    commit('UPDATE_USER_NOTIFICATIONS', resp.data)
                })
                .catch((e) => {
                    __console.log(e)
                })
        },
        1000,
        { leading, trailing }
    ),

    fbq: debounce(
        async function ({ state }, events) {
            var data = []
            if (Array.isArray(events)) {
                data = events
            } else {
                data.push(events)
            }
            data = data.map((item) => {
                item.event_source_url = window.location.href
                if (getCookies._fbc) {
                    item.fbc = getCookies._fbc
                }
                if (getCookies._fbp) {
                    item.fbp = getCookies._fbp
                }
                if (localStorage.getItem(`_fb_event_id_${item.event_name}`)) {
                    item.event_id = localStorage.getItem(`_fb_event_id_${item.event_name}`)
                }
                return item
            })
            const axios = await initMMIOaxios()
            axios
                .post('/apis_user/fb_capi_process_event_api', {
                    events: data
                })
                .then((resp) => {
                    __console.log(resp.data)
                })
                .catch((error) => __console.log(error))
        },
        2000,
        { leading, trailing }
    ),

    async getUserPermissions({ state }, payload) {
        const axios = await initMMIOaxios()
        const { data } = await axios.post('/apis_livestream/get_user_permissions', payload)
        return data
    },

    getMessengerNoticeRequest:
        debounce(function ({ state, dispatch }) {
            let shouldSend = true
            const lastSent = localStorage.getItem('notificationRequestSent')
            if (lastSent) {
                const now = moment().tz('Asia/Manila').unix()
                const _3DaysAgo = now - 3600 * 24 * 3
                if (Number(_3DaysAgo) > now - Number(lastSent)) {
                    return __console.log(`not eligible for sending | not yet expired.`)
                }
            }

            if (!state.AppActiveUser.subscriber_id && shouldSend) {
                var sentTime = moment().tz('Asia/Manila').unix()
                localStorage.setItem('notificationRequestSent', sentTime)
                Swal.fire({
                    title: `Not Receiveing Important Notifications❓`,
                    html: `
            <div class="flex flex-col items-start justify-center">
              <p class="mb-5 text-left">Hello ${state.AppActiveUser.displayName}👋</p>
              <p class="mb-5 text-left">We noticed that you are not subscribed to your Account's important notifications in Messenger.</p>
              <p class="mb-5 text-left">It is highly recommended that you receive it so that you can take action whenever there's a need for it, such as refreshing expired Page access token, new Ecommerce orders, etc. etc.</p>
              <p class="mb-5 text-left">Please click the "Subscribe Now" button below.</p>
            </div>
            `,
                    icon: 'info',
                    showCancelButton: true,
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    confirmButtonText: '👍 SUBSCRIBE NOW'
                }).then((value) => {
                    if (value.value) {
                        const basePageID = this.$MMIO_DEFAULTS?.BASE_PAGE_ID
                            ? this.$MMIO_DEFAULTS?.BASE_PAGE_ID
                            : '115841346479762'
                        const confirmationMessage = this.$MMIO_DEFAULTS?.CONFIRMATION_MESSAGE
                            ? this.$MMIO_DEFAULTS?.CONFIRMATION_MESSAGE
                            : 'c7d29da0-002a-41c5-84ca-b1d92b736edc'
                        const user_id = !!this.AppActiveUser?.proxy_id
                            ? this.AppActiveUser?.proxy_id
                            : this.AppActiveUser.user_id
                        window
                            .open(
                                `http://m.me/${basePageID}?ref=${confirmationMessage}__user__${user_id}`,
                                '_blank'
                            )
                            .focus()
                        setTimeout(() => {
                            dispatch('getUserAccount').then(() => {
                                if (state.AppActiveUser.subscriber_id) {
                                    Swal.fire('🎉Success!🎉', 'You are confirmed!👍', 'success')
                                }
                            })
                        }, 5000)
                    }
                })
            }
        }, 15000) || function () {},

    async getUserLocation() {
        try {
            const axios = await initMMIOaxios()
            const { data } = await axios.get('/apis_user/get_user_location')
            return data
        } catch (e) {}
    },

    getUserIntegrations: debounce(
        async function ({ commit, dispatch }) {
            const axios = await initMMIOaxios()
            const { data } = await axios.get('/apis_integrations/get_user_integrations')
            commit('UPDATE_USER_INTEGRATIONS', data)
            dispatch('getUserIntegrationsEmailLists')
        },
        1000,
        { leading, trailing }
    ),

    async getUserIntegrationsEmailLists({ commit, getters }) {
        const axios = await initMMIOaxios()
        const requests = getters.getUserChatbotIntegrations
        axios
            .post('/apis_integrations/get_list', { requests })
            .then((resp) => {
                __console.log('getUserIntegrationsEmailLists', resp.data)
                commit('UPDATE_CHATBOTINT_EMAILLISTS', resp.data)
            })
            .catch((e) => __console.log(e))
    },

    async getExtraFeeManualPay({ commit }) {
        if (typeof window.moment === 'undefined') {
            return
        }
        const axios = await initMMIOaxios()
        axios
            .get('/apis_user/get_manual_extra_fee_payables')
            .then((resp) => {
                commit('UPDATE_MANUAL_PAY_EXTRA_FEES', resp.data)
            })
            .catch((e) => __console.log(e))
    },

    async getSubUsers({ commit, dispatch }) {
        const axios = await initMMIOaxios()
        const resp = await axios.post('/apis_user/get_sub_users')
        commit('UPDATE_SUB_USERS', resp.data)
        dispatch('executeUpdateActualUsageLog', { moduleName: 'TEAM_MEMBERS' })
        return resp.data
    },

    async ssoFeatureMonkeyRoadmap({ state }) {
        try {
            await window.$loadScript('https://smaartrio.b-cdn.net/js/jsrsasign.js')
            if (typeof window.KJUR === 'undefined') {
                return
            }

            var Header = JSON.stringify({ alg: 'HS256', typ: 'JWT' })
            // add user data in payload
            var user_data = JSON.stringify({
                full_name: state.AppActiveUser.displayName,
                email: state.AppActiveUser.user_login_email,
                picture: state.AppActiveUser.brand_logo
            })
            //add your secret
            var secret = 'jJU8auPI1ze1atGTZ6I5qQ'
            var token = KJUR.jws.JWS.sign('HS256', Header, user_data, secret)
            return 'https://roadmap.marketingmaster.io/sso/' + token
        } catch (e) {
            __console.log(e)
        }
    },

    async ssoLoginToSmaartrIODashboard({ state }) {
        await window.$loadScript('https://smaartrio.b-cdn.net/js/jsrsasign.js')
        if (typeof window.KJUR === 'undefined') {
            return
        }
        const header = JSON.stringify({ alg: 'HS256', typ: 'JWT' })
        // add user data in payload
        const {
            displayName: name,
            user_login_email: email,
            brand_logo: avatar
        } = state.AppActiveUser
        const tNow = KJUR.jws.IntDate.get('now') //<-- Time token is issued
        const tEnd = KJUR.jws.IntDate.get('now') + 60 * 5 //<-- expiration time is maximum of 5 minutes
        const payload = JSON.stringify({
            name,
            email,
            avatar,
            exp: tEnd,
            iat: tNow
        })

        //add your secret
        const secret = process.env.VUE_APP_SMAARTRIO_KEY
        const token = KJUR.jws.JWS.sign('HS256', header, payload, { rstr: secret })
        return 'https://dash.smaartr.io?token=' + token
    },

    async ssoLoginToMMIOAcademy({ state }) {
        await window.$loadScript('https://smaartrio.b-cdn.net/js/jsrsasign.js')
        if (typeof window.KJUR === 'undefined') {
            return
        }
        const header = JSON.stringify({ alg: 'HS256', typ: 'JWT' })
        // add user data in payload
        const auto_enroll = ['FWJ3APi4Xyh2B8uF4qDc']
        const {
            displayName: name,
            user_login_email: email,
            brand_logo: avatar
        } = state.AppActiveUser
        const tNow = KJUR.jws.IntDate.get('now') //<-- Time token is issued
        const tEnd = KJUR.jws.IntDate.get('now') + 60 * 5 //<-- expiration time is maximum of 5 minutes
        const payload = JSON.stringify({
            name,
            email,
            avatar,
            exp: tEnd,
            iat: tNow,
            auto_enroll
        })

        //add your secret
        const secret = process.env.VUE_APP_MMIO_ACADEMY
        const token = KJUR.jws.JWS.sign('HS256', header, payload, { rstr: secret })
        return 'https://mmio.smaartr.io/courses/FWJ3APi4Xyh2B8uF4qDc?token=' + token
    },

    async ssoLoginToMMIOConnect({ state }) {
        try {
            await window.$loadScript('https://smaartrio.b-cdn.net/js/jsrsasign.js')
            if (typeof window.KJUR === 'undefined') {
                return
            }

            var Header = JSON.stringify({ alg: 'HS256', typ: 'JWT' })
            // add user data in payload
            var user_data = JSON.stringify({
                full_name: state.AppActiveUser.displayName,
                email: state.AppActiveUser.user_login_email,
                picture: state.AppActiveUser.brand_logo
            })
            //add your secret
            var secret = 'jJU8auPI1ze1atGTZ6I5qQ'
            var token = KJUR.jws.JWS.sign('HS256', Header, user_data, secret)
            return 'https://connect.marketingmaster.io/signin?token=' + token
        } catch (e) {
            __console.log(e)
        }
    },

    async getAccessLevels({ commit }) {
        const axios = await initMMIOaxios()
        const { data } = await axios.post('/apis_user/get_user_access_levels')
        commit('UPDATE_USER_ACCESSLEVELS', data)
        return data
    }
}

export default userAccountActions

//*************** HELPER FUNCTIONS ***************//

async function get_user_groups() {
    try {
        const axios = await initMMIOaxios()
        const { data } = await axios.get('/apis_user/get_user_groups')
        return data
    } catch (e) {}
}

async function get_user_accounts() {
    try {
        const axios = await initMMIOaxios()
        const { data } = await axios.get('/apis_user/get_user_accounts')
        const businessAccountUser = {
            id: '7777777',
            facebook_rx_config_id: '3',
            user_id: '1',
            access_token: '',
            name: 'Business Account User',
            email: '',
            fb_id: '',
            add_date: '',
            deleted: '0',
            need_to_delete: '0',
            valid_access_token: true,
            picture: 'https://mmio-store.b-cdn.net/main/businessman.png'
        }
        return {
          data: [...data.data, ...[businessAccountUser]]
        }
    } catch (e) {}
}

async function get_packages() {
    try {
        const axios = await initMMIOaxios()
        const { data } = await axios.get('/apis_user/get_packages')
        return data
    } catch (e) {}
}

async function get_modules() {
    try {
        const axios = await initMMIOaxios()
        const { data } = await axios.get('/apis_user/get_modules')
        return data
    } catch (e) {}
}

async function get_usage_log() {
    try {
        const axios = await initMMIOaxios()
        const { data } = await axios.get('/apis_user/get_usage_log')
        return data
    } catch (e) {}
}

async function get_transaction_history() {
    try {
        const axios = await initMMIOaxios()
        const { data } = await axios.get('/apis_user/get_transaction_history')
        return data
    } catch (e) {}
}

function ___(val) {
    return JSON.parse(JSON.stringify(val))
}

async function get_user_tickets(data) {
    try {
        const axios = await initMMIOaxios()
        const { data } = await axios.post('/apis_user/get_user_tickets_notice', data)
        return data
    } catch (e) {}
}

function sortObjectKeys(obj) {
    if (obj == null || obj == undefined) {
        return obj
    }
    if (typeof obj != 'object') {
        // it is a primitive: number/string (in an array)
        return obj
    }
    return Object.keys(obj)
        .sort()
        .reduce((acc, key) => {
            if (Array.isArray(obj[key])) {
                acc[key] = obj[key].map(sortObjectKeys)
            } else if (typeof obj[key] === 'object') {
                acc[key] = sortObjectKeys(obj[key])
            } else {
                acc[key] = obj[key]
            }
            return acc
        }, {})
}

function hashObject(Obj) {
    let SortedObject = sortObjectKeys(Obj)
    let jsonstring = JSON.stringify(SortedObject, function (k, v) {
        return v === undefined ? 'undef' : v
    })
    let jsonstringNoWhitespace = jsonstring.replace(/\s+/g, '')
    return window.MD5(jsonstringNoWhitespace)
}

function registerTransientWindowData(key, value) {
    window[key] = value
    setTimeout(() => {
        __console.log(`Deleting ${key}`)
        delete window[key]
    }, 50 * 60 * 1000)
}
