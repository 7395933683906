import ecom_mutations from './ecommerce/mutations';
import moduleOpenAi from '@/store/openai/moduleOpenAi.js';
import booking from './mutations/booking.js';
import components from './mutations/components.js';
import ecommerce from './mutations/ecommerce.js';
import ecomPageBuilder from './mutations/ecomPageBuilder.js';
import emailFlow from './mutations/emailFlow.js';
import fbAutomations from './mutations/fbAutomations.js';
import flowsCommon from './mutations/flowsCommon.js';
import gmb from './mutations/gmb.js';
import instagram from './mutations/instagram.js';
import integrations from './mutations/integrations.js';
import landingPages from './mutations/landingPages.js';
import messengerIgFlow from './mutations/messengerIgFlow.js';
import notifications from './mutations/notifications.js';
import omniChannel from './mutations/omniChannel.js';
import openAiEmbeddings from './mutations/openAiEmbeddings.js';
import smsMarketing from './mutations/smsMarketing.js';
import suitcases from './mutations/suitcases.js';
import telegram from './mutations/telegram.js';
import ui from './mutations/ui.js';
import userAccount from './mutations/userAccount.js';
import whatsapp from './mutations/whatsapp.js';
import webChat from './mutations/webChat';

export default {
	...booking,
	...components,
	...ecommerce,
	...ecomPageBuilder,
	...emailFlow,
	...fbAutomations,
	...flowsCommon,
	...gmb,
	...instagram,
	...integrations,
	...landingPages,
	...messengerIgFlow,
	...notifications,
	...omniChannel,
	...openAiEmbeddings,
	...smsMarketing,
	...suitcases,
	...telegram,
	...ui,
	...userAccount,
	...whatsapp,
	...webChat,
	...ecom_mutations,
	...moduleOpenAi.mutations
}